export default {
    "name": "RecalculateSamplesModalAnalyses",
    "kind": "HoudiniQuery",
    "hash": "1549d60736d8ae15264a7906665a2b2945afcda10b0f122fb7a86c923e2fd810",

    "raw": `query RecalculateSamplesModalAnalyses($filter: AnalysisFilter) {
  analyses(filter: $filter, pagination: {pageSize: 0}) {
    data {
      ...AnalysisReturnData
      id
    }
  }
}

fragment AnalysisReturnData on Analysis {
  id
  analysisType
  name
  active
  testingPeriod
  category
  visibleGroup {
    id
    name
  }
  groupSamples
  requireAuthentication
  instructions
  createdProduct {
    id
    name
  }
  batchVolume
  batchUnit
  inUseAtPlantIDs
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $filter, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "analysisType": {
                                        "type": "AnalysisType",
                                        "keyRaw": "analysisType",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "testingPeriod": {
                                        "type": "Float",
                                        "keyRaw": "testingPeriod",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "visibleGroup": {
                                        "type": "Group",
                                        "keyRaw": "visibleGroup",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "groupSamples": {
                                        "type": "Boolean",
                                        "keyRaw": "groupSamples",
                                        "visible": true
                                    },

                                    "requireAuthentication": {
                                        "type": "Boolean",
                                        "keyRaw": "requireAuthentication",
                                        "visible": true
                                    },

                                    "instructions": {
                                        "type": "String",
                                        "keyRaw": "instructions",
                                        "visible": true
                                    },

                                    "createdProduct": {
                                        "type": "Product",
                                        "keyRaw": "createdProduct",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "batchVolume": {
                                        "type": "Float",
                                        "keyRaw": "batchVolume",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "batchUnit": {
                                        "type": "String",
                                        "keyRaw": "batchUnit",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "AnalysisReturnData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "AnalysisFilter"
        },

        "types": {
            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=f4197b383c0f81514061e33f1a0068398c6931fac93fbe17cd4487eb5b098ae6";