<script lang="ts">
	import type { i18n } from 'i18next'
	import type { PreviewConfiguration, AlertSubscription, AlertTypeMap } from 'utility/alert-subscription-helper'

	import PreviewButton from './PreviewButton.svelte'
	import Input from '@isoftdata/svelte-input'
	import { klona } from 'klona'
	import { getContext, createEventDispatcher } from 'svelte'

	const { t: translate } = getContext<i18n>('i18next')
	const dispatch = createEventDispatcher<{ preview: PreviewConfiguration }>()

	const defaultPreviewConfiguration = Object.freeze({
		documentId: '',
		scheduleId: null,
		date: '',
		loadedPreview: null,
	})

	let preview: PreviewConfiguration = klona(defaultPreviewConfiguration)
	export let alertSubscription: AlertSubscription
	export let alertTypeMap: AlertTypeMap
	export let isLoading: boolean = false
	let canPreviewAlert: boolean = false
</script>

<form on:submit|preventDefault={() => dispatch('preview', preview)}>
	<div class="form-row">
		<div class="col-12 col-sm-8 col-md-6 col-lg-5">
			<Input
				label="{alertSubscription.type ? alertTypeMap.get(alertSubscription.type)?.name : translate('common:document', 'Document')} #"
				type="number"
				bind:value={preview.documentId}
			>
				<svelte:fragment slot="append">
					<PreviewButton
						{alertSubscription}
						{alertTypeMap}
						{preview}
						{isLoading}
						bind:canPreviewAlert
					/>
				</svelte:fragment>
			</Input>
		</div>
	</div>
</form>
