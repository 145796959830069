/**
* @param  {Array} list
* @param  {Map} plantMap
* @param  {String} listKeyName
*/

export default (list, plantMap, listKeyName) => {
	let listGroupedByPlant = {}

	for (const item of list) {
		const id = item.plantId

		if (!listGroupedByPlant[id]) {
			const plant = plantMap.get(id)
			listGroupedByPlant[id] = { ...plant, optGroupLabel: `${plant.code} - ${plant.name}` }
			listGroupedByPlant[id][listKeyName] = []
		}

		listGroupedByPlant[id][listKeyName].push(item)
	}

	return listGroupedByPlant
}
