import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/WorkOrderSearch'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class WorkOrderSearchStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "WorkOrderSearchStore",
			variables: false,
		})
	}
}

export async function load_WorkOrderSearch(params) {
	await initClient()

	const store = new WorkOrderSearchStore()

	await store.fetch(params)

	return {
		WorkOrderSearch: store,
	}
}
