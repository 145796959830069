/** The the acceptability of a value for a given Analysis Option. */
export const Acceptability = {
    "ALLOWED": "ALLOWED",
    "INVALID": "INVALID",
    "MARGINAL": "MARGINAL",
    "NOT_CALCULATED": "NOT_CALCULATED",
    "UNACCEPTABLE": "UNACCEPTABLE"
};

/** The outcome of the access attempt */
export const AccessStatus = {
    "ACCOUNT_DEACTIVATED": "ACCOUNT_DEACTIVATED",
    "ACCOUNT_LOCKED": "ACCOUNT_LOCKED",
    "ACCOUNT_PENDING_ACTIVATION": "ACCOUNT_PENDING_ACTIVATION",
    "CONNECTIVITY_ERROR": "CONNECTIVITY_ERROR",
    "GRANTED": "GRANTED",
    "INSUFFICIENT_PRIVILEGES": "INSUFFICIENT_PRIVILEGES",
    "INVALID_ACCOUNT": "INVALID_ACCOUNT",
    "INVALID_PASSWORD": "INVALID_PASSWORD",
    "MODULE_MISSING": "MODULE_MISSING"
};

/** What type of system access was being requested */
export const AccessType = {
    "LOGIN": "LOGIN",
    "PASSWORD_CHANGE": "PASSWORD_CHANGE",
    "PERMISSION_VERIFICATION": "PERMISSION_VERIFICATION",
    "SESSION_UNLOCK": "SESSION_UNLOCK"
};

/**  */
export const AlertMethod = {
    "EMAIL": "EMAIL",
    "SHOW": "SHOW",
    "TEXT": "TEXT"
};

export const AlertStatus = {
    "CANCELLED": "CANCELLED",
    "PROCESSING": "PROCESSING",
    "READY": "READY",
    "SENT": "SENT"
};

/**  */
export const AlertTiming = {
    "CLOSED": "CLOSED",
    "CREATED": "CREATED",
    "DELETED": "DELETED",
    "EXPIRATION_APPROACHING": "EXPIRATION_APPROACHING",
    "MODIFIED": "MODIFIED",
    "OPENED": "OPENED",
    "SCHEDULED": "SCHEDULED",
    "VERIFICATION_EXPIRATION_APPROACHING": "VERIFICATION_EXPIRATION_APPROACHING"
};

/**  */
export const AlertType = {
    "INVESTIGATION": "INVESTIGATION",
    "SAMPLE": "SAMPLE",
    "SAMPLE_VALUE": "SAMPLE_VALUE",
    "SAMPLE_VALUE_SUMMARY": "SAMPLE_VALUE_SUMMARY",
    "SUPPLIER_DOCUMENT": "SUPPLIER_DOCUMENT",
    "USER_AUTHENTICATION": "USER_AUTHENTICATION",
    "USER_AUTHENTICATION_SUMMARY": "USER_AUTHENTICATION_SUMMARY",
    "WORK_ORDER": "WORK_ORDER"
};

export const AnalysisType = {
    "RECIPE": "RECIPE",
    "TESTING": "TESTING"
};

/** Any or All */
export const AnyAllEnum = {
    "ALL": "ALL",
    "ANY": "ANY"
};

/** Whether this choice/threshold is applicable for a given threshold test */
export const Applicability = {
    "APPLICABLE": "APPLICABLE",
    "DEACTIVATED": "DEACTIVATED",
    "WRONG_BATCH": "WRONG_BATCH",
    "WRONG_PLANT": "WRONG_PLANT",
    "WRONG_PRODUCT": "WRONG_PRODUCT",
    "WRONG_SEVERITY_CLASS": "WRONG_SEVERITY_CLASS"
};

/** Defines what this choice/threshold means abstractly:

BOUNDARY: values that don't match this threshold are considered out of bounds

UNACCEPTABLE: values that don't match this threshold are considered not acceptable

MARGINAL: values that don't match this threshold are considered marginal

ALLOWED: this value is allowed/acceptable.  Use this boundary type for choices in a dropdown */
export const BoundaryType = {
    "ALLOWED": "ALLOWED",
    "BOUNDARY": "BOUNDARY",
    "MARGINAL": "MARGINAL",
    "UNACCEPTABLE": "UNACCEPTABLE"
};

/** How this choice/threshold should be compared to user values */
export const ConstraintType = {
    "MAXIMUM": "MAXIMUM",
    "MINIMUM": "MINIMUM",
    "NONE": "NONE",
    "NOT_EQUAL": "NOT_EQUAL"
};

/**  */
export const ConversionType = {
    "RAW": "RAW",
    "SQL": "SQL"
};

/**  */
export const DateRange = {
    "CUSTOM": "CUSTOM",
    "LAST_7_DAYS": "LAST_7_DAYS",
    "LAST_30_DAYS": "LAST_30_DAYS",
    "LAST_90_DAYS": "LAST_90_DAYS",
    "LAST_365_DAYS": "LAST_365_DAYS",
    "PREVIOUS_MONTH": "PREVIOUS_MONTH",
    "PREVIOUS_QUARTER": "PREVIOUS_QUARTER",
    "PREVIOUS_WEEK": "PREVIOUS_WEEK",
    "PREVIOUS_YEAR": "PREVIOUS_YEAR",
    "THIS_MONTH": "THIS_MONTH",
    "THIS_QUARTER": "THIS_QUARTER",
    "THIS_WEEK": "THIS_WEEK",
    "THIS_YEAR": "THIS_YEAR",
    "TODAY": "TODAY",
    "YESTERDAY": "YESTERDAY"
};

/**  */
export const DefaultStatus = {
    "CANCELLED": "CANCELLED",
    "IGNORED": "IGNORED",
    "OPEN": "OPEN",
    "RESOLVED": "RESOLVED"
};

/** Describes the defaultValue in the following ways:FIXED: a fixed/static default valuePER_BATCH: a fixed value that should be multiplied by the number of batches being produced (recipes only)SQL: the description is a MySQL statement that evaluates to a default valueSTATIC_SQL: similar to a SQL default, but contains non-deterministic calculations that should only be calculated once */
export const DefaultType = {
    "FIXED": "FIXED",
    "PER_BATCH": "PER_BATCH",
    "SQL": "SQL",
    "STATIC_SQL": "STATIC_SQL"
};

export const DestinationType = {
    "DIRECTORY": "DIRECTORY",
    "EMAIL": "EMAIL",
    "EMAILCSV": "EMAILCSV",
    "PRINTER": "PRINTER"
};

/** The current state of the document.  Once data has been collected, the document will move from Open to Sampled.  Closed means data entry is complete, and cancelled means the document will not be performed. */
export const DocumentStatus = {
    "CANCELLED": "CANCELLED",
    "CLOSED": "CLOSED",
    "OPEN": "OPEN",
    "SAMPLED": "SAMPLED"
};

/** Defines if the user can choose a value for this field.  Limited means they cannot type a new entry in the dropdowns */
export const Editibility = {
    /**
     * User can change the value, but not add new values to the dropdown
    */
    "LIMITED": "LIMITED",

    /**
     * User cannot change the value
    */
    "READONLY": "READONLY",

    /**
     * User can change the value and add new values to the dropdown
    */
    "UNRESTRICTED": "UNRESTRICTED"
};

export const EntityTagType = {
    "ANALYSIS": "ANALYSIS",
    "LOCATION": "LOCATION",
    "PLANT": "PLANT",
    "PRODUCT": "PRODUCT",
    "SUPPLIER": "SUPPLIER"
};

/** How data is expected to be entered. Some methods are only for recipes. */
export const EntryMethod = {
    "MACHINE_ENTERED": "MACHINE_ENTERED",
    "TOTAL_QUANTITY": "TOTAL_QUANTITY",
    "USER_ENTERED": "USER_ENTERED"
};

/**  */
export const ExpansionMethod = {
    "NEAREST_LOCATIONS": "NEAREST_LOCATIONS",
    "NONE": "NONE"
};

/** Type of File */
export const FileType = {
    "EXECUTABLE": "EXECUTABLE",
    "IMAGE": "IMAGE",
    "PDF": "PDF",
    "REPORT": "REPORT",
    "TEXT": "TEXT",
    "UNKNOWN": "UNKNOWN"
};

/** (Recipes only) controls whether an ingredient can have quantity/batches/lots entered */
export const InventoryMode = {
    "BASIC": "BASIC",
    "MULTIPLE_LOTS": "MULTIPLE_LOTS",
    "NONE": "NONE",
    "SINGLE_LOT": "SINGLE_LOT"
};

/** Current status of the investigation */
export const InvestigationStatus = {
    "CANCELLED": "CANCELLED",
    "IGNORED": "IGNORED",
    "OPEN": "OPEN",
    "RESOLVED": "RESOLVED"
};

/**  */
export const InvestigationTriggerPeriodType = {
    "DAY": "DAY",
    "HOUR": "HOUR",
    "MINUTE": "MINUTE",
    "SAMPLE_VALUE": "SAMPLE_VALUE"
};

/**  */
export const InvestigationTriggerResultStatus = {
    "ACCEPTABLE": "ACCEPTABLE",
    "ANY_BUT_ACCEPTABLE": "ANY_BUT_ACCEPTABLE",
    "INVALID": "INVALID",
    "MARGINAL": "MARGINAL",
    "UNACCEPTABLE": "UNACCEPTABLE",
    "WESTERN_ELECTRIC_RULE_1": "WESTERN_ELECTRIC_RULE_1",
    "WESTERN_ELECTRIC_RULE_2": "WESTERN_ELECTRIC_RULE_2",
    "WESTERN_ELECTRIC_RULE_3": "WESTERN_ELECTRIC_RULE_3",
    "WESTERN_ELECTRIC_RULE_4": "WESTERN_ELECTRIC_RULE_4"
};

/** The current restriction applied to the option, including "None", if there are no applicable restrictions. */
export const OutcomeOrNone = {
    "HIDDEN": "HIDDEN",
    "INACTIVE": "INACTIVE",
    "NONE": "NONE",
    "READONLY": "READONLY",
    "REQUIRED_TO_CLOSE": "REQUIRED_TO_CLOSE",
    "REQUIRED_TO_PERFORM": "REQUIRED_TO_PERFORM"
};

/** What this rule will do if it is triggered */
export const OutcomeType = {
    "HIDDEN": "HIDDEN",
    "INACTIVE": "INACTIVE",
    "READONLY": "READONLY",
    "REQUIRED_TO_CLOSE": "REQUIRED_TO_CLOSE",
    "REQUIRED_TO_PERFORM": "REQUIRED_TO_PERFORM"
};

/**  */
export const ParameterType = {
    "BOOLEAN": "BOOLEAN",
    "CHOICE": "CHOICE",
    "CURRENCY": "CURRENCY",
    "DATE": "DATE",
    "DATE_TIME": "DATE_TIME",
    "KEY_CHOICE": "KEY_CHOICE",
    "NUMBER": "NUMBER",
    "SQL_CLAUSE": "SQL_CLAUSE",
    "STRING": "STRING",
    "TIME": "TIME"
};

/** The scheduling requirement. Use custom for a specific period. */
export const PeriodType = {
    "ANNUALLY": "ANNUALLY",
    "CUSTOM": "CUSTOM",
    "DAILY": "DAILY",
    "MONTHLY": "MONTHLY",
    "QUARTERLY": "QUARTERLY",
    "SEMIANNUALLY": "SEMIANNUALLY",
    "WEEKLY": "WEEKLY"
};

/** All permission level set to user/group */
export const PermissionValue = {
    "GLOBAL": "GLOBAL",
    "NONE": "NONE",
    "PLANT": "PLANT"
};

/** Whether a batch is required, shown, or hidden */
export const ProductBatchRequired = {
    "HIDE": "HIDE",
    "REQUIRE": "REQUIRE",
    "SHOW": "SHOW"
};

/** Current status of this batch, including whether or not the batch has expired or been deactivated */
export const ProductBatchStatus = {
    "ACTIVE": "ACTIVE",
    "EXPIRED": "EXPIRED",
    "INACTIVE": "INACTIVE"
};

/** Order by name ascending or descending */
export const ProductOrderBy = {
    "NAME_ASC": "NAME_ASC",
    "NAME_DESC": "NAME_DESC"
};

/** Whether this product represents an ingredient (consumed by recipes), or a finished product (produced at the plant, and possibly consumed by other recipes) */
export const ProductType = {
    "INGREDIENT": "INGREDIENT",
    "PRODUCT": "PRODUCT"
};

/** The type of record referred to by the record # */
export const RecordType = {
    "INVESTIGATION": "INVESTIGATION",
    "NONE": "NONE",
    "PLANT": "PLANT",
    "SAMPLE": "SAMPLE",
    "WORK_ORDER": "WORK_ORDER"
};

/** Default: dateCreated_DESC */
export const ReportQueueOrderBy = {
    "createdBy_ASC": "createdBy_ASC",
    "createdBy_DESC": "createdBy_DESC",
    "dateCreated_ASC": "dateCreated_ASC",
    "dateCreated_DESC": "dateCreated_DESC",
    "destinationType_ASC": "destinationType_ASC",
    "destinationType_DESC": "destinationType_DESC",
    "destination_ASC": "destination_ASC",
    "destination_DESC": "destination_DESC",
    "error_ASC": "error_ASC",
    "error_DESC": "error_DESC",
    "executionTime_ASC": "executionTime_ASC",
    "executionTime_DESC": "executionTime_DESC",
    "name_ASC": "name_ASC",
    "name_DESC": "name_DESC",
    "notes_ASC": "notes_ASC",
    "notes_DESC": "notes_DESC",
    "quantity_ASC": "quantity_ASC",
    "quantity_DESC": "quantity_DESC",
    "status_ASC": "status_ASC",
    "status_DESC": "status_DESC",
    "type_ASC": "type_ASC",
    "type_DESC": "type_DESC"
};

/** The status of the print job */
export const ReportQueueStatus = {
    "CANCELLED": "CANCELLED",
    "FAILED": "FAILED",
    "READY": "READY",
    "SENT": "SENT"
};

/**  */
export const ReportSubscriptionDestinationType = {
    "DIRECTORY": "DIRECTORY",
    "EMAIL": "EMAIL",
    "PRINTER": "PRINTER"
};

/** Whether this rule applies in the presence or absence of given tags or values. */
export const RestrictionType = {
    "ABSENT": "ABSENT",
    "PRESENT": "PRESENT"
};

/** For any given value, this represents the calculated acceptability */
export const ResultStatus = {
    "ALLOWED": "ALLOWED",
    "ERROR": "ERROR",
    "NOT_CALCULATED": "NOT_CALCULATED",
    "OUT_OF_BOUNDS": "OUT_OF_BOUNDS",
    "WARNING": "WARNING"
};

/**  */
export const RetestMode = {
    "MANUAL": "MANUAL",
    "NEW_DOCUMENT": "NEW_DOCUMENT",
    "SAME_DOCUMENT": "SAME_DOCUMENT"
};

/** The type of date range to filter by */
export const SampleDateRangeType = {
    "CREATED": "CREATED",
    "PERFORMED": "PERFORMED"
};

export const SampleOrderBy = {
    "analysisName_ASC": "analysisName_ASC",
    "analysisName_DESC": "analysisName_DESC",
    "comments_ASC": "comments_ASC",
    "comments_DESC": "comments_DESC",
    "due_ASC": "due_ASC",
    "due_DESC": "due_DESC",
    "findings_ASC": "findings_ASC",
    "findings_DESC": "findings_DESC",
    "id_ASC": "id_ASC",
    "id_DESC": "id_DESC",
    "location_ASC": "location_ASC",
    "location_DESC": "location_DESC",
    "performed_ASC": "performed_ASC",
    "performed_DESC": "performed_DESC",
    "plantName_ASC": "plantName_ASC",
    "plantName_DESC": "plantName_DESC",
    "productName_ASC": "productName_ASC",
    "productName_DESC": "productName_DESC",
    "scheduled_ASC": "scheduled_ASC",
    "scheduled_DESC": "scheduled_DESC",
    "status_ASC": "status_ASC",
    "status_DESC": "status_DESC",
    "tagNumber_ASC": "tagNumber_ASC",
    "tagNumber_DESC": "tagNumber_DESC"
};

/**  */
export const SchedulePeriodType = {
    "DAY": "DAY",
    "HOUR": "HOUR",
    "MONTH": "MONTH",
    "QUARTER": "QUARTER",
    "WEEK": "WEEK",
    "YEAR": "YEAR"
};

export const ScheduleType = {
    "DAILY": "DAILY",
    "DAILY_WEEKDAYS": "DAILY_WEEKDAYS",
    "MONTHLY": "MONTHLY",
    "QUARTERLY": "QUARTERLY",
    "WEEKLY": "WEEKLY",
    "YEARLY": "YEARLY"
};

/** What level this search should be shown at */
export const SeachScope = {
    "GLOBAL": "GLOBAL",
    "SITE": "SITE",
    "USER": "USER"
};

/** The current status of this session if the access attempt started one */
export const SessionStatus = {
    "CLOSED": "CLOSED",
    "EXPIRED": "EXPIRED",
    "OPEN": "OPEN"
};

export const SettingType = {
    "ACCESSIBILITY": "ACCESSIBILITY",
    "HIDDEN": "HIDDEN",
    "IMPORTANT_CONFIGURATION": "IMPORTANT_CONFIGURATION",
    "INTERFACE_HISTORY": "INTERFACE_HISTORY",
    "INTERFACE_PREFERENCE": "INTERFACE_PREFERENCE",
    "OPTIONAL_CONFIGURATION": "OPTIONAL_CONFIGURATION",
    "PREFERENCE": "PREFERENCE"
};

/** Should we show products/ingredients/both/neither */
export const ShowProduct = {
    "BOTH": "BOTH",
    "INGREDIENT": "INGREDIENT",
    "NONE": "NONE",
    "PRODUCT": "PRODUCT"
};

export const SourceType = {
    "CRYSTAL": "CRYSTAL",
    "QUERY": "QUERY"
};

/** Controls whether values shoud be compared to thresholds as a total or per batch value */
export const ThresholdType = {
    "FIXED": "FIXED",
    "PER_BATCH": "PER_BATCH"
};

/** The current status of the user account */
export const UserAccountStatus = {
    "ACTIVE": "ACTIVE",
    "DEACTIVATED": "DEACTIVATED",
    "LOCKED": "LOCKED",
    "PENDING_ACTIVATION": "PENDING_ACTIVATION"
};

/** Represents the type of data stored in the option value */
export const ValueType = {
    "BOOLEAN": "BOOLEAN",
    "CHOICE": "CHOICE",
    "CURRENCY": "CURRENCY",
    "DATE": "DATE",
    "DATETIME": "DATETIME",
    "INTEGER": "INTEGER",
    "NUMBER": "NUMBER",
    "TEXT": "TEXT",
    "TIME": "TIME"
};

/** The type of permission verification to perform. */
export const VerificationAction = {
    "AUTH_SAMPLE_ON_WORK_ORDER": "AUTH_SAMPLE_ON_WORK_ORDER",
    "VERIFY_WORK_ORDER": "VERIFY_WORK_ORDER"
};

/** Whether this choice/threshold was violated for a given threshold test */
export const Violated = {
    "FALSE": "FALSE",
    "NOT_CALCULATED": "NOT_CALCULATED",
    "TRUE": "TRUE"
};