import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/TestRulesModalPlantTags'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class TestRulesModalPlantTagsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "TestRulesModalPlantTagsStore",
			variables: true,
		})
	}
}

export async function load_TestRulesModalPlantTags(params) {
	await initClient()

	const store = new TestRulesModalPlantTagsStore()

	await store.fetch(params)

	return {
		TestRulesModalPlantTags: store,
	}
}
