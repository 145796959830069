export default {
    "name": "WoTypeConfigData",
    "kind": "HoudiniQuery",
    "hash": "0816661d9d549a8649db1203d750234b7bfc75aacfbb466c263c1baaf7449a36",

    "raw": `query WoTypeConfigData($pagination: PaginatedInput, $analysesFilter: AnalysisFilter) {
  workOrderTypes(pagination: $pagination) {
    data {
      defaultAnalysis {
        id
        name
      }
      daysTillDue
      daysTillVerificationDue
      defaultGroup {
        id
        name
      }
      defaultVerificationGroup {
        id
        name
      }
      visibleGroup {
        id
        name
      }
      id
      verificationRequired
      titleRequired
      productBatchRequired
      showDue
      name
      showProduct
      showSamplingDetail
      showTestingDetail
      showLocation
      showLocationDescription
      showSampling
      showTasks
      showRecipes
      inUseAtPlantIDs
      workOrderCount
    }
  }
  plants(pagination: $pagination) {
    data {
      id
      code
      name
    }
  }
  analyses(pagination: $pagination, filter: $analysesFilter) {
    data {
      id
      name
    }
  }
  groups(pagination: $pagination) {
    data {
      id
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "workOrderTypes": {
                "type": "WorkOrderTypeResponse",
                "keyRaw": "workOrderTypes(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "WorkOrderType",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "defaultAnalysis": {
                                        "type": "Analysis",
                                        "keyRaw": "defaultAnalysis",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "daysTillDue": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "daysTillDue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "daysTillVerificationDue": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "daysTillVerificationDue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultGroup": {
                                        "type": "Group",
                                        "keyRaw": "defaultGroup",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "defaultVerificationGroup": {
                                        "type": "Group",
                                        "keyRaw": "defaultVerificationGroup",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "visibleGroup": {
                                        "type": "Group",
                                        "keyRaw": "visibleGroup",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "verificationRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "verificationRequired",
                                        "visible": true
                                    },

                                    "titleRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "titleRequired",
                                        "visible": true
                                    },

                                    "productBatchRequired": {
                                        "type": "ProductBatchRequired",
                                        "keyRaw": "productBatchRequired",
                                        "visible": true
                                    },

                                    "showDue": {
                                        "type": "Boolean",
                                        "keyRaw": "showDue",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "showProduct": {
                                        "type": "ShowProduct",
                                        "keyRaw": "showProduct",
                                        "visible": true
                                    },

                                    "showSamplingDetail": {
                                        "type": "Boolean",
                                        "keyRaw": "showSamplingDetail",
                                        "visible": true
                                    },

                                    "showTestingDetail": {
                                        "type": "Boolean",
                                        "keyRaw": "showTestingDetail",
                                        "visible": true
                                    },

                                    "showLocation": {
                                        "type": "Boolean",
                                        "keyRaw": "showLocation",
                                        "visible": true
                                    },

                                    "showLocationDescription": {
                                        "type": "Boolean",
                                        "keyRaw": "showLocationDescription",
                                        "visible": true
                                    },

                                    "showSampling": {
                                        "type": "Boolean",
                                        "keyRaw": "showSampling",
                                        "visible": true
                                    },

                                    "showTasks": {
                                        "type": "Boolean",
                                        "keyRaw": "showTasks",
                                        "visible": true
                                    },

                                    "showRecipes": {
                                        "type": "Boolean",
                                        "keyRaw": "showRecipes",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    },

                                    "workOrderCount": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "workOrderCount",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $analysesFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "groups": {
                "type": "GroupResponse",
                "keyRaw": "groups(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Group",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "analysesFilter": "AnalysisFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c6f4cc0374fb4b6ad71efed9909008b48e0892f2a18b3a0576a2ac508dcc4487";