export default {
    "name": "AttachFileToProduct",
    "kind": "HoudiniMutation",
    "hash": "88f737b8bc95537032b78d351d96b2e9b62760e9976e7b8d7479d0aa42cce8cb",

    "raw": `mutation AttachFileToProduct($input: NewProductFile!) {
  attachFilesToProduct(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "attachFilesToProduct": {
                "type": "ProductFile",
                "keyRaw": "attachFilesToProduct(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "NewProductFile"
        },

        "types": {
            "NewProductFile": {
                "base64String": "String",
                "fileName": "String",
                "productId": "PositiveInt",
                "public": "Boolean",
                "rank": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=da15d187348a1175ea1fb2b10a1c16afcafa1c089119264f5c8006fc1fa623ce";