export default {
    "name": "AlertSubscriptionData",
    "kind": "HoudiniFragment",
    "hash": "f7b59bd3a106897479db35b562e624750223fea501f4f97b36d4584de4f5fb3e",

    "raw": `fragment AlertSubscriptionData on AlertSubscription {
  id
  emailAddresses
  phoneNumbers {
    number
    carrier {
      domain
      id
    }
  }
  active
  body
  description
  expirationHourOffset
  method
  rank
  requireAcceptable
  requireFailed
  requireMarginal
  requireMissing
  sendAcceptable
  sendFailed
  sendMarginal
  timing
  title
  type
  template {
    id
    name
  }
  plant {
    id
    code
    name
  }
  analysis {
    id
    name
  }
  location {
    id
    location
  }
  workOrderType {
    id
    name
  }
  investigationTrigger {
    id
    description
  }
  group {
    id
    name
  }
  schedule {
    id
    name
  }
  userAccount {
    id
    name
  }
  product {
    id
    name
  }
  __typename
}
`,

    "rootType": "AlertSubscription",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "Float",
                "keyRaw": "id",
                "visible": true
            },

            "emailAddresses": {
                "type": "EmailAddress",
                "keyRaw": "emailAddresses",
                "visible": true
            },

            "phoneNumbers": {
                "type": "PhoneContact",
                "keyRaw": "phoneNumbers",

                "selection": {
                    "fields": {
                        "number": {
                            "type": "String",
                            "keyRaw": "number",
                            "visible": true
                        },

                        "carrier": {
                            "type": "PhoneCarrier",
                            "keyRaw": "carrier",

                            "selection": {
                                "fields": {
                                    "domain": {
                                        "type": "String",
                                        "keyRaw": "domain",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "body": {
                "type": "String",
                "keyRaw": "body",
                "nullable": true,
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "nullable": true,
                "visible": true
            },

            "expirationHourOffset": {
                "type": "Float",
                "keyRaw": "expirationHourOffset",
                "visible": true
            },

            "method": {
                "type": "AlertMethod",
                "keyRaw": "method",
                "visible": true
            },

            "rank": {
                "type": "Float",
                "keyRaw": "rank",
                "visible": true
            },

            "requireAcceptable": {
                "type": "Boolean",
                "keyRaw": "requireAcceptable",
                "nullable": true,
                "visible": true
            },

            "requireFailed": {
                "type": "Boolean",
                "keyRaw": "requireFailed",
                "nullable": true,
                "visible": true
            },

            "requireMarginal": {
                "type": "Boolean",
                "keyRaw": "requireMarginal",
                "nullable": true,
                "visible": true
            },

            "requireMissing": {
                "type": "Boolean",
                "keyRaw": "requireMissing",
                "nullable": true,
                "visible": true
            },

            "sendAcceptable": {
                "type": "Boolean",
                "keyRaw": "sendAcceptable",
                "visible": true
            },

            "sendFailed": {
                "type": "Boolean",
                "keyRaw": "sendFailed",
                "visible": true
            },

            "sendMarginal": {
                "type": "Boolean",
                "keyRaw": "sendMarginal",
                "visible": true
            },

            "timing": {
                "type": "AlertTiming",
                "keyRaw": "timing",
                "visible": true
            },

            "title": {
                "type": "String",
                "keyRaw": "title",
                "nullable": true,
                "visible": true
            },

            "type": {
                "type": "AlertType",
                "keyRaw": "type",
                "visible": true
            },

            "template": {
                "type": "AlertTemplate",
                "keyRaw": "template",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Float",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "plant": {
                "type": "Plant",
                "keyRaw": "plant",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "analysis": {
                "type": "Analysis",
                "keyRaw": "analysis",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "location": {
                "type": "Location",
                "keyRaw": "location",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "location": {
                            "type": "String",
                            "keyRaw": "location",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "workOrderType": {
                "type": "WorkOrderType",
                "keyRaw": "workOrderType",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "investigationTrigger": {
                "type": "InvestigationTrigger",
                "keyRaw": "investigationTrigger",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Float",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "group": {
                "type": "Group",
                "keyRaw": "group",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "schedule": {
                "type": "Schedule",
                "keyRaw": "schedule",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userAccount": {
                "type": "UserAccount",
                "keyRaw": "userAccount",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "product": {
                "type": "Product",
                "keyRaw": "product",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=bebb0b97c2a22920e5f9270c648a79d3191e50798d23fbc1d76f4da132318f91";