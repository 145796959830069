import type { ActionReturn, Action } from 'svelte/action'
import type { Options } from 'split.js'
import Split from 'split.js'
import userLocalWritable from 'stores/user-local-writable'
import { browserEvent } from '@isoftdata/browser-event'
import { get } from 'svelte/store'

interface SplitActionArgs extends Omit<Options, 'gutter'> {
	/** Store the sizes to local storage using a userLocalWritable store */
	localStorageKey: string
	/** Store the sizes to local storage for this user */
	userAccountId: number
	/** The default sizes that will be reverted to on gutter double-click */
	defaultSizes: number[]
	/** If present, the elements to split. Otherwise, all child elements of this node are split. */
	elements?: Array<string | HTMLElement>
}

const splitAction: Action<HTMLElement, SplitActionArgs> = (node: HTMLElement, initArgs: SplitActionArgs): ActionReturn<SplitActionArgs> => {
	const { localStorageKey, userAccountId, defaultSizes, elements: initElements, direction: initDirection, ...splitArgs } = initArgs ?? {}
	const sizeStore = userLocalWritable<Array<number>>(userAccountId, localStorageKey, splitArgs.sizes ?? defaultSizes)
	const elements = initElements ? initElements : (Array.from(node.children) as Array<HTMLElement>)
	const direction = initDirection ?? 'horizontal'
	let removeSplitEventListener: (() => void) | undefined

	node.classList.add(`split-cards-${direction}`)

	if (elements.length !== defaultSizes.length) {
		console.warn('Split action: elements array length does not match the number of elements')
	}

	const split = Split(elements, {
		...splitArgs,
		// this will get any previously stored sizes
		sizes: get(sizeStore),
		minSize: splitArgs.minSize ?? 450,
		direction,
		gutter(_index, direction) {
			const gutter = document.createElement('div')
			gutter.classList.add('gutter', `gutter-${direction}`)

			removeSplitEventListener = browserEvent(gutter, 'dblclick', () => {
				split?.setSizes(defaultSizes)
			})

			return gutter
		},
		onDragEnd(sizes) {
			sizeStore.set(sizes)
		},
	})

	return {
		destroy() {
			removeSplitEventListener?.()
			node.classList.remove(`split-cards-${direction}`)
			split.destroy()
		},
	}
}

export default splitAction
