export default {
    "name": "GetDefaultSampleValues",
    "kind": "HoudiniQuery",
    "hash": "52182e454a0944a1b3b7a6e88bc4ed750a3b92b0956116334c56a2c93601d326",

    "raw": `query GetDefaultSampleValues($input: GetDefaultSampleValueComputation!) {
  getDefaultSampleValues(input: $input) {
    analysisId
    samples {
      analysisOptionId
      defaultValue
      result
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getDefaultSampleValues": {
                "type": "GetDefaultSampleValuesResponse",
                "keyRaw": "getDefaultSampleValues(input: $input)",

                "selection": {
                    "fields": {
                        "analysisId": {
                            "type": "PositiveInt",
                            "keyRaw": "analysisId",
                            "visible": true
                        },

                        "samples": {
                            "type": "DefaultSampleValueSample",
                            "keyRaw": "samples",

                            "selection": {
                                "fields": {
                                    "analysisOptionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisOptionId",
                                        "visible": true
                                    },

                                    "defaultValue": {
                                        "type": "String",
                                        "keyRaw": "defaultValue",
                                        "visible": true
                                    },

                                    "result": {
                                        "type": "String",
                                        "keyRaw": "result",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "GetDefaultSampleValueComputation"
        },

        "types": {
            "GetSampleValueDefaultSampleValue": {
                "analysisOptionId": "PositiveInt",
                "defaultValue": "String",
                "result": "String"
            },

            "GetSampleValueDefaultsWorkOrder": {
                "assignedToGroupId": "PositiveInt",
                "due": "DateTime",
                "favorite": "Boolean",
                "id": "PositiveInt",
                "instructions": "String",
                "internalNotes": "String",
                "plantId": "PositiveInt",
                "platesReadByUserId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "scheduled": "DateTime",
                "scheduledAnalysisId": "PositiveInt",
                "sequenceId": "PositiveInt",
                "status": "DocumentStatus",
                "title": "String",
                "verificationDue": "DateTime",
                "verifiedByUserId": "PositiveInt",
                "verifiedOn": "DateTime",
                "workOrderTypeId": "PositiveInt"
            },

            "GetSampleValueDefaultsSample": {
                "collectedByUserId": "PositiveInt",
                "due": "DateTime",
                "incubationBegan": "DateTime",
                "incubationEnded": "DateTime",
                "investigationId": "PositiveInt",
                "locationId": "PositiveInt",
                "performed": "DateTime",
                "plantId": "PositiveInt",
                "platesReadByUserId": "PositiveInt",
                "productId": "PositiveInt",
                "productionVolume": "Float",
                "scheduled": "DateTime",
                "scheduledAnalysisId": "PositiveInt",
                "sequenceId": "PositiveInt"
            },

            "GetDefaultSampleValueComputation": {
                "analysisId": "PositiveInt",
                "currentSampleValues": "GetSampleValueDefaultSampleValue",
                "currentWorkOrder": "GetSampleValueDefaultsWorkOrder",
                "sample": "GetSampleValueDefaultsSample"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=fcd796aacca1873ed4e390788e0ec4214971a6ccbb191b48b8cfb98f8146f399";