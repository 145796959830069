export default {
    "name": "WoUpdateWorkOrders",
    "kind": "HoudiniMutation",
    "hash": "8bc4a4deef665e6597d2143b93746f880d96ba8f736a665e59b773ea6b7080aa",

    "raw": `mutation WoUpdateWorkOrders($workOrders: [UpdateWorkOrder!]!) {
  updateWorkOrders(workOrders: $workOrders) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateWorkOrders": {
                "type": "WorkOrder",
                "keyRaw": "updateWorkOrders(workOrders: $workOrders)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "workOrders": "UpdateWorkOrder"
        },

        "types": {
            "NewSampleValue": {
                "analysisOptionId": "PositiveInt",
                "defaultValue": "String",
                "expiration": "Date",
                "lot": "String",
                "result": "String"
            },

            "NewSampleOnUnsavedWorkOrder": {
                "analysisId": "PositiveInt",
                "collectedByUserId": "PositiveInt",
                "comments": "String",
                "due": "DateTime",
                "findings": "String",
                "incubationBegan": "DateTime",
                "incubationEnded": "DateTime",
                "investigationId": "PositiveInt",
                "locationId": "PositiveInt",
                "performed": "DateTime",
                "plantId": "PositiveInt",
                "platesReadByUserId": "PositiveInt",
                "productId": "PositiveInt",
                "productionVolume": "Float",
                "sampleValuesToAdd": "NewSampleValue",
                "scheduled": "DateTime",
                "scheduledAnalysisId": "PositiveInt",
                "sequenceId": "PositiveInt",
                "sourceSampleId": "PositiveInt",
                "status": "DocumentStatus",
                "tagNumber": "String"
            },

            "UpdateSampleValue": {
                "analysisOptionId": "PositiveInt",
                "defaultValue": "String",
                "expiration": "Date",
                "id": "PositiveInt",
                "lot": "String",
                "result": "String"
            },

            "UpdateSample": {
                "analysisId": "PositiveInt",
                "collectedByUserId": "PositiveInt",
                "comments": "String",
                "due": "DateTime",
                "findings": "String",
                "id": "PositiveInt",
                "incubationBegan": "DateTime",
                "incubationEnded": "DateTime",
                "investigationId": "PositiveInt",
                "locationId": "PositiveInt",
                "performed": "DateTime",
                "plantId": "PositiveInt",
                "platesReadByUserId": "PositiveInt",
                "productId": "PositiveInt",
                "productionVolume": "Float",
                "sampleValuesToAdd": "NewSampleValue",
                "sampleValuesToUpdate": "UpdateSampleValue",
                "scheduled": "DateTime",
                "scheduledAnalysisId": "PositiveInt",
                "sequenceId": "PositiveInt",
                "sourceSampleId": "PositiveInt",
                "status": "DocumentStatus",
                "tagNumber": "String",
                "userAccessId": "PositiveInt",
                "workOrderId": "PositiveInt"
            },

            "UpdateWorkOrder": {
                "assignedToGroupId": "PositiveInt",
                "due": "DateTime",
                "favorite": "Boolean",
                "id": "PositiveInt",
                "instructions": "String",
                "internalNotes": "String",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "samplesToAdd": "NewSampleOnUnsavedWorkOrder",
                "samplesToRemove": "PositiveInt",
                "samplesToUpdate": "UpdateSample",
                "scheduled": "DateTime",
                "sourceWorkOrderId": "PositiveInt",
                "title": "String",
                "userAccessId": "PositiveInt",
                "verificationDue": "DateTime",
                "verifiedByUserId": "PositiveInt",
                "verifiedOn": "DateTime",
                "workOrderTypeId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b954874c5d547f8ddc6a2e25b2d1ec27290d61dd0e652ad9df7cc03a67de7b25";