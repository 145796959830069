//setting object is expected to look like this:
/* {
	  "category": "Sampling",
	  "name": "Show work order information in sample detail",
	  "settingType": "INTERFACE_HISTORY",
	  "newValue": "True"
	}
 */
export default (mediator, setting = {}) => {
	const mutation = `#graphql
		mutation SetUserSetting($setting: SettingChange!) {
			setUserSetting(value: $setting)
		}
	`

	return mediator.call('apiFetch', mutation, { setting })
}
