import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/WoTypeConfigData'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class WoTypeConfigDataStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "WoTypeConfigDataStore",
			variables: false,
		})
	}
}

export async function load_WoTypeConfigData(params) {
	await initClient()

	const store = new WoTypeConfigDataStore()

	await store.fetch(params)

	return {
		WoTypeConfigData: store,
	}
}
