export default {
    "name": "DeleteProductEntityTags",
    "kind": "HoudiniMutation",
    "hash": "a9e9aabd8217458eaed05ce65a156bf914fe519f9c676fd1d5ca3f94b1dd6f64",

    "raw": `mutation DeleteProductEntityTags($ids: [PositiveInt!]!) {
  deleteEntityTags(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteEntityTags": {
                "type": "Boolean",
                "keyRaw": "deleteEntityTags(ids: $ids)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "ids": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=f3e3b00c8e1de7ceac075e4481226f05621bb8707831fec6a247918a3161d877";