export default {
    "name": "TestRulesModalProducts",
    "kind": "HoudiniQuery",
    "hash": "eb2107e3997b3b5ecbaaad7ccaace5db074bc5471f2b76d0439e97bec19ab696",

    "raw": `query TestRulesModalProducts($productFilter: ProductFilter) {
  products(filter: $productFilter, pagination: {pageSize: 0}) {
    data {
      id
      name
      tags {
        id
        name
        entityType
        active
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "products": {
                "type": "ProductResponse",
                "keyRaw": "products(filter: $productFilter, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Product",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "tags": {
                                        "type": "EntityTag",
                                        "keyRaw": "tags",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "entityType": {
                                                    "type": "EntityTagType",
                                                    "keyRaw": "entityType",
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "productFilter": "ProductFilter"
        },

        "types": {
            "ProductFilter": {
                "active": "Boolean",
                "activeOnly": "Boolean",
                "plantIds": "PositiveInt",
                "plantIdsFilteringByChildren": "PositiveInt",
                "plants": "PositiveInt",
                "productName": "NonEmptyString",
                "productType": "ProductType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d436271c9d1ee9421d5d0c2f3b3a2c5490f40a6badfdd17cf680eb185718759e";