<script lang="ts">
	import type { SvelteAsr } from 'client/types/common'
	import { onMount, type ComponentProps } from 'svelte'
	import type { Reports$result } from '$houdini'
	import { graphql, GenerateCrystalReportStore } from '$houdini'
	import { ReportViewerPreview } from '@isoftdata/svelte-report-viewer'

	export let asr: SvelteAsr
	export let selectedReportId: number | null
	export let reportList: Reports$result['reports']['data']
	export let parameters: any
	export let preview: boolean = false

	let reportViewerPreview: ReportViewerPreview

	const generateCrystalReport: GenerateCrystalReportStore = graphql`
		mutation GenerateCrystalReport($reportJob: GenerateCrystalReportInput!) {
			generateCrystalReport(reportJob: $reportJob) {
				data
				mimeType
			}
		}
	`

	async function generatePdfPreview({ name, type, parameters }: Parameters<ComponentProps<ReportViewerPreview>['generatePdfPreview']>[0]) {
		const { data } = await generateCrystalReport.mutate({
			reportJob: {
				name,
				parameters: Object.entries(parameters).map(([key, value]) => ({ key, value })),
				type,
				id: selectedReportId,
			},
		})
		if (!data) {
			throw new Error('No data returned from generateCrystalReport')
		}
		return data.generateCrystalReport
	}

	onMount(() => {
		if (preview) {
			reportViewerPreview.doPreview()
			asr.go(null, { preview: undefined }, { inherit: true })
		}
	})
</script>

{#if selectedReportId}
	<ReportViewerPreview
		bind:this={reportViewerPreview}
		bind:selectedReportId
		{generatePdfPreview}
		{parameters}
		{reportList}
	/>
{/if}
