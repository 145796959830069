import sessionStore from 'stores/session'
import type { Mediator } from 'client/services/api-fetch'

const loginQuery = `#graphql
	mutation login($userName: String!, $passPhrase: String!, $plantId: PositiveInt!) {
		createSession(userName: $userName, password: $passPhrase, plantId: $plantId) {
			sessionToken,
			userName,
			userAccountId,
			user {
				workEmail,
				recoveryEmail,
				firstName,
				fullName,
				lastName,
				permissions: userRoles {
					permissionName
					value
				}
				authorizedPlants {
					id
					code
					name
					timezone: computedTimezone
				}
				userGroups {
        			id
     			}
			}
			plant {
				id
				code
				name
				timezone: computedTimezone
			}
		}
	}
`

interface MakeLoginRequest {
	username: string
	password: string
	plantId: number
	mediator: Mediator
}

interface LoginResponse {
	createSession: {
		sessionToken: string
		userName: string
		userAccountId: number
		user: {
			workEmail: string
			recoveryEmail: string
			firstName: string
			fullName: string
			lastName: string
			permissions: {
				permissionName: string
				value: string
			}[]
			authorizedPlants: {
				id: number
				code: string
				name: string
				timezone: string
			}[]
			userGroups: {
				id: number
			}[]
		}
		plant: {
			id: number
			code: string
			name: string
			timezone: string
		}
	}
}

export async function makeLoginRequest({ mediator, username, password, plantId }: MakeLoginRequest): Promise<string> {
	const res = (await mediator.call(
		'apiFetch',
		loginQuery,
		{
			userName: username,
			passPhrase: password,
			plantId,
		},
		{}
	)) as LoginResponse

	const { sessionToken, ...session } = res?.createSession || {}

	sessionStore.set({
		authToken: sessionToken,
		...session,
		siteId: plantId,
		user: {
			...session.user,
			permissions: session.user.permissions.reduce((acc, { permissionName, value }) => {
				acc[permissionName] = value.toUpperCase()
				return acc
			}, {}),
			userGroupIds: session.user.userGroups.map(g => g.id),
		},
		authorizedPlantIDs: session.user.authorizedPlants.map(p => p.id),
	})

	return sessionToken
}
