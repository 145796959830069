export default {
    "name": "CreateProductEntityTags",
    "kind": "HoudiniMutation",
    "hash": "4bbb68922324b17899e3d677c5810411fb7eb4154b20bdeefdd941fed28565b5",

    "raw": `mutation CreateProductEntityTags($input: [NewEntityTag!]!) {
  createEntityTags(input: $input) {
    active
    entityType
    id
    name
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createEntityTags": {
                "type": "EntityTag",
                "keyRaw": "createEntityTags(input: $input)",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityTagType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "NewEntityTag"
        },

        "types": {
            "NewEntityTag": {
                "active": "Boolean",
                "entityType": "EntityTagType",
                "name": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=1650213e2729a13be16b44fa8142a879071f05b93fe274c114dcab02d66cc389";