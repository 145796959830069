import { persist, createLocalStorage } from '@macfja/svelte-persistent-store'
import { writable, get } from 'svelte/store'

const session = persist(writable<ClientSession | Record<string, never>>(), createLocalStorage(), 'session')

export default session

/** Runs `get` from `svelte/store` on the session store. You should only use this in the case where subscribing doesn't make sense. */
export function getSession() {
	return get(session)
}

// We mutate the session after receiving it from the API, so this is slightly different than what they return
export type ClientSession = {
	authToken: string
	userName: string
	userAccountId: number
	siteId: number
	user: {
		workEmail?: string
		recoveryEmail?: string
		firstName?: string
		lastName?: string
		fullName?: string
		permissions: {
			[permissionName: string]: 'PLANT' | 'GLOBAL' | 'NONE'
		}
		authorizedPlants: { id: number; code: string; name: string; timezone: string }[]
		userGroups: { id: number }[]
		userGroupIds: number[]
	}
	plant: {
		id: number
		code: string
		name: string
		timezone: string
	}
	authorizedPlantIDs: number[]
}
