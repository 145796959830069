import pProps from 'p-props'
import { v4 as uuid } from '@lukeed/uuid'
import Timezone from 'timezone-enum'

import { stringToBoolean } from '@isoftdata/utility-string'

import apiFetch from 'utility/api-fetch'
import { getSession } from 'stores/session'

import { AutomaticWorkOrder, AutomaticWorkOrderData, convertToScheduleTypeEnum } from 'types/automatic-work-order'
import type { SvelteDomApi, AppContext } from 'types/common'

import svelteComponent from './AutomaticWorkOrder.svelte'
import showErrorAndRedirect from 'utility/show-error-and-redirect'

const gqlPagninationAllPages = { pagination: { pageSize: 0, pageNumber: 1 } }

export default ({ mediator, stateRouter, i18next: { t: translate }, hasPermission }: AppContext) => {
	stateRouter.addState({
		name: 'app.work-order.automatic',
		route: 'automatic',
		template: {
			svelte: true,
			component: svelteComponent,
			options: {
				// these are the options passed to the svelte component
			},
		},
		canLeaveState(domApi) {
			return (
				!(domApi as SvelteDomApi & svelteComponent).unsavedChanges ||
				confirm(translate('common:canLeaveState', 'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.'))
			)
		},
		async resolve(_data, _parameters) {
			const { siteId } = getSession()

			if (!hasPermission('WORK_ORDERS_CAN_MANAGE_AUTOMATIC', siteId)) {
				throw showErrorAndRedirect(mediator, 'Error Loading Automatic Work Orders', 'You do not have permission to view this page.')
			}

			const { automaticWorkOrders, plants, workOrderTypes, groups, interfaceSettings } = (await pProps({
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
				automaticWorkOrders: apiFetch(
					mediator,
					{
						query: queries.automaticWorkOrders,
						variables: {
							...gqlPagninationAllPages,
						},
					},
					'automaticWorkOrders.data'
				) as Promise<AutomaticWorkOrderData[]>,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
				plants: apiFetch(
					mediator,
					{
						query: queries.plants,
						variables: {
							...gqlPagninationAllPages,
						},
					},
					'plants.data'
				) as Promise<{ id: number; name: string; code: string }[]>,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
				workOrderTypes: apiFetch(
					mediator,
					{
						query: queries.workOrderTypes,
					},
					'workOrderTypes.data'
				) as Promise<{ id: number; name: string }[]>,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
				groups: apiFetch(
					mediator,
					{
						query: queries.groups,
					},
					'groups.data'
				) as Promise<{ id: number; name: string }[]>,
				interfaceSettings: (await pProps({
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
					showOpenDocuments: apiFetch(
						mediator,
						{
							query: queries.getUserSetting,
							variables: {
								lookup: {
									category: 'Work Orders',
									name: 'Automatic WOs: show open documents',
									settingType: 'INTERFACE_HISTORY',
									defaultValue: 'True',
								},
							},
						},
						'getCurrentUserSetting.value'
					),
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
					showClosedDocuments: apiFetch(
						mediator,
						{
							query: queries.getUserSetting,
							variables: {
								lookup: {
									category: 'Work Orders',
									name: 'Automatic WOs: show closed documents',
									settingType: 'INTERFACE_HISTORY',
									defaultValue: 'True',
								},
							},
						},
						'getCurrentUserSetting.value'
					),
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
					workOrdersPerPage: apiFetch(
						mediator,
						{
							query: queries.getUserSetting,
							variables: {
								lookup: {
									category: 'Work Orders',
									name: 'Automatic WOs: Work orders per page',
									settingType: 'INTERFACE_PREFERENCE',
									defaultValue: '100',
								},
							},
						},
						'getCurrentUserSetting.value'
					),

					dateRangeFilter: apiFetch(
						mediator,
						{
							query: queries.getUserSetting,
							variables: {
								lookup: {
									category: 'Work Orders',
									name: 'Automatic WOs: date range filter',
									settingType: 'INTERFACE_HISTORY',
									defaultValue: 'Last 30 Days',
								},
							},
						},
						'getCurrentUserSetting.value'
					),

					showInactive: apiFetch(
						mediator,
						{
							query: queries.getUserSetting,
							variables: {
								lookup: {
									category: 'Work Orders',
									name: 'Automatic WOs: show inactive',
									settingType: 'INTERFACE_HISTORY',
									defaultValue: 'True',
								},
							},
						},
						'getCurrentUserSetting.value'
					),
				})) as {
					showOpenDocuments: string
					showClosedDocuments: string
					workOrdersPerPage: string
					dateRangeFilter: string
					showInactive: string
				},
			})) as {
				automaticWorkOrders: AutomaticWorkOrderData[]
				plants: { id: number; name: string; code: string }[]
				workOrderTypes: { id: number; name: string }[]
				groups: { id: number; name: string }[]
				interfaceSettings: {
					showOpenDocuments: string
					showClosedDocuments: string
					workOrdersPerPage: string
					dateRangeFilter: string
					showInactive: string
				}
			}

			const mappedAutomaticWorkOrders = automaticWorkOrders.map(automaticWorkOrder => {
				return {
					...automaticWorkOrder,
					scheduleType: convertToScheduleTypeEnum(automaticWorkOrder.scheduleType),
					nextOccurance: automaticWorkOrder?.nextOccurance ? new Date(automaticWorkOrder?.nextOccurance) : null,
					uuid: uuid(),
				} as AutomaticWorkOrder
			})

			const formattedInterfaceSettings = {
				showOpenDocuments: stringToBoolean(interfaceSettings.showOpenDocuments),
				showClosedDocuments: stringToBoolean(interfaceSettings.showClosedDocuments),
				workOrdersPerPage: Number(interfaceSettings.workOrdersPerPage),
				dateRangeFilter: interfaceSettings.dateRangeFilter,
				showInactive: stringToBoolean(interfaceSettings.showInactive),
			}

			const timezones = Object.values(Timezone)
				.filter(value => typeof value === 'string')
				.map(value => value as string)
				.concat('SYSTEM')

			return {
				automaticWorkOrders: mappedAutomaticWorkOrders,
				timezones,
				plants,
				workOrderTypes,
				groups,
				selectedPlantId: siteId,
				interfaceSettings: formattedInterfaceSettings,
			}
		},
	})
}

const queries = {
	automaticWorkOrders: `#graphql
		query AutomaticWorkOrders($filter: AutomaticWorkOrderFilter, $pagination: PaginatedInput) {
			automaticWorkOrders(filter: $filter, pagination: $pagination) {
				data {
					id
					workOrderId
					scheduleType
					monthOffset
					timeOffset
					dayOffset
					timezone
					active
					description
					dateCreated
					dayOfWeek
					rank
					cloneAdditionalData
					cloneMostRecent
					nextOccurance
					workOrder {
						id
						plant {
							id
							name
							code
						}
					}
				}
			}
		}
	`,
	plants: `#graphql
		query Plants($pagination: PaginatedInput) {
		  plants(pagination: $pagination) {
			data {
				id,
				name,
				code,
			}
		  }
		}
	`,
	workOrderTypes: `#graphql
		query WorkOrderTypes {
			workOrderTypes {
				data {
					id
					name
				}
			}
		}
	`,
	groups: `#graphql
		query Groups {
			groups {
				data {
					id
					name
				}
			}
		}
	`,
	getUserSetting: `#graphql
		query GetCurrentUserSetting($lookup: SettingLookup!) {
			getCurrentUserSetting(lookup: $lookup) {
				value
			}
		}
	`,
}
