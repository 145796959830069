import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LOCATIONS_LIST_PAGE'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LOCATIONS_LIST_PAGEStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LOCATIONS_LIST_PAGEStore",
			variables: true,
		})
	}
}

export async function load_LOCATIONS_LIST_PAGE(params) {
	await initClient()

	const store = new LOCATIONS_LIST_PAGEStore()

	await store.fetch(params)

	return {
		LOCATIONS_LIST_PAGE: store,
	}
}
