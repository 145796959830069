export default {
    "name": "ReportSubscriptions",
    "kind": "HoudiniQuery",
    "hash": "2bd1a77afb3190ab26379996163b9c88e1ea25600b04b32a4abf5a4f12c1ef51",

    "raw": `query ReportSubscriptions($reportSubscriptionFilter: ReportSubscriptionFilter, $pagination: PaginatedInput) {
  reportSubscriptions(filter: $reportSubscriptionFilter, pagination: $pagination) {
    data {
      id
      createdByUserId
      createdByUser {
        fullName
        id
      }
      sourceType
      destinationType
      destination
      title
      emailBody
      emailFrom
      attachmentName
      reportId
      nextInstance
      schedulePeriodType
      schedulePeriod
      active
      dateCreated
      comments
      sourceQuery
      parameterDateRange
      report {
        id
        name
        type
        category
        description
      }
    }
    info {
      totalItems
      totalPages
      pageSize
      pageNumber
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reportSubscriptions": {
                "type": "ReportSubscriptionResponse",
                "keyRaw": "reportSubscriptions(filter: $reportSubscriptionFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ReportSubscription",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "createdByUserId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "createdByUserId",
                                        "visible": true
                                    },

                                    "createdByUser": {
                                        "type": "UserAccount",
                                        "keyRaw": "createdByUser",

                                        "selection": {
                                            "fields": {
                                                "fullName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "fullName",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "sourceType": {
                                        "type": "SourceType",
                                        "keyRaw": "sourceType",
                                        "visible": true
                                    },

                                    "destinationType": {
                                        "type": "ReportSubscriptionDestinationType",
                                        "keyRaw": "destinationType",
                                        "visible": true
                                    },

                                    "destination": {
                                        "type": "String",
                                        "keyRaw": "destination",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "title": {
                                        "type": "String",
                                        "keyRaw": "title",
                                        "visible": true
                                    },

                                    "emailBody": {
                                        "type": "String",
                                        "keyRaw": "emailBody",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "emailFrom": {
                                        "type": "String",
                                        "keyRaw": "emailFrom",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "attachmentName": {
                                        "type": "String",
                                        "keyRaw": "attachmentName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "reportId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "reportId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "nextInstance": {
                                        "type": "DateTime",
                                        "keyRaw": "nextInstance",
                                        "visible": true
                                    },

                                    "schedulePeriodType": {
                                        "type": "SchedulePeriodType",
                                        "keyRaw": "schedulePeriodType",
                                        "visible": true
                                    },

                                    "schedulePeriod": {
                                        "type": "PositiveInt",
                                        "keyRaw": "schedulePeriod",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "dateCreated": {
                                        "type": "DateTime",
                                        "keyRaw": "dateCreated",
                                        "visible": true
                                    },

                                    "comments": {
                                        "type": "String",
                                        "keyRaw": "comments",
                                        "visible": true
                                    },

                                    "sourceQuery": {
                                        "type": "String",
                                        "keyRaw": "sourceQuery",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "parameterDateRange": {
                                        "type": "DateRange",
                                        "keyRaw": "parameterDateRange",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "report": {
                                        "type": "Report",
                                        "keyRaw": "report",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "String",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                },

                                                "category": {
                                                    "type": "String",
                                                    "keyRaw": "category",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "reportSubscriptionFilter": "ReportSubscriptionFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "ReportSubscriptionFilter": {
                "createdByUserId": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=2a56bf530aec5c95597034038266275ecdddb07d20bc6f05d8a7cfde6eb13f5e";