import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/AmAnalysisOptionChoices'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class AmAnalysisOptionChoicesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "AmAnalysisOptionChoicesStore",
			variables: false,
		})
	}
}

export async function load_AmAnalysisOptionChoices(params) {
	await initClient()

	const store = new AmAnalysisOptionChoicesStore()

	await store.fetch(params)

	return {
		AmAnalysisOptionChoices: store,
	}
}
