/** Checks that Intl.DateTimeFormat can format a date in the given time zone successfully. Returns true if so, false if not. */
export function isValidTimeZone(timeZoneString: string) {
	if (timeZoneString.startsWith('SystemV/')) {
		return false
	}

	try {
		new Intl.DateTimeFormat(undefined, { timeZone: timeZoneString })
		return true
	} catch (error) {
		return false
	}
}
export default isValidTimeZone
