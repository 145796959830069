import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ReportQueueJobs'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ReportQueueJobsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ReportQueueJobsStore",
			variables: true,
		})
	}
}

export async function load_ReportQueueJobs(params) {
	await initClient()

	const store = new ReportQueueJobsStore()

	await store.fetch(params)

	return {
		ReportQueueJobs: store,
	}
}
