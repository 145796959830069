export default {
    "name": "AnalysisManagementOptionData",
    "kind": "HoudiniFragment",
    "hash": "806acd5e3494ddcbe4ddea1e404754fdf35613f98c0d9f315231d9c0ae417423",

    "raw": `fragment AnalysisManagementOptionData on AnalysisOption {
  id
  active
  analysisId
  option
  unit
  valueType
  defaultValue
  defaultType
  entryMethod
  thresholdType
  requiredToPerform
  requiredToClose
  informational
  inventoryMode
  rank
  product {
    name
    id
  }
  rules {
    id
    analysisOptionId
    active
    restriction
    outcome
    description
    created
    tags {
      id
      name
      active
      entityType
    }
  }
  __typename
}
`,

    "rootType": "AnalysisOption",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "analysisId": {
                "type": "PositiveInt",
                "keyRaw": "analysisId",
                "visible": true
            },

            "option": {
                "type": "String",
                "keyRaw": "option",
                "visible": true
            },

            "unit": {
                "type": "String",
                "keyRaw": "unit",
                "visible": true
            },

            "valueType": {
                "type": "ValueType",
                "keyRaw": "valueType",
                "visible": true
            },

            "defaultValue": {
                "type": "String",
                "keyRaw": "defaultValue",
                "nullable": true,
                "visible": true
            },

            "defaultType": {
                "type": "DefaultType",
                "keyRaw": "defaultType",
                "visible": true
            },

            "entryMethod": {
                "type": "EntryMethod",
                "keyRaw": "entryMethod",
                "visible": true
            },

            "thresholdType": {
                "type": "ThresholdType",
                "keyRaw": "thresholdType",
                "visible": true
            },

            "requiredToPerform": {
                "type": "Boolean",
                "keyRaw": "requiredToPerform",
                "visible": true
            },

            "requiredToClose": {
                "type": "Boolean",
                "keyRaw": "requiredToClose",
                "visible": true
            },

            "informational": {
                "type": "Boolean",
                "keyRaw": "informational",
                "visible": true
            },

            "inventoryMode": {
                "type": "InventoryMode",
                "keyRaw": "inventoryMode",
                "visible": true
            },

            "rank": {
                "type": "NonNegativeInt",
                "keyRaw": "rank",
                "visible": true
            },

            "product": {
                "type": "Product",
                "keyRaw": "product",
                "nullable": true,

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "rules": {
                "type": "AnalysisOptionRule",
                "keyRaw": "rules",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "analysisOptionId": {
                            "type": "PositiveInt",
                            "keyRaw": "analysisOptionId",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "restriction": {
                            "type": "RestrictionType",
                            "keyRaw": "restriction",
                            "visible": true
                        },

                        "outcome": {
                            "type": "OutcomeType",
                            "keyRaw": "outcome",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "created": {
                            "type": "Timestamp",
                            "keyRaw": "created",
                            "visible": true
                        },

                        "tags": {
                            "type": "EntityTag",
                            "keyRaw": "tags",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityTagType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=f85c11bf45975453c456f8b821139dd6a6947e370bb6e181269926d2774c7bee";