export default {
    "name": "AmCreateEntityTags",
    "kind": "HoudiniMutation",
    "hash": "1744bd25d534a06c69a49966f69f27ae2bb82917393075892bfd31162b11ea48",

    "raw": `mutation AmCreateEntityTags($input: [NewEntityTag!]!) {
  createEntityTags(input: $input) {
    active
    entityType
    id
    name
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createEntityTags": {
                "type": "EntityTag",
                "keyRaw": "createEntityTags(input: $input)",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityTagType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewEntityTag"
        },

        "types": {
            "NewEntityTag": {
                "active": "Boolean",
                "entityType": "EntityTagType",
                "name": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=e697c4c1abdde63ca4429149a775e1fca15801de8712ff4cbf3093f1c919e74a";