export default {
    "name": "TestRulesModalPlantTags",
    "kind": "HoudiniQuery",
    "hash": "f19cb6fc358a781fa858358db56f93db26b90769d13157f9a894f81039463358",

    "raw": `query TestRulesModalPlantTags($plantId: ID!) {
  plant(id: $plantId) {
    tags {
      active
      entityType
      id
      name
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "plant": {
                "type": "Plant",
                "keyRaw": "plant(id: $plantId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "tags": {
                            "type": "EntityTag",
                            "keyRaw": "tags",

                            "selection": {
                                "fields": {
                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityTagType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "plantId": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=eb2aea41fcd8aa73ce1cb87a010ca7c58408ff4ea1f4cdfd8fdd535491d9df76";