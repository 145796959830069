export default {
    "name": "AnalysisOptionChoices",
    "kind": "HoudiniQuery",
    "hash": "c1aa1486d7284650b7b191a947d359eded48d98b62b6df473faf730633113520",

    "raw": `query AnalysisOptionChoices($filter: AnalysisOptionChoiceFilter, $pagination: PaginatedInput) {
  analysisOptionChoices(filter: $filter, pagination: $pagination) {
    data {
      active
      choice
      constraint
      boundaryType
      severityClassId
      plantId
      productId
      productBatchId
      requiredAnalysisOptionId
      requiredConstraint
      requiredChoice
      id
      analysisOptionId
      severityClass {
        id
        default
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analysisOptionChoices": {
                "type": "AnalysisOptionChoiceResponse",
                "keyRaw": "analysisOptionChoices(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "AnalysisOptionChoice",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "choice": {
                                        "type": "String",
                                        "keyRaw": "choice",
                                        "visible": true
                                    },

                                    "constraint": {
                                        "type": "ConstraintType",
                                        "keyRaw": "constraint",
                                        "visible": true
                                    },

                                    "boundaryType": {
                                        "type": "BoundaryType",
                                        "keyRaw": "boundaryType",
                                        "visible": true
                                    },

                                    "severityClassId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "severityClassId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "productId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productBatchId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "productBatchId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "requiredAnalysisOptionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "requiredAnalysisOptionId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "requiredConstraint": {
                                        "type": "ConstraintType",
                                        "keyRaw": "requiredConstraint",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "requiredChoice": {
                                        "type": "String",
                                        "keyRaw": "requiredChoice",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "analysisOptionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisOptionId",
                                        "visible": true
                                    },

                                    "severityClass": {
                                        "type": "SeverityClass",
                                        "keyRaw": "severityClass",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "default": {
                                                    "type": "Boolean",
                                                    "keyRaw": "default",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "AnalysisOptionChoiceFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "AnalysisOptionChoiceFilter": {
                "active": "Boolean",
                "optionId": "PositiveInt",
                "optionName": "String",
                "plantId": "PositiveInt",
                "productIds": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=07bb9296a72de6c7433e2194b3012a9e6c624507cc584e88120ab36e8cf8bce5";