<script lang="ts">
	import type { DisplaySample, Sample } from '../work-order'
	import type { ComponentProps } from 'svelte'
	import type { i18n } from 'i18next'
	import type { Colors } from '@isoftdata/utility-bootstrap'

	import Icon from '@isoftdata/svelte-icon'
	import Button from '@isoftdata/svelte-button'
	import { createEventDispatcher, getContext } from 'svelte'

	const dispatch = createEventDispatcher<{
		save: { sampleUuid: string }
		close: { sampleUuid: string }
		attachments: { sampleUuid: string }
	}>()

	const { t: translate } = getContext<i18n>('i18next')

	export let inline: boolean = false
	export let sample: DisplaySample | Sample
	export let sampleModified: boolean = false

	$: activeCount = Object.values(iconMap).filter(({ active }) => active).length

	let iconMap: Record<
		string,
		{
			icon: ComponentProps<Icon>['icon']
			title: string
			active: boolean
			color?: Colors
			className?: string
			href?: string
			onClick?: (event: MouseEvent) => void
			disabled?: boolean
		}
	> = {}
	$: iconMap = {
		// Does the sample, or any of its values, have unsaved changes?
		hasUnsavedChanges: {
			icon: 'save',
			title: translate('workOrder.attnHasUnsavedChangesTitle', 'This sample has unsaved changes. Click to save the document & all samples.'),
			active: !sample.id || sampleModified,
			onClick: () => dispatch('save', { sampleUuid: sample.uuid }),
		},
		// Is the sample editable (not closed), but has all values entered?
		allOptionsEntered: {
			icon: 'list-check',
			title: translate('workOrder.attnAllOptionsEnteredTitle', "All analysis options have been filled out, but the sample isn't closed yet. Click to close this sample."),
			active: sample.status !== 'CLOSED' && sample.sampleValues.every(sv => !sv || (sv.result !== null && sv.result !== '')),
			onClick: () => dispatch('close', { sampleUuid: sample.uuid }),
		},
		// Does the sample have any attachments?
		hasAttachments: {
			icon: 'paperclip',
			title: translate('workOrder.attnHasAttachmentsTitle', 'This sample has attachments. Click to view them'),
			active: sample.attachments.length > 0,
			onClick: () => dispatch('attachments', { sampleUuid: sample.uuid }),
		},
		// if at least 1 of the sample values analysisoptionvalue.analysisoptionvalueid matches an investigation.analysisoptionvalueid meaning that this value ‘caused’ an investigation.
		// TODO, un-disable, and update href when we implement the investigation page
		createdInvestigation: {
			icon: 'magnifying-glass-arrow-right',
			className: 'fa-flip-horizontal',
			// title: 'An investigation was created based on the results of this sample. Click to view.',
			title: translate('workOrder.attnCreatedInvestigationTitle', 'An investigation was created based on the results of this sample.'),
			active: sample.sampleValues.some(sv => sv?.createdInvestigationId),
			disabled: true,
			// href: asr.makePath('app.investigation.history', { investigationId: 'todo' }),
		},
		// is true if sample.investigationid is set, meaning this sample is a retest that is part of an investigation.
		// TODO, un-disable, and update href when we implement the investigation page
		onInvestigation: {
			icon: 'magnifying-glass-arrow-right',
			// title: 'This sample was created to continue an investigation. Click to view.',
			title: translate('workOrder.attnOnInvestigationTitle', 'This sample was created to continue an investigation.'),
			active: !!sample.investigationId,
			disabled: true,
			// href: asr.makePath('app.investigation.history', { investigationId: 'todo' }),
		},
	}
</script>

<!-- Not sure how to make this component properly expand, so I'm just gonna do it manually /shrug -->
<div
	class="form-row"
	class:row-cols-2={!inline}
	style:min-width={activeCount > 1 && !inline ? '5rem' : ''}
	style:gap={inline ? '0.25rem' : '0'}
>
	{#if $$slots.default}
		<div class:col={!inline}>
			<slot />
		</div>
	{/if}
	{#each Object.values(iconMap) as { icon, className, title, active, onClick, href, disabled }}
		{#if active}
			<div class:col={!inline}>
				<Button
					outline
					size="xs"
					color="dark"
					icon={{
						icon,
						class: className,
					}}
					{title}
					{href}
					{disabled}
					colorGreyDisabled={false}
					style={inline ? 'margin-bottom: 3px;' : ''}
					on:click={event => {
						event.stopPropagation()
						onClick?.(event)
					}}
				/>
			</div>
		{/if}
	{/each}
</div>

<style>
</style>
