export default {
    "name": "AmDeleteAnalyses",
    "kind": "HoudiniMutation",
    "hash": "a38a895e23abf25d5f870351247cf3dbd2829dcaffe2f05bd65b5dca2d0f5934",

    "raw": `mutation AmDeleteAnalyses($ids: [ID!]!) {
  deleteAnalyses(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteAnalyses": {
                "type": "Boolean",
                "keyRaw": "deleteAnalyses(ids: $ids)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "ids": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=c9bb20789f9b36f64107392a01211a50edf597645721f4c1d260cf44c8dcfd22";