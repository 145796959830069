export default {
    "name": "LoadProductAnalyses",
    "kind": "HoudiniQuery",
    "hash": "c1de417c65beebd16a7c215df0ff9a13952f7c2c84b944056f9239807ba8e8fe",

    "raw": `query LoadProductAnalyses($filter: AnalysisFilter, $pagination: PaginatedInput) {
  analyses(filter: $filter, pagination: $pagination) {
    data {
      id
      name
      inUseAtPlantIDs
      options {
        id
        option
        valueType
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                },

                                                "valueType": {
                                                    "type": "ValueType",
                                                    "keyRaw": "valueType",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "AnalysisFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=2cec7169a6e618269517e86273559aa3f74b8251862e5cd06d98c086a19286e6";