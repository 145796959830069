export default {
    "name": "AmTestDefaultValue",
    "kind": "HoudiniQuery",
    "hash": "587cab87a6bcf12ab22506aaebd4124b1b301ba9c14d074315720402fee11852",

    "raw": `query AmTestDefaultValue($query: String!) {
  testSelectQuery(query: $query)
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "testSelectQuery": {
                "type": "String",
                "keyRaw": "testSelectQuery(query: $query)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "query": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=acbb69869ccbdaa15afcd483a4550f6df5ea963557c95f3c61a9201493a54b0c";