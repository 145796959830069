export default {
    "name": "UpdateEntityTags",
    "kind": "HoudiniMutation",
    "hash": "e4d2850b6930d84b5eb115641ad3f78ecf075730244da7c56e921d3fa4eeaa75",

    "raw": `mutation UpdateEntityTags($input: [EntityTagUpdate!]!) {
  updateEntityTags(input: $input) {
    id
    name
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateEntityTags": {
                "type": "EntityTag",
                "keyRaw": "updateEntityTags(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "EntityTagUpdate"
        },

        "types": {
            "EntityTagUpdate": {
                "active": "Boolean",
                "entityType": "EntityTagType",
                "id": "PositiveInt",
                "name": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=cffa125681e9403064bfb5745e20a6e943a0f4aac5c3d64c1f2bfe46e24882d0";