import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ALERT_SUBSCRIPTION'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ALERT_SUBSCRIPTIONStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ALERT_SUBSCRIPTIONStore",
			variables: false,
		})
	}
}

export async function load_ALERT_SUBSCRIPTION(params) {
	await initClient()

	const store = new ALERT_SUBSCRIPTIONStore()

	await store.fetch(params)

	return {
		ALERT_SUBSCRIPTION: store,
	}
}
