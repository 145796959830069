export const thresholdsMap = Object.freeze({
	ALLOWED: 'Allowed',
	BOUNDARY: 'Invalid',
	INVALID: 'Invalid',
	MARGINAL: 'Marginal',
	NOT_CALCULATED: 'Not Calculated',
	UNACCEPTABLE: 'Unacceptable',
})

export const applicabilityMap = Object.freeze({
	APPLICABLE: 'Applicable',
	DEACTIVATED: 'Deactivated',
	WRONG_BATCH: 'Wrong Batch',
	WRONG_PLANT: 'Wrong Plant',
	WRONG_PRODUCT: 'Wrong Product',
	WRONG_SEVERITY_CLASS: 'Wrong Severity Class',
})

export const outcomeMap = Object.freeze({
	INACTIVE: 'Inactive',
	HIDDEN: 'Hidden',
	READONLY: 'Readonly',
	REQUIRED_TO_PERFORM: 'Required to Perform',
	REQUIRED_TO_CLOSE: 'Required to Close',
})

export function buildTranslatedConstants(translate: (key: string, defaultValue: string) => string) {
	const constraintsMap = Object.freeze({
		MINIMUM: translate('common:above', 'Above'),
		MAXIMUM: translate('common:below', 'Below'),
		NOT_EQUAL: translate('common:doesNotEqual', "Doesn't equal"),
		NONE: translate('common:equals', 'Equals'),
	})

	const thresholdsWhenMap = Object.freeze({
		ALLOWED: translate('analysisManagement.allowedWhen', 'Allowed when'),
		BOUNDARY: translate('analysisManagement.invalidWhen', 'Invalid when'),
		MARGINAL: translate('analysisManagement.marginalWhen', 'Marginal when'),
		UNACCEPTABLE: translate('analysisManagement.unacceptableWhen', 'Unacceptable when'),
	})

	return {
		constraintsMap,
		thresholdsWhenMap,
	}
}
