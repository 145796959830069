import sessionStore, { type ClientSession } from 'stores/session'

let session: ClientSession | Record<string, never> = {}
sessionStore.subscribe(value => {
	session = value
})
/**
 *
 * @param {string} permission The Permission code
 * @param {number | null } plantId The id of the plant you are evaluating the permission at. If null, you are checking for a global permission
 * @param {boolean} ignoreGlobal Whether to ignore global level permissions. If true, you must have the permission at the plant level.
 * @returns boolean - true if you have the permission at the specified plant
 */
export default function hasPermission(permission: string, plantId?: number | null, ignoreGlobal = false) {
	const { user, siteId, authorizedPlantIDs } = session

	if (plantId === undefined) {
		plantId = siteId
	}

	const userPermissions = user.permissions
	const userPermissionLevel = userPermissions[permission]

	if (userPermissionLevel === undefined) {
		console.warn(`Permission ${permission} not found in user permissions`)
		return false
	}

	// Does not account for private plants, which require you have that permission at that plant
	const hasGlobalPermission = userPermissionLevel === 'GLOBAL'
	const hasPlantPermission = userPermissionLevel === 'PLANT' && plantId !== null && authorizedPlantIDs.includes(plantId)

	return (hasGlobalPermission && !ignoreGlobal) || hasPlantPermission
}
