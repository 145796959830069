export default {
    "name": "GetReportParameters",
    "kind": "HoudiniQuery",
    "hash": "5ad6e66d2918f113e758e122b555b9e45f9920445e14e618092250b64ec2f50e",

    "raw": `query GetReportParameters($reportId: ID!) {
  report(id: $reportId) {
    id
    type
    category
    name
    parameters {
      id
      label
      name
      rank
      type
      visible
      editability
      required
      options {
        isDefault
        id
        label
        value
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "report": {
                "type": "Report",
                "keyRaw": "report(id: $reportId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "type": {
                            "type": "String",
                            "keyRaw": "type",
                            "visible": true
                        },

                        "category": {
                            "type": "String",
                            "keyRaw": "category",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "parameters": {
                            "type": "ReportParameter",
                            "keyRaw": "parameters",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Float",
                                        "keyRaw": "rank",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "ParameterType",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "visible": {
                                        "type": "Boolean",
                                        "keyRaw": "visible",
                                        "visible": true
                                    },

                                    "editability": {
                                        "type": "Editibility",
                                        "keyRaw": "editability",
                                        "visible": true
                                    },

                                    "required": {
                                        "type": "Boolean",
                                        "keyRaw": "required",
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "ReportParameterOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "isDefault": {
                                                    "type": "Boolean",
                                                    "keyRaw": "isDefault",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Float",
                                                    "keyRaw": "id",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "reportId": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=7359ea6194670120c1093370118904038cfce2647078064ea4a96b8c26d68915";