export default {
    "name": "TestSelectQuery",
    "kind": "HoudiniQuery",
    "hash": "9437d87adb9496f29f511e0431a7b9bf7b34c8009229c6fe153d6a1695856e45",

    "raw": `query TestSelectQuery($query: String!) {
  testSelectQuery(query: $query)
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "testSelectQuery": {
                "type": "String",
                "keyRaw": "testSelectQuery(query: $query)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "query": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=272314bd62a83d72c618eb7ff652e7f7e78494e946f9e69f3d452c41116f3b53";