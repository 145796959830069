export default {
    "name": "Reports",
    "kind": "HoudiniQuery",
    "hash": "9857274648725ef43a789dc76ed2b4da7419d9afab67fe10aaa0825e083ffcff",

    "raw": `query Reports($pagination: PaginatedInput, $filter: ReportsFilter) {
  reports(pagination: $pagination, filter: $filter) {
    data {
      id
      type
      category
      name
      userVisible
      description
      numberOfParameters
      averageExecutionTime
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reports": {
                "type": "ReportResponse",
                "keyRaw": "reports(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Report",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "userVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "userVisible",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "numberOfParameters": {
                                        "type": "Float",
                                        "keyRaw": "numberOfParameters",
                                        "visible": true
                                    },

                                    "averageExecutionTime": {
                                        "type": "Float",
                                        "keyRaw": "averageExecutionTime",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "filter": "ReportsFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "ReportsFilter": {
                "type": "String",
                "userVisible": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=725328497ca752c448ef4d01d7cfd7d2a17dbf23c2fa8b3427245747ad750ceb";