export default {
    "name": "CreateInvestigationTriggers",
    "kind": "HoudiniMutation",
    "hash": "bd0ad8fc7bb4c1c054ed18c04b80af6335c0dca640f30d34c683fa3db145def3",

    "raw": `mutation CreateInvestigationTriggers($inputs: [NewInvestigationTrigger!]!) {
  createInvestigationTriggers(inputs: $inputs) {
    id
    description
    active
    resultStatus
    resultCount
    period
    periodType
    defaultComments
    defaultInvestigationType
    defaultStatus
    retestMode
    daysUntilInvestigationDue
    expansionMethod
    expansionSize
    sampleCollectionCount
    retestDocumentCount
    daysBetweenRetests
    analysisOptionId
    analysisOption {
      id
      option
      analysisId
    }
    plantId
    locationId
    severityClassId
    productId
    retestWorkOrderTypeId
    retestAnalysisId
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createInvestigationTriggers": {
                "type": "InvestigationTrigger",
                "keyRaw": "createInvestigationTriggers(inputs: $inputs)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Float",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "resultStatus": {
                            "type": "InvestigationTriggerResultStatus",
                            "keyRaw": "resultStatus",
                            "visible": true
                        },

                        "resultCount": {
                            "type": "PositiveInt",
                            "keyRaw": "resultCount",
                            "visible": true
                        },

                        "period": {
                            "type": "NonNegativeInt",
                            "keyRaw": "period",
                            "visible": true
                        },

                        "periodType": {
                            "type": "InvestigationTriggerPeriodType",
                            "keyRaw": "periodType",
                            "visible": true
                        },

                        "defaultComments": {
                            "type": "String",
                            "keyRaw": "defaultComments",
                            "visible": true
                        },

                        "defaultInvestigationType": {
                            "type": "String",
                            "keyRaw": "defaultInvestigationType",
                            "visible": true
                        },

                        "defaultStatus": {
                            "type": "DefaultStatus",
                            "keyRaw": "defaultStatus",
                            "visible": true
                        },

                        "retestMode": {
                            "type": "RetestMode",
                            "keyRaw": "retestMode",
                            "visible": true
                        },

                        "daysUntilInvestigationDue": {
                            "type": "NonNegativeInt",
                            "keyRaw": "daysUntilInvestigationDue",
                            "nullable": true,
                            "visible": true
                        },

                        "expansionMethod": {
                            "type": "ExpansionMethod",
                            "keyRaw": "expansionMethod",
                            "visible": true
                        },

                        "expansionSize": {
                            "type": "NonNegativeInt",
                            "keyRaw": "expansionSize",
                            "visible": true
                        },

                        "sampleCollectionCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "sampleCollectionCount",
                            "visible": true
                        },

                        "retestDocumentCount": {
                            "type": "Int",
                            "keyRaw": "retestDocumentCount",
                            "visible": true
                        },

                        "daysBetweenRetests": {
                            "type": "NonNegativeInt",
                            "keyRaw": "daysBetweenRetests",
                            "visible": true
                        },

                        "analysisOptionId": {
                            "type": "PositiveInt",
                            "keyRaw": "analysisOptionId",
                            "visible": true
                        },

                        "analysisOption": {
                            "type": "AnalysisOption",
                            "keyRaw": "analysisOption",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "option": {
                                        "type": "String",
                                        "keyRaw": "option",
                                        "visible": true
                                    },

                                    "analysisId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "plantId": {
                            "type": "PositiveInt",
                            "keyRaw": "plantId",
                            "nullable": true,
                            "visible": true
                        },

                        "locationId": {
                            "type": "PositiveInt",
                            "keyRaw": "locationId",
                            "nullable": true,
                            "visible": true
                        },

                        "severityClassId": {
                            "type": "PositiveInt",
                            "keyRaw": "severityClassId",
                            "nullable": true,
                            "visible": true
                        },

                        "productId": {
                            "type": "PositiveInt",
                            "keyRaw": "productId",
                            "nullable": true,
                            "visible": true
                        },

                        "retestWorkOrderTypeId": {
                            "type": "PositiveInt",
                            "keyRaw": "retestWorkOrderTypeId",
                            "nullable": true,
                            "visible": true
                        },

                        "retestAnalysisId": {
                            "type": "PositiveInt",
                            "keyRaw": "retestAnalysisId",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inputs": "NewInvestigationTrigger"
        },

        "types": {
            "NewInvestigationTrigger": {
                "active": "Boolean",
                "analysisOptionId": "PositiveInt",
                "daysBetweenRetests": "Int",
                "daysUntilInvestigationDue": "NonNegativeInt",
                "defaultComments": "String",
                "defaultInvestigationType": "String",
                "defaultStatus": "DefaultStatus",
                "description": "String",
                "expansionMethod": "ExpansionMethod",
                "expansionSize": "Int",
                "locationId": "PositiveInt",
                "period": "Int",
                "periodType": "InvestigationTriggerPeriodType",
                "plantId": "PositiveInt",
                "productId": "PositiveInt",
                "resultCount": "PositiveInt",
                "resultStatus": "InvestigationTriggerResultStatus",
                "retestAnalysisId": "PositiveInt",
                "retestDocumentCount": "Int",
                "retestMode": "RetestMode",
                "retestWorkOrderTypeId": "PositiveInt",
                "sampleCollectionCount": "Int",
                "severityClassId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=e182f97f9d13b471750b7251246894e23bb6f22151a628e4a0dbda1bb17a7b21";