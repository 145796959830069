<script lang="ts">
	import TagBadge from 'components/TagBadge.svelte'
	import type { ComponentProps } from 'svelte'

	export let tags: Array<ComponentProps<TagBadge>['tag']>
	export let activeTags: Set<number> | undefined = undefined
</script>

<div
	class="d-inline-flex"
	style="gap: 0.25rem;"
>
	{#each tags as tag}
		<TagBadge
			color={tag.id && activeTags?.has(tag.id) ? 'success' : 'secondary'}
			{tag}
		/>
	{:else}
		<i class="text-muted">N/A</i>
	{/each}
</div>
