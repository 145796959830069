import { FragmentStore } from '../runtime/stores/fragment'
import artifact from '$houdini/artifacts/AnalysisReturnData'


// create the fragment store

export class AnalysisReturnDataStore extends FragmentStore {
	constructor() {
		super({
			artifact,
			storeName: "AnalysisReturnDataStore",
			variables: true,
			
		})
	}
}
