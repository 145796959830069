import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ProductSpecifications'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ProductSpecificationsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ProductSpecificationsStore",
			variables: false,
		})
	}
}

export async function load_ProductSpecifications(params) {
	await initClient()

	const store = new ProductSpecificationsStore()

	await store.fetch(params)

	return {
		ProductSpecifications: store,
	}
}
