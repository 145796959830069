import type { AppContext } from 'types/common'
import workOrderListSharedStateOptions from 'utility/work-order-list-shared-state'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		...workOrderListSharedStateOptions,
		name: 'app.home.work-order-list',
		route: 'work-order-list',
	})
}
