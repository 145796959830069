import UserGroup from './UserGroup.svelte'
import { v4 as uuid } from '@lukeed/uuid'
import { AppContext, SvelteDomApi } from 'client/types/common'

import { graphql } from '$houdini'
import type { LoadUserGroups$result } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'

type UserAccount = Omit<LoadUserGroups$result['groups']['data'][0]['userAccounts'][0], 'userGroups'> & {
	isMember: boolean
}
type groupPermission = Omit<LoadUserGroups$result['groups']['data'][0]['groupPermissions'][0], 'permissionId'> & {
	id: number
	displayName: string
	category: string
}

type Group = Omit<LoadUserGroups$result['groups']['data'][0], 'userAccounts' | 'groupPermissions'> & {
	userAccounts: UserAccount[]
	groupPermissions: groupPermission[]
	uuid: string
}

export default ({ mediator, stateRouter, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.configuration.user-group',
		route: 'user-group',
		querystringParameters: ['lastResetTime', 'lastSavedTime'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: {
			svelte: true,
			component: UserGroup,
			options: {
				// these are the options passed to the svelte component
			},
		},
		canLeaveState(svelte) {
			return (
				!(svelte as SvelteDomApi & UserGroup).hasUnsavedChanges ||
				confirm(translate('common:canLeaveState', 'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.'))
			)
		},
		async resolve(_data, _parameters) {
			const { data } = await userGroups.fetch()
			if (!data) {
				console.error('No data was returned from the server.')
				throw showErrorAndRedirect(
					mediator,
					translate('configuration.userGroup.noDataReturnErrorHeading', 'Error!'),
					translate('configuration.userGroup.noDataReturnErrorMessage', 'No data was returned from the server.'),
				)
			}

			const groupRes = data.groups.data
			const permissionRes = data.permissions.data
			const userAccountRes = data.userAccounts.data

			const formattedGroups = groupRes.reduce(
				(acc, group) => {
					acc[group.id] = {
						...group,
						userAccounts: userAccountRes.map(userAccount => {
							return {
								id: userAccount.id,
								name: userAccount.name,
								isMember: userAccount.userGroups ? userAccount.userGroups.some(userGroup => userGroup.id === group.id) : false,
							}
						}),
						groupPermissions: permissionRes.map(permission => {
							return {
								...permission,
								value: group.groupPermissions.find(groupPermission => groupPermission.permissionId === permission.id)?.value ?? 'NONE',
							}
						}),
						uuid: uuid(),
					}
					return acc
				},
				{} as Record<number, Group>,
			)

			return {
				groups: formattedGroups,
				selectedGroup: Object.values(formattedGroups)[0],
				hasUnsavedChanges: false,
			}
		},
	})
}

const userGroups = graphql(`
	query LoadUserGroups {
		groups {
			data {
				id
				name
				workerGroup
				userAccounts {
					id
					name
				}
				groupPermissions {
					permissionId
					value
				}
				groupInUseData {
					alertSubscriptionCount
					analysisCount
					workOrderTypeCount
				}
			}
		}
		permissions {
			data {
				id
				displayName
				category
			}
		}
		userAccounts {
			data {
				id
				name
				userGroups {
					id
				}
			}
		}
	}
`)
