import type { ComponentProps } from 'svelte'
import type { AppContext } from 'types/common'
import component from './Investigation.svelte'
import type { InvestigationTrigger } from './configure-investigation/configure-investigation'

import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
import makeCrudStore from '@isoftdata/svelte-store-crud'

export default ({ mediator, hasPermission, stateRouter, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.investigation',
		route: 'investigation',
		defaultChild: 'configure',
		querystringParameters: ['lastSavedTime', 'lastResetTime'],
		defaultParameters: {
			lastSavedTime: null,
			lastResetTime: null,
		},
		template: {
			svelte: true,
			component,
		},
		resolve(_data, parameters) {
			const childStates: ComponentProps<AsrNavTabBar>['tabs'] = [
				{
					name: 'app.investigation.configure',
					title: translate('investigation.titles.configure-investigation', 'Configure Investigation'),
					inherit: false,
					disabled: !hasPermission('INVESTIGATION_CAN_CONFIGURE'),
				},
			]

			return Promise.resolve({
				childStates,
				lastSavedTime: parameters?.lastSavedTime,
				investigationTriggersCrudStore: makeCrudStore<InvestigationTrigger, 'uuid'>('uuid'),
			})
		},
	})
}
