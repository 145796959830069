export default {
    "name": "DetachFilesFromSample",
    "kind": "HoudiniMutation",
    "hash": "92be001063360c9c85152e146c6cf9547d44073ec71688d2a2f802f901793743",

    "raw": `mutation DetachFilesFromSample($fileIds: [PositiveInt!]!, $sampleId: PositiveInt!) {
  detachFilesFromSample(fileIds: $fileIds, sampleId: $sampleId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "detachFilesFromSample": {
                "type": "Void",
                "keyRaw": "detachFilesFromSample(fileIds: $fileIds, sampleId: $sampleId)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "fileIds": "PositiveInt",
            "sampleId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=0f1c4a084d4cfddfda2b6aefb50509dded25097231d8a2d4afbe822f435fb562";