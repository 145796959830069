import List from './List.svelte'
import { treeify, type TreeNode, findNodeById } from '@isoftdata/svelte-table'
import { v4 as uuid } from '@lukeed/uuid'
import type { AppContext, DefaultParameters, ResolveParameters } from 'types/common'
import { LocationsParams } from '../locations'
import { writable, get } from 'svelte/store'
import { getSession } from 'stores/session'
import { canLeaveLocationState } from '../locations'
import type { MetaAttachment } from 'types/files'
import showErrorAndRedirect from 'utility/show-error-and-redirect'

import { LOCATIONS_LIST_PAGEStore } from '$houdini'
import type { LOCATIONS_LIST_PAGE$result } from '$houdini'

type SeverityClass = LOCATIONS_LIST_PAGE$result['defaultSeverityClass']
type Location = LOCATIONS_LIST_PAGE$result['locations']['data'][0]

export interface ListParams extends LocationsParams {
	locationId: string | null
}

function getVariables(plantId: number) {
	return {
		pagination: {
			pageNumber: null,
			pageSize: 0,
		},
		filter: {
			plantIds: [plantId],
		},
	}
}

export default ({ stateRouter, hasPermission, mediator, i18next: { t: translate }, fileBaseUrl }: AppContext) => {
	stateRouter.addState({
		name: 'app.locations.list',
		route: '/list',
		querystringParameters: ['plantId'],
		defaultParameters: {
			plantId: () => getSession()?.siteId?.toString(),
		} satisfies DefaultParameters<Omit<ListParams, 'plantId'>>,
		template: {
			svelte: true,
			component: List,
			options: {
				// these are the options passed to the svelte component
			},
		},
		canLeaveState(svelte) {
			if (svelte.renderer === 'ractive') {
				return true
			}
			// Only need to check for location changes
			const hasLocationChanges = svelte.locationCrudStore.hasChanges()
			// If we've already said we can leave the top level state, don't check again in the child states
			const canLeaveParent = get(canLeaveLocationState)
			const canLeave =
				canLeaveParent ||
				!hasLocationChanges ||
				confirm(translate('location.canLeaveListState', 'You have unsaved changes. Either save your changes, or press "OK" to discard any unsaved changes and leave this page.'))
			// Reset the flag so we don't bypass just checking this state for changes next time
			canLeaveLocationState.set(false)
			// Revert plant dropdown value if we don't switch
			const urlPlantId = parseInt(svelte.asr.getActiveState().parameters.plantId, 10)
			if (!canLeave && urlPlantId !== svelte.plantId) {
				svelte.revertSelectedPlant(urlPlantId)
			}
			return canLeave
		},
		async resolve(_data, parameters: ResolveParameters<ListParams>) {
			if (!parameters.plantId || parameters.plantId === 'null') {
				console.warn('No plantId provided, redirecting to app.locations')
				throw { redirectTo: { name: 'app.locations' } }
			}
			const plantId = parseInt(parameters.plantId, 10)
			let locationId: number | null

			if (!parameters.locationId || parameters.locationId === 'null') {
				locationId = null
			} else {
				locationId = parseInt(parameters.locationId, 10)
			}
			// Load all the plant-specific data
			const { filter, pagination } = getVariables(plantId)

			const { data, errors } = await new LOCATIONS_LIST_PAGEStore().fetch({
				variables: {
					pagination,
					locationFilter: filter,
					processZoneFilter: filter,
					productProximityFilter: filter,
					// Used to get the default severity class
					processZoneId: null,
					productProximityId: null,
					plantId,
				},
			})

			if (errors) {
				console.error(errors)
				throw showErrorAndRedirect(mediator, translate('location.errorLoadingLocationList', 'Error Loading Location List'), errors[0].message)
			} else if (!data) {
				console.error('No data was returned from the server.')
				throw showErrorAndRedirect(
					mediator,
					translate('location.errorLoadingLocationList', 'Error Loading Location List'),
					translate('location.noDataWasReturnedFromTheServer', 'No data was returned from the server.')
				)
			}

			const {
				locations: { data: locations },
				processZones: { data: processZones },
				productProximities: { data: productProximities },
				processZoneAssignments: { data: processZoneAssignments },
				defaultSeverityClass,
			} = data

			const severityClassAssignments = processZoneAssignments.reduce((acc, processZone) => {
				acc[processZone.id] = processZone.severityClassAssignments.reduce((acc, assignment) => {
					acc[assignment.productProximity.id] = assignment.severityClass
					return acc
				}, {} as Record<number, SeverityClass>)
				return acc
			}, {} as Record<number, Record<number, SeverityClass>>)
			type UuidLocation = Omit<Location, 'attachments'> & { uuid: string; attachments: Array<MetaAttachment>; imageAttachments: Array<MetaAttachment>; thumbnailPath: string | null }

			// Store the uuids of the locations so we can use them to build the tree
			const { uuidMap, uuidLocations } = locations.reduce(
				({ uuidMap, uuidLocations }, location) => {
					const theUuid = uuid()
					uuidMap[location.id] = theUuid
					const attachments = location.attachments.map(attachment => ({
						...attachment,
						...attachment.file,
						locationId: location.id,
						path: `${fileBaseUrl}${attachment.file.path}`,
						uuid: uuid(),
						locationUuid: theUuid,
						rank: attachment.rank ?? 0,
						// I can't figure out how to make the size resolver not nullable, so we'll just default to 0 if that ever happens
						size: attachment.file.size ?? 0,
					}))
					const imageAttachments = attachments.filter(file => file.mimeType.startsWith('image/'))
					uuidLocations.push({
						...location,
						attachments,
						imageAttachments,
						thumbnailPath: imageAttachments[0]?.path ?? null,
						tags: location.tags?.sort((a, b) => a.name.length - b.name.length) ?? [],
						uuid: theUuid,
					})
					return { uuidMap, uuidLocations }
				},
				{ uuidMap: {} as Record<number, string>, uuidLocations: new Array<UuidLocation>() }
			)

			const locationsTree = treeify(
				uuidLocations.map(location => {
					const parentId = location.parentLocationId
					return {
						...location,
						parentLocationUuid: (parentId && uuidMap[parentId]) ?? null,
					}
				}),
				'uuid',
				'parentLocationUuid'
			)

			// Get the selected location from the tree so we have all the data the tree adds that we need
			const selectedLocation = locationId ? findNodeById(locationsTree, 'uuid', uuidMap[locationId]) ?? null : null
			if (selectedLocation) {
				selectedLocation.tags ??= []
				selectedLocation.description ??= ''
			}

			const expandedCard = writable<'DETAILS' | 'TAGS'>('DETAILS')

			return {
				locations: writable<TreeNode<Omit<Location, 'attachments'> & { uuid: string; attachments: MetaAttachment[] }, 'uuid', 'parentLocationId'>[]>(locationsTree),
				locationId,
				plantId: parseInt(parameters.plantId, 10),
				processZones,
				productProximities,
				selectedLocation: writable<(Omit<Location, 'attachments'> & { uuid: string; attachments: MetaAttachment[] }) | null>(selectedLocation),
				expandedCard,
				allowEditing: hasPermission('CONFIGURATION_CAN_CONFIGURE_LOCATIONS', plantId),
				severityClassAssignments,
				defaultSeverityClass,
			}
		},
	})
}
