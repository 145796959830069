export default {
    "name": "WoAnalysisData",
    "kind": "HoudiniFragment",
    "hash": "b34987bb46e27a031b519623089a27bda71a987f03fed4adee0dda3393a3514c",

    "raw": `fragment WoAnalysisData on Analysis {
  id
  name
  requireAuthentication
  options {
    ...WoAnalysisOptionData
    id
  }
  __typename
}

fragment WoAnalysisOptionData on AnalysisOption {
  active
  id
  option
  valueType
  thresholdType
  rank
  defaultType
  defaultValue
  unit
  __typename
}
`,

    "rootType": "Analysis",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "requireAuthentication": {
                "type": "Boolean",
                "keyRaw": "requireAuthentication",
                "visible": true
            },

            "options": {
                "type": "AnalysisOption",
                "keyRaw": "options",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "option": {
                            "type": "String",
                            "keyRaw": "option",
                            "visible": true
                        },

                        "valueType": {
                            "type": "ValueType",
                            "keyRaw": "valueType",
                            "visible": true
                        },

                        "thresholdType": {
                            "type": "ThresholdType",
                            "keyRaw": "thresholdType",
                            "visible": true
                        },

                        "rank": {
                            "type": "NonNegativeInt",
                            "keyRaw": "rank",
                            "visible": true
                        },

                        "defaultType": {
                            "type": "DefaultType",
                            "keyRaw": "defaultType",
                            "visible": true
                        },

                        "defaultValue": {
                            "type": "String",
                            "keyRaw": "defaultValue",
                            "nullable": true,
                            "visible": true
                        },

                        "unit": {
                            "type": "String",
                            "keyRaw": "unit",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "WoAnalysisOptionData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=8deccc703e8564002439e197d580ca783fbf2835c32c797c584b853e45ec57cc";