export default {
    "name": "WoChangeWorkOrderStatus",
    "kind": "HoudiniMutation",
    "hash": "4f612a2f9b7c66b73ca21f9189334a01425f6c5664649133e3c649df3e29f29e",

    "raw": `mutation WoChangeWorkOrderStatus($status: DocumentStatus!, $changeWorkOrderStatusId: ID!) {
  changeWorkOrderStatus(status: $status, id: $changeWorkOrderStatusId) {
    documentStatus
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "changeWorkOrderStatus": {
                "type": "WorkOrder",
                "keyRaw": "changeWorkOrderStatus(id: $changeWorkOrderStatusId, status: $status)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "documentStatus": {
                            "type": "DocumentStatus",
                            "keyRaw": "documentStatus",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "status": "DocumentStatus",
            "changeWorkOrderStatusId": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=7593fcd318cc80a6177d707d6fc5b98b03ecd43fc03be6ede1edd316e4a9e71a";