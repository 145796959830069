<script lang="ts">
	import type { i18n } from 'i18next'
	import type { AlertSubscription, AlertTypeMap, PreviewConfiguration } from 'utility/alert-subscription-helper'

	import Button from '@isoftdata/svelte-button'
	import { getContext } from 'svelte'

	const { t: translate } = getContext<i18n>('i18next')

	export let alertSubscription: AlertSubscription
	export let preview: PreviewConfiguration
	export let alertTypeMap: AlertTypeMap
	export let isLoading: boolean = false
	export let canPreviewAlert: boolean = false

	function getCanPreviewAlert(alertSubscription: AlertSubscription, preview: PreviewConfiguration) {
		const { documentId, scheduleId, date } = preview
		const { type } = alertSubscription

		return type && alertTypeMap.get(type)?.isSummary ? !!(scheduleId && date) : !!documentId
	}

	$: canPreviewAlert = getCanPreviewAlert(alertSubscription, preview)
</script>

<Button
	class="btn-sm w-100"
	type="submit"
	iconClass="play"
	disabled={!canPreviewAlert || isLoading}
>
	{translate('common:preview', 'Preview')}...
</Button>
