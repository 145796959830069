<script lang="ts">
	import type {
		OutcomeOrNone$options,
		RestrictionType$options,
		TestOptionRestrictions$result,
		TestRulesModalLocations$result,
		TestRulesModalPlantTags$result,
		TestRulesModalProducts$result,
		ValueOf,
	} from '$houdini'
	import type { Plant, Option } from 'utility/analysis-management-helper'
	import type { i18n } from 'types/common'
	import type { Simplify } from 'type-fest'
	type TestOptionRestrictionsRow = Simplify<
		Omit<TestOptionRestrictions$result['testOptionRestrictions']['rules'][number], 'outcome' | 'restriction'> & {
			tagNames: string
			outcome: OutcomeOrNone$options
			restriction: RestrictionType$options | null
		}
	>
	type Location = TestRulesModalLocations$result['locations']['data'][number]
	type Product = TestRulesModalProducts$result['products']['data'][number]

	import { graphql } from '$houdini'
	import makePlantListProxy from 'utility/entity-proxy-map'
	import { getContext } from 'svelte'
	import { makeTranslatedConstants } from 'utility/analysis-management-helper'
	import session from 'stores/session'

	import Modal from '@isoftdata/svelte-modal'
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import Icon from '@isoftdata/svelte-icon'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import TagBadgeList from 'components/TagBadgeList.svelte'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import userLocalWritable from 'stores/user-local-writable'

	export let selectedOption: Option
	export let plants: Array<Plant>
	export { defaultSelectedPlant as selectedPlant }
	let defaultSelectedPlant: Plant
	export let showDirtyWarning = false

	let show = false
	let selectedLocation: Location | null = null
	let selectedProduct: Product | null = null
	let selectedPlant: Plant = defaultSelectedPlant

	let rows: Array<TestOptionRestrictionsRow> = []
	let displayOutcome: ValueOf<typeof outcomeMap> = 'None'
	let outcomeSetByOption: boolean = false
	let plantTags: NonNullable<TestRulesModalPlantTags$result['plant']>['tags'] = []

	const showTags = userLocalWritable($session.userAccountId, 'rulesTestingModalShowTags', false)

	$: products = productsProxyMap[selectedPlant.id]
	$: locations = locationsProxyMap[selectedPlant.id]
	$: Promise.resolve(plantTagProxyMap[selectedPlant.id]).then(tags => (plantTags = tags))
	$: activeTags = new Set([...(selectedLocation?.tags ?? []), ...(selectedProduct?.tags ?? []), ...plantTags].map(tag => tag.id))
	$: console.log('active tags', activeTags)

	const { t: translate } = getContext<i18n>('i18next')
	const { outcomeMap } = makeTranslatedConstants(translate)

	const columns: Array<Column<TestOptionRestrictionsRow>> = [
		{
			name: translate('analyses.testRulesRuleColumn', 'Rule'),
			property: 'rule',
			sortType: false,
			title: translate('analyses.testRulesruleColumnTitle', "The rule's description"),
		},
		{
			name: translate('analyses.testRulesTagsColumn', 'Tags'),
			property: 'tagNames',
			sortType: false,
			width: '1rem',
			title: translate('analyses.testRulesTagsColumnTitle', 'The tags that must all be present or absent to trigger the rule'),
		},
		{
			name: translate('analyses.testRulesRestrictionColumn', 'Restriction'),
			property: 'restriction',
			sortType: false,
			width: '1rem',
			title: translate('analyses.testRulesRestrictionColumnTitle', 'Whether the tags must all be present or absent to trigger the rule'),
		},
		{
			name: translate('analyses.testRulesOutcomeColumn', 'Outcome'),
			property: 'outcome',
			sortType: false,
			width: '1rem',
			title: translate('analyses.testRulesOutcomeColumnTitle', 'The outcome of the rule, if it is triggered'),
		},
		{
			name: translate('analyses.testRulesTriggeredColumn', 'Triggered?'),
			property: 'triggered',
			sortType: false,
			width: '1rem',
			title: translate('analyses.testRulesTriggeredColumnTitle', 'Was this rule triggered?'),
		},
		{
			name: translate('analyses.testRulesAffectsOutcomeColumn', 'Affects Outcome?'),
			property: 'affectsOutcome',
			sortType: false,
			defaultSortColumn: true,
			defaultSortDirection: 'DESC',
			title: translate('analyses.testRulesAffectsOutcomeColumnTitle', 'If this rule was triggered, does it affect the overall outcome?'),
		},
	]

	export function open() {
		selectedLocation = null
		selectedProduct = null
		selectedPlant = defaultSelectedPlant
		displayOutcome = 'None'
		outcomeSetByOption = false
		rows = []
		show = true
		fetchData()
	}

	async function fetchData() {
		if (!selectedOption?.id) {
			return
		}
		const { data } = await testQuery.fetch({
			variables: {
				input: {
					analysisOptionId: selectedOption.id,
					plantId: selectedPlant.id,
					locationId: selectedLocation?.id ?? null,
					productId: selectedProduct?.id ?? null,
				},
			},
		})

		displayOutcome = data?.testOptionRestrictions?.outcome ? (outcomeMap[data.testOptionRestrictions.outcome] ?? 'None') : 'None'
		outcomeSetByOption = data?.testOptionRestrictions?.outcomeSetByOption ?? false

		const optionDefaultRow: TestOptionRestrictionsRow = {
			analysisOptionRuleId: -1,
			affectsOutcome: outcomeSetByOption,
			outcome: selectedOption.requiredToPerform ? 'REQUIRED_TO_PERFORM' : selectedOption.requiredToClose ? 'REQUIRED_TO_CLOSE' : 'NONE',
			restriction: null,
			rule: translate('analyses.testRulesOptionDefault', 'Option Default'),
			tagNames: '',
			tags: [],
			triggered: outcomeSetByOption,
		}

		rows = (
			data?.testOptionRestrictions?.rules.map(
				(rule): TestOptionRestrictionsRow => ({
					...rule,
					outcome: rule.outcome ?? 'NONE',
					tagNames: rule.tags.map(tag => tag.name).join(', '),
				}),
			) ?? []
		).concat(optionDefaultRow)
	}

	function getTriggeredText(row: TestOptionRestrictionsRow) {
		if ((row.triggered && row.restriction === 'PRESENT') || (!row.triggered && row.restriction === 'ABSENT')) {
			return translate('analyses.testRulesTagsPresent', 'Tags Present')
		} else if ((row.triggered && row.restriction === 'ABSENT') || (!row.triggered && row.restriction === 'PRESENT')) {
			return translate('analyses.testRulesTagsAbsent', 'Tags Absent')
		} else if (row.triggered) {
			return translate('common:yes', 'Yes')
		} else {
			return translate('common:no', 'No')
		}
	}

	function getAffectsOutcomeText(row: TestOptionRestrictionsRow) {
		const displayRowOutcome = row.outcome ? outcomeMap[row.outcome] : 'None'
		// Affects outcome
		if (outcomeSetByOption && row.analysisOptionRuleId === -1 && displayOutcome === displayRowOutcome) {
			return translate('analyses.testRulesOptionDefault', 'Option Default')
		} else if (row.triggered && row.affectsOutcome) {
			return translate('common:yes', 'Yes')
		}

		// Does not affect outcome
		if ((row.triggered && displayRowOutcome !== displayOutcome) || (row.analysisOptionRuleId === -1 && !outcomeSetByOption)) {
			return `"${displayOutcome}" overrides "${displayRowOutcome}"`
		} else if (!row.triggered) {
			return translate('analyses.rulesTestingNotTriggered', 'Not Triggered')
		}
		return translate('common:no', 'No')
	}

	// #region Queries & Proxies
	const locationsProxyMap = makePlantListProxy([], -1, async plantId => {
		const { data } = await getLocations.fetch({
			variables: {
				filter: {
					plantIds: [plantId],
					activeOnly: true,
				},
			},
			policy: 'CacheOrNetwork',
		})
		return data?.locations.data ?? []
	})
	const productsProxyMap = makePlantListProxy([], -1, async plantId => {
		const { data } = await getProducts.fetch({
			variables: {
				productFilter: {
					plantIds: [plantId],
					activeOnly: true,
				},
			},
			policy: 'CacheOrNetwork',
		})
		return data?.products.data ?? []
	})
	const plantTagProxyMap = makePlantListProxy([], -1, async plantId => {
		const { data } = await getPlantTags.fetch({
			variables: {
				plantId: plantId.toString(),
			},
			policy: 'CacheOrNetwork',
		})
		return data?.plant?.tags ?? []
	})

	const testQuery = graphql(`
		query TestOptionRestrictions($input: TestOptionRestrictionsInput!) {
			testOptionRestrictions(input: $input) {
				analysisOptionId
				locationId
				plantId
				productId
				outcome
				outcomeSetByOption
				rules {
					affectsOutcome
					triggered
					rule
					tags {
						active
						entityType
						id
						name
					}
					restriction
					outcome
					analysisOptionRuleId
				}
			}
		}
	`)

	const getPlantTags = graphql(`
		query TestRulesModalPlantTags($plantId: ID!) {
			plant(id: $plantId) {
				tags {
					active
					entityType
					id
					name
				}
			}
		}
	`)

	const getLocations = graphql(`
		query TestRulesModalLocations($filter: LocationFilter) {
			locations(filter: $filter, pagination: { pageSize: 0 }) {
				data {
					id
					plantId
					code
					location
					tags {
						id
						name
						entityType
						active
					}
				}
			}
		}
	`)

	const getProducts = graphql(`
		query TestRulesModalProducts($productFilter: ProductFilter) {
			products(filter: $productFilter, pagination: { pageSize: 0 }) {
				data {
					id
					name
					tags {
						id
						name
						entityType
						active
					}
				}
			}
		}
	`)
	// #endregion
</script>

<Modal
	bind:show
	modalSize="xl"
	title={translate('testRules.title', "Test Rules & Restrictions for Analysis Option '{{- option}}'", { option: selectedOption.option })}
	subtitle={translate('testRules.subtitle', 'Select a plant, location, and product to test the rules and restrictions for this analysis option.')}
	cancelButtonText={translate('common:close', 'Close')}
	confirmShown={false}
	on:close={() => {
		show = false
	}}
>
	<div class="form-row mb-2">
		<div class="align-content-end col-3">
			<SiteAutocomplete
				label={translate('common:plant', 'Plant')}
				options={plants}
				bind:value={selectedPlant}
				on:change={() => {
					selectedLocation = null
					selectedProduct = null
					fetchData()
				}}
			></SiteAutocomplete>
		</div>
		<div class="align-content-end col-3">
			<Autocomplete
				label={translate('common:Location', 'Location')}
				placeholder={translate('common:allLocations', 'All Locations')}
				emptyValue={null}
				isLoading={$getLocations.fetching}
				options={locations}
				getLabel={location => location?.location ?? ''}
				bind:value={selectedLocation}
				on:change={fetchData}
			>
				<svelte:fragment
					slot="option"
					let:option
				>
					{#if option}
						{option.location}
					{/if}
				</svelte:fragment>
			</Autocomplete>
		</div>
		<div class="align-content-end col-3">
			<Autocomplete
				label={translate('common:product', 'Product')}
				placeholder={translate('common:allProducts', 'All Products')}
				emptyValue={null}
				isLoading={$getProducts.fetching}
				options={products}
				getLabel={product => product?.name ?? ''}
				bind:value={selectedProduct}
				on:change={fetchData}
			>
				<svelte:fragment
					slot="option"
					let:option
				>
					{#if option}
						{option.name}
					{/if}
				</svelte:fragment>
			</Autocomplete>
		</div>
		<div class="col-3 align-content-end">
			<Checkbox
				label="Show Tags"
				bind:checked={$showTags}
			></Checkbox>
			<div class="w-100">
				<strong>{translate('testRules.outcome', 'Outcome')}:</strong>
				{#if $testQuery.fetching}
					<Icon
						fixedWidth
						isLoading
					></Icon>
				{:else}
					{displayOutcome || translate('common:none', 'None')}
				{/if}
			</div>
		</div>
		{#if $showTags}
			<div
				class="col-3"
				style="overflow-x: auto; scrollbar-width: thin;"
			>
				{#await plantTagProxyMap[selectedPlant.id] then plantTags}
					<TagBadgeList tags={plantTags}></TagBadgeList>
				{/await}
			</div>
			<div
				class="col-3"
				style="overflow-x: auto; scrollbar-width: thin;"
			>
				<TagBadgeList tags={selectedLocation?.tags ?? []}></TagBadgeList>
			</div>
			<div
				class="col-3"
				style="overflow-x: auto; scrollbar-width: thin;"
			>
				<TagBadgeList tags={selectedProduct?.tags ?? []}></TagBadgeList>
			</div>
		{/if}
	</div>
	<Table
		responsive
		idProp="analysisOptionRuleId"
		class="text-nowrap"
		{rows}
		{columns}
		let:row
	>
		<tr>
			<Td property="rule"
				>{#if row.analysisOptionRuleId === -1}
					<i>{row.rule}</i>
				{:else if row.rule}
					{row.rule}
				{:else}
					<i class="text-muted">{translate('testRules.noDescription', 'No description')}</i>
				{/if}
			</Td>
			<Td property="tagNames"
				><div
					class="d-flex"
					style="gap: 0.25rem"
				>
					{#if row.tags.length}
						<TagBadgeList
							{activeTags}
							tags={row.tags}
						></TagBadgeList>
					{:else}
						<i class="text-muted">N/A</i>
					{/if}
				</div>
			</Td>
			<Td property="restriction">
				{#if row.restriction}
					{row.restriction === 'PRESENT' ? translate('common:present', 'Present') : translate('common:absent', 'Absent')}
				{:else}
					<i class="text-muted">N/A</i>
				{/if}
			</Td>
			<Td property="outcome">
				{row.outcome ? (outcomeMap[row.outcome] ?? 'None') : 'None'}</Td
			>
			<Td property="triggered">
				<Icon
					fixedWidth
					icon={row.triggered ? 'check' : 'xmark'}
					class="mr-1 {row.triggered ? 'text-success' : 'text-danger'}"
				></Icon>{getTriggeredText(row)}
			</Td>
			<Td property="affectsOutcome"
				><Icon
					fixedWidth
					icon={row.affectsOutcome ? 'check' : 'xmark'}
					class="mr-1 {row.affectsOutcome ? 'text-success' : 'text-danger'}"
				></Icon>{getAffectsOutcomeText(row)}</Td
			>
		</tr>
		<svelte:fragment slot="no-rows">
			{#if $testQuery.fetching}
				<tr>
					<td
						colspan={columns.length}
						class="text-center"
						><Icon
							isLoading
							fixedWidth
							class="mr-1"
						></Icon>{translate('common:loading', 'Loading')}...</td
					>
				</tr>
			{:else}
				<tr>
					<td
						colspan={columns.length}
						class="text-center">{translate('testRules.noRules', 'This option has no rules')}</td
					>
				</tr>
			{/if}
		</svelte:fragment>
	</Table>
	{#if showDirtyWarning}
		<p class="mt-3 mb-0 text-info text-center">
			{translate('testThresholds.unsavedChangesWarning', 'The selected analysis option has unsaved changes. Unsaved changes will not be reflected in test results.')}
		</p>
	{/if}
</Modal>
