export default {
    "name": "WorkOrderTypeData",
    "kind": "HoudiniFragment",
    "hash": "5f30bed64fc15e5e1024be361bba869c9d7631cd50aa7ec366ac19f87b033c1e",

    "raw": `fragment WorkOrderTypeData on WorkOrderType {
  daysTillDue
  daysTillVerificationDue
  defaultAnalysisId
  defaultGroupId
  defaultReport
  defaultVerificationGroupId
  id
  inUseAtPlantIDs
  name
  productBatchRequired
  showDue
  showLocation
  showLocationDescription
  showProduct
  showSamplingDetail
  showTestingDetail
  titleRequired
  verificationRequired
  visibleGroupId
  __typename
}
`,

    "rootType": "WorkOrderType",
    "stripVariables": [],

    "selection": {
        "fields": {
            "daysTillDue": {
                "type": "NonNegativeInt",
                "keyRaw": "daysTillDue",
                "nullable": true,
                "visible": true
            },

            "daysTillVerificationDue": {
                "type": "NonNegativeInt",
                "keyRaw": "daysTillVerificationDue",
                "nullable": true,
                "visible": true
            },

            "defaultAnalysisId": {
                "type": "PositiveInt",
                "keyRaw": "defaultAnalysisId",
                "nullable": true,
                "visible": true
            },

            "defaultGroupId": {
                "type": "PositiveInt",
                "keyRaw": "defaultGroupId",
                "nullable": true,
                "visible": true
            },

            "defaultReport": {
                "type": "String",
                "keyRaw": "defaultReport",
                "visible": true
            },

            "defaultVerificationGroupId": {
                "type": "PositiveInt",
                "keyRaw": "defaultVerificationGroupId",
                "nullable": true,
                "visible": true
            },

            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "inUseAtPlantIDs": {
                "type": "PositiveInt",
                "keyRaw": "inUseAtPlantIDs",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "productBatchRequired": {
                "type": "ProductBatchRequired",
                "keyRaw": "productBatchRequired",
                "visible": true
            },

            "showDue": {
                "type": "Boolean",
                "keyRaw": "showDue",
                "visible": true
            },

            "showLocation": {
                "type": "Boolean",
                "keyRaw": "showLocation",
                "visible": true
            },

            "showLocationDescription": {
                "type": "Boolean",
                "keyRaw": "showLocationDescription",
                "visible": true
            },

            "showProduct": {
                "type": "ShowProduct",
                "keyRaw": "showProduct",
                "visible": true
            },

            "showSamplingDetail": {
                "type": "Boolean",
                "keyRaw": "showSamplingDetail",
                "visible": true
            },

            "showTestingDetail": {
                "type": "Boolean",
                "keyRaw": "showTestingDetail",
                "visible": true
            },

            "titleRequired": {
                "type": "Boolean",
                "keyRaw": "titleRequired",
                "visible": true
            },

            "verificationRequired": {
                "type": "Boolean",
                "keyRaw": "verificationRequired",
                "visible": true
            },

            "visibleGroupId": {
                "type": "PositiveInt",
                "keyRaw": "visibleGroupId",
                "nullable": true,
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=4fe73d43a135c4f9e15180df536d74085abfd6d76925dfc6e1e43774ab722198";