export default {
    "name": "AmCreateAnalyses",
    "kind": "HoudiniMutation",
    "hash": "6b749bf12a22acf9a6daf63f4cdc996dca70003b2b5379e60c5e5750248ffc65",

    "raw": `mutation AmCreateAnalyses($input: [NewAnalysis!]!) {
  createAnalyses(input: $input) {
    id
    name
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createAnalyses": {
                "type": "Analysis",
                "keyRaw": "createAnalyses(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewAnalysis"
        },

        "types": {
            "NestedNewAnalysisOptionChoice": {
                "active": "Boolean",
                "boundaryType": "BoundaryType",
                "choice": "String",
                "constraint": "ConstraintType",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "productId": "PositiveInt",
                "requiredAnalysisOptionId": "PositiveInt",
                "requiredChoice": "String",
                "requiredConstraint": "ConstraintType",
                "severityClassId": "PositiveInt"
            },

            "NestedNewAnalysisOptionRule": {
                "active": "Boolean",
                "description": "String",
                "outcome": "OutcomeType",
                "restriction": "RestrictionType",
                "tags": "PositiveInt"
            },

            "NewAnalysisOption": {
                "active": "Boolean",
                "choices": "NestedNewAnalysisOptionChoice",
                "defaultType": "DefaultType",
                "defaultValue": "String",
                "entryMethod": "EntryMethod",
                "informational": "Boolean",
                "inventoryMode": "InventoryMode",
                "option": "String",
                "productId": "PositiveInt",
                "rank": "Int",
                "requiredToClose": "Boolean",
                "requiredToPerform": "Boolean",
                "rules": "NestedNewAnalysisOptionRule",
                "thresholdType": "ThresholdType",
                "unit": "String",
                "valueType": "ValueType"
            },

            "PrintQuantityInput": {
                "plantId": "PositiveInt",
                "sampleTagQuantity": "NonNegativeInt",
                "testingTagQuantity": "NonNegativeInt"
            },

            "NewAnalysis": {
                "active": "Boolean",
                "analysisType": "AnalysisType",
                "batchUnit": "String",
                "batchVolume": "Int",
                "category": "String",
                "createdProductId": "PositiveInt",
                "groupSamples": "Boolean",
                "inUseAtPlantIDs": "PositiveInt",
                "instructions": "String",
                "name": "String",
                "options": "NewAnalysisOption",
                "printQuantityInput": "PrintQuantityInput",
                "requireAuthentication": "Boolean",
                "testingPeriod": "Float",
                "visibleGroupId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=49de51b3dbacef1b47a7bc2740ac3a51e6ae47e1011eb001d9ffd5e71c17e7f2";