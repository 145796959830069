/**
 *
 * @param entity The entity to get a duplicate name for
 * @param reference Siblings of the entity
 * @param key The key being de-duplicated
 * @returns The de-duplicated value
 */
export default function getDuplicateName<T, K extends keyof T>(entity: T, reference: Array<T>, key: K) {
	const nameNoCount = (entity[key] as string).replace(/\s\((\d+)\)$/, '')

	const highestNumber = reference.reduce((acc, loc) => {
		const thisName = loc[key] as string
		const match = thisName.includes(nameNoCount) && thisName.match(/^(.+)\s\((\d+)\)$/)
		if (match) {
			const [, baseName, number] = match
			if (baseName === nameNoCount) {
				return Math.max(acc, parseInt(number, 10) + 1)
			}
		} else if (thisName === nameNoCount) {
			return Math.max(acc, 1)
		}
		return acc
	}, 0)

	return highestNumber ? `${nameNoCount} (${highestNumber})` : nameNoCount
}
