import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetReportParameters'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetReportParametersStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetReportParametersStore",
			variables: true,
		})
	}
}

export async function load_GetReportParameters(params) {
	await initClient()

	const store = new GetReportParametersStore()

	await store.fetch(params)

	return {
		GetReportParameters: store,
	}
}
