import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/WoGetOptionRestrictions'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class WoGetOptionRestrictionsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "WoGetOptionRestrictionsStore",
			variables: true,
		})
	}
}

export async function load_WoGetOptionRestrictions(params) {
	await initClient()

	const store = new WoGetOptionRestrictionsStore()

	await store.fetch(params)

	return {
		WoGetOptionRestrictions: store,
	}
}
