export default {
    "name": "WoLocationData",
    "kind": "HoudiniFragment",
    "hash": "6a218046d9ce773cb9fa49ce6faad05c53dc23589ae80efc27274a6613d9f395",

    "raw": `fragment WoLocationData on Location {
  id
  location
  description
  plantId
  severityClass {
    id
    default
  }
  attachmentCount(fileType: IMAGE)
  __typename
}
`,

    "rootType": "Location",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "location": {
                "type": "String",
                "keyRaw": "location",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "visible": true
            },

            "plantId": {
                "type": "PositiveInt",
                "keyRaw": "plantId",
                "visible": true
            },

            "severityClass": {
                "type": "SeverityClass",
                "keyRaw": "severityClass",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "default": {
                            "type": "Boolean",
                            "keyRaw": "default",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "attachmentCount": {
                "type": "UnsignedInt",
                "keyRaw": "attachmentCount(fileType: IMAGE)",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=c0b94067d1b151090f47e3ed5558f58e3f216ee0bbc0c98c044f98708e19d860";