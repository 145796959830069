import Cookies from 'js-cookie'
import sessionStore, { getSession } from 'stores/session'
import type { Mediator } from 'services/api-fetch'

export default async function logOut(mediator: Mediator): Promise<void> {
	const oldSession = getSession()

	if (oldSession?.authToken) {
		try {
			await mediator.call('apiFetch', 'mutation logout { closeSession }', {}, {})
		} catch (err) {
			console.log(err)
		}
	}

	sessionStore.set({})
	Cookies.remove('auth-token')
}
