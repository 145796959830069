import type { AppContext } from 'client/types/common'
import type SaveResetButton from '@isoftdata/svelte-save-reset-button'
import type { ComponentProps } from 'svelte'

import component from './AnalysisManagement.svelte'

import { getSession } from 'stores/session'
import { writable } from 'svelte/store'

export default function ({ stateRouter, hasPermission }: AppContext) {
	stateRouter.addState({
		name: 'app.analysis-management',
		route: 'analysis-management',
		defaultChild: 'analyses',
		querystringParameters: ['lastResetTime', 'lastSavedTime'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: {
			svelte: true,
			component,
		},
		resolve(_data, { lastSavedTime }) {
			const { authorizedPlantIDs } = getSession()

			if (!authorizedPlantIDs.some(id => hasPermission('ANALYSIS_CAN_OPEN_SCREEN', id))) {
				return Promise.reject({
					redirectTo: { name: 'app.home' },
				})
			}
			return Promise.resolve({
				lastSavedTime,
				saveResetProps: writable<ComponentProps<SaveResetButton> | null>(null),
			})
		},
	})
}
