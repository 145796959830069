import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/TestRulesModalLocations'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class TestRulesModalLocationsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "TestRulesModalLocationsStore",
			variables: false,
		})
	}
}

export async function load_TestRulesModalLocations(params) {
	await initClient()

	const store = new TestRulesModalLocationsStore()

	await store.fetch(params)

	return {
		TestRulesModalLocations: store,
	}
}
