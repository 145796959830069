import type { AppContext, ResolveParameters } from 'types/common'
import component from './Reports.svelte'

import showErrorAndRedirect from 'utility/show-error-and-redirect'
import loadReports from 'utility/report-viewer-helper'

type StateParameters = {
	selectedReportId: number | null
	selectedCategory: string | null
}

export default function ({ mediator, stateRouter, i18next }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.reports',
		route: 'reports',
		defaultChild: 'preview',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, stateParameters: ResolveParameters<StateParameters>) {
			const selectedReportId = parseInt(stateParameters.selectedReportId, 10) || null

			const reportList = await loadReports({ filter: { userVisible: true }, pagination: { pageNumber: 1, pageSize: 0 } }, i18next)

			if (!reportList) {
				throw showErrorAndRedirect(mediator, 'Failed to load Report Viewer', 'No data returned from server', { name: 'app' })
			}

			return { reportList, selectedReportId, selectedCategory: stateParameters.selectedCategory }
		},
	})
}
