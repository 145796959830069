export default {
    "name": "DeleteLocations",
    "kind": "HoudiniMutation",
    "hash": "5fd58896061b8934244016714624cf3ad43d1ec6d53733653e474a8af5565b4f",

    "raw": `mutation DeleteLocations($locationIds: [UnsignedInt!]!) {
  deleteLocations(locationIds: $locationIds)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteLocations": {
                "type": "Void",
                "keyRaw": "deleteLocations(locationIds: $locationIds)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "locationIds": "UnsignedInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=364ecd99b3170d58f673ea3ecfee4b6550f508639017d95b46d67e317443aa57";