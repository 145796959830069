export default {
    "name": "CreateOrUpdateProducts",
    "kind": "HoudiniMutation",
    "hash": "b5f0edd977477e6583c4c184dafa96bc8a52d9d2c5a9fd3a87a42699923d16a8",

    "raw": `mutation CreateOrUpdateProducts($product: CreateOrUpdateProduct!) {
  createOrUpdateProduct(product: $product) {
    id
    active
    category
    description
    inUseAtPlants {
      id
    }
    name
    parentProductId
    productType
    tags {
      id
      name
      entityType
      active
    }
    attachmentCount
    attachments {
      id
      public
      rank
      fileId
      file {
        path
        name
        created
        updated
        type
        hash
        mimeType
        size
        id
      }
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createOrUpdateProduct": {
                "type": "Product",
                "keyRaw": "createOrUpdateProduct(product: $product)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "category": {
                            "type": "String",
                            "keyRaw": "category",
                            "nullable": true,
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "inUseAtPlants": {
                            "type": "Plant",
                            "keyRaw": "inUseAtPlants",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "parentProductId": {
                            "type": "PositiveInt",
                            "keyRaw": "parentProductId",
                            "nullable": true,
                            "visible": true
                        },

                        "productType": {
                            "type": "ProductType",
                            "keyRaw": "productType",
                            "visible": true
                        },

                        "tags": {
                            "type": "EntityTag",
                            "keyRaw": "tags",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityTagType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "attachmentCount": {
                            "type": "UnsignedInt",
                            "keyRaw": "attachmentCount",
                            "visible": true
                        },

                        "attachments": {
                            "type": "ProductFile",
                            "keyRaw": "attachments",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "SafeInt",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "fileId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "fileId",
                                        "visible": true
                                    },

                                    "file": {
                                        "type": "File",
                                        "keyRaw": "file",

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "updated": {
                                                    "type": "DateTime",
                                                    "keyRaw": "updated",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "FileType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                },

                                                "hash": {
                                                    "type": "String",
                                                    "keyRaw": "hash",
                                                    "visible": true
                                                },

                                                "mimeType": {
                                                    "type": "String",
                                                    "keyRaw": "mimeType",
                                                    "visible": true
                                                },

                                                "size": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "size",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "product": "CreateOrUpdateProduct"
        },

        "types": {
            "CreateOrUpdateProduct": {
                "active": "Boolean",
                "barcodeFormat": "String",
                "category": "String",
                "description": "String",
                "fileIds": "PositiveInt",
                "id": "PositiveInt",
                "inUseAtPlantIDs": "PositiveInt",
                "itemNumber": "String",
                "name": "String",
                "parentProductId": "PositiveInt",
                "productType": "ProductType",
                "supplierItemNumber": "String",
                "tagIds": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a95c91120a3b495cfca1b7b003489a5c34cb517fbec9839961b36bf83bc55a16";