<script lang="ts">
	import type { ValueType$options as ValueType } from '$houdini'
	import type { i18n } from 'i18next'

	import Icon from '@isoftdata/svelte-icon'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import Checkbox from '@isoftdata/svelte-checkbox'

	import { onMount, createEventDispatcher, getContext } from 'svelte'
	import { v4 as uuid } from '@lukeed/uuid'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import { getEventValue } from '@isoftdata/browser-event'
	import { getDefaultValidatorArgs, type ValidatorArgs } from '@isoftdata/svelte-action-validate'

	export let valueType: ValueType
	export let value: string

	export let id: string = uuid()
	export let defaultChoice: string | undefined = undefined
	export let disabled = false
	export let choices: Array<string> | Promise<Array<string>> = []
	export let placeholder: string = ''
	export let required = false
	export let label = ''
	export let showLabel = true
	export let validation: ValidatorArgs<string> = {
		...getDefaultValidatorArgs(),
		validator: value => (showLabel ? !!value : !!value || ''),
		value,
	}

	$: inputType = getInputType(valueType)

	const dispatch = createEventDispatcher<{
		change: { value: string }
	}>()
	const { t: translate } = getContext<i18n>('i18next')

	function getInputType(valueType: ValueType): 'number' | 'date' | 'time' | 'datetime-local' | 'text' {
		switch (valueType) {
			case 'NUMBER':
			case 'INTEGER':
			case 'CURRENCY':
				return 'number'
			case 'DATE':
				return 'date'
			case 'TIME':
				return 'time'
			case 'DATETIME':
				return 'datetime-local'
			default:
				return 'text'
		}
	}

	onMount(() => {
		if (!value && defaultChoice) {
			value = defaultChoice
		}
	})
</script>

{#if valueType === 'CHOICE'}
	<Select
		{id}
		{label}
		{required}
		{showLabel}
		{disabled}
		emptyValue=""
		options={choices}
		bind:value
		on:change={event => dispatch('change', { value: getEventValue(event) })}
		let:option
	>
		{#if option}
			<option value={option}>{option}</option>
		{/if}
	</Select>
{:else if valueType === 'BOOLEAN'}
	{@const valid = !required || validation.validator(value)}
	<div>
		<Checkbox
			{id}
			{label}
			{required}
			{showLabel}
			{disabled}
			type="radio"
			btnGroupClass="w-100"
			trueLabel={translate('workOrder.true', 'True')}
			falseLabel={translate('workOrder.false', 'False')}
			checked={stringToBoolean(value)}
			on:change={event => {
				const checked = stringToBoolean(getEventValue(event))
				value = checked ? 'True' : 'False'
				dispatch('change', { value })
			}}
		>
			<svelte:fragment slot="hint">
				{#if !valid}
					<small class="text-danger">{translate('common:required', 'Required')}</small>
				{/if}
			</svelte:fragment>
		</Checkbox>
		{#if !showLabel && !valid}
			<Icon
				prefix="far"
				class="text-danger"
				icon="circle-exclamation"
				title={translate('workOrder.missingRequiredBooleanValue', 'Value is required, but is not filled out.')}
			></Icon>
		{/if}
	</div>
{:else}
	<Input
		{id}
		{label}
		{required}
		{showLabel}
		{disabled}
		{placeholder}
		{validation}
		type={inputType}
		{value}
		on:change={e => {
			// Get value as a string even if type="number"
			value = getEventValue(e)
			dispatch('change', { value })
		}}
	></Input>
{/if}
