export default {
    "name": "ReportSubscriptionParameters",
    "kind": "HoudiniQuery",
    "hash": "0364cf25612089c60d9b9767b566807c9f9a85b081e8c6a6481b3e98c043a02f",

    "raw": `query ReportSubscriptionParameters($reportSubscriptionId: PositiveInt!) {
  reportSubscriptionParameters(reportSubscriptionId: $reportSubscriptionId) {
    reportParameterId
    value
    reportParameter {
      required
      type
      visible
      label
      name
      editability
      options {
        id
        label
        value
        isDefault
      }
      id
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reportSubscriptionParameters": {
                "type": "ReportSubscriptionParameter",
                "keyRaw": "reportSubscriptionParameters(reportSubscriptionId: $reportSubscriptionId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "reportParameterId": {
                            "type": "PositiveInt",
                            "keyRaw": "reportParameterId",
                            "visible": true
                        },

                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        },

                        "reportParameter": {
                            "type": "ReportParameter",
                            "keyRaw": "reportParameter",

                            "selection": {
                                "fields": {
                                    "required": {
                                        "type": "Boolean",
                                        "keyRaw": "required",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "ParameterType",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "visible": {
                                        "type": "Boolean",
                                        "keyRaw": "visible",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "editability": {
                                        "type": "Editibility",
                                        "keyRaw": "editability",
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "ReportParameterOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Float",
                                                    "keyRaw": "id",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "isDefault": {
                                                    "type": "Boolean",
                                                    "keyRaw": "isDefault",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "reportSubscriptionId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1348b6008c652ad899f8d7ebd24873ad7546abe04009f6fdc68ca1b9ce52bc07";