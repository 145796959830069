import { Mediator } from 'client/services/api-fetch'

export async function getPasswordRules(mediator: Mediator): Promise<Record<string, number>> {
	const { getPasswordRules } = (await mediator.call(
		'apiFetch',
		`#graphql
            query Query {
                getPasswordRules {
                    minimumPasswordLength
                    maximumPasswordLength
                    numberOfRequiredLowercaseCharacters
                    numberOfRequiredUppercaseCharacters
                    numberOfRequiredNumericCharacters
                    numberOfRequiredSpecialCharacters
                }
            }
        `,
		{},
		{},
	)) as { getPasswordRules: Record<string, string> }

	const passwordRules = Object.entries(getPasswordRules).reduce((acc, [key, value]) => {
		const numberValue = Number(value)
		if (!isNaN(numberValue)) {
			acc[key] = numberValue
		}
		return acc
	}, {})

	return passwordRules
}
