export default {
    "name": "LoadProductAnalysisOptions",
    "kind": "HoudiniQuery",
    "hash": "39870dffbcad8ec5a33e6acd54c6055cdf024b83d8f6fbd25fd73db71e824700",

    "raw": `query LoadProductAnalysisOptions($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
  analysisOptions(filter: $filter, pagination: $pagination) {
    data {
      id
      active
      analysisId
      option
      unit
      valueType
      defaultValue
      defaultType
      thresholdType
      requiredToPerform
      requiredToClose
      informational
      rank
      productId
      rules {
        id
        analysisOptionId
        active
        restriction
        outcome
        description
        created
        tags {
          id
          name
          entityType
          active
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analysisOptions": {
                "type": "AnalysisOptionResponse",
                "keyRaw": "analysisOptions(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "AnalysisOption",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "analysisId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisId",
                                        "visible": true
                                    },

                                    "option": {
                                        "type": "String",
                                        "keyRaw": "option",
                                        "visible": true
                                    },

                                    "unit": {
                                        "type": "String",
                                        "keyRaw": "unit",
                                        "visible": true
                                    },

                                    "valueType": {
                                        "type": "ValueType",
                                        "keyRaw": "valueType",
                                        "visible": true
                                    },

                                    "defaultValue": {
                                        "type": "String",
                                        "keyRaw": "defaultValue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultType": {
                                        "type": "DefaultType",
                                        "keyRaw": "defaultType",
                                        "visible": true
                                    },

                                    "thresholdType": {
                                        "type": "ThresholdType",
                                        "keyRaw": "thresholdType",
                                        "visible": true
                                    },

                                    "requiredToPerform": {
                                        "type": "Boolean",
                                        "keyRaw": "requiredToPerform",
                                        "visible": true
                                    },

                                    "requiredToClose": {
                                        "type": "Boolean",
                                        "keyRaw": "requiredToClose",
                                        "visible": true
                                    },

                                    "informational": {
                                        "type": "Boolean",
                                        "keyRaw": "informational",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "productId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "productId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "rules": {
                                        "type": "AnalysisOptionRule",
                                        "keyRaw": "rules",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "analysisOptionId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "analysisOptionId",
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "restriction": {
                                                    "type": "RestrictionType",
                                                    "keyRaw": "restriction",
                                                    "visible": true
                                                },

                                                "outcome": {
                                                    "type": "OutcomeType",
                                                    "keyRaw": "outcome",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "Timestamp",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "tags": {
                                                    "type": "EntityTag",
                                                    "keyRaw": "tags",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "entityType": {
                                                                "type": "EntityTagType",
                                                                "keyRaw": "entityType",
                                                                "visible": true
                                                            },

                                                            "active": {
                                                                "type": "Boolean",
                                                                "keyRaw": "active",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "AnalysisOptionFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "AnalysisOptionFilter": {
                "activeOnly": "Boolean",
                "analysisIds": "PositiveInt",
                "ids": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=3adb2105eb15ce80ff0d0ab5b00b4bffc9fac97ba4c9f49c20897348eed634a4";