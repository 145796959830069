export default {
    "name": "AmDeleteEntityTags",
    "kind": "HoudiniMutation",
    "hash": "8202f5d0eb60bd3adf970988654e22a9ef66fc9d996c4f787079db9aeb151072",

    "raw": `mutation AmDeleteEntityTags($ids: [PositiveInt!]!) {
  deleteEntityTags(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteEntityTags": {
                "type": "Boolean",
                "keyRaw": "deleteEntityTags(ids: $ids)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "ids": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=5fe401bada83588bbe8905598ecc47dad92a4a9f54c822f94f92a395b645db06";