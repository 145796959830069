export type ErrorWithExtensions = Error & { extensions: Record<string, unknown> }
export type ErrorWithCode = Error & { extensions: { code: string } }

export function isError(value): value is Error {
	return value instanceof Error
}

export function isErrorWithCause(value): value is Error & { cause: unknown } {
	return isError(value) && 'cause' in value
}

export function isErrorWithExtensions(value): value is ErrorWithExtensions {
	return isError(value) && 'extensions' in value
}

export function isErrorWithCode(value): value is ErrorWithCode {
	return isErrorWithExtensions(value) && 'code' in value.extensions && typeof value.extensions.code === 'string'
}

// Houdini re-throws our GQL errors as a new error with the original error as the cause, so we need to check the cause (which is not necessarily an error) for the error code
export function isObjectWithExtensions(value): value is { extensions: Record<string, unknown> } {
	return typeof value === 'object' && value !== null && 'extensions' in value
}

export function isObjectWithCode(value): value is { extensions: { code: string } } {
	return isObjectWithExtensions(value) && 'code' in value.extensions
}

export function getErrorCode(error: unknown) {
	if (isErrorWithCode(error)) {
		return error.extensions.code
	}

	if (isErrorWithCause(error)) {
		return isObjectWithCode(error.cause) ? error.cause.extensions.code : null
	}
}
