import { writable } from 'svelte/store'
/** Track which child items need to be added to/removed from a parent item. */
export default function makeAddRemoveStore<ChildKeyType = string>(initialValue: AddRemoveObject<ChildKeyType> = {}) {
	const theStore = writable<AddRemoveObject<ChildKeyType>>(initialValue)
	let theStoreValue = initialValue
	return {
		subscribe: theStore.subscribe,
		set: (value: AddRemoveObject<ChildKeyType>) => {
			theStore.set(value)
			theStoreValue = value
		},
		/**
		 * Add a child item to a parent item. Also removes it from the `remove` list as well, if it is there.
		 * @param parentUuid The UUID of the parent item.
		 * @param childUuid The UUID of the child item to add.
		 */
		add: (parentUuid: string, childUuid: ChildKeyType) => {
			if (!parentUuid || !childUuid) {
				console.error('AddRemoveStore.add called with invalid parameters', parentUuid, childUuid)
				return
			}
			theStore.update(store => {
				if (!store[parentUuid]) {
					store[parentUuid] = { add: [], remove: [] }
				}
				store[parentUuid].add.push(childUuid)
				store[parentUuid].remove = store[parentUuid].remove.filter(uuid => uuid !== childUuid)
				theStoreValue = store
				return store
			})
		},
		/**
		 * Remove a child item from a parent item. Also removes it from the `add` list as well, if it is there.
		 * @param parentUuid The UUID of the parent item.
		 * @param childUuid The UUID of the child item to remove.
		 */
		remove: (parentUuid: string, childUuid: ChildKeyType) => {
			if (!parentUuid || !childUuid) {
				console.error('AddRemoveStore.remove called with invalid parameters', parentUuid, childUuid)
				return
			}
			theStore.update(store => {
				if (!store[parentUuid]) {
					store[parentUuid] = { add: [], remove: [] }
				}
				store[parentUuid].remove.push(childUuid)
				store[parentUuid].add = store[parentUuid].add.filter(uuid => uuid !== childUuid)
				theStoreValue = store
				return store
			})
		},
		/**
		 * Get the IDs of all child items that need to be added to a parent item.
		 * @param parentUuid The UUID of the parent item.
		 * @param list (Optional) The list of child items.
		 * @param idKey (Optional) The key of the ID property on the child items.
		 */
		getAddIds: <T extends { uuid: string }, K extends keyof T>(parentUuid: string, list?: Array<T>, idKey?: K) => {
			const childIdsToAdd = theStoreValue[parentUuid]?.add ?? []

			if (!list || !idKey) {
				return childIdsToAdd
			}

			return childIdsToAdd.map(uuid => list.find(item => item.uuid === uuid)?.[idKey]).filter(Boolean) as ChildKeyType[]
		},
		/**
		 * Get the IDs of all child items that need to be removed from a parent item.
		 * @param parentUuid The UUID of the parent item.
		 * @param list (Optional) The list of child items.
		 * @param idKey (Optional) The key of the ID property on the child items.
		 */
		getRemoveIds: <T extends { uuid: string }, K extends keyof T>(parentUuid: string, list?: Array<T>, idKey?: K) => {
			const uuidsToRemove = theStoreValue[parentUuid]?.remove ?? []
			if (!list || !idKey) {
				return uuidsToRemove
			}
			return uuidsToRemove.map(uuid => list.find(item => item.uuid === uuid)?.[idKey]).filter(Boolean) as ChildKeyType[]
		},
		clear: () => {
			theStore.set({})
			theStoreValue = {}
		},
	}
}

export type AddRemoveObject<ChildKeyType> = { [parentUuid: string]: { add: ChildKeyType[]; remove: ChildKeyType[] } }

export type AddRemoveStore<ChildKeyType = string> = ReturnType<typeof makeAddRemoveStore<ChildKeyType>>
