import artifact from '$houdini/artifacts/DetachFilesFromSample'
import { MutationStore } from '../runtime/stores/mutation'

export class DetachFilesFromSampleStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
