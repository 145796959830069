export default {
    "name": "getValueAcceptability",
    "kind": "HoudiniQuery",
    "hash": "703aa429edefe88ee137194ee8bf5188b59e26f0970b4ed170abbbd201f3d51f",

    "raw": `query getValueAcceptability($currentResult: String!, $productBatchId: PositiveInt, $analysisOptionId: PositiveInt!, $productId: PositiveInt, $severityClassId: PositiveInt, $plantId: PositiveInt) {
  getValueAcceptability(
    currentResult: $currentResult
    productBatchId: $productBatchId
    analysisOptionId: $analysisOptionId
    productId: $productId
    severityClassId: $severityClassId
    plantId: $plantId
  )
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getValueAcceptability": {
                "type": "Acceptability",
                "keyRaw": "getValueAcceptability(analysisOptionId: $analysisOptionId, currentResult: $currentResult, plantId: $plantId, productBatchId: $productBatchId, productId: $productId, severityClassId: $severityClassId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "currentResult": "String",
            "productBatchId": "PositiveInt",
            "analysisOptionId": "PositiveInt",
            "productId": "PositiveInt",
            "severityClassId": "PositiveInt",
            "plantId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=5cdc167bf634d766bf3b4b4606560fcda38230e363c711d934608190ca6585fd";