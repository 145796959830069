<script lang="ts">
	import type { Colors } from '@isoftdata/utility-bootstrap'
	export let maxChars: number = 25
	export let text: string
	export let color: Colors = 'primary'
	export let disabled = false
	let className: string = ''
	export let expanded = false
	export { className as class }
	$: expandable = text.length > maxChars + 3 && !disabled
	const baseStyle = 'text-overflow: ellipsis; overflow: hidden;'
	$: style = `${baseStyle} max-width: ${expanded && expandable ? '100%' : `${maxChars}ch`};`
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<span
	class="badge badge-{color} {className}"
	class:cursor-pointer={expandable}
	{style}
	aria-label={text}
	title={text}
	on:click={event => {
		if (expandable) {
			event.stopPropagation()
			expanded = !expanded
		}
	}}
	>{text}
</span>
