import template from './results.ractive.html'
import makeItTable from '@isoftdata/table'
import makeItCheckbox from '@isoftdata/checkbox'
import makeItTableCardFooter from '@isoftdata/table-card-footer'
import makeItButton from '@isoftdata/button'
import { stringToBoolean, pluralIfNeeded } from '@isoftdata/utility-string'
import { booleanToString } from '@isoftdata/utility-boolean'
import setUserSetting from 'utility/set-user-setting'
import { klona } from 'klona'
import valueAcceptabilityMap from 'utility/value-acceptability-map'
import makeImageThumbnail from '@isoftdata/image-thumbnail-viewer'
import makeImageViewerModal from '@isoftdata/image-viewer-modal'
import { encode as encodeCsvLine } from 'csv-line'
import camelCase from 'just-camel-case'
import { parseISO } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import pMap from 'p-map'
import { getSession } from 'stores/session'

const FILE_BASE_URL = '__apiUrl__'.replace('/graphql', '')

function computeSampleValue(sampleValue) {
	return {
		...sampleValue,
		filledOut: sampleValue.filledOut ? parseISO(sampleValue.filledOut) : null,
		lastModified: sampleValue.lastModified ? parseISO(sampleValue.lastModified) : null,
		expiration: sampleValue.expiration ? parseISO(sampleValue.expiration) : null,
		analysisOptionName: sampleValue.analysisOption?.option,
	}
}

function computeWorkOrder(workOrder) {
	if (workOrder) {
		return {
			...workOrder,
			due: workOrder.due ? parseISO(workOrder.due) : null,
			verificationDue: workOrder.verificationDue ? parseISO(workOrder.verificationDue) : null,
			dateCreated: parseISO(workOrder.dateCreated),
			verifiedOn: workOrder.verifiedOn ? parseISO(workOrder.verifiedOn) : null,
			scheduled: workOrder.scheduled ? parseISO(workOrder.scheduled) : null,
		}
	}
	return null
}

const interfaceSettingMap = new Map([
	[ 'optionsAndValues', 'Show analysis options and values in sample detail' ],
	[ 'collectionDetails', 'Show collection information in sample detail' ],
	[ 'workOrderDetails', 'Show work order information in sample detail' ],
	[ 'testingDetails', 'Show testing information in sample detail' ],
	[ 'showImageThumbnails', 'Show image thumbnails in sample detail' ],
])

function computeSample(sample) {
	return {
		...sample,
		workOrder: computeWorkOrder(sample.workOrder),
		sampleValues: sample.sampleValues.map(computeSampleValue),
		incubationBegan: sample.incubationBegan ? parseISO(sample.incubationBegan) : null,
		incubationEnded: sample.incubationEnded ? parseISO(sample.incubationEnded) : null,
		performed: sample.performed ? parseISO(sample.performed) : null,
		due: sample.due ? parseISO(sample.due) : null,
		scheduled: sample.scheduled ? parseISO(sample.scheduled) : null,
	}
}

const samplesQuery = `#graphql
	query SampleValues($filter: SampleFilter, $pagination: PaginatedInput, $orderBy: [SampleOrderBy!]) {
 		samples(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
			info {
				pageNumber
				pageSize
				totalItems
				totalPages
			}
			data {
				sampleFiles {
				  file {
					path
					mediaType: type
				  }
				  rank
				}
				plant {
					name
					code
				}

				product {
				  name
				  id
				}
				location {
				  id
				  location
				  description
				}
				collectedBy {
					fullName
				}
				findings
				comments
				status
				tagNumber

				sampleValues {
					result
					resultStatus
					defaultValue
					lastModifiedByUserId
					lastModified
					lot
					expiration
					filledOut
					analysisOptionId
					analysisOption {
						id
						option
					}
				}
				id
				workOrder {
					id
					title
					verificationDue
					dateCreated
					due
					internalNotes
					instructions
					scheduled
					assignedToGroup {
						name
					}
					workOrderType {
						name
						showTestingDetail
					}
					productBatch {
						product {
							name
						}
					}
				}
				analysisId
				incubationBegan
				incubationEnded
				performed
				scheduled
      			due
				platesReadBy {
					fullName
				}
			}
		}
	}
`

const userInterfaceSettingsQuery = `#graphql
	query Session {
		session {
			user {
				optionsAndValues: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show analysis options and values in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
				collectionInformation: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show collection information in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
				workOrderInformation: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show work order information in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
				testingInformation: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show testing information in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
				showImageThumbnails: getUserSetting(
					lookup: {
						category: "Sampling"
						name: "Show image thumbnails in sample detail"
						settingType: INTERFACE_HISTORY
						defaultValue: "True"
					}
				) {
					value
				}
			}
		}
	}
`

// Have to map client column property names to db property names for sorting
const tableColumnSortNames = {
	performedOn: 'performed',
	scheduled: 'scheduled',
	locationName: 'location',
	//productName: 'product.name',
	//analysisName: 'analysis.name',
}

function computeSamplesWithDependencies(samples = [], analysisListMap, maxAnalysisOptionsCount, timezone, parseSamplesJson = false) {
	return samples.map(sample => {
		if (parseSamplesJson) {
			//This will parse values such as date strings to actual JS Date objects
			sample = computeSample(sample)
		}

		let { id, sampleValues, workOrder, analysisId, incubationBegan, plant, product, location, incubationEnded, sampleFiles, status, ...rest } = sample

		workOrder = workOrder ? {
			workOrderTitle: workOrder.title,
			workOrderId: workOrder.id,
			workOrderType: workOrder.workOrderType.name,
			workOrderInternalNotes: workOrder.internalNotes,
			workOrderInstructions: workOrder.instructions,
			workOrderCreated: workOrder.dateCreated,
			displayWorkOrderCreated: workOrder.dateCreated ? toZonedTime(workOrder.dateCreated, timezone).toLocaleString() : '',
			workOrderDue: workOrder.due,
			displayWorkOrderDue: workOrder.due ? toZonedTime(workOrder.due, timezone).toLocaleString() : '',
			workOrderScheduled: workOrder.scheduled,
			displayWorkOrderScheduled: workOrder.scheduled ? toZonedTime(workOrder.scheduled, timezone).toLocaleString() : '',
			workOrderGroupAssigned: workOrder.assignedToGroup?.name,
			workOrderApprovedBy: workOrder.verifiedByUser?.fullName,
			workOrderApprovedOn: workOrder.verifiedOn,
			workOrderBatch: workOrder.productBatch?.product?.name,
		} : {}

		const analysis = klona(analysisListMap.get(analysisId))

		//{ property: `option${i + 1}`, name: `Option ${i + 1}` })
		let options = []

		if (analysis) {
			for (let i = 0; i < maxAnalysisOptionsCount; i++) {
				if (analysis.options?.[i]) {
					const optionId = analysis.options?.[i].id
					const sampleValue = sampleValues.find(sampleValue => sampleValue.analysisOptionId === optionId)
					analysis.options[i].values = sampleValue
				}

				options.push(analysis.options?.[i])
			}
		}

		let thumbnailFile = { path: sampleFiles[0]?.file?.path }
		if (thumbnailFile.path) {
			thumbnailFile.path = FILE_BASE_URL + sampleFiles[0].file.path
		}

		return {
			imageCount: sampleFiles.length,
			thumbnailFile,
			files: sampleFiles.map(({ file }) => FILE_BASE_URL + file.path),
			id,
			analysis,
			sampleValues, //TODO flatten this
			displayIncubationBegan: incubationBegan ? incubationBegan.toLocaleString() : '',
			displayIncubationEnded: incubationEnded ? incubationEnded.toLocaleString() : '',
			analysisName: analysis?.name || '',
			plantName: plant.name,
			productName: product?.name,
			locationName: location?.location,
			options,
			status: `${status.charAt(0).toUpperCase()}${status.toLowerCase().slice(1)}`,
			...rest,
			...workOrder,
		}
	})
}

export default ({ mediator, stateRouter, i18next }) => {
	let csvExport = null //we need to keep a reference to the csv export so we can revoke it if the user navigates away. There's multiple ways they could "navigate away",
	//so we need to handle navigating to a different state(see active.on('deactivate') below), and also navigating to the same state with different parameters(see the resolve function below)

	stateRouter.addState({
		name: 'app.sampling-history.results',
		route: 'results',
		querystringParameters: [ 'filter', 'uuid', 'pageNumber', 'orderBy', 'sortDirection' ],
		defaultParameters: {
			pageNumber: 1,
		},
		template: {
			template,
			translate: i18next.t,
			camelCase,
			components: {
				itTable: makeItTable({
					stickyHeader: true,
					methods: {
						columnClicked(column, direction) {
							try {
								let orderByProp = column.property
								const orderByDirection = direction

								// Some properties need to be renamed for the api
								if (orderByProp in tableColumnSortNames) {
									orderByProp = tableColumnSortNames[orderByProp]
								}

								stateRouter.go(null, { orderBy: orderByProp, sortDirection: orderByDirection, pageNumber: 1 }, { inherit: true })
							} catch (err) {
								console.error(err)
							}
						},
					},
				}),
				itCheckbox: makeItCheckbox(),
				itTableCardFooter: makeItTableCardFooter(),
				itButton: makeItButton(),
				imageThumbnail: makeImageThumbnail(),
				imageViewerModal: makeImageViewerModal(),
			},
			getDateTimeString() {
				const now = new Date()
				return now.toLocaleString()
			},
			async generateCsvExport() {
				const totalItems = this.get('totalItems')
				// Trying to get a CSV for too many results will take forever
				if (totalItems > 1000 && !confirm(`This will generate a CSV file with ${totalItems} rows. Are you sure you want to continue?`)) {
					return
				}

				const pageSize = this.get('pageSize')

				this.set({ isLoadingCsvExport: true })
				const results = []
				// page # -> results
				const pages = {}
				try {
					let currentPage = 1
					await pMap(Array.from({ length: Math.ceil(totalItems / pageSize) }, (_, i) => i + 1), async pageNumber => {
						if (pageNumber > currentPage) {
							mediator.call('showMessage', {
								heading: i18next.t('samplingHistory.csvExportProgress', 'Loading Data for CSV Export...'),
								message: i18next.t('samplingHistory.csvExportProgressMessage', 'Loading page {{pageNumber}} of {{totalPages}}...', { pageNumber, totalPages: Math.ceil(totalItems / pageSize) }),
								type: 'info',
								time: false,
							})
							currentPage = pageNumber
						}
						const pagedResults = await mediator.call('apiFetch', samplesQuery, {
							filter: this.get('filter'),
							pagination: { pageSize, pageNumber },
						})
						pages[pageNumber] = pagedResults.samples.data
					}, { concurrency: 2 })
					Object.values(pages).forEach(page => results.push(...page))
				} catch (err) {
					console.error(err)
					this.set({ isLoadingCsvExport: false })
					mediator.call('showMessage', {
						heading: i18next.t('samplingHistory.csvExportError', 'Error Loading Data for CSV Export'),
						message: err.message,
						type: 'danger',
						time: false,
					})
					return
				}

				const analysisListMap = this.get('analysisListMap')
				const computedSamples = computeSamplesWithDependencies(results, analysisListMap, this.get('maxAnalysisOptionsCount'), this.get('plantTimezone'), true)
				const analysisIdsInResults = [ ...new Set(computedSamples.map(result => result.analysis?.id)) ]

				let headers = []
				let sampleValueHeaders = []

				const samplesForCsv = computedSamples.map((sample, sampleIndex) => {
					let output = {
						'Analysis': sample.analysis?.name,
						'Plant': sample.plantName,
						'Product': sample.productName,
						'Location': sample.locationName,
						'Location Description': sample.location?.description,
						'Testing Comments': sample.comments,
						'Sampling Comments': sample.findings,
						'Status': sample.status,
						'Scheduled': sample.displayWorkOrderScheduled,
						'Due': sample.displayWorkOrderDue,
						'Performed On': sample.performed?.toLocaleString?.(),
						'Performed By': sample.collectedBy?.fullName,
						'Tag #': sample.tagNumber,
						'Testing Began': sample.displayIncubationBegan,
						'Testing Ended': sample.displayIncubationEnded,
						'Tested By': sample.platesReadBy?.fullName || 'Unknown',
					}

					if (sampleIndex === 0) {
						headers = Object.keys(output)
					}

					let sampleValues = []

					const analysis = analysisListMap.get(sample.analysis.id)
					const validAnalysisOptionIds = analysis.options.map(option => option.id)

					//we have to use our own interator value instead of the sampleValue array's index because the sampleValue may not be in the validAnalysisOptionIds array
					let index = 0
					sample.sampleValues.forEach(sampleValue => {
						if (validAnalysisOptionIds.includes(sampleValue.analysisOptionId)) {
							if (analysisIdsInResults.length > 1) {
								//divide by 2 because for each option we have 2 columns, Label and Value
								if ((sampleValueHeaders.length / 2) < (index + 1)) {
									sampleValueHeaders.push(`Label ${sampleValueHeaders.length + 1}`)
									sampleValueHeaders.push(`Result ${sampleValueHeaders.length + 1}`)
								}
								sampleValues.push(sampleValue.analysisOptionName)
								sampleValues.push(sampleValue.result)
							} else {
								//Here, since we only have a single analysis, we can just use the analysis option names as the column header.
								//only a single column per option(we don't need a label column)
								if (sampleValueHeaders.length < (index + 1)) {
									sampleValueHeaders.push(sampleValue.analysisOptionName)
								}
								sampleValues.push(sampleValue.result)
							}
							index++
						}
					})

					return [ ...Object.values(output), ...sampleValues ]
				})

				const csv = [ [ ...headers, ...sampleValueHeaders ], ...samplesForCsv ].map(sample => encodeCsvLine(sample)).join('\n')

				this.set({
					csvExport: URL.createObjectURL(new Blob([ csv ])),
					isLoadingCsvExport: false,
				})

				csvExport = this.get('csvExport')
				mediator.call('showMessage', {
					heading: i18next.t('samplingHistory.csvExportReady', 'CSV Generated!'),
					message: i18next.t('samplingHistory.csvExportReadyMessage', 'The CSV file is ready for download.'),
					type: 'success',
					time: 10,
				})
			},
			computed: {
				maxAnalysisOptionsCount() {
					const analysisIdsInResults = this.get('analysisIdsInResults')
					const fullAnalysesList = this.get('analysisList')

					const analysesInResults = fullAnalysesList.filter(analysis => analysisIdsInResults.includes(analysis.id))
					let maxOptionsCount = Math.max(...analysesInResults.map(analysis => analysis.options.length))
					maxOptionsCount = maxOptionsCount > 0 ? maxOptionsCount : 0

					return maxOptionsCount
				},
				optionsAndValuesColumns() {
					let columns = []
					const analysisIdsInResults = this.get('analysisIdsInResults')
					if (analysisIdsInResults.length === 1) {
						const analysisListMap = this.get('analysisListMap')
						const analysis = analysisListMap.get(analysisIdsInResults[0])
						if (analysis) {
							analysis.options.forEach((option, index) => {
								columns.push({ property: `option${index}`, name: `${option.option}`, columnMinWidth: "8rem", sortType: false })
							})
						}
					} else {
						const maxAnalysisOptionsCount = this.get('maxAnalysisOptionsCount')

						for (let i = 0; i < maxAnalysisOptionsCount; i++) {
							columns.push({ property: `option${i}`, name: `${i18next.t('common:option', 'Option')} ${i + 1}`, columnMinWidth: "8rem", sortType: false	})
						}
					}

					return columns
				},
				resultsColumns() {
					const showColumns = this.get('showColumns')
					const optionsAndValuesColumns = this.get('optionsAndValuesColumns')

					let columns = [
						{ property: 'analysisName', name: i18next.t('common:analysis', 'Analysis'), sortType: false },
						{ property: 'imageCount', icon: 'fas fa-photo-video', sortType: false },
						{ property: 'plantName', name: i18next.t('common:plant', 'Plant') },
						{ property: 'productName', name: i18next.t('samplingHistory.results.table.column.IngredientOrProduct', 'Ingredient/Product'), sortType: false },
						{ property: 'locationName', name: i18next.t('common:location', 'Location') },
						{ property: 'findings', name: i18next.t('common:findings', 'Findings') },
						{ property: 'comments', name: i18next.t('common:comments', 'Comments') },
						{ property: 'status', name: i18next.t('common:status', 'Status') },
						{ property: 'tagNumber', name: i18next.t('common:tagNumber', 'Tag #') },
					]

					if (showColumns.optionsAndValues) {
						columns.push(...optionsAndValuesColumns)
					}

					if (showColumns.collectionDetails) {
						//TODO
						columns.push(...[
							{ property: 'scheduled', name: i18next.t('common:scheduled', 'Scheduled') },
							{ property: 'due', name: i18next.t('common:due', 'Due') },
							{ property: 'performedOn', name: i18next.t('common:performedOn', 'Performed On') },
						])
					}

					if (showColumns.testingDetails && this.get('showTestingDetail')) {
						columns.push(...[
							{ property: 'incubationBegan', name: i18next.t('common:incubationBegan', 'Incubation Began'), sortType: false },
							{ property: 'incubationEnded', name: i18next.t('common:incubationEnded', 'Incubation Ended'), sortType: false },
							{ property: 'platesReadBy', name: i18next.t('common:platesReadBy', 'Plates Read By'), sortType: false },
						])
					}

					if (showColumns.workOrderDetails) {
						columns.push(...[
							{ property: 'workOrderTitle', name: `${i18next.t('common:abbreviations.workorder', 'WO')} ${i18next.t('common:title', 'Title')}`, sortType: false },
							{ property: 'workOrderId', name: `${i18next.t('common:abbreviations.workorder', 'WO')} #`, sortType: false },
							{ property: 'workOrderType', name: `${i18next.t('common:abbreviations.workorder', 'WO')} ${i18next.t('common:type', 'Type')}`, sortType: false },
							{ property: 'workOrderInternalNotes', name: i18next.t('common:internalNotes', 'Internal Notes'), sortType: false },
							{ property: 'workOrderInstructions', name: `${i18next.t('common:abbreviations.workorder', 'WO')} ${i18next.t('common:instructions', 'Instructions')}`, sortType: false },
							{ property: 'workOrderCreated', name: `${i18next.t('common:abbreviations.workorder', 'WO')} ${i18next.t('common:created', 'Created')}`, sortType: false },
							{ property: 'workOrderDue', name: `${i18next.t('common:abbreviations.workorder', 'WO')} ${i18next.t('common:due', 'Due')}`, sortType: false },
							{ property: 'workOrderScheduled', name: `${i18next.t('common:abbreviations.workorder', 'WO')} ${i18next.t('common:scheduled', 'Scheduled')}`, sortType: false },
							{ property: 'workOrderGroupAssigned', name: i18next.t('samplingHistory.results.table.column.groupAssigned', 'Group Assigned'), sortType: false },
							{ property: 'workOrderApprovedBy', name: i18next.t('common:approvedBy', 'Approved By'), sortType: false },
							{ property: 'workOrderApprovedOn', name: i18next.t('common:approvedOn', 'Approved On'), sortType: false },
							{ property: 'workOrderBatch', name: i18next.t('common:batch', 'Batch'), sortType: false },
						])
					}

					return columns
				},
				showTestingDetail() {
					return this.get('results').some(result => result?.workOrder?.workOrderType?.showTestingDetail)
				},
				analysisIdsInResults() {
					return [ ...new Set(this.get('results').map(result => result.analysisId)) ]
				},
				analysisListMap() {
					let analysisListMap = new Map()
					this.get('analysisList').forEach(analysis => analysisListMap.set(analysis.id, analysis))
					return analysisListMap
				},
				displayResults() {
					//we need to flatten the structure for the table
					return computeSamplesWithDependencies(this.get('results'), this.get('analysisListMap'), this.get('maxAnalysisOptionsCount'), this.get('plantTimezone'))
				},
			},
			pluralIfNeeded,
		},
		async resolve(data, parameters) {
			if (csvExport) {
				URL.revokeObjectURL(csvExport)
			}

			const { plant: { timezone: plantTimezone } } = getSession()

			const filter = { ...JSON.parse(parameters.filter) }
			let pageNumber = parseInt(parameters.pageNumber, 10) || 1
			const pageSize = 100 // eventually I'd want to make this a user setting but as it stands now those load at the same time as the resutls themselves

			let orderBy = null
			if (parameters.orderBy) {
				orderBy = `${parameters.orderBy}_${parameters.sortDirection}`
			}

			const [ samplesResult, userInterfaceSettingsResult ] = await Promise.all([
				mediator.call('apiFetch', samplesQuery, { filter, pagination: { pageNumber, pageSize }, orderBy }),
				mediator.call('apiFetch', userInterfaceSettingsQuery),
			])
			const { samples: { data: samples, info: { totalItems, totalPages } } } = samplesResult
			const userInterfaceSettings = userInterfaceSettingsResult.session.user
			const results = samples.map(computeSample)

			return {
				filter,
				pageNumber,
				pageSize,
				totalItems,
				totalPages,
				imageViewerModal: {
					show: false,
					files: [],
					currentPhotoIndex: 0,
				},
				results,
				showColumns: {
					optionsAndValues: stringToBoolean(userInterfaceSettings.optionsAndValues.value),
					collectionDetails: stringToBoolean(userInterfaceSettings.collectionInformation.value),
					workOrderDetails: stringToBoolean(userInterfaceSettings.workOrderInformation.value),
					testingDetails: stringToBoolean(userInterfaceSettings.testingInformation.value),
				},
				analysisList: [],
				valueAcceptabilityMap,
				showImageThumbnails: stringToBoolean(userInterfaceSettings.showImageThumbnails.value),
				csvExport: null, //This will get set by the export button later
				isLoadingCsvExport: false,
				plantTimezone,
				toZonedTime,
			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext
			//ractive.observe('sortedResults', (newVal, oldVal, keypath) => console.log(keypath, newVal))

			ractive.on('showImages', (ractiveContext, sortedIndex) => {
				const files = ractive.get(`sortedResults.${sortedIndex}.files`)
				ractive.set({
					imageViewerModal: {
						show: true,
						files,
						currentPhotoIndex: 0,
					},
				})
			})

			ractive.on('itTable.paginationPageChange', ({ pageNumber }, cb) => {
				stateRouter.go(null, { pageNumber }, { inherit: true })
			})

			ractive.observe('showColumns.*', (value, _oldVal, keypath) => {
				const settingName = interfaceSettingMap.get(keypath.split('.')[1])
				setUserSetting(mediator, {
					name: settingName,
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					newValue: booleanToString(value),
				})
			}, { init: false })

			ractive.observe('showImageThumbnails', (value, _oldVal, keypath) => {
				const settingName = interfaceSettingMap.get(keypath)
				setUserSetting(mediator, {
					name: settingName,
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					newValue: booleanToString(value),
				})
			}, { init: false })

			window.emitter.emit('getAnalyses', analysisList => {
				ractive.set({ analysisList })
			})

			activateContext.on('destroy', () => {
				try {
					const csvExport = ractive.get('csvExport')

					if (csvExport) {
						URL.revokeObjectURL(csvExport)
					}
				} catch (err) {
					//swallow
				}
			})
		},
	})
}
