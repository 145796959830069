<script lang="ts">
	import type { OutcomeOrNone$options, ValueType$options } from '$houdini'
	import type { DisplaySample, Sample, WorkOrder } from '../states/work-order/work-order'
	import type { AnalysisOptionChoice, DocumentVersion } from 'client/states/work-order/edit/edit'
	import type { Mediator, i18n } from 'types/common'
	import type { EntityProxyMap } from 'utility/entity-proxy-map'

	type SampleValue = Sample['sampleValues'][number]

	import { v4 as uuid } from '@lukeed/uuid'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import { graphql } from '$houdini'

	import valueAcceptabilityMap, { acceptabilityToResultStatus } from 'utility/value-acceptability-map'
	import { slide } from 'svelte/transition'
	import financialNumber from 'financial-number'

	import Label from '@isoftdata/svelte-label'
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import Icon from '@isoftdata/svelte-icon'
	import Select from '@isoftdata/svelte-select'
	import { createEventDispatcher, getContext, tick } from 'svelte'
	import ThresholdTable from './ThresholdTable.svelte'

	export let workOrder: WorkOrder
	export let sample: Sample | DisplaySample
	// even though technically this should always be defined, I'm getting some weird errors about it being undefined sometimes, probably due to a race condition - it might get an undefined SV before it can un-render this component
	export let sampleValue: SampleValue | undefined
	export let id = uuid()
	export let labelType: 'COMPACT' | 'NORMAL' = 'COMPACT'
	export let disabled = false
	export let showModifiedIcons: boolean = true
	export let showLabel: boolean = true
	export let restriction: OutcomeOrNone$options = 'NONE'
	/** This should match the global setting Scanner: showthresholds*/
	export let allowShowThresholdsTable: boolean = false
	export let required: false | 'PERFORM' | 'CLOSE' = false
	export let document: DocumentVersion | null = null
	export let choicesMap: EntityProxyMap<AnalysisOptionChoice>
	let isLoading = false
	let thresholdTable: ThresholdTable | undefined = undefined
	let showThresholdTable = false
	let showDocument = false

	const dispatch = createEventDispatcher<{
		change: { value: string }
	}>()

	const { t: translate } = getContext<i18n>('i18next')

	const mediator = getContext<Mediator>('mediator')
	const fileBaseUrl = getContext<string>('fileBaseUrl')

	// We only care about the choices if the valueType === 'CHOICE', and only for displaying them in a dropdown, so load them just-in-time
	let choices: Array<AnalysisOptionChoice> = []
	$: Promise.resolve(sampleValue?.analysisOption.valueType === 'CHOICE' ? choicesMap[sampleValue.analysisOption.id] : []).then(value => (choices = value))

	$: valueType = sampleValue?.analysisOption.valueType ?? 'TEXT'
	$: edited = showModifiedIcons && !!sampleValue?.filledOut && sampleValue?.filledOut !== sampleValue?.lastModified
	$: acceptabilityObject = valueAcceptabilityMap.get(sampleValue?.resultStatus ?? 'NOT_CALCULATED')!
	$: ({ icon: iconClass, colorClass: color, label } = acceptabilityObject)
	$: inputType = getInputType(valueType)
	$: labelClass = labelType === 'COMPACT' ? 'badge px-0' : ''
	$: optionLabel = sampleValue?.analysisOption.unit ? `${sampleValue.analysisOption.option} (${sampleValue.analysisOption.unit})` : (sampleValue?.analysisOption.option ?? '')
	$: acceptabilityButtonDisabled = isDisabled || !allowShowThresholdsTable

	$: isRequired = required === 'PERFORM' || required === 'CLOSE' || restriction === 'REQUIRED_TO_CLOSE' || restriction === 'REQUIRED_TO_PERFORM'
	$: isDisabled = disabled || restriction === 'HIDDEN' || restriction === 'INACTIVE'
	// for some reason isReadonly = false still makes the input readonly
	$: isReadonly = restriction === 'READONLY' ? true : undefined
	$: boolIsValid = valueType === 'BOOLEAN' && (!isRequired || sampleValue?.result === 'True' || sampleValue?.result === 'False')
	$: requiredTooltip =
		required === 'PERFORM' || restriction === 'REQUIRED_TO_PERFORM' ? translate('workOrder.requiredToPerform', 'Required to Perform') : translate('workOrder.requiredToClose', 'Required to Close')

	$: formattedLastModified = sampleValue?.lastModified ? new Date(sampleValue.lastModified).toLocaleString() : ''
	$: formattedFilledOut = sampleValue?.filledOut ? new Date(sampleValue.filledOut).toLocaleString() : ''

	function validator<T>(value: string | T) {
		return !!value ? true : showLabel ? `${requiredTooltip}` : ''
	}

	function makeOptions(choices: Array<AnalysisOptionChoice>, result: string) {
		const options = choices.filter(shouldShowChoice).map(choice => choice.choice)
		if (result && !options.includes(result)) {
			options.push(result)
		}
		return options
	}

	function shouldShowChoice(choice: AnalysisOptionChoice) {
		// Plant must match if present (null plantId = global)
		if (choice.plantId && choice.plantId !== sample.plant?.id) {
			return false
		}
		// If no location, only show choices with no SC or default SC
		if (!sample.location && choice.severityClass && !choice.severityClass.default) {
			return false
		}
		// If location, only show choices with no SC or matching SC
		if (sample.location && choice.severityClass?.id && choice.severityClass?.id !== sample.location.severityClass?.id) {
			return false
		}
		// Product must match if present
		if (choice.productId && choice.productId !== sample.product?.id) {
			return false
		}
		// Product batch must match if present
		if (choice.productBatchId && choice.productBatchId !== workOrder.productBatch?.id) {
			return false
		}
		// Required Option/Choice/Constraint must match if present
		if (choice.requiredAnalysisOptionId && choice.requiredChoice && choice.requiredConstraint) {
			const requiredOptionValue = sample.sampleValues.find(sv => sv && sv.analysisOption.id === choice.requiredAnalysisOptionId)
			let result = requiredOptionValue?.result?.toString() ?? ''
			if (
				(choice.requiredConstraint === 'MAXIMUM' && !(result < choice.requiredChoice)) ||
				(choice.requiredConstraint === 'MINIMUM' && !(result > choice.requiredChoice)) ||
				// Seems like the desktop treats absent values as '0' or '' when testing equality so we need to check for both
				(choice.requiredConstraint === 'NOT_EQUAL' && (result === choice.requiredChoice || (result || '0') === choice.requiredChoice)) ||
				(choice.requiredConstraint === 'NONE' && result !== choice.requiredChoice && (result || '0') !== choice.requiredChoice)
			) {
				return false
			}
		}
		return true
	}

	async function onAcceptabilityClick() {
		showThresholdTable = !showThresholdTable && allowShowThresholdsTable
		await tick()
		await getThresholdTableData()
	}

	function onDocumentClick() {
		showDocument = !showDocument && !!document
	}

	async function getThresholdTableData() {
		await tick() // tick so data & UI are up to date
		if (showThresholdTable && sampleValue) {
			await thresholdTable?.loadData?.({
				analysisOptionId: sampleValue.analysisOption.id,
				currentResult: sampleValue.result,
				onlyApplicable: true,
				productBatchId: workOrder.productBatch?.id,
				productId: sample.product?.id,
				severityClassId: sample.location?.severityClass?.id,
				plantId: workOrder.plant?.id,
			})
		}
	}

	async function onValueChange(value: string) {
		if (!sampleValue) {
			return
		}
		isLoading = true
		try {
			// WO screen will update acceptability for all SVs, but we still need to fetch it for the table here.
			const [{ data }] = await Promise.all([
				getValueAcceptabilityQuery.fetch({
					variables: {
						currentResult: (value ?? '').toString(),
						productBatchId: workOrder.productBatch?.id,
						analysisOptionId: sampleValue.analysisOption.id,
						productId: sample.product?.id,
						plantId: workOrder.plant?.id,
						severityClassId: sample.location?.severityClass?.id,
					},
				}),
				await getThresholdTableData(),
				tick(),
			])
			if (data) {
				sampleValue.resultStatus = acceptabilityToResultStatus[data.getValueAcceptability]
				acceptabilityObject = valueAcceptabilityMap.get(sampleValue.resultStatus)!
			}
		} catch (err) {
			mediator.call('showMessage', {
				heading: translate('workOrder.errorFetchingAcceptabilityHeading', 'Error Fetching Acceptability'),
				message: (err as Error).message,
				type: 'danger',
				time: false,
			})
		}

		await tick()
		if (sample.status === 'OPEN') {
			sample.status = 'SAMPLED'
		}
		// If it's saved, update the lastModified date so they see the pencil. This isn't sent to the API, it just updates the UI
		if (sampleValue.id && sampleValue.filledOut && sampleValue.lastModified && value !== sampleValue.result) {
			sampleValue.lastModified = new Date().toISOString()
		}
		// appparently setting isLoading before triggering the event is load bearing, or else the spinner gets stuck?
		isLoading = false
		dispatch('change', { value })
	}

	function getInputType(valueType: ValueType$options): 'text' /* | 'select' */ | 'number' | 'date' | 'time' | 'datetime-local' {
		switch (valueType) {
			case 'TEXT':
				return 'text'
			case 'NUMBER':
			case 'INTEGER':
			case 'CURRENCY':
				return 'number'
			case 'DATE':
				return 'date'
			case 'TIME':
				return 'time'
			case 'DATETIME':
				return 'datetime-local'
			default:
				return 'text'
		}
	}

	const getValueAcceptabilityQuery = graphql(`
		query getValueAcceptability($currentResult: String!, $productBatchId: PositiveInt, $analysisOptionId: PositiveInt!, $productId: PositiveInt, $severityClassId: PositiveInt, $plantId: PositiveInt) {
			getValueAcceptability(
				currentResult: $currentResult
				productBatchId: $productBatchId
				analysisOptionId: $analysisOptionId
				productId: $productId
				severityClassId: $severityClassId
				plantId: $plantId
			)
		}
	`)
</script>

{#if document && showDocument}
	<div
		class="border bg-white mb-1"
		style="min-width: 300px"
		transition:slide={{ duration: 100 }}
	>
		<object
			class="w-100 h-100"
			title={document.file.name}
			data="{fileBaseUrl}{document.file.path}"
			type={document.file.mimeType}
		></object>
	</div>
{/if}
{#if !sampleValue}
	<!-- todo? -->
	<i class="text-muted">N/A</i>
{:else if restriction === 'INACTIVE'}
	<Input
		disabled={isDisabled}
		{showLabel}
		{labelClass}
		label={optionLabel}
		title={translate('workOrder.inactiveOptionTitle', 'This option is inactive')}
		value={sampleValue.result}
	>
		<Button
			slot="prepend"
			disabled
			style="min-width: 35.5px;"
		></Button>
	</Input>
{:else if valueType == 'CHOICE'}
	<Select
		disabled={isDisabled}
		readonly={isReadonly}
		{showLabel}
		label={optionLabel}
		showAppend={edited || !!document}
		required={isRequired}
		{labelClass}
		emptyValue=""
		validation={{ validator, value: sampleValue.result }}
		style="min-width: 125px;"
		labelParentClass="flex-nowrap-hack"
		options={makeOptions(choices, sampleValue.result)}
		bind:value={sampleValue.result}
		on:change={() => onValueChange(sampleValue.result)}
	>
		<svelte:fragment slot="prepend">
			<Button
				tabindex={-1}
				{color}
				{iconClass}
				{isLoading}
				colorGreyDisabled={false}
				disabled={acceptabilityButtonDisabled}
				id="thresholdsButton{id}"
				title={translate('workOrder.acceptabilityButtonTitle', 'The acceptability ({{acceptability}}) for this sample. Click to view detailed acceptability information.', { acceptability: label })}
				style="min-width: 35.5px;"
				on:click={onAcceptabilityClick}
			></Button>
		</svelte:fragment>
		<svelte:fragment slot="append">
			{#if edited}
				<span
					class="input-group-text"
					title={translate('workOrder.lastModifiedPencilTitle', 'Value was edited on {{formattedLastModified}}, and was first filled out on {{formattedFilledOut}}.', {
						formattedLastModified,
						formattedFilledOut,
					})}
				>
					<Icon icon="pencil"></Icon>
				</span>
			{/if}
			{#if document}
				<Button
					outline
					tabindex={-1}
					iconClass="info"
					title={translate('workOrder.documentButtonTitle', 'Click to view document')}
					style="min-width: 35.5px;"
					on:click={onDocumentClick}
				></Button>
			{/if}
		</svelte:fragment>
	</Select>
{:else if valueType === 'BOOLEAN'}
	<Label
		controlFor={id}
		label={optionLabel}
		{labelClass}
		required={isRequired}
		hint={isRequired && !sampleValue.result ? requiredTooltip : undefined}
		hintClass="text-danger font-60"
		{showLabel}
	>
		<div
			{id}
			class="btn-group btn-group-toggle w-100"
			data-toggle="buttons"
		>
			<Button
				class="flex-grow-0"
				tabindex={-1}
				{color}
				{iconClass}
				{isLoading}
				colorGreyDisabled={false}
				disabled={acceptabilityButtonDisabled}
				id="thresholdsButton{id}"
				title={translate('workOrder.acceptabilityButtonTitle', 'The acceptability ({{acceptability}}) for this sample. Click to view detailed acceptability information.', { acceptability: label })}
				size="sm"
				style="min-width: 35.5px;"
				on:click={onAcceptabilityClick}
			></Button>
			<label
				class="btn btn-sm cursor-pointer"
				class:btn-secondary={stringToBoolean(sampleValue.result)}
				class:btn-outline-secondary={boolIsValid && !stringToBoolean(sampleValue.result)}
				class:btn-outline-danger={!boolIsValid}
				class:active={stringToBoolean(sampleValue.result)}
				style:font-weight={boolIsValid ? 'normal' : 'bold'}
				class:disabled={isDisabled || isReadonly}
				style:cursor={isDisabled || isReadonly ? 'unset' : 'pointer'}
				style:min-width="50px"
			>
				<input
					id="radio-true-{id}"
					type="radio"
					autocomplete="off"
					name="options"
					disabled={isDisabled}
					readonly={isReadonly}
					value={true}
					on:click={() => {
						// Hack because radio buttons can't be readonly?
						if (!isReadonly && !isDisabled) {
							sampleValue.result = sampleValue.result === 'True' ? '' : 'True'
							onValueChange(sampleValue.result)
						}
					}}
				/>
				{translate('workOrder.true', 'True')}
			</label>
			<label
				class="btn btn-sm cursor-pointer"
				class:btn-secondary={boolIsValid && !stringToBoolean(sampleValue.result) && sampleValue.result !== ''}
				class:btn-outline-secondary={(boolIsValid && stringToBoolean(sampleValue.result)) || sampleValue.result === ''}
				class:btn-outline-danger={!boolIsValid}
				class:active={!stringToBoolean(sampleValue.result) && sampleValue.result !== ''}
				class:disabled={isDisabled || isReadonly}
				style:font-weight={boolIsValid ? 'normal' : 'bold'}
				style:cursor={isDisabled || isReadonly ? 'unset' : 'pointer'}
				style:min-width="50px"
			>
				<input
					id="radio-false-{id}"
					type="radio"
					autocomplete="off"
					name="options"
					disabled={isDisabled}
					readonly={isReadonly}
					value={false}
					on:click={() => {
						// Hack because radio buttons can't be readonly?
						if (!isReadonly && !isDisabled) {
							sampleValue.result = sampleValue.result === 'False' ? '' : 'False'
							onValueChange(sampleValue.result)
						}
					}}
				/>
				{translate('workOrder.false', 'False')}
			</label>
			{#if !boolIsValid}
				<span
					class="input-group-text text-danger border-danger border-left-0"
					style="border-top-left-radius: 0; border-bottom-left-radius: 0; padding: .25rem .5rem; border-left: none;"
					title={translate('workOrder.missingRequiredBooleanValue', 'Value is required, but is not filled out.')}
				>
					<Icon
						prefix="far"
						icon="circle-exclamation"
					></Icon>
				</span>
			{:else if edited || !!document}
				{#if edited}
					<span
						class="input-group-text"
						style="border-top-left-radius: 0; border-bottom-left-radius: 0; padding: .25rem .5rem; border-left: none;"
						title={translate('workOrder.lastModifiedPencilTitle', 'Value was edited on {{formattedLastModified}}, and was first filled out on {{formattedFilledOut}}.', {
							formattedLastModified,
							formattedFilledOut,
						})}
					>
						<Icon icon="pencil"></Icon>
					</span>
				{/if}
				{#if document}
					<Button
						outline
						size="sm"
						tabindex={-1}
						iconClass="info"
						class="flex-grow-0"
						title={translate('workOrder.documentButtonTitle', 'Click to view document')}
						on:click={onDocumentClick}
					></Button>
				{/if}
			{/if}
		</div>
	</Label>
{:else}
	<Input
		disabled={isDisabled}
		readonly={isReadonly}
		{showLabel}
		{labelClass}
		label={optionLabel}
		showAppend={edited || !!document}
		required={isRequired}
		type={inputType}
		validation={{ validator, value: sampleValue.result }}
		id="thresholdsButton{id}"
		style="min-width: 125px;"
		labelParentClass="flex-nowrap-hack"
		maxlength={100}
		bind:value={sampleValue.result}
		on:change={() => {
			if (sampleValue.analysisOption.valueType === 'INTEGER') {
				const parsed = parseInt(sampleValue.result, 10)
				sampleValue.result = Number.isNaN(parsed) ? '' : parsed.toString()
			} else if (sampleValue.analysisOption.valueType === 'NUMBER') {
				sampleValue.result = financialNumber(sampleValue.result).toString(6)
			} else if (sampleValue.analysisOption.valueType === 'CURRENCY') {
				sampleValue.result = financialNumber(sampleValue.result).toString(2)
			}
			onValueChange(sampleValue.result)
		}}
	>
		<svelte:fragment slot="prepend">
			<Button
				tabindex={-1}
				{color}
				{iconClass}
				{isLoading}
				colorGreyDisabled={false}
				disabled={acceptabilityButtonDisabled}
				title={translate('workOrder.acceptabilityButtonTitle', 'The acceptability ({{acceptability}}) for this sample. Click to view detailed acceptability information.', { acceptability: label })}
				style="min-width: 35.5px;"
				on:click={onAcceptabilityClick}
			></Button>
		</svelte:fragment>
		<svelte:fragment slot="append">
			{#if edited}
				<span
					class="input-group-text"
					title={translate('workOrder.lastModifiedPencilTitle', 'Value was edited on {{formattedLastModified}}, and was first filled out on {{formattedFilledOut}}.', {
						formattedLastModified,
						formattedFilledOut,
					})}
				>
					<Icon icon="pencil"></Icon>
				</span>
			{/if}
			{#if document}
				<Button
					outline
					tabindex={-1}
					iconClass="info"
					title={translate('workOrder.documentButtonTitle', 'Click to view document')}
					style="min-width: 35.5px;"
					on:click={onDocumentClick}
				></Button>
			{/if}
		</svelte:fragment>
	</Input>
{/if}

{#if showThresholdTable}
	<div transition:slide={{ duration: 100 }}>
		<ThresholdTable bind:this={thresholdTable}></ThresholdTable>
	</div>
{/if}

<style>
	/* Temp hack to make font sizes at the very least consistent */
	:global(small.font-60) {
		font-size: 60%;
	}

	:global(.form-group.flex-nowrap-hack .input-group) {
		flex-wrap: nowrap !important;
	}

	/* Fix for radio buttons that are label>btn.btn-secondary>input not showing that they are focused */
	.btn-secondary:focus-within,
	.btn-outline-secondary:focus-within {
		border-color: #545b62;
		box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
	}

	.btn-outline-danger:focus-within {
		border-color: #dc3545;
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
	}
</style>
