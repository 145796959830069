export default {
    "name": "LoadProducts",
    "kind": "HoudiniQuery",
    "hash": "7c8b84bec711f18872f2fa731d774580b624841f8e1fb60ad1c823e931252ba9",

    "raw": `query LoadProducts($filter: ProductFilter, $pagination: PaginatedInput, $orderBy: [ProductOrderBy!]) {
  products(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
    data {
      id
      active
      barcodeFormat
      category
      description
      inUseAtPlants {
        id
      }
      itemNumber
      name
      parentProductId
      productType
      supplierItemNumber
      tags {
        id
        name
        entityType
        active
      }
      attachmentCount
      attachments {
        id
        public
        rank
        fileId
        file {
          path
          name
          created
          updated
          type
          hash
          mimeType
          size
          id
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "products": {
                "type": "ProductResponse",
                "keyRaw": "products(filter: $filter, orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Product",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "barcodeFormat": {
                                        "type": "String",
                                        "keyRaw": "barcodeFormat",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inUseAtPlants": {
                                        "type": "Plant",
                                        "keyRaw": "inUseAtPlants",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "itemNumber": {
                                        "type": "String",
                                        "keyRaw": "itemNumber",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "parentProductId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "parentProductId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productType": {
                                        "type": "ProductType",
                                        "keyRaw": "productType",
                                        "visible": true
                                    },

                                    "supplierItemNumber": {
                                        "type": "String",
                                        "keyRaw": "supplierItemNumber",
                                        "visible": true
                                    },

                                    "tags": {
                                        "type": "EntityTag",
                                        "keyRaw": "tags",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "entityType": {
                                                    "type": "EntityTagType",
                                                    "keyRaw": "entityType",
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "attachmentCount": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "attachmentCount",
                                        "visible": true
                                    },

                                    "attachments": {
                                        "type": "ProductFile",
                                        "keyRaw": "attachments",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "public": {
                                                    "type": "Boolean",
                                                    "keyRaw": "public",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "SafeInt",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "fileId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "fileId",
                                                    "visible": true
                                                },

                                                "file": {
                                                    "type": "File",
                                                    "keyRaw": "file",

                                                    "selection": {
                                                        "fields": {
                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "created": {
                                                                "type": "DateTime",
                                                                "keyRaw": "created",
                                                                "visible": true
                                                            },

                                                            "updated": {
                                                                "type": "DateTime",
                                                                "keyRaw": "updated",
                                                                "visible": true
                                                            },

                                                            "type": {
                                                                "type": "FileType",
                                                                "keyRaw": "type",
                                                                "visible": true
                                                            },

                                                            "hash": {
                                                                "type": "String",
                                                                "keyRaw": "hash",
                                                                "visible": true
                                                            },

                                                            "mimeType": {
                                                                "type": "String",
                                                                "keyRaw": "mimeType",
                                                                "visible": true
                                                            },

                                                            "size": {
                                                                "type": "UnsignedInt",
                                                                "keyRaw": "size",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ProductFilter",
            "pagination": "PaginatedInput",
            "orderBy": "ProductOrderBy"
        },

        "types": {
            "ProductFilter": {
                "active": "Boolean",
                "activeOnly": "Boolean",
                "plantIds": "PositiveInt",
                "plantIdsFilteringByChildren": "PositiveInt",
                "plants": "PositiveInt",
                "productName": "NonEmptyString",
                "productType": "ProductType"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b6d44ffe97ebccddc222309039e31d7a0e66f77f0d0db17efe772e5b77fde21f";