export default {
    "name": "ProductSpecifications",
    "kind": "HoudiniQuery",
    "hash": "23c7bd7552f8dde48ae26a5efb0947d0fa54e809c1351053ba01812e553812bc",

    "raw": `query ProductSpecifications($filter: AnalysisOptionChoiceFilter, $pagination: PaginatedInput) {
  analysisOptionChoices(filter: $filter, pagination: $pagination) {
    data {
      id
      plantId
      productId
      choice
      constraint
      boundaryType
      requiredAnalysisOption {
        id
        option
        valueType
        analysis {
          id
          name
        }
      }
      requiredConstraint
      requiredChoice
      productBatchId
      severityClass {
        id
        name
      }
      analysisOption {
        id
        option
        valueType
        analysis {
          id
          name
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analysisOptionChoices": {
                "type": "AnalysisOptionChoiceResponse",
                "keyRaw": "analysisOptionChoices(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "AnalysisOptionChoice",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "productId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "choice": {
                                        "type": "String",
                                        "keyRaw": "choice",
                                        "visible": true
                                    },

                                    "constraint": {
                                        "type": "ConstraintType",
                                        "keyRaw": "constraint",
                                        "visible": true
                                    },

                                    "boundaryType": {
                                        "type": "BoundaryType",
                                        "keyRaw": "boundaryType",
                                        "visible": true
                                    },

                                    "requiredAnalysisOption": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "requiredAnalysisOption",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                },

                                                "valueType": {
                                                    "type": "ValueType",
                                                    "keyRaw": "valueType",
                                                    "visible": true
                                                },

                                                "analysis": {
                                                    "type": "Analysis",
                                                    "keyRaw": "analysis",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "requiredConstraint": {
                                        "type": "ConstraintType",
                                        "keyRaw": "requiredConstraint",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "requiredChoice": {
                                        "type": "String",
                                        "keyRaw": "requiredChoice",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productBatchId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "productBatchId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "severityClass": {
                                        "type": "SeverityClass",
                                        "keyRaw": "severityClass",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "analysisOption": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "analysisOption",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                },

                                                "valueType": {
                                                    "type": "ValueType",
                                                    "keyRaw": "valueType",
                                                    "visible": true
                                                },

                                                "analysis": {
                                                    "type": "Analysis",
                                                    "keyRaw": "analysis",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "AnalysisOptionChoiceFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "AnalysisOptionChoiceFilter": {
                "active": "Boolean",
                "optionId": "PositiveInt",
                "optionName": "String",
                "plantId": "PositiveInt",
                "productIds": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d5ddb5242f1a0d901c68264ec1c31f0453c1a7543ff34e83f53852f338096818";