export default {
    "name": "UserAccounts",
    "kind": "HoudiniQuery",
    "hash": "6ccc96721394f9fcdace6cf404e250b0b1bfa4356c780745ab68aadfc2a28848",

    "raw": `query UserAccounts($filter: UserFilter, $pagination: PaginatedInput) {
  userAccounts(filter: $filter, pagination: $pagination) {
    data {
      id
      name
      firstName
      lastName
      workEmail
      authorizedSites: authorizedPlants {
        id
      }
      userGroups {
        id
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccounts": {
                "type": "UserAccountResponse",
                "keyRaw": "userAccounts(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "UserAccount",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "workEmail": {
                                        "type": "String",
                                        "keyRaw": "workEmail",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "authorizedSites": {
                                        "type": "Plant",
                                        "keyRaw": "authorizedSites",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "userGroups": {
                                        "type": "Group",
                                        "keyRaw": "userGroups",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "UserFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "UserAuthorizedPlantsFilter": {
                "ids": "PositiveInt",
                "matches": "AnyAllEnum"
            },

            "UserGroupsFilter": {
                "ids": "PositiveInt",
                "matches": "AnyAllEnum"
            },

            "UserFilter": {
                "activeOnly": "Boolean",
                "authorizedPlants": "UserAuthorizedPlantsFilter",
                "groups": "UserGroupsFilter"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=83e9ab2f777fbb2e1f7814e53a5d6d797ff08c89f1007f62b3aa8347484effc5";