<script lang="ts">
	import Icon from '@isoftdata/svelte-icon'
	import type { ComponentProps } from 'svelte'

	export let icon: ComponentProps<Icon>['icon'] | ComponentProps<Icon>
	export let title

	$: iconProps = typeof icon === 'string' ? { icon } : icon
</script>

<div class="card-header">
	<div class="d-flex flex-wrap justify-content-between">
		<h4 class="text-ellipsis">
			<Icon
				{...iconProps}
				fixedWidth
			></Icon>
			{title}
		</h4>
		<div class="d-flex flex-wrap">
			<slot name="right"></slot>
		</div>
	</div>
	<slot></slot>
</div>
