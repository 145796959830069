export default {
    "name": "WorkOrderSettings",
    "kind": "HoudiniQuery",
    "hash": "6ef9c4f9d782f56bdf733f48f85347061989a629eba6bd00a4edc280cc3cc586",

    "raw": `query WorkOrderSettings {
  session {
    user {
      showOptions: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show analysis options and values in sample detail", defaultValue: "True"}
      ) {
        value
      }
      showCollectionDetail: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show collection information in sample detail", defaultValue: "True"}
      ) {
        value
      }
      showTestingDetail: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show testing information in sample detail", defaultValue: "True"}
      ) {
        value
      }
      showWorkOrderDetail: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show work order information in sample detail", defaultValue: "False"}
      ) {
        value
      }
      showModifiedIcons: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show icon for any values modified from their initial version", defaultValue: "True"}
      ) {
        value
      }
      favoriteReports: getUserSetting(
        lookup: {category: "Report Viewer", settingType: INTERFACE_HISTORY, name: "Favorite reports", defaultValue: ""}
      ) {
        value
      }
      id
    }
  }
  allowShowThresholdsTable: getGlobalSetting(
    lookup: {category: "Scanner", name: "showthresholds", settingType: INTERFACE_PREFERENCE, defaultValue: "False"}
  ) {
    value
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "showOptions": {
                                        "type": "SettingResult",
                                        "keyRaw": "showOptions(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show analysis options and values in sample detail\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showCollectionDetail": {
                                        "type": "SettingResult",
                                        "keyRaw": "showCollectionDetail(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show collection information in sample detail\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showTestingDetail": {
                                        "type": "SettingResult",
                                        "keyRaw": "showTestingDetail(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show testing information in sample detail\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showWorkOrderDetail": {
                                        "type": "SettingResult",
                                        "keyRaw": "showWorkOrderDetail(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show work order information in sample detail\", defaultValue: \"False\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showModifiedIcons": {
                                        "type": "SettingResult",
                                        "keyRaw": "showModifiedIcons(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show icon for any values modified from their initial version\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "favoriteReports": {
                                        "type": "SettingResult",
                                        "keyRaw": "favoriteReports(lookup: {category: \"Report Viewer\", settingType: INTERFACE_HISTORY, name: \"Favorite reports\", defaultValue: \"\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "allowShowThresholdsTable": {
                "type": "SettingResult",
                "keyRaw": "allowShowThresholdsTable(lookup: {category: \"Scanner\", name: \"showthresholds\", settingType: INTERFACE_PREFERENCE, defaultValue: \"False\"})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c088c456822e128429981d6d0c69c06e26f14f43cbbe1e9d3f14bb82bcf10d29";