export default {
    "name": "DeleteProducts",
    "kind": "HoudiniMutation",
    "hash": "a800350b98c4031b316e738a39d7d08d40597b08cf1a3e07ab5ca30971e46be1",

    "raw": `mutation DeleteProducts($ids: [PositiveInt!]!) {
  deleteProducts(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteProducts": {
                "type": "Boolean",
                "keyRaw": "deleteProducts(ids: $ids)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "ids": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=fd4b1520ab05312ae80fb8123d067c14c4fe48d0cde6349f1904910828d9b627";