import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/AmAnalysisOptionsChoicesQuery'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class AmAnalysisOptionsChoicesQueryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "AmAnalysisOptionsChoicesQueryStore",
			variables: false,
		})
	}
}

export async function load_AmAnalysisOptionsChoicesQuery(params) {
	await initClient()

	const store = new AmAnalysisOptionsChoicesQueryStore()

	await store.fetch(params)

	return {
		AmAnalysisOptionsChoicesQuery: store,
	}
}
