export default {
    "name": "TestOptionRestrictions",
    "kind": "HoudiniQuery",
    "hash": "1350cf5e3932e44a97a90e6c60104ccad0193fa0c8f0fc07565e0870a9151c67",

    "raw": `query TestOptionRestrictions($input: TestOptionRestrictionsInput!) {
  testOptionRestrictions(input: $input) {
    analysisOptionId
    locationId
    plantId
    productId
    outcome
    outcomeSetByOption
    rules {
      affectsOutcome
      triggered
      rule
      tags {
        active
        entityType
        id
        name
      }
      restriction
      outcome
      analysisOptionRuleId
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "testOptionRestrictions": {
                "type": "TestOptionRestrictionsOutput",
                "keyRaw": "testOptionRestrictions(input: $input)",

                "selection": {
                    "fields": {
                        "analysisOptionId": {
                            "type": "PositiveInt",
                            "keyRaw": "analysisOptionId",
                            "visible": true
                        },

                        "locationId": {
                            "type": "PositiveInt",
                            "keyRaw": "locationId",
                            "nullable": true,
                            "visible": true
                        },

                        "plantId": {
                            "type": "PositiveInt",
                            "keyRaw": "plantId",
                            "visible": true
                        },

                        "productId": {
                            "type": "PositiveInt",
                            "keyRaw": "productId",
                            "nullable": true,
                            "visible": true
                        },

                        "outcome": {
                            "type": "OutcomeOrNone",
                            "keyRaw": "outcome",
                            "visible": true
                        },

                        "outcomeSetByOption": {
                            "type": "Boolean",
                            "keyRaw": "outcomeSetByOption",
                            "visible": true
                        },

                        "rules": {
                            "type": "TestOptionRestrictionsRule",
                            "keyRaw": "rules",

                            "selection": {
                                "fields": {
                                    "affectsOutcome": {
                                        "type": "Boolean",
                                        "keyRaw": "affectsOutcome",
                                        "visible": true
                                    },

                                    "triggered": {
                                        "type": "Boolean",
                                        "keyRaw": "triggered",
                                        "visible": true
                                    },

                                    "rule": {
                                        "type": "String",
                                        "keyRaw": "rule",
                                        "visible": true
                                    },

                                    "tags": {
                                        "type": "EntityTag",
                                        "keyRaw": "tags",

                                        "selection": {
                                            "fields": {
                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "entityType": {
                                                    "type": "EntityTagType",
                                                    "keyRaw": "entityType",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "restriction": {
                                        "type": "RestrictionType",
                                        "keyRaw": "restriction",
                                        "visible": true
                                    },

                                    "outcome": {
                                        "type": "OutcomeType",
                                        "keyRaw": "outcome",
                                        "visible": true
                                    },

                                    "analysisOptionRuleId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisOptionRuleId",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "TestOptionRestrictionsInput"
        },

        "types": {
            "TestOptionRestrictionsInput": {
                "analysisOptionId": "PositiveInt",
                "locationId": "PositiveInt",
                "onlyTriggered": "Boolean",
                "plantId": "PositiveInt",
                "productId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c307bcfe3d458af81afedb4d45e0d80190c3d5f0f9ba7f15b612f7e76699bf23";