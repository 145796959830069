export default {
    "name": "UpdateProductEntityTags",
    "kind": "HoudiniMutation",
    "hash": "4bb44f5c71a6476f9ed56c2977b71edd69f24872154731cfc08506fc55ebb690",

    "raw": `mutation UpdateProductEntityTags($input: [EntityTagUpdate!]!) {
  updateEntityTags(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateEntityTags": {
                "type": "EntityTag",
                "keyRaw": "updateEntityTags(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "EntityTagUpdate"
        },

        "types": {
            "EntityTagUpdate": {
                "active": "Boolean",
                "entityType": "EntityTagType",
                "id": "PositiveInt",
                "name": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ad9b0874b7966ed7077d108aa41646ee53f23b280c99d913d8d9cb1c1a672ebc";