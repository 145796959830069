export default {
    "name": "UpdateProductSpecification",
    "kind": "HoudiniMutation",
    "hash": "9ff4e274a36b6d481e158a0811a9eb969bd273ff7a542417f0ce38de4bbe7c1b",

    "raw": `mutation UpdateProductSpecification($analysisOptionChoices: [AnalysisOptionChoiceUpdate!]!) {
  updateAnalysisOptionChoices(analysisOptionChoices: $analysisOptionChoices) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateAnalysisOptionChoices": {
                "type": "AnalysisOptionChoice",
                "keyRaw": "updateAnalysisOptionChoices(analysisOptionChoices: $analysisOptionChoices)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "analysisOptionChoices": "AnalysisOptionChoiceUpdate"
        },

        "types": {
            "AnalysisOptionChoiceUpdate": {
                "active": "Boolean",
                "analysisOptionId": "PositiveInt",
                "boundaryType": "BoundaryType",
                "choice": "String",
                "constraint": "ConstraintType",
                "id": "PositiveInt",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "productId": "PositiveInt",
                "requiredAnalysisOptionId": "PositiveInt",
                "requiredChoice": "String",
                "requiredConstraint": "ConstraintType",
                "severityClassId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=c789dbae2835f102109cbbdc77fb645e8ea0f2ebd4ec5b72dc52680f83c89a1c";