<svelte:options accessors />

<script lang="ts">
	import type { AddRemoveStore } from 'stores/add-remove-store'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { Mediator, i18n, SvelteAsr } from 'types/common'
	import type SaveResetButton from '@isoftdata/svelte-save-reset-button'
	import type { AnalysisOptionChoiceUpdate, CreateOrUpdateProduct, EntityTagUpdate, NewAnalysisOptionChoice } from '$houdini'
	import type { Writable } from 'svelte/store'

	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Attachments, { type BaseAttachmentFile } from '@isoftdata/svelte-attachments'
	import Input from '@isoftdata/svelte-input'
	import Textarea from '@isoftdata/svelte-textarea'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import ImageThumbnail from '@isoftdata/svelte-image-thumbnail'
	import ImageViewer from '@isoftdata/svelte-image-viewer'
	import Icon from '@isoftdata/svelte-icon'
	import Dropdown from '@isoftdata/svelte-dropdown'
	import Table, { type Column, type IndexedRowProps, Td, TreeRow, removeFromTree, upsertIntoTree } from '@isoftdata/svelte-table'
	import ExpandableBadge from 'components/ExpandableBadge.svelte'
	import ConfigureSpecificationCard, { type MetaSpecification } from 'components/ConfigureSpecificationCard.svelte'
	import TagSelection, { type MetaTag } from 'components/TagSelection.svelte'
	import BarcodeFormatModal from './BarcodeFormatModal.svelte'

	import {
		type Plant,
		type ProductNode,
		type ProductAttachment,
		type Analysis,
		type SeverityClass,
		type Product,
		loadProductSpecificationsQuery,
		createTagsMutation,
		deleteTagsMutation,
		updateTagsMutation,
		createOrUpdateProductMutation,
		deleteProductsMutation,
		attachFileToProductMutation,
		detachFilesFromProductMutation,
		deleteSpecificationMutation,
		createSpecificationMutation,
		updateSpecificationMutation,
		checkProductReferenceCounts,
	} from 'utility/product-helper'
	import { v4 as uuid } from '@lukeed/uuid'
	import { klona } from 'klona'
	import b64ify from 'utility/b64ify'
	import pMap from 'p-map'
	import hasPermission from 'utility/has-permission'
	import { getContext, onMount, tick, type ComponentProps } from 'svelte'
	import userLocalWritable from 'stores/user-local-writable'
	import session from 'stores/session'
	import getDuplicateName from 'utility/get-duplicate-name'

	type SaveResetProps = Writable<ComponentProps<SaveResetButton> | null>
	type IndexedRow = ProductNode & IndexedRowProps
	type SavableProductToCreateOrUpdate = CreateOrUpdateProduct & { uuid: string; parentProductUuid: string | null }

	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')

	const productTypes = [
		{ label: translate('product.showProductsLabel', 'Show Products'), value: 'PRODUCT' },
		{ label: translate('product.showIngredientsLabel', 'Show Ingredients'), value: 'INGREDIENT' },
	]

	export let asr: SvelteAsr
	export let authorizedPlantIds: Array<number>
	export let tags: Array<MetaTag>
	export let plantId: number
	export let clipboard: ProductNode | null
	export let plants: Array<Plant>
	export let tagCrudStore: CrudStore<MetaTag, 'uuid'>
	export let specificationCrudStore: CrudStore<MetaSpecification, 'uuid'>
	export let tagAddRemoveStore: AddRemoveStore
	export let productCategories: Array<string | null>
	export let productCrudStore: CrudStore<ProductNode, 'uuid'>
	export let attachmentCrudStore: CrudStore<ProductAttachment, 'uuid'>
	export let productsList: Array<Product>
	export let productsTree: Array<ProductNode>
	export let expandedCard: 'Details' | 'Tags'
	export let selectedProduct: ProductNode | null
	export let selectedSpecification: MetaSpecification | null
	export let specificationCache: Map<string, MetaSpecification[]>
	export let selectedPlant: Plant
	export let analyses: Array<Analysis> = []
	export let analysesById: Record<number, Analysis>
	export let severityClasses: Array<SeverityClass> = []
	export let selectedProductTypeFilter: 'PRODUCT' | 'INGREDIENT' = 'PRODUCT'
	export let showInactive = false
	export let showUnused = false
	export let saveResetProps: SaveResetProps
	export let hasUnsavedChanges = false
	export let canEditGlobalFields: (productId?: number | null) => boolean

	function isString(val: unknown): val is string {
		return typeof val === 'string'
	}

	let selectedProductSpecifications: Array<MetaSpecification> = []

	let theTable: Table<ProductNode>
	let currentPageRows: Array<IndexedRow> = []
	let isCut = false
	let copiedProductUuid: string | null = null
	let showAttachmentsModal = false
	let barcodeFormatModal: BarcodeFormatModal | undefined = undefined

	let showImageThumbnails = userLocalWritable($session.userAccountId, 'showProductThumbnails', false)
	let showImageViewer = false
	let currentPhotoIndex = 0

	let hasTagChanges = false
	$: hasTagChanges = hasTagChanges || (!!$tagCrudStore && tagCrudStore.hasChanges())
	let hasProductChanges = false
	$: hasProductChanges = hasProductChanges || (!!$productCrudStore && productCrudStore.hasChanges())
	let hasAttachmentChanges = false
	$: hasAttachmentChanges = hasAttachmentChanges || (!!$attachmentCrudStore && attachmentCrudStore.hasChanges())
	let hasSpecificationChanges = false
	$: hasSpecificationChanges = hasSpecificationChanges || (!!$specificationCrudStore && specificationCrudStore.hasChanges())

	$: hasUnsavedChanges = hasTagChanges || hasProductChanges || hasAttachmentChanges || hasSpecificationChanges
	$: selectedRowIds = selectedProduct ? [selectedProduct.uuid] : []
	$: selectedProductImages = selectedProduct?.attachments.map(({ path }) => path) ?? []
	$: analysesAtCurrentPlant = analyses.filter(analysis => analysis.inUseAtPlantIDs.includes(selectedPlant.id)) ?? []
	$: parentReference = getChildrenOfParentReferenceMap(productsTree)

	$: $saveResetProps = {
		save,
		resetHref: asr.makePath(null, { lastResetTime: Date.now() }, { inherit: true }),
		disabled: !hasUnsavedChanges,
	}

	const columns: Array<Column<ProductNode>> = [
		{
			name: translate('product.name', 'Name'),
			property: 'name',
			title: translate('product.nameColumnTitle', 'A unique name describing a product provided.'),
			minWidth: '200px',
		},
		{
			name: '',
			property: 'dirty',
			icon: 'save',
			width: '1rem',
			sortType: false,
			title: translate('product.dirtyColumnTitle', 'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button.'),
		},
		{
			name: '',
			icon: 'photo-video',
			property: 'attachmentCount',
			width: '1rem',
			align: 'center',
			sortType: false,
			title: translate('product.attachmentCountColumnTitle', 'The number of attachments at the location. Click to view image attachments'),
		},
		{
			name: translate('product.active', 'Active'),
			property: 'active',
			title: translate('product.activeColumnTitle', 'Indicates whether the product is active or not.'),
			width: '1rem',
			align: 'center',
			sortType: false,
		},
		{
			name: translate('product.inUse', 'In Use'),
			property: 'inUse',
			title: translate('product.inUseColumnTitle', 'Indicates whether the product is in use at the current plant or not.'),
			width: '1rem',
			align: 'center',
			sortType: false,
		},
	]

	function canEditChoice(plantId: number | null) {
		if (!selectedProduct) {
			return false
		}
		if (plantId === null) {
			return canEditGlobalFields(selectedProduct.id)
		}

		if (plantId) {
			return hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', plantId)
		}

		return false
	}

	function validateNamesBeforeSave(productsToValidate: Array<ProductNode>): boolean {
		return productsToValidate.every(product => {
			if (!productHasUniqueName(product)) {
				mediator.call('showMessage', {
					heading: translate('product.duplicateNameError', 'Duplicate Name Error'),
					message: translate('product.duplicateNameErrorMessage', 'Sibling products cannot have the same name.'),
					type: 'danger',
					time: false,
				})
				return false
			}
			return true
		})
	}

	async function save() {
		const productsToSave: Array<ProductNode> = productCrudStore.createdValues.concat(productCrudStore.updatedValues)
		if (!validateNamesBeforeSave(productsToSave)) {
			console.error('Products not saved due to validation error.')
			throw new Error('Validation error.')
		}

		const flattenedProductsToSave = flattenAndSortProductsByDepthForSave(productsToSave)

		let newTagNamesToIds: Record<string, number> = {}

		try {
			const tagsToCreate = tagCrudStore.createdValues
			const tagsToUpdate = tagCrudStore.updatedValues
			const tagsToDelete = tagCrudStore.deletedValues
			if (tagsToCreate.length) {
				const { data } = await createTagsMutation.mutate({
					input: tagsToCreate.map(tag => {
						return {
							active: tag.active,
							entityType: 'PRODUCT',
							name: tag.name,
						}
					}),
				})

				const createdTags = data?.createEntityTags ?? []

				if (createdTags.length) {
					newTagNamesToIds = createdTags.reduce((acc: Record<string, number>, tag) => {
						acc[tag.name] = tag.id
						return acc
					}, {})
					tags = tags.map(tag => {
						if (tag.name in newTagNamesToIds) {
							tag.id = newTagNamesToIds[tag.name]
						}
						return tag
					})
				}
			}

			if (tagsToUpdate.length) {
				await updateTagsMutation.mutate({
					input: tagsToUpdate.reduce((acc: EntityTagUpdate[], tag) => {
						if (tag.id) {
							acc.push({
								id: tag.id,
								entityType: 'PRODUCT',
								active: tag.active,
								name: tag.name,
							})
						}
						return acc
					}, []),
				})
			}

			if (tagsToDelete.length) {
				await deleteTagsMutation.mutate({
					ids: tagsToDelete.reduce((acc: number[], tag) => {
						if (tag.id) {
							acc.push(tag.id)
						}
						return acc
					}, []),
				})
			}
		} catch (err: unknown) {
			const error = err as Error
			console.error('Error saving tags:', error)
			return mediator.call('showMessage', {
				heading: translate('product.errorSavingTags', 'Error Saving Tags; Products not Saved'),
				message: error.message ?? translate('product.unknownError', 'Unknown Error.'),
				type: 'danger',
				time: false,
			})
		}

		let productUuidsToIds: Record<string, number> = {}
		// TODO: At some point make the products API side behave the same as locations in saving
		if (productCrudStore.hasChanges()) {
			const productsToCreateOrUpdate: SavableProductToCreateOrUpdate[] = flattenedProductsToSave.map(product => {
				const productTagIds = product.tags.reduce((acc: Array<number>, tag) => {
					if (tag.id) {
						acc.push(tag.id)
					}
					return acc
				}, [])
				const tagIdsToAdd = tagAddRemoveStore.getAddIds(product.uuid, tags, 'id').map(tagId => parseInt(tagId, 10))
				const tagIdsToRemove = tagAddRemoveStore.getRemoveIds(product.uuid, tags, 'id').map(tagId => parseInt(tagId, 10))
				const tagsToKeep = productTagIds.filter(tagId => tagId && !tagIdsToRemove.includes(tagId)).concat(tagIdsToAdd)
				return {
					id: product.id,
					name: product.name,
					active: product.active,
					barcodeFormat: product.barcodeFormat,
					category: product.category,
					description: product.description,
					inUseAtPlantIDs: product.inUseAtPlants?.map(plant => plant.id) ?? [],
					parentProductId: undefined,
					productType: product.productType,
					tagIds: tagsToKeep.filter(tagId => tagId !== null),
					uuid: product.uuid,
					parentProductUuid: product.parentProductUuid,
					itemNumber: product.itemNumber,
					supplierItemNumber: product.supplierItemNumber,
				}
			})

			const productsIdsToDelete = productCrudStore.deletedValues.reduce((acc: Array<number>, product) => {
				if (product.id) {
					acc.push(product.id)
				}
				return acc
			}, [])
			try {
				if (productsToCreateOrUpdate.length) {
					let remainingProductsToCreateAndUpdate = klona(productsToCreateOrUpdate)
					for (const productToCreateOrUpdate of productsToCreateOrUpdate) {
						const product = remainingProductsToCreateAndUpdate.find(product => product.uuid === productToCreateOrUpdate.uuid)
						if (!product) {
							continue
						}
						const { uuid, parentProductUuid, ...savableProduct } = product
						const { data } = await createOrUpdateProductMutation.mutate({ product: savableProduct })
						if (!data?.createOrUpdateProduct) {
							throw new Error(`No data returned from the server when saving product: ${product?.name}`)
						}

						const savedProductId = data.createOrUpdateProduct.id

						if (savedProductId) {
							productUuidsToIds[uuid] = savedProductId
						}

						remainingProductsToCreateAndUpdate.reduce((acc, product) => {
							if (product.parentProductUuid === uuid) {
								product.parentProductUuid = uuid
								product.parentProductId = savedProductId
							}
							return acc
						}, remainingProductsToCreateAndUpdate)
					}
				}

				//This happens after to prevent collisions
				if (productsIdsToDelete.length) {
					await deleteProductsMutation.mutate({ ids: productsIdsToDelete })
				}
			} catch (err: any) {
				console.error('Error saving products:', err)
				return mediator.call('showMessage', {
					heading: translate('product.errorSavingProducts', 'Error Saving Products'),
					message: err.message ?? translate('product.unknownError', 'Unknown Error.'),
					type: 'danger',
					time: false,
				})
			}
		}
		try {
			const attachmentCreatedValues = attachmentCrudStore.createdValues
			const attachmentDeletedValues = attachmentCrudStore.deletedValues
			if (attachmentCreatedValues.length) {
				const filesToSave = await Promise.all(
					attachmentCreatedValues.map(async file => {
						let productId = file.productId ?? productUuidsToIds[file.productUuid]
						if (!file.File) {
							throw new Error('File not found.')
						}
						const base64String = await b64ify(file.File)
						return {
							productId,
							fileName: file.name,
							base64String,
							public: true,
							rank: file.rank,
						}
					}),
				)

				await pMap(filesToSave, async input => await attachFileToProductMutation.mutate({ input }), { concurrency: 2 })
			}
			if (attachmentDeletedValues.length) {
				const fileIdsByProductId: Record<number, Array<number>> = attachmentDeletedValues.reduce((acc: Record<number, number[]>, file) => {
					if (file.productId && file.fileId) {
						acc[file.productId] ??= []
						acc[file.productId].push(file.fileId)
					}
					return acc
				}, {})
				await pMap(Object.entries(fileIdsByProductId), async ([productId, fileIds]) => {
					await detachFilesFromProductMutation.mutate({ productId: parseInt(productId, 10), fileIds })
				})
			}
		} catch (err: unknown) {
			const error = err as Error
			console.error('Error saving attachments:', error)
			return mediator.call('showMessage', {
				heading: translate('product.errorSavingAttachments', 'Error Saving Attachments'),
				message: error.message ?? translate('product.unknownError', 'Unknown Error.'),
				type: 'danger',
				time: false,
			})
		}

		try {
			if (specificationCrudStore.hasChanges()) {
				const formattedSpecificationsToCreate = specificationCrudStore.createdValues.reduce((acc: Array<NewAnalysisOptionChoice>, spec) => {
					if (spec.analysisOption && spec.productUuid) {
						acc.push({
							active: true,
							analysisOptionId: spec.analysisOption.id,
							boundaryType: spec.boundaryType,
							choice: spec.choice,
							constraint: spec.constraint,
							plantId: spec.plantId,
							requiredAnalysisOptionId: spec.requiredAnalysisOption?.id,
							requiredChoice: spec.requiredChoice,
							requiredConstraint: spec.requiredConstraint,
							severityClassId: spec.severityClass?.id,
							productId: spec.productId ?? productUuidsToIds[spec.productUuid],
						})
					}
					return acc
				}, [])

				const formattedSpecificationsToUpdate = specificationCrudStore.updatedValues.reduce((acc: Array<AnalysisOptionChoiceUpdate>, spec) => {
					if (spec.analysisOption && spec.id && spec.productUuid) {
						acc.push({
							id: spec.id,
							active: true,
							analysisOptionId: spec.analysisOption.id,
							boundaryType: spec.boundaryType,
							choice: spec.choice,
							constraint: spec.constraint,
							plantId: spec.plantId,
							requiredAnalysisOptionId: spec.requiredAnalysisOption?.id,
							requiredChoice: spec.requiredChoice,
							requiredConstraint: spec.requiredConstraint,
							severityClassId: spec.severityClass?.id,
							productId: spec.productId ?? productUuidsToIds[spec.productUuid],
						})
					}
					return acc
				}, [])

				const specificationsToDelete = Object.values($specificationCrudStore.deleted).map(specification => specification.id?.toString()!)
				await Promise.all([
					formattedSpecificationsToCreate.length
						? createSpecificationMutation.mutate({
								analysisOptionChoices: formattedSpecificationsToCreate,
							})
						: [],
					formattedSpecificationsToUpdate.length
						? updateSpecificationMutation.mutate({
								analysisOptionChoices: formattedSpecificationsToUpdate,
							})
						: [],
					specificationsToDelete.length ? deleteSpecificationMutation.mutate({ ids: specificationsToDelete }) : null,
				])
			}
		} catch (err: unknown) {
			const error = err as Error
			console.error('Error saving specifications:', error)
			return mediator.call('showMessage', {
				heading: translate('product.errorSavingSpecifications', 'Error Saving Specifications'),
				message: error.message ?? translate('product.unknownError', 'Unknown Error.'),
				type: 'danger',
				time: false,
			})
		}

		hasUnsavedChanges = false
		asr.go(
			'app.product-management.product',
			{
				lastSavedTime: Date.now(),
				lastResetTime: null,
				selectedProductId: selectedProduct?.id ? selectedProduct.id : selectedProduct ? productUuidsToIds[selectedProduct.uuid] : null,
			},
			{ inherit: true },
		)
	}

	function flattenAndSortProductsByDepthForSave(productsTreeTopLevelArray: Array<ProductNode>): Array<ProductNode> {
		const processedProductUuids = new Set<string>()
		function flatten(acc: Array<ProductNode>, product: ProductNode) {
			if (!processedProductUuids.has(product.uuid)) {
				acc.push(product)
				processedProductUuids.add(product.uuid)
			}

			if (product.children.length) {
				acc.push(...product.children.reduce(flatten, []))
			}
			return acc
		}
		const flattenedProducts = productsTreeTopLevelArray.reduce(flatten, []).sort((a, b) => a.depth - b.depth)
		return flattenedProducts
	}

	function updateProduct(product: ProductNode | null) {
		if (!product) {
			return
		}
		if (!productHasUniqueName(product)) {
			return mediator.call('showMessage', {
				heading: translate('product.duplicateNameError', 'Duplicate Name Error'),
				message: translate('product.duplicateNameErrorMessage', 'Sibling products cannot have the same name.'),
				type: 'danger',
				time: false,
			})
		}
		if (product.id) {
			productCrudStore.update(product)
		} else {
			productCrudStore.create(product)
		}

		productsTree = upsertIntoTree(productsTree, product, 'uuid', 'parentProductUuid')
	}

	function updateSpecification(specification: MetaSpecification) {
		if (!specification || !selectedProduct) {
			return
		}
		if (specification.id) {
			specificationCrudStore.update(specification)
		} else {
			specificationCrudStore.create(specification)
		}
		// Reup the cached specifications version for the selected product
		specificationCache[selectedProduct.uuid] = selectedProductSpecifications
	}

	function getChildrenOfParentReferenceMap(productArray: Array<ProductNode>) {
		return productArray.reduce((acc: { [key: string]: Array<ProductNode> }, product) => {
			function traverseTree(product: ProductNode) {
				if (product.parentProductUuid) {
					acc[product.parentProductUuid] ??= []
					acc[product.parentProductUuid].push(product)
				}
				if (product.children && product.children.length > 0) {
					product.children.forEach(child => traverseTree(child))
				}
			}
			traverseTree(product)
			return acc
		}, {})
	}

	async function onNewProduct(target: 'TOP' | 'PARENT' | 'SIBLING' | 'CHILD') {
		if (!selectedProduct) {
			target = 'TOP'
		}
		// Get new parent product (null for top-level products)
		let newParent: ProductNode | null = null
		switch (target) {
			case 'TOP':
				break
			case 'PARENT':
			case 'SIBLING':
				newParent = selectedProduct?.parent ?? null
				break
			case 'CHILD':
				newParent = selectedProduct ?? null
				break
			default:
				throw new Error(`Invalid value for 'target': ${target} When calling onNewProduct`)
		}
		// "copy" the selected product, or the template if no product is selected
		const emptyProduct: Readonly<ProductNode> = Object.freeze({
			id: null,
			uuid: uuid(),
			name: '',
			barcodeFormat: '',
			category: '',
			description: '',
			productType: 'PRODUCT',
			active: true,
			inUseAtPlants: [],
			itemNumber: '',
			supplierItemNumber: '',
			parentProductId: null,
			parentProductUuid: null,
			tags: [],
			attachments: [],
			imageAttachments: [],
			attachmentCount: 0,
			thumbnailPath: '',
			inUse: true,
			children: [],
			parent: null,
			depth: 0,
		})

		const newProduct = copyProduct(selectedProduct ?? emptyProduct, newParent, true)
		// Move stuff around if we're inserting as parent of selected product
		if (target === 'PARENT' && selectedProduct) {
			// Get parent's children (now this products's children)
			newProduct.children = newParent?.children.map(child => cutProductAndChildren(child, newProduct)) ?? [cutProductAndChildren(selectedProduct, newProduct)]
			// Clear newParent's children (newProduct will be added later)

			if (newParent) {
				newParent.children = []
			} else {
				// If newParent is null, we're inserting at the top level, so remove selectedProduct
				productsTree = removeFromTree(productsTree, selectedProduct, 'uuid', 'parentProductUuid', false)
			}
			// Add all freshly moved children to crud store (only top layer's parent has changed)
			// Should keep all "new" children in the "created" object
			newProduct.children.forEach(child => productCrudStore.update(child))
		}
		// Make unique after we possibly move children around
		const uniqueProduct = makeProductUnique(newProduct)
		// Insert new product into tree
		productsTree = upsertIntoTree(productsTree, uniqueProduct, 'uuid', 'parentProductUuid')

		// Insert new product into crud store
		productCrudStore.create(uniqueProduct)
		await tick()
		selectedProduct = uniqueProduct
		selectedSpecification = null
		selectedProductSpecifications = []
		if (newParent) {
			theTable.expandRow(newParent.uuid)
		}
		await tick()
		const newRow = document.querySelector(`tr[data-id=${uniqueProduct.uuid}]`)
		if (newRow) {
			newRow.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
		}
		await onProductSelect(uniqueProduct)
	}

	function makeProductUnique(product: ProductNode, siblings: Array<ProductNode> = productsTree) {
		product.name = getDuplicateName(product, siblings, 'name')
		return product
	}

	function cutProductAndChildren(product: ProductNode, newParent: ProductNode | null) {
		product.parentProductId = newParent?.id ?? null
		product.parentProductUuid = newParent?.uuid ?? null
		product.parent = newParent

		return product
	}

	/**
	 * Used for both the copy/paste feature, and inserting new products
	 * @param product The product to copy
	 * @param parentProduct The new parent product that the copied product will be inserted into
	 * @param asNewLocation Whether to copy the parent's tags into the new product (used when inserting new, not when copy/pasting)
	 */
	function copyProduct(product: ProductNode, parentProduct: ProductNode | null, asNewLocation = false) {
		// remove parent and children to avoid circular references inside of klona
		const { parent, children, ...productToCopy } = product

		const newProduct: ProductNode = {
			...klona(productToCopy),
			id: null,
			uuid: uuid(),
			attachmentCount: 0,
			attachments: [],
			children: new Array<ProductNode>(),
			parentProductId: parentProduct?.id ?? null,
			parentProductUuid: parentProduct?.uuid ?? null,
			parent: parentProduct,
			inUseAtPlants: selectedPlant ? [{ ...selectedPlant }] : [],
			// copy some stuff from the parent when inserting a new product
			tags: asNewLocation ? mergeTags(product.tags ?? [], parentProduct?.tags ?? []) : mergeTags(product.tags, []),
		}

		// Track tags to add to the new Product
		newProduct.tags.forEach(tag => {
			tagAddRemoveStore.add(newProduct.uuid, tag.uuid)
		})
		return newProduct
	}

	function mergeTags(targetTags: Array<MetaTag>, parentTags: Array<MetaTag>) {
		const uuids = new Set<string>(targetTags.concat(parentTags).map(tag => tag.uuid))
		return tags.filter(tag => uuids.has(tag.uuid))
	}

	function copyProductAndChildren(product: ProductNode, parentProduct: ProductNode | null) {
		const newProduct = copyProduct(product, parentProduct)
		if (Array.isArray(product.children)) {
			newProduct.children = product.children.map(child => {
				return copyProductAndChildren(child, newProduct)
			})
		}
		return newProduct
	}

	async function paste(atTopLevel?: boolean) {
		const clipboardValue = clipboard // can't type narrow a $store, so assign it to a const instead
		if (!clipboardValue) {
			return
		}

		// On first cut -> paste, don't clone, just move
		const newParent = atTopLevel ? null : (selectedProduct ?? null)

		// Make sure we're not pasting into the tree being cut BEFORE we do any cutting/copying
		if (isCut && newParent && uuidInTree(newParent.uuid, clipboardValue)) {
			return alert(translate('product.cannotPasteIntoTreeBeingCut', 'Cannot paste into the tree being cut. Select another area, or use the copy operation.'))
		}

		// remove from old product if cutting
		if (isCut) {
			productsTree = removeFromTree(productsTree, clipboardValue, 'uuid', 'parentProductUuid', false)
		}

		// THEN we can cut/copy
		const productToPaste = isCut ? cutProductAndChildren(clipboardValue, newParent) : copyProductAndChildren(clipboardValue, newParent)

		if (!newParent) {
			// top level
			productToPaste.parentProductId = null
			productToPaste.parentProductUuid = null
			productToPaste.name = getDuplicateName(productToPaste, productsTree, 'name')
		} else if (selectedProduct) {
			//below selected product
			productToPaste.name = getDuplicateName(productToPaste, selectedProduct.children, 'name')
		}

		// reinsert into tree
		productsTree = upsertIntoTree(productsTree, productToPaste, 'uuid', 'parentProductUuid')

		// Only first paste after cut should be a cut
		isCut = false

		productCrudStore.create(productToPaste)
		productsTree = productsTree
		await tick()
		selectedProduct = productToPaste
		document.querySelector(`tr[data-id=${productToPaste.uuid}]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
		await onProductSelect(productToPaste)
	}

	function uuidInTree(uuid: string, tree: ProductNode) {
		if (tree.uuid === uuid) {
			return true
		}
		if (Array.isArray(tree.children)) {
			return tree.children.some(child => uuidInTree(uuid, child))
		}
		return false
	}

	async function deleteProduct(product: ProductNode, deleteChildren: boolean = false) {
		if (!product) {
			return
		}

		let productIds = product.id ? [product.id] : []
		let productsToDelete = [product]
		if (deleteChildren) {
			productIds = [...productIds, ...getChildrenIds(product)]
			productsToDelete = [...productsToDelete, ...getAllChildren(product)]
		}

		function doTheDelete() {
			// Mark for deletion
			productsToDelete.forEach(product => productCrudStore.delete(product))
			// Remove from tree
			productsTree = removeFromTree(productsTree, product, 'uuid', 'parentProductUuid', deleteChildren)
			// Set parent IDs to null and set in update map
			if (!deleteChildren) {
				product.children.forEach(child => {
					child.parentProductId = product?.parent?.id ?? null
					productCrudStore.update(child)
				})
			}
		}

		if (product.id) {
			const productRefCounts = await checkProductReferenceCounts(productIds)

			// If we are deleting products we cannot delete products attached to samples
			const safe = productRefCounts.sampleCount === 0

			let message = ''
			if (safe) {
				if (deleteChildren) {
					//No samples but deleting children
					message = translate(
						'product.safeConfirmDeleteProductAndChildren',
						`This product and all its children will be deleted. Are you sure you want to delete this product?
Referenced by: Samples: {{sampleCount}}; Analysis Threhsholds: {{analysisCount}}; Product Batches: {{productBatchCount}}.
After saving this cannot be undone.`,
						{
							sampleCount: productRefCounts.sampleCount,
							analysisCount: productRefCounts.analysisCount,
							productBatchCount: productRefCounts.productBatchCount,
						},
					)
				} else {
					//No samples and not deleting children
					message = translate(
						'product.safeConfirmDeleteProductNoChildren',
						`Are you sure you want to delete this product?
This product is referenced by: Samples: {{sampleCount}}; Analysis Threhsholds: {{analysisCount}}; Product Batches: {{productBatchCount}}; and has {{childProductCount}}.
After saving this cannot be undone.`,
						{
							sampleCount: productRefCounts.sampleCount,
							analysisCount: productRefCounts.analysisCount,
							productBatchCount: productRefCounts.productBatchCount,
							childProductCount: product.children.length,
						},
					)
				}
			} else {
				//Samples exist therefore cannot delete
				message = translate(
					'product.unsafeConfirmDeleteProduct',
					`This product is referenced by: Samples: {{sampleCount}}; Analysis Threhsholds: {{analysisCount}}; Product Batches: {{productBatchCount}}; and has {{childProductCount}}.
A product cannot be deleted while any samples are attached to it.
Either delete said samples or have an administrator perform this operation.`,
					{
						sampleCount: productRefCounts.sampleCount,
						analysisCount: productRefCounts.analysisCount,
						productBatchCount: productRefCounts.productBatchCount,
						childProductCount: product.children.length,
					},
				)
			}
			if (safe && confirm(message)) {
				doTheDelete()
			} else if (!safe) {
				alert(message)
			}
		}
	}

	function getAllChildren(product: ProductNode) {
		const children = new Array<ProductNode>()
		if (Array.isArray(product.children)) {
			product.children.forEach(child => {
				children.push(child)
				getAllChildren(child).forEach(child => children.push(child))
			})
		}
		return children
	}

	function getChildrenIds(product: ProductNode) {
		const childrenIds = new Array<number>()
		if (Array.isArray(product.children)) {
			product.children.forEach(child => {
				if (child.id) {
					childrenIds.push(child.id)
				}
				getChildrenIds(child).forEach(id => childrenIds.push(id))
			})
		}
		return childrenIds
	}

	async function addFilesToSelectedProduct({ detail: filesToAdd }: { detail: BaseAttachmentFile[] }) {
		if (selectedProduct) {
			const productId = selectedProduct.id
			const productUuid = selectedProduct.uuid
			const metaAttachments: ProductAttachment[] = filesToAdd.map(file => ({ ...file, productId, productUuid, path: file.path ?? '', uuid: file.uuid ?? '' }))
			metaAttachments.forEach(file => attachmentCrudStore.create(file))
			selectedProduct.attachments.push(...metaAttachments)
			selectedProduct.imageAttachments.push(...metaAttachments.filter(file => file.mimeType?.startsWith('image/')))
			selectedProduct.thumbnailPath = selectedProduct.imageAttachments[0].path
			selectedProduct.attachmentCount += filesToAdd.length
			selectedProduct = selectedProduct
			productsTree = productsTree
		}
	}

	function removeFilesFromSelectedProduct({ detail: filesToDelete }: { detail: BaseAttachmentFile[] }) {
		if (selectedProduct) {
			const productId = selectedProduct.id
			const productUuid = selectedProduct.uuid
			filesToDelete.forEach(file => attachmentCrudStore.delete({ ...file, productId, productUuid, path: file.path ?? '', uuid: file.uuid ?? '' }))
			if (selectedProduct) {
				selectedProduct.attachments = selectedProduct.attachments.filter(attachment => !filesToDelete.some(file => file.uuid === attachment.uuid)) ?? []
				selectedProduct.imageAttachments = selectedProduct.imageAttachments.filter(attachment => !filesToDelete.some(file => file.uuid === attachment.uuid)) ?? []
			}
			selectedProduct.thumbnailPath = selectedProduct.imageAttachments[0]?.path ?? null
			selectedProduct.attachmentCount -= filesToDelete.length
			selectedProduct = selectedProduct
			productsTree = productsTree
		}
	}

	function productHasUniqueName(selectedProduct: ProductNode | null): boolean {
		if (!selectedProduct) {
			return true
		}

		if (!selectedProduct.parentProductUuid) {
			// If the product is top level, it can't have a duplicate name with other top level products
			// In this case the productsTree array is all the top level products
			const names = productsTree.map(product => product.name)
			return names.length === new Set(names).size
		}
		const children = parentReference[selectedProduct.parentProductUuid] ?? []
		const childrenNames = children.map(child => child.name)
		return childrenNames.length === new Set(childrenNames).size
	}

	async function onProductSelect(product: ProductNode) {
		selectedProductSpecifications = []
		selectedProduct = product
		// put the product in the url so it stays selected on refresh
		asr.go(null, { selectedProductId: product.id }, { inherit: true })

		// We use this cache to prevent uuids from changing between product selections
		if (specificationCache.has(product.uuid)) {
			selectedProductSpecifications = specificationCache[product.uuid]
			return
		}

		if (!product.id) {
			// If the product is new, we don't need to load the specifications but we can innitialize its cache entry
			specificationCache[product.uuid] = []
			return
		}

		try {
			if (!selectedPlant) {
				mediator.call('showMessage', {
					type: 'danger',
					heading: translate('product.errorLoadingSpecificationsHeading', 'No Plant Selected'),
					message: translate('product.errorLoadingSpecificationsMessage', 'Plant selection is required to load product specifications.'),
					time: false,
				})
				throw new Error('No plant selected')
			}
			const { data } = await loadProductSpecificationsQuery.fetch({
				variables: {
					filter: {
						plantId: selectedPlant.id,
						productIds: [product.id],
					},
				},
			})
			if (!data?.analysisOptionChoices.data) {
				selectedProductSpecifications = []
			}
			selectedProductSpecifications =
				data?.analysisOptionChoices.data.map(spec => {
					return {
						...spec,
						productUuid: product.uuid,
						uuid: uuid(),
					}
				}) ?? []
			specificationCache[product.uuid] = selectedProductSpecifications
		} catch (error) {
			console.error('Error loading product specifications:', error)
			selectedProductSpecifications = []
		}

		if (product?.tags.some(tag => tag.id && !tag.uuid)) {
			product.tags = product.tags.reduce((acc, tag) => {
				if (tag.uuid !== '') {
					acc.push(tag)
				} else if (tag.id) {
					const matchingTag = tags.find(t => t.id === tag.id)
					if (matchingTag) {
						acc.push({
							...tag,
							uuid: matchingTag.uuid,
						})
					}
				}
				return acc
			}, new Array<MetaTag>())
		}

		await tick()

		document.getElementById(`product-name-input-${product.uuid}`)?.focus()
	}

	async function addSpecification() {
		if (!canEditChoice(plantId)) {
			mediator.call('showMessage', {
				type: 'danger',
				heading: translate('product.errorAddingSpecificationHeading', 'Unauthorized'),
				message: translate('product.errorAddingSpecificationMessage', 'You do not have permission to add a specification to this product.'),
				time: false,
			})
			console.error(translate('product.errorAddingSpecificationMessage', 'You do not have permission to add a specification to this product.'))
			return
		}
		if (!selectedProduct) {
			return
		}
		const specificationTemplate: Readonly<MetaSpecification> = Object.freeze({
			id: null,
			uuid: uuid(),
			productId: selectedProduct?.id,
			productUuid: selectedProduct.uuid,
			plantId: selectedPlant.id,
			analysisOption: null,
			productBatchId: null,
			requiredAnalysisOption: null,
			requiredChoice: null,
			requiredConstraint: 'NONE',
			severityClass: null,
			boundaryType: 'UNACCEPTABLE',
			choice: '',
			constraint: 'MINIMUM',
		})

		const newSpecification = klona(specificationTemplate)

		selectedProductSpecifications.push(newSpecification)
		specificationCache[selectedProduct.uuid] = selectedProductSpecifications
		specificationCrudStore.create(newSpecification)
		selectedProductSpecifications = selectedProductSpecifications
		await tick()
		onSpecificationSelect(newSpecification)
	}

	async function restoreSpecification(specification: MetaSpecification) {
		if (specification.id) {
			specificationCrudStore.unDelete(specification)
		}

		await tick()
	}

	async function deleteSpecification(specification: MetaSpecification) {
		if (specification.id && confirm(translate('product.confirmDeleteSavedSpecification', 'Are you sure you want to delete this saved specification? This cannot be undone after save.'))) {
			// We don't filter out the deleted specification from the cache because we want to keep it in case of an undo
			specificationCrudStore.delete(specification)
		} else if (!specification.id && confirm(translate('product.confirmDeleteUnsavedSpecification', 'Are you sure you want to delete this unsaved specification? This cannot be undone.'))) {
			selectedProductSpecifications = selectedProductSpecifications.filter(spec => spec.uuid !== specification.uuid)
			if (selectedProduct) {
				specificationCache[selectedProduct.uuid] = selectedProductSpecifications
			}
			selectedSpecification = null
		}

		await tick()
	}

	function onSpecificationSelect(row: MetaSpecification) {
		if (row === selectedSpecification) {
			selectedSpecification = null
		} else {
			selectedSpecification = row
		}
	}

	onMount(() => {
		if (selectedProduct) {
			onProductSelect(selectedProduct)
		}
	})
</script>

<div class="form-row">
	<div
		class="col-12"
		class:col-xl-9={!!selectedProduct}
	>
		<div class="card mb-1 w-100">
			<div class="card-header d-flex h5 justify-content-between border-radius-2rem">
				<h5 class="card-title mb-2">{translate('common:products', 'Products')}</h5>
			</div>
			<div class="card-body">
				<Table
					stickyHeader
					columnHidingEnabled
					filterEnabled
					responsive
					tree
					parentClass="mh-40vh"
					{columns}
					rows={productsTree}
					{currentPageRows}
					rowSelectionIdProp="uuid"
					filterPlaceholder={translate('product.filterProductAndChildren', 'Filter Products and Children')}
					bind:selectedRowIds
					bind:this={theTable}
				>
					<svelte:fragment slot="header">
						<div class="form-row">
							<div class="col-12 col-md-3">
								<div
									class="mr-2"
									style="min-width: 300px;"
								>
									<SiteAutocomplete
										label={translate('product.plant', 'Plant')}
										options={plants}
										bind:value={selectedPlant}
										on:change={() => {
											asr.go('app.product-management.product', { plantId: selectedPlant.id }, { inherit: true })
										}}
									/>
								</div>
							</div>
							<div class="col-12 mb-2">
								<div class="d-flex flex-row">
									<div>
										{#each productTypes as type}
											<div class="form-check d-flex align-items-center mr-2">
												<input
													class="form-check-input"
													type="radio"
													name={selectedProductTypeFilter}
													value={type.value}
													id="selected-product-{type.value}"
													bind:group={selectedProductTypeFilter}
													on:change={() => {
														asr.go('app.product-management.product', { selectedProductTypeFilter, selectedProductId: null }, { inherit: true })
													}}
												/>
												<label
													class="form-check-label"
													for="selected-product-{type.value}"
												>
													{type.label}
												</label>
											</div>
										{/each}
									</div>
									<div class="row">
										<div class="col">
											<div class="form-check">
												<Checkbox
													inline
													label={translate('product.showInactiveProducts', 'Show Inactive Products')}
													bind:checked={showInactive}
													on:change={() => {
														asr.go('app.product-management.product', { showInactive }, { inherit: true })
													}}
												/>
											</div>
											<div class="form-check">
												<Checkbox
													inline
													label={translate('product.showProductsNotInUse', `Show Products Not In Use at {{plantCode}}`, { plantCode: selectedPlant?.code })}
													bind:checked={showUnused}
													on:change={() => {
														asr.go('app.product-management.product', { showUnused }, { inherit: true })
													}}
												/>
											</div>
										</div>
										<div class="col-auto align-self-end form-check">
											<Checkbox
												label={translate('product.showThumbnails', 'Show Thumbnails')}
												bind:checked={$showImageThumbnails}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</svelte:fragment>
					<svelte:fragment
						slot="body"
						let:rows
					>
						{#if rows.length > 0}
							{#each rows as row (row.uuid)}
								{@const isDirty = $productCrudStore && (productCrudStore.isUpdated(row) || productCrudStore.isCreated(row))}
								{@const deleted = $productCrudStore && productCrudStore.isDeleted(row)}
								<TreeRow
									idProp="uuid"
									parentIdProp="parentProductUuid"
									property="name"
									node={row}
									let:node
									on:rowClick={async event => {
										await onProductSelect(event.detail)
									}}
								>
									<span
										let:node
										slot="first"
										class:text-primary={node.children.length}
										title={isCut
											? translate('product.cutProductIconTitle', 'This product has been cut, and will be moved when pasted.')
											: translate('product.copyProductIconTitle', 'This product has been copied, and will be duplicated when pasted.')}
									>
										{#if copiedProductUuid === node.uuid}
											<Icon
												fixedWidth
												icon={isCut ? 'scissors' : 'copy'}
											/>
										{/if}
									</span>
									<Td property="dirty">
										{#if !!isDirty}
											<Icon
												fixedWidth
												icon="save"
											/>
										{:else if !!deleted}
											<Icon
												fixedWidth
												icon="trash"
											/>
										{/if}
									</Td>
									<Td
										stopPropagation
										property="attachmentCount"
										title={translate('product.imageThumbnailTdTitle', 'Click to view image attachments at the selected product.')}
									>
										<ImageThumbnail
											showImageCount
											noImagePath="images/noimage.jpg"
											fileCount={node.imageAttachments.length ?? 0}
											thumbnailFile={{ path: node.thumbnailPath ?? '' }}
											showThumbnail={$showImageThumbnails}
											on:click={async () => {
												await onProductSelect(node)
												currentPhotoIndex = 0
												await tick()
												showImageViewer = true
											}}
										/>
									</Td>
									<Td property="active">
										<Icon
											fixedWidth
											icon={node.active ? 'check' : 'xmark'}
											class={node.active ? 'text-success' : 'text-danger'}
										/>
									</Td>
									<Td property="inUse">
										<Icon
											fixedWidth
											icon={node.inUse ? 'check' : 'xmark'}
											class={node.inUse ? 'text-success' : 'text-danger'}
										/>
									</Td>
								</TreeRow>
							{:else}
								<tr>
									<td
										class="text-center"
										colspan={columns.length}
									>
										{translate('product.noProductsMatchingTheCurrentFilter', 'No Products Matching the Current Filter')}
									</td>
								</tr>
							{/each}
						{:else}
							<tr>
								<td
									class="text-center"
									colspan={columns.length}
								>
									{translate('product.noProducts', 'No Products! Click "New Product" to add one.')}
								</td>
							</tr>
						{/if}
					</svelte:fragment>
				</Table>
			</div>
			<div class="card-footer d-flex justify-content-between">
				<div>
					<Dropdown
						split
						outline
						size="sm"
						color="success"
						iconClass="plus"
						title={translate('product.newProductButtonTitle', 'Add a new product as a sibling of the selected product.')}
						disabled={!canEditGlobalFields()}
						on:click={() => onNewProduct('SIBLING')}
					>
						{translate('product.newProductButton', 'New Product')}
						<svelte:fragment slot="dropdownItems">
							<button
								class="dropdown-item"
								type="button"
								disabled={!canEditGlobalFields()}
								on:click={() => onNewProduct('TOP')}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="plus"
								/>
								{translate('product.newTopLevelProductButton', 'New Top-Level Product')}
							</button>
							<h6 class="dropdown-header">{translate('product.relativeToSelectedProductDropdownTitle', 'Relative to Selected Product')}</h6>
							<button
								class="dropdown-item"
								type="button"
								disabled={!selectedProduct || !canEditGlobalFields()}
								on:click={() => onNewProduct('PARENT')}
							>
								<Icon
									fixedWidth
									class="fa-rotate-180 mr-1"
									icon="turn-down-right"
								/>
								{translate('product.aboveParentButton', 'Above (Parent)')}
							</button>
							<button
								class="dropdown-item"
								type="button"
								disabled={!selectedProduct || !canEditGlobalFields()}
								on:click={() => onNewProduct('SIBLING')}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="down"
								/>
								{translate('product.nextToSiblingButton', 'Next to (Sibling)')}
							</button>
							<button
								class="dropdown-item"
								type="button"
								disabled={!selectedProduct || !canEditGlobalFields()}
								on:click={() => onNewProduct('CHILD')}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="turn-down-right"
								/>
								{translate('product.belowChildButton', 'Below (Child)')}
							</button>
						</svelte:fragment>
					</Dropdown>
					<Button
						outline
						size="sm"
						iconClass="cut"
						title={translate('product.cutProductButtonTitle', 'Cut selected product to clipboard; the first paste will move the product, subsequent pastes will copy it.')}
						disabled={!selectedProduct || !canEditGlobalFields()}
						on:click={() => {
							if (selectedProduct) {
								isCut = true
								clipboard = selectedProduct
								copiedProductUuid = selectedProduct.uuid
								mediator.call('showMessage', {
									heading: translate('product.copiedToClipboardMessageHeading', `Product "{{- name}}" Copied to Clipboard`, { name: selectedProduct.name }),
									message: translate('product.copiedToClipboardCutPasteMessage', 'You can now paste it elsewhere.'),
									type: 'info',
								})
							}
						}}
						>{translate('product.cutButton', 'Cut')}
					</Button>
					<Button
						outline
						size="sm"
						iconClass="copy"
						title={translate('product.copyProductButtonTitle', 'Copy selected product to clipboard; you can then paste it elsewhere.')}
						disabled={!selectedProduct || !canEditGlobalFields()}
						on:click={() => {
							if (selectedProduct) {
								isCut = false
								clipboard = copyProductAndChildren(selectedProduct, null)
								copiedProductUuid = selectedProduct.uuid
								mediator.call('showMessage', {
									heading: translate('product.copiedToClipboardMessageHeading', `Product "{{name}}" Copied to Clipboard`, { name: selectedProduct.name }),
									message: translate('product.copiedToClipboardCopyPasteMessage', 'You can now paste a copy of it elsewhere.'),
									type: 'info',
								})
							}
						}}
						>{translate('product.copyButton', 'Copy')}
					</Button>
					<Dropdown
						split
						outline
						size="sm"
						iconClass="paste"
						disabled={!clipboard || !canEditGlobalFields()}
						title={translate('product.pasteProductButtonTitle', 'Paste the product from the clipboard.')}
						on:click={() => paste(!selectedProduct)}
					>
						{translate('product.paste', 'Paste')}
						<svelte:fragment slot="dropdownItems">
							<h6 class="dropdown-header">{translate('product.pasteDropdownHeader')}</h6>
							<button
								class="dropdown=item"
								type="button"
								disabled={!clipboard || !canEditGlobalFields()}
								on:click={() => paste(true)}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="plus"
								/>
								{translate('product.pasteAtTheTopLevel', 'At the Top Level')}
							</button>
							<button
								class="dropdown-item"
								type="button"
								disabled={!clipboard || !canEditGlobalFields()}
								on:click={() => paste()}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="turn-down-right"
								/>
								{translate('product.pasteBelowSelectedProduct', 'Below (child of) Selected Product')}
							</button></svelte:fragment
						>
					</Dropdown>
					<Dropdown
						split
						outline
						size="sm"
						color="danger"
						iconClass="trash"
						title={translate('product.deleteProductButton', 'Delete the selected product.')}
						disabled={!selectedProduct || !canEditGlobalFields()}
						on:click={() => selectedProduct && deleteProduct(selectedProduct)}
					>
						{translate('product.deleteButton', 'Delete')}...
						<svelte:fragment slot="dropdownItems">
							<h6 class="dropdown-header">{translate('product.deleteDropdownHeader', 'Delete')}...</h6>
							<button
								class="dropdown-item"
								type="button"
								disabled={!selectedProduct || !canEditGlobalFields()}
								on:click={() => selectedProduct && deleteProduct(selectedProduct)}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="trash"
								/>
								{translate('product.deleteSelectedProductOnlyButton', 'Selected Product Only')}
							</button>
							<button
								type="button"
								class="dropdown-item"
								disabled={!selectedProduct || !canEditGlobalFields()}
								on:click={() => selectedProduct && deleteProduct(selectedProduct, true)}
							>
								<Icon
									fixedWidth
									class="mr-1"
									icon="turn-down-right"
								/>
								{translate('product.deleteSelectedProductAndChildrenButton', 'Selected Product and Children')}
							</button>
						</svelte:fragment>
					</Dropdown>
				</div>
				<Button
					outline
					size="sm"
					iconClass="paperclip"
					title={translate('product.openAttachmentsButtonTitle', 'Open the attachments interface, where you can view and manage attachments, for the selected product.')}
					disabled={!selectedProduct}
					on:click={() => (showAttachmentsModal = true)}
				>
					{translate('product.openAttachmentsButton', 'Attachments')}...
				</Button>
			</div>
		</div>
	</div>
	{#if selectedProduct}
		<div
			class="col-xl-3 col-12 mb-1"
			class:d-none={!selectedProduct}
		>
			<div class="h-100 d-flex flex-column mt-2 mt-xl-0">
				<CollapsibleCard
					entireHeaderToggles
					bodyShown={expandedCard === 'Details'}
					cardClass={expandedCard === 'Details' ? 'flex-grow-1' : ''}
					cardStyle="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
					bodyClass="d-flex flex-column"
					cardHeaderClass="card-header d-flex justify-content-between h5"
					on:show={() => (expandedCard = 'Details')}
					on:hide={() => (expandedCard = 'Tags')}
				>
					<svelte:fragment slot="cardHeader">
						<h5 class="card-title mb-2">{translate('product.detailsCardTitle', 'Details')}</h5>
					</svelte:fragment>
					{@const deleted = productCrudStore.isDeleted(selectedProduct)}
					<fieldset class="d-flex flex-column flex-grow-1">
						<div>
							<Checkbox
								inline
								label={translate('product.activeCheckboxLabel', 'Active')}
								disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
								bind:checked={selectedProduct.active}
								on:change={() => updateProduct(selectedProduct)}
							/>
							<Checkbox
								inline
								disabled={deleted || !hasPermission('PRODUCT_CAN_CHANGE_IN_USE', selectedPlant?.id)}
								label={translate('product.inUseAtCheckboxLabel', `In Use at {{- plantName}}`, { plantName: selectedPlant?.name })}
								bind:checked={selectedProduct.inUse}
								on:change={() => updateProduct(selectedProduct)}
							/>
						</div>
						<div>
							<Input
								id="product-name-input-{selectedProduct.uuid}"
								label={translate('product.nameInputLabel', 'Name')}
								required
								autofocus
								disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
								bind:value={selectedProduct.name}
								on:change={() => updateProduct(selectedProduct)}
								validation={{
									validator: () => {
										const isUnique = productHasUniqueName(selectedProduct)
										let translatedValidation = ''

										if (!isUnique) {
											translatedValidation = translate('product.nameMustBeUnique', 'Name must be unique.')
										}
										const translatedvalidationString = isString(translatedValidation) ? translatedValidation : ''
										return isUnique ? true : translatedvalidationString
									},
								}}
							/>
							<Autocomplete
								label={translate('product.categoryInputLabel', 'Category')}
								options={productCategories}
								getLabel={category => category ?? ''}
								disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
								bind:value={selectedProduct.category}
								on:change={() => updateProduct(selectedProduct)}
							/>
						</div>
						<div class="flex-grow-1">
							<Textarea
								label={translate('product.descriptionLabel', 'Description')}
								labelParentClass="h-100 d-flex flex-column"
								class="flex-grow-1"
								disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
								placeholder={translate('product.descriptionTitle', 'Enter a description (optional)')}
								bind:value={selectedProduct.description}
								on:change={() => updateProduct(selectedProduct)}
							/>
						</div>
						<Input
							label={translate('products.barcodeFormatLabel', 'Barcode Format')}
							title={translate('products.barcodeFormatTitle', "A regular expression representing the product's barcode format")}
							disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
							bind:value={selectedProduct.barcodeFormat}
						>
							<svelte:fragment slot="append">
								<Button
									outline
									iconClass="gear"
									disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
									on:click={() => selectedProduct && barcodeFormatModal?.open(selectedProduct.barcodeFormat)}
								></Button>
							</svelte:fragment>
						</Input>
						<Input
							label={translate('products.itemNumberLabel', 'Item Number')}
							title={translate('products.itemNumberTitle', 'Internal identifier of this form factor of ingredient')}
							disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
							bind:value={selectedProduct.itemNumber}
							on:change={() => updateProduct(selectedProduct)}
						></Input>
						<Input
							label={translate('products.supplierItemNumberLabel', 'Supplier Item Number')}
							title={translate('products.supplierItemNumberTitle', 'The unique identifier of the item from the manufacturer')}
							disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
							bind:value={selectedProduct.supplierItemNumber}
							on:change={() => updateProduct(selectedProduct)}
						></Input>
					</fieldset>
				</CollapsibleCard>
				<CollapsibleCard
					entireHeaderToggles
					bodyShown={expandedCard === 'Tags'}
					cardClass="{expandedCard === 'Tags' ? 'flex-grow-1' : ''} border-top-0"
					cardStyle="border-top-left-radius: 0px; border-top-right-radius: 0px;"
					bodyClass="d-flex flex-column"
					cardHeaderClass="card-header d-flex justify-content-between h5"
					on:show={() => (expandedCard = 'Tags')}
					on:hide={() => (expandedCard = 'Details')}
				>
					<svelte:fragment slot="cardHeader">
						<h5 class="card-title mb-2">{translate('product.tagsCardTitle', 'Tags')}</h5>
						{@const theTags = selectedProduct?.tags ?? []}
						<div
							class="align-self-end mr-auto ml-2"
							style="font-size: initial;"
						>
							{#each theTags as tag, index}
								<ExpandableBadge
									disabled
									class={index < theTags.length - 1 ? 'mr-1' : ''}
									text={tag.name}
								/>
							{/each}
						</div>
					</svelte:fragment>
					{#if selectedProduct?.tags}
						<TagSelection
							entityType="PRODUCT"
							tableParentClass="mh-60vh"
							includeCard={false}
							title={selectedProduct?.name ?? ''}
							disabled={!selectedProduct || !canEditChoice(plantId) || !canEditGlobalFields(selectedProduct?.id)}
							bind:tags
							bind:tagsInUse={selectedProduct.tags}
							bind:tagCrudStore
							on:tagsInUseAdd={({ detail: tag }) => (selectedProduct?.uuid ? tagAddRemoveStore.add(selectedProduct.uuid, tag.uuid) : null)}
							on:tagsInUseRemove={({ detail: tag }) => (selectedProduct?.uuid ? tagAddRemoveStore.remove(selectedProduct.uuid, tag.uuid) : null)}
							on:tagsInUseChange={() => updateProduct(selectedProduct)}
						/>
					{/if}
				</CollapsibleCard>
			</div>
		</div>
	{/if}
</div>
<ConfigureSpecificationCard
	bind:specifications={selectedProductSpecifications}
	bind:selectedSpecification
	{specificationCrudStore}
	{analysesAtCurrentPlant}
	{severityClasses}
	{plants}
	{plantId}
	{authorizedPlantIds}
	selectedEntityPlants={selectedProduct?.inUseAtPlants ?? []}
	{analysesById}
	{canEditChoice}
	canCreateSpec={!!selectedProduct && canEditChoice(plantId) && !productCrudStore.isDeleted(selectedProduct) && canEditGlobalFields(selectedProduct?.id)}
	canRestoreOrDeleteSpec={canEditChoice(plantId)}
	on:updateSpecification={event => updateSpecification(event.detail)}
	on:createSpecification={() => addSpecification()}
	on:restoreSpecification={event => restoreSpecification(event.detail)}
	on:deleteSpecification={event => deleteSpecification(event.detail)}
/>

<Modal
	closeShown={false}
	cancelShown={false}
	title="{selectedProduct?.name} Attachments"
	backdropClickCancels={false}
	modalSize="xxl"
	bind:show={showAttachmentsModal}
	on:confirm={() => (showAttachmentsModal = false)}
>
	{#if selectedProduct}
		<Attachments
			hidePublicFeatures
			hideRankFeatures
			uploadDisabled={!canEditGlobalFields(selectedProduct.id)}
			modificationDisabled={!canEditGlobalFields(selectedProduct.id)}
			fileList={selectedProduct.attachments}
			on:filesAdded={addFilesToSelectedProduct}
			on:filesDeleted={removeFilesFromSelectedProduct}
		/>
	{/if}
</Modal>

<ImageViewer
	title="{selectedProduct?.name} Images"
	files={selectedProductImages}
	bind:currentPhotoIndex
	bind:show={showImageViewer}
/>

<BarcodeFormatModal
	bind:this={barcodeFormatModal}
	{productsList}
	setBarcodeFormat={format => {
		if (selectedProduct) {
			selectedProduct.barcodeFormat = format
			updateProduct(selectedProduct)
		}
	}}
></BarcodeFormatModal>
