export default {
    "name": "LOCATIONS_PAGE",
    "kind": "HoudiniQuery",
    "hash": "4b3544b3d9d96b5c706f3dc47639fc8195b302d30a654b66db96ada9ef2bf999",

    "raw": `query LOCATIONS_PAGE($pagination: PaginatedInput, $lookup: SettingLookup!, $filter: EntityTagFilter) {
  plants(pagination: $pagination) {
    data {
      id
      code
      name
    }
    info {
      pageSize
      pageNumber
    }
  }
  getGlobalSetting(lookup: $lookup) {
    value
  }
  entityTags(filter: $filter) {
    name
    active
    entityType
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "getGlobalSetting": {
                "type": "SettingResult",
                "keyRaw": "getGlobalSetting(lookup: $lookup)",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "entityTags": {
                "type": "EntityTag",
                "keyRaw": "entityTags(filter: $filter)",

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityTagType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "lookup": "SettingLookup",
            "filter": "EntityTagFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "SettingLookup": {
                "category": "String",
                "defaultValue": "String",
                "name": "String",
                "settingType": "SettingType"
            },

            "EntityTagFilter": {
                "active": "Boolean",
                "entityTypes": "EntityTagType",
                "ids": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=87aeb6fd34ed13950a0450d4b9473dc3ae267fb5c4c1873fb4a0b55601ade055";