export default {
    "name": "GetProductReferenceCounts",
    "kind": "HoudiniQuery",
    "hash": "5c66c99d3727db40d096255c3f1c488b30afc32e1c0fd0934d5bde133afa095c",

    "raw": `query GetProductReferenceCounts($productIds: [NonNegativeInt!]!) {
  getProductReferenceCounts(productIds: $productIds) {
    analysisCount
    childProductCount
    productBatchCount
    sampleCount
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getProductReferenceCounts": {
                "type": "ProductReferenceCounts",
                "keyRaw": "getProductReferenceCounts(productIds: $productIds)",

                "selection": {
                    "fields": {
                        "analysisCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "analysisCount",
                            "visible": true
                        },

                        "childProductCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "childProductCount",
                            "visible": true
                        },

                        "productBatchCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "productBatchCount",
                            "visible": true
                        },

                        "sampleCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "sampleCount",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "productIds": "NonNegativeInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4efbf9cb132eb61c33ed18516f19532fb7a0b6f03ba5afb0c918663c62632a41";