<script lang="ts">
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import TextArea from '@isoftdata/svelte-textarea'
	import Table from '@isoftdata/svelte-table'
	import { getContext, tick } from 'svelte'
	import type { i18n } from 'types/common'
	import { Dropdown, DropdownItem } from '@isoftdata/svelte-dropdown'
	import Icon from '@isoftdata/svelte-icon'
	import { fade } from 'svelte/transition'
	import Button from '@isoftdata/svelte-button'

	let show = false
	let barcodeFormat = ''
	let testBarcode = ''
	let barcodeFormatTextArea: HTMLTextAreaElement | undefined = undefined
	let selectedRegexGroup: string | null = null
	let insertLength: number | null = null

	export let setBarcodeFormat: (barcodeFormat: string) => void
	export let productsList: Array<{ name: string; barcodeFormat: string }>

	const { t: translate } = getContext<i18n>('i18next')
	// TODO make this type suck less
	type Token =
		| {
				label: string
				regex: string
		  }
		| {
				label: string
				group: string
				length?: number | undefined
				lengths?: never
				numeric: boolean
		  }
		| {
				label: string
				group: string
				lengths?: Array<number>
				length?: never
				numeric: boolean
		  }

	const tokens: Array<Token> = [
		{
			label: translate('barcodeFormat.startOfString', 'Start of Barcode'),
			regex: '^',
		},
		{
			label: translate('barcodeFormat.endOfString', 'End of Barcode'),
			regex: '$',
		},
		{
			label: translate('barcodeFormat.expirationMonth', 'Expiration Month'),
			group: 'expirationMonth',
			length: 2,
			numeric: true,
		},
		{
			label: translate('barcodeFormat.expirationDay', 'Expiration Day'),
			group: 'expirationDay',
			length: 2,
			numeric: true,
		},
		{
			label: translate('barcodeFormat.expirationYear', 'Expiration Year'),
			group: 'expirationYear',
			lengths: [2, 4],
			numeric: true,
		},
		{
			label: translate('barcodeFormat.lotNumber', 'Lot Number'),
			group: 'lotNumber',
			numeric: false,
		},
		{
			label: translate('barcodeFormat.supplierItemNumber', 'Supplier Item Number'),
			group: 'supplierItemNumber',
			numeric: false,
		},
	]

	$: regexResult = testRegex(barcodeFormat, testBarcode)
	$: rows =
		regexResult &&
		tokens.reduce((acc: Array<{ label: string; value: string }>, token) => {
			if ('group' in token && token.group) {
				acc.push({
					label: token.label,
					value: !token.length || token.length === regexResult[token.group]?.length ? regexResult[token.group] : '',
				})
			}
			return acc
		}, [])

	function testRegex(barcodeFormat: string, testBarcode: string) {
		try {
			return new RegExp(barcodeFormat).exec(testBarcode)?.groups ?? {}
		} catch (err: any) {
			console.error(err)
			return {}
		}
	}

	function buildTokenRegex(token: Token) {
		return 'group' in token ? `(?<${token.group}>${token.numeric ? '[0-9]' : '.'}${token.length ? `{${token.length}}` : '+'})` : token.regex
	}

	export async function open(productBarcodeFormat: string) {
		show = true
		barcodeFormat = productBarcodeFormat
		await tick()
		barcodeFormatTextArea = document.getElementById('barcodeFormatTextArea') as HTMLTextAreaElement
	}
</script>

<Modal
	bind:show
	modalSize="xl"
	title={translate('barcodeFormat.modalTitle', 'Barcode Format')}
	subtitle={translate('barcodeFormat.modalSubtitle', 'Enter a regular expression representing the barcode format, which will be used to populate fields upon scanning a product barcode.')}
	backdropClickCancels={false}
	on:close={() => (show = false)}
	on:confirm={() => {
		show = false
		setBarcodeFormat?.(barcodeFormat)
	}}
>
	<div class="card-group">
		<div class="card">
			<div class="card-header h6">{translate('barcodeFormat.barcodePartsTitle', 'Barcode Parts')} <small>{translate('barcodeFormat.barcodePartsSubtitle', '(Click to insert)')}</small></div>
			<div class="card-body p-0">
				<li class="list-group list-group-flush">
					{#each tokens as token}
						{@const disabled = 'group' in token && !!token.group && barcodeFormat.includes(token.group)}
						{@const selected = 'group' in token && token.group && token.group === selectedRegexGroup}
						{@const expandable = 'group' in token && token.group && !token.length}
						<button
							class="list-group-item list-group-item-action"
							class:list-group-item-primary={selected}
							{disabled}
							on:click={() => {
								if (selected) {
									selectedRegexGroup = null
								} else if ('group' in token && expandable) {
									selectedRegexGroup = token.group
									insertLength = token.lengths ? token.lengths[0] : null
								} else {
									barcodeFormat += buildTokenRegex(token)
									barcodeFormatTextArea?.focus()
								}
							}}
							><div class="d-flex justify-content-between align-items-baseline">
								{token.label}<Icon
									fixedWidth
									class="ml-1"
									icon={disabled ? 'check' : expandable && !selected ? 'caret-right' : expandable ? 'caret-down' : 'arrow-right'}
								></Icon>
							</div></button
						>
						{#if selected}
							<div class="list-group-item list-group-item-primary">
								{#if token.lengths}
									<span class="mr-1 mb-1">{translate('barcodeFormat.lengthLabel', 'Length')} </span>
								{/if}
								{#each token.lengths ?? [] as length}
									<div class="form-check form-check-inline">
										<input
											class="form-check-input"
											type="radio"
											id="length-{length}"
											value={length}
											bind:group={insertLength}
										/>
										<label
											class="form-check-label"
											for="length-{length}">{length}</label
										>
									</div>
								{/each}
								{#if !token.lengths}
									<Input
										autofocus
										min="0"
										type="number"
										label={translate('barcodeFormat.lengthLabel', 'Length')}
										placeholder={translate('barcodeFormat.lengthPlaceholder', 'Leave blank for any length')}
										bind:value={insertLength}
									></Input>
								{/if}
								<Button
									class="w-100"
									iconClass="arrow-right"
									size="sm"
									on:click={() => {
										barcodeFormat += buildTokenRegex({
											label: token.label,
											group: token.group,
											length: insertLength ?? undefined,
											numeric: token.numeric,
										})
										barcodeFormatTextArea?.focus()
										selectedRegexGroup = null
									}}>{translate('barcodeFormat.insertButtonLabel', 'Insert')}</Button
								>
							</div>
						{/if}
					{/each}
				</li>
			</div>
		</div>
		<div
			class="card"
			style:flex-grow="1.5"
		>
			<div class="card-header h6">{translate('barcodeFormat.barcodeFormatTitle', 'Barcode Format')}</div>
			<div class="card-body d-flex flex-column">
				<TextArea
					id="barcodeFormatTextArea"
					label={translate('barcodeFormat.barcodeFormatLabel', 'Barcode Format')}
					class="flex-grow-1"
					spellcheck={false}
					labelParentClass="h-100 d-flex flex-column"
					style="min-height: 120px"
					bind:value={barcodeFormat}
				></TextArea>
				<Input
					label={translate('barcodeFormat.testBarcodeLabel', 'Test Barcode')}
					placeholder={translate('barcodeFormat.testBarcodePlaceholder', 'Enter a barcode to test the formula')}
					bind:value={testBarcode}
				>
					<svelte:fragment slot="hint">
						{#if barcodeFormat && testBarcode && !Object.values(regexResult).length}
							<small
								transition:fade={{ delay: 500, duration: 0 }}
								class="text-danger">{translate('barcodeFormat.invalidBarcode', 'Does not match barcode format')}</small
							>
						{/if}
					</svelte:fragment>
				</Input>
			</div>
			<div class="card-footer">
				<Dropdown
					outline
					size="sm"
					iconClass="copy"
				>
					{translate('barcodeFormat.copyFormatLabel', 'Copy from Existing Product')}
					<svelte:fragment slot="dropdownItems">
						{#each productsList.filter(product => product.barcodeFormat) as product}
							<DropdownItem
								on:click={() => {
									barcodeFormat = product.barcodeFormat
								}}
							>
								<div class="d-flex justify-content-between align-items-baseline">
									{product.name}
									{#if barcodeFormat === product.barcodeFormat}<Icon
											fixedWidth
											class="ml-1"
											icon="check"
											title={translate('barcodeFormat.productHasSameBarcode', 'Product has same barcode format as selected')}
										></Icon>{/if}
								</div>
							</DropdownItem>
						{/each}
					</svelte:fragment>
				</Dropdown>
			</div>
		</div>
		<div class="card">
			<div class="card-header h6">{translate('barcodeFormat.testResultsTitle', 'Test Results')}</div>
			<div class="card-body">
				<Table
					responsive
					{rows}
					columns={[
						{
							name: translate('barcodeFormat.tokenColumnName', 'Token'),
							property: 'label',
						},
						{
							name: translate('barcodeFormat.resultColumnName', 'Result'),
							property: 'value',
						},
					]}
					class="text-nowrap"
				></Table>
			</div>
		</div>
	</div>
</Modal>
