import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/InitialPageLoadData'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class InitialPageLoadDataStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "InitialPageLoadDataStore",
			variables: false,
		})
	}
}

export async function load_InitialPageLoadData(params) {
	await initClient()

	const store = new InitialPageLoadDataStore()

	await store.fetch(params)

	return {
		InitialPageLoadData: store,
	}
}
