import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/WorkOrderData'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class WorkOrderDataStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "WorkOrderDataStore",
			variables: true,
		})
	}
}

export async function load_WorkOrderData(params) {
	await initClient()

	const store = new WorkOrderDataStore()

	await store.fetch(params)

	return {
		WorkOrderData: store,
	}
}
