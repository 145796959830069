import { v4 as uuid } from '@lukeed/uuid'

//Ractive components
import makeSelect from '@isoftdata/select'
import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'

import template from './wild-select.html'

/**
 * The goal of this component is to function as an itSelect in most instances,
 * but still give you the ability to fuzzy search with wildcard by turning into an itInput with a datalist.
 *
 * The 'options' attribute should be an object, or an array of objects.
 */
const states = Object.freeze({
	select: {
		enum: 1,
		icon: 'fa-asterisk',
		id: uuid(),
		title: 'Click to allow typing to wildcard search',
	},
	input: {
		enum: 2,
		icon: 'fa-list',
		id: uuid(),
		title: 'Click to select from a list of options',
	},
})

export default function createComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				options: {},
				labelProp: 'label',
				valueProp: 'value',
				isLoading: false,
				states,
				state: states.select,
				useOptGroup: false,
				optGroupLabelProp: 'optGroupLabel',
				optGroupValueProp: 'options',
				multiple: false,
				listId: uuid(),
				emptyValue: '',
				value: '',
				selectValue: '',
				inputValue: '',
				inputValueInternal: '',
				selectValueInternal: '',
			}
		},
		components: {
			itSelect: makeSelect({ twoway: true }),
			itInput: makeInput({ twoway: true }),
			itButton: makeButton(),
		},
		attributes: {
			required: [ 'options' ],
			optional: [
				'label', 'disabled', 'state', 'isLoading', 'multiple',
				'labelProp', 'selectLabelProp', 'inputLabelProp',
				'valueProp', 'selectValueProp', 'inputValueProp',
				'useOptGroup', 'optGroupLabelProp', 'optGroupValueProp',
				'selectValue', 'inputValue', 'value', 'emptyText', 'emptyValue',
			],
		},
		observe: {
			'value': {
				handler(val) {
					const state = this.get('state')
					const allOptions = this.get('allOptions')
					// If the value is not in the options, and is not the emptyValue, then we need to set the input value, otherwise use current state
					const setSelectValue = state.enum === states.select.enum
						&& (val === this.get('emptyValue') || allOptions.some(({ value, label }) => val === value || val === label))
					this.set(setSelectValue ? 'selectValue' : 'inputValue', val ?? this.get('emptyValue'))
				},
				init: false,
			},
			'selectValue inputValue': {
				handler(val, _o, keypath) {
					this.set({
						[`${keypath}Internal`]: val ?? this.get('emptyValue'), // selectValueInternal or inputValueInternal
					})
					// TODO only change state if not an empty value
					if (val !== this.get('emptyValue') && (val || val === 0)) {
						this.set('state', states[keypath === 'selectValue' ? 'select' : 'input'])
					}
				},
				init: false,
			},
			'selectValueInternal inputValueInternal': {
				handler(val, _o, keypath) {
					this.set({
						// value: val,
						[keypath.slice(0, -8)]: val ?? this.get('emptyValue'), // selectValue or inputValue
					})
					// Only set 'value' if  we're in that state already, otherwise it could clear out the value unintentionally
					if (this.get('state').enum === states[keypath.slice(0, -13)].enum) {
						this.set('value', val)
					}
				},
				init: false,
			},
		},
		computed: {
			computedInputLabelProp() {
				return this.get('inputLabelProp') || this.get('labelProp')
			},
			computedInputValueProp() {
				return this.get('inputValueProp') || this.get('valueProp')
			},
			computedSelectLabelProp() {
				return this.get('selectLabelProp') || this.get('labelProp')
			},
			computedSelectValueProp() {
				return this.get('selectValueProp') || this.get('valueProp')
			},
			// key/value object for the autocomplete
			inputOptions() {
				const ractive = this
				const labelProp = ractive.get('computedInputLabelProp')
				const valueProp = ractive.get('computedInputValueProp')
				const useOptGroup = ractive.get('useOptGroup')
				let options = ractive.get('options')

				// make it an array if it's not already
				// It shouldn't matter that we're losing the keys since those are just for the optgroup
				if (options && !(options instanceof Array)) {
					options = Object.values(options)
				}

				if (!useOptGroup) {
					return options.reduce((acc, option) => {
						acc[option[valueProp]] = option[labelProp]
						return acc
					}, {})
				}
				const optGroupValueProp = ractive.get('optGroupValueProp')
				// We can't use optgroups in a datalist, so just ignore them I guess
				return options.reduce((totalList, group) => {
					return {
						...totalList,
						...group[optGroupValueProp].reduce((acc, option) => {
							acc[option[valueProp]] = option[labelProp]
							return acc
						}) }
				}, {})
			},
			// array of objects with properties `label`, `value` for the select
			selectOptions() {
				const ractive = this
				const labelProp = ractive.get('computedSelectLabelProp')
				const valueProp = ractive.get('computedSelectValueProp')
				const useOptGroup = ractive.get('useOptGroup')
				let options = ractive.get('options')

				// make it an array if it's not already
				// It shouldn't matter that we're losing the keys since those are just for the optgroup
				if (options && !(options instanceof Array)) {
					options = Object.values(options)
				}

				if (!useOptGroup) {
					return options.map(option => ({
						label: option[labelProp],
						value: option[valueProp],
					}))
				}

				const optGroupLabelProp = ractive.get('optGroupLabelProp')
				const optGroupValueProp = ractive.get('optGroupValueProp')

				return options.map(optGroup => ({
					label: optGroup[optGroupLabelProp],
					options: optGroup[optGroupValueProp].map(opt => ({
						label: opt[labelProp],
						value: opt[valueProp],
					})),
				}))
			},
			// Used to get the corresponding label/value for the current selection when switching states
			allOptions() {
				const ractive = this
				if (ractive.get('useOptGroup')) {
					return ractive.get('selectOptions').reduce((acc, optGroup) => {
						return acc.concat(optGroup.options)
					}, [])
				}
				return ractive.get('selectOptions')
			},
		},
		oninit() {
			const ractive = this

			ractive.on('state-change', () => {
				const lastState = ractive.get('state')
				const prevValue = ractive.get('value')
				const allOptions = ractive.get('allOptions')
				const emptyValue = ractive.get('emptyValue')

				let newState
				let newValue

				if (lastState.enum === states.select.enum) {
					newState = states.input
					newValue = (allOptions.find(option => option.value === prevValue))?.label
				} else if (lastState.enum === states.input.enum) {
					newState = states.select
					newValue = (allOptions.find(option => option.label === prevValue))?.value
				}
				ractive.set({
					value: newValue ?? emptyValue,
					inputValue: newState.enum === states.input.enum ? newValue : emptyValue,
					selectValue: newState.enum === states.select.enum ? newValue : emptyValue,
					state: newState,
				})

				ractive.find(`#${CSS.escape(newState.id)}`).focus()
			})
		},
	})
}
