import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/RecalculateSamplesModalLocations'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class RecalculateSamplesModalLocationsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "RecalculateSamplesModalLocationsStore",
			variables: false,
		})
	}
}

export async function load_RecalculateSamplesModalLocations(params) {
	await initClient()

	const store = new RecalculateSamplesModalLocationsStore()

	await store.fetch(params)

	return {
		RecalculateSamplesModalLocations: store,
	}
}
