export default {
    "name": "GetLocationsReferenceCounts",
    "kind": "HoudiniQuery",
    "hash": "6f3c4d2d1cfbc6323ab46052b931d03fe1aa8167e058a904f845954f446b8f4b",

    "raw": `query GetLocationsReferenceCounts($locationIds: [NonNegativeInt!]!) {
  getLocationsReferenceCounts(locationIds: $locationIds) {
    childLocationCount
    investigationTriggerCount
    productBatchCount
    sampleCount
    scheduledAnalysisCount
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getLocationsReferenceCounts": {
                "type": "LocationReferenceCounts",
                "keyRaw": "getLocationsReferenceCounts(locationIds: $locationIds)",

                "selection": {
                    "fields": {
                        "childLocationCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "childLocationCount",
                            "visible": true
                        },

                        "investigationTriggerCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "investigationTriggerCount",
                            "visible": true
                        },

                        "productBatchCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "productBatchCount",
                            "visible": true
                        },

                        "sampleCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "sampleCount",
                            "visible": true
                        },

                        "scheduledAnalysisCount": {
                            "type": "NonNegativeInt",
                            "keyRaw": "scheduledAnalysisCount",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "locationIds": "NonNegativeInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=21c0294618be854bec3a876961d0f0d2336970e12b17b326bf08599468b35f0c";