export default {
    "name": "AnalysisManagementChoiceData",
    "kind": "HoudiniFragment",
    "hash": "0e4bcada7e51d5f2d1c774c9aa72c3485af336e888c34336cabff11698d3e24f",

    "raw": `fragment AnalysisManagementChoiceData on AnalysisOptionChoice {
  active
  analysisOptionId
  plantId
  constraint
  boundaryType
  choice
  id
  product {
    name
    id
  }
  severityClass {
    id
    name
  }
  __typename
}
`,

    "rootType": "AnalysisOptionChoice",
    "stripVariables": [],

    "selection": {
        "fields": {
            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "analysisOptionId": {
                "type": "PositiveInt",
                "keyRaw": "analysisOptionId",
                "visible": true
            },

            "plantId": {
                "type": "PositiveInt",
                "keyRaw": "plantId",
                "nullable": true,
                "visible": true
            },

            "constraint": {
                "type": "ConstraintType",
                "keyRaw": "constraint",
                "visible": true
            },

            "boundaryType": {
                "type": "BoundaryType",
                "keyRaw": "boundaryType",
                "visible": true
            },

            "choice": {
                "type": "String",
                "keyRaw": "choice",
                "visible": true
            },

            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "product": {
                "type": "Product",
                "keyRaw": "product",
                "nullable": true,

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "severityClass": {
                "type": "SeverityClass",
                "keyRaw": "severityClass",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=64b47a8d807fb6b1918e92d4de3ef8afebabe6d2d0d7bb3b75c2dd6daf99f54a";