export default {
    "name": "ALERT_SUBSCRIPTION",
    "kind": "HoudiniQuery",
    "hash": "9261254551e938f2cde0b96d1cb3c64b8087db82f704d6f6585b350cf343d665",

    "raw": `query ALERT_SUBSCRIPTION($pagination: PaginatedInput, $analysesFilter: AnalysisFilter, $alertSubscriptionFilter: AlertSubscriptionFilter, $productFilter: ProductFilter, $workOrderTypeFilter: WorkOrderTypeFilter) {
  analyses(filter: $analysesFilter, pagination: $pagination) {
    data {
      id
      name
      inUseAtPlantIDs
    }
  }
  alertSubscriptions(filter: $alertSubscriptionFilter) {
    ...AlertSubscriptionData
    id
  }
  plants(pagination: $pagination) {
    data {
      id
      code
      name
    }
    info {
      pageNumber
      pageSize
      totalItems
      totalPages
    }
  }
  products(filter: $productFilter, pagination: $pagination) {
    data {
      id
      name
      inUseAtPlants {
        id
      }
    }
  }
  workOrderTypes(filter: $workOrderTypeFilter, pagination: $pagination) {
    data {
      name
      id
    }
  }
  groups(pagination: $pagination) {
    data {
      name
      id
    }
  }
  investigationTriggers {
    data {
      id
      description
    }
  }
  alertTemplates {
    id
    name
    title
    body
    type
    method
  }
  schedules(pagination: $pagination) {
    data {
      id
      name
    }
  }
  phoneCarriers {
    name
    domain
    id
  }
}

fragment AlertSubscriptionData on AlertSubscription {
  id
  emailAddresses
  phoneNumbers {
    number
    carrier {
      domain
      id
    }
  }
  active
  body
  description
  expirationHourOffset
  method
  rank
  requireAcceptable
  requireFailed
  requireMarginal
  requireMissing
  sendAcceptable
  sendFailed
  sendMarginal
  timing
  title
  type
  template {
    id
    name
  }
  plant {
    id
    code
    name
  }
  analysis {
    id
    name
  }
  location {
    id
    location
  }
  workOrderType {
    id
    name
  }
  investigationTrigger {
    id
    description
  }
  group {
    id
    name
  }
  schedule {
    id
    name
  }
  userAccount {
    id
    name
  }
  product {
    id
    name
  }
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $analysesFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "alertSubscriptions": {
                "type": "AlertSubscription",
                "keyRaw": "alertSubscriptions(filter: $alertSubscriptionFilter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Float",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "emailAddresses": {
                            "type": "EmailAddress",
                            "keyRaw": "emailAddresses",
                            "visible": true
                        },

                        "phoneNumbers": {
                            "type": "PhoneContact",
                            "keyRaw": "phoneNumbers",

                            "selection": {
                                "fields": {
                                    "number": {
                                        "type": "String",
                                        "keyRaw": "number",
                                        "visible": true
                                    },

                                    "carrier": {
                                        "type": "PhoneCarrier",
                                        "keyRaw": "carrier",

                                        "selection": {
                                            "fields": {
                                                "domain": {
                                                    "type": "String",
                                                    "keyRaw": "domain",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "body": {
                            "type": "String",
                            "keyRaw": "body",
                            "nullable": true,
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "expirationHourOffset": {
                            "type": "Float",
                            "keyRaw": "expirationHourOffset",
                            "visible": true
                        },

                        "method": {
                            "type": "AlertMethod",
                            "keyRaw": "method",
                            "visible": true
                        },

                        "rank": {
                            "type": "Float",
                            "keyRaw": "rank",
                            "visible": true
                        },

                        "requireAcceptable": {
                            "type": "Boolean",
                            "keyRaw": "requireAcceptable",
                            "nullable": true,
                            "visible": true
                        },

                        "requireFailed": {
                            "type": "Boolean",
                            "keyRaw": "requireFailed",
                            "nullable": true,
                            "visible": true
                        },

                        "requireMarginal": {
                            "type": "Boolean",
                            "keyRaw": "requireMarginal",
                            "nullable": true,
                            "visible": true
                        },

                        "requireMissing": {
                            "type": "Boolean",
                            "keyRaw": "requireMissing",
                            "nullable": true,
                            "visible": true
                        },

                        "sendAcceptable": {
                            "type": "Boolean",
                            "keyRaw": "sendAcceptable",
                            "visible": true
                        },

                        "sendFailed": {
                            "type": "Boolean",
                            "keyRaw": "sendFailed",
                            "visible": true
                        },

                        "sendMarginal": {
                            "type": "Boolean",
                            "keyRaw": "sendMarginal",
                            "visible": true
                        },

                        "timing": {
                            "type": "AlertTiming",
                            "keyRaw": "timing",
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "nullable": true,
                            "visible": true
                        },

                        "type": {
                            "type": "AlertType",
                            "keyRaw": "type",
                            "visible": true
                        },

                        "template": {
                            "type": "AlertTemplate",
                            "keyRaw": "template",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Float",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "plant": {
                            "type": "Plant",
                            "keyRaw": "plant",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "analysis": {
                            "type": "Analysis",
                            "keyRaw": "analysis",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "location": {
                            "type": "Location",
                            "keyRaw": "location",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "String",
                                        "keyRaw": "location",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "workOrderType": {
                            "type": "WorkOrderType",
                            "keyRaw": "workOrderType",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "investigationTrigger": {
                            "type": "InvestigationTrigger",
                            "keyRaw": "investigationTrigger",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Float",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "group": {
                            "type": "Group",
                            "keyRaw": "group",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "schedule": {
                            "type": "Schedule",
                            "keyRaw": "schedule",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "product": {
                            "type": "Product",
                            "keyRaw": "product",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "AlertSubscriptionData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "products": {
                "type": "ProductResponse",
                "keyRaw": "products(filter: $productFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Product",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "inUseAtPlants": {
                                        "type": "Plant",
                                        "keyRaw": "inUseAtPlants",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "workOrderTypes": {
                "type": "WorkOrderTypeResponse",
                "keyRaw": "workOrderTypes(filter: $workOrderTypeFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "WorkOrderType",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "groups": {
                "type": "GroupResponse",
                "keyRaw": "groups(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Group",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "investigationTriggers": {
                "type": "InvestigationTriggerResponse",
                "keyRaw": "investigationTriggers",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "InvestigationTrigger",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Float",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "alertTemplates": {
                "type": "AlertTemplate",
                "keyRaw": "alertTemplates",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Float",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "nullable": true,
                            "visible": true
                        },

                        "body": {
                            "type": "String",
                            "keyRaw": "body",
                            "nullable": true,
                            "visible": true
                        },

                        "type": {
                            "type": "AlertType",
                            "keyRaw": "type",
                            "visible": true
                        },

                        "method": {
                            "type": "AlertMethod",
                            "keyRaw": "method",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "schedules": {
                "type": "ScheduleResponse",
                "keyRaw": "schedules(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Schedule",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "phoneCarriers": {
                "type": "PhoneCarrier",
                "keyRaw": "phoneCarriers",

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "domain": {
                            "type": "String",
                            "keyRaw": "domain",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "analysesFilter": "AnalysisFilter",
            "alertSubscriptionFilter": "AlertSubscriptionFilter",
            "productFilter": "ProductFilter",
            "workOrderTypeFilter": "WorkOrderTypeFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            },

            "AlertSubscriptionFilter": {
                "active": "Boolean",
                "userAccountIds": "PositiveInt"
            },

            "ProductFilter": {
                "active": "Boolean",
                "activeOnly": "Boolean",
                "plantIds": "PositiveInt",
                "plantIdsFilteringByChildren": "PositiveInt",
                "plants": "PositiveInt",
                "productName": "NonEmptyString",
                "productType": "ProductType"
            },

            "WorkOrderTypeFilter": {
                "id": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=694f2633e9957340d6b5c92205a14f295b0db29297c2c1378dd97bf2f2330836";