export default {
    "name": "DetachFilesFromProduct",
    "kind": "HoudiniMutation",
    "hash": "d63fbb5119d76acf30d770e0ed986a9100f079ed922f6d8966547ae2d2ef754d",

    "raw": `mutation DetachFilesFromProduct($productId: PositiveInt!, $fileIds: [PositiveInt!]!) {
  detachFilesFromProduct(productId: $productId, fileIds: $fileIds)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "detachFilesFromProduct": {
                "type": "Boolean",
                "keyRaw": "detachFilesFromProduct(fileIds: $fileIds, productId: $productId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "productId": "PositiveInt",
            "fileIds": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a72eae1c26a13fdf7d9d449459d967c42243c7ff927cf9a3230210ddcd388b8f";