<script lang="ts">
	import type { i18n } from 'i18next'
	import type { Mediator, SvelteAsr } from 'types/common'
	import { type ComponentEvents, getContext, type ComponentProps } from 'svelte'
	import { reportParameterQuery } from 'utility/report-viewer-helper'
	import { ReportSubscriptions, type ReportSubscription, type UpdateReportSubscriptionInput } from '@isoftdata/svelte-report-viewer'
	import { getSession } from 'stores/session'
	import {
		graphql,
		type CreateReportSubscription$result,
		type UpdateExistingReportSubscription$result,
		type InitialPageLoadData$result,
		type CreateReportSubscription$input,
		type UpdateExistingReportSubscription$input,
	} from '$houdini'
	import camelCase from 'just-camel-case'

	type SavedReportSubscription = CreateReportSubscription$result['createReportSubscription'] | UpdateExistingReportSubscription$result['updateReportSubscription']

	export let asr: SvelteAsr
	export let reportSubscriptionList: ReportSubscription[] = []
	export let showOnlyMine: boolean = true
	export let reportList: InitialPageLoadData$result['reports']['data'] = []
	export let userAccounts: InitialPageLoadData$result['userAccounts']['data'] = []
	export let plants: InitialPageLoadData$result['plants']['data'] = []
	export let groups: InitialPageLoadData$result['groups']['data'] = []
	export let printers: InitialPageLoadData$result['reportQueuePrinters']['data'] = []
	export let perPageCount = 10
	export let currentPageNumber = 1
	export let totalItemsCount: number | undefined = undefined

	let selectedReportSubscription: ReportSubscription | null = null

	const timezone = getSession().plant.timezone
	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next') || { t: (_key: string, fallback: string) => fallback }
	const isDateOrDateTime = (type: string) => type === 'DATE' || type === 'DATE_TIME'

	async function getSelectedReportSubscriptionParameters(reportSubscriptionId: number): Promise<ReportSubscription['parameters']> {
		try {
			const { data } = await reportSubscriptionParametersQuery.fetch({
				variables: {
					reportSubscriptionId,
				},
			})
			if (!data) {
				throw new Error('No data returned from reportSubscriptionParametersQuery')
			}

			return (
				data.reportSubscriptionParameters?.map(param => {
					return {
						...param,
						required: param.reportParameter.required,
						type: param.reportParameter.type,
						visible: param.reportParameter.visible,
						label: translate(`reports:parameters.${camelCase(param.reportParameter.name)}Label`, param.reportParameter.label),
						name: param.reportParameter.name,
						displayValue: null,
						editability: param.reportParameter.editability,
						options: param.reportParameter.options.map(option => {
							return {
								id: option.id,
								label: option.label,
								value: option.value ?? null,
							}
						}),
					}
				}) ?? []
			)
		} catch (err) {
			const error = err as Error
			console.error(err)
			mediator.call('showMessage', {
				heading: translate('reportViewer.errorLoadingReportSubscriptionParametersHeading', 'Error Loading Report Subscription Parameters'),
				type: 'danger',
				time: false,
				message: error.message,
			})
			return []
		}
	}

	async function getReportParameters(reportId: number): Promise<ReportSubscription['parameters']> {
		const { data } = await reportParameterQuery.fetch({
			variables: {
				reportId: reportId.toString(),
			},
		})

		if (!data) {
			throw new Error('No data returned from reportParameterQuery')
		}

		const reportParameters = data.report?.parameters
		return reportParameters
			? reportParameters.map(parameter => {
					let newParameterValue: string = ''
					parameter.options.map(option => {
						if (option.isDefault) {
							newParameterValue = option.id?.toString() ?? option.label ?? option.value ?? ''
						}
					})
					return {
						...parameter,
						label: translate(`reports:parameters.${camelCase(parameter.name)}Label`, parameter.label),
						reportParameterId: parameter.id,
						value: newParameterValue,
						displayValue: null,
						editability: parameter.editability,
						options: parameter.options,
					}
				})
			: []
	}

	function getCreateReportSubscriptionInputs(reportSubscriptionToCreate: ReportSubscription): CreateReportSubscription$input['inputs'] {
		return {
			sourceType: reportSubscriptionToCreate.sourceType,
			destinationType: reportSubscriptionToCreate.destinationType,
			destination: reportSubscriptionToCreate.destination,
			title: reportSubscriptionToCreate.title,
			emailBody: reportSubscriptionToCreate.emailBody,
			emailFrom: reportSubscriptionToCreate.emailFrom,
			attachmentName: reportSubscriptionToCreate.attachmentName,
			reportId: reportSubscriptionToCreate.reportId,
			schedulePeriodType: reportSubscriptionToCreate.schedulePeriodType,
			schedulePeriod: reportSubscriptionToCreate.schedulePeriod,
			comments: reportSubscriptionToCreate.comments,
			nextInstance: reportSubscriptionToCreate.nextInstance ?? null,
			sourceQuery: reportSubscriptionToCreate.sourceQuery,
			reportDateRange: reportSubscriptionToCreate.reportId ? reportSubscriptionToCreate.parameterDateRange : null,
			reportSubscriptionParameters: reportSubscriptionToCreate.reportId
				? reportSubscriptionToCreate.parameters.map(parameter => {
						if (isDateOrDateTime(parameter.type) && reportSubscriptionToCreate.parameterDateRange === 'CUSTOM') {
							return {
								reportParameterId: parameter.reportParameterId,
								sqlValue: parameter.value,
							}
						} else {
							return {
								reportParameterId: parameter.reportParameterId,
								rawValue: parameter.value,
							}
						}
					})
				: null,
		}
	}

	function getUpdateReportSubscriptionInputs(reportSubscriptionToUpdate: UpdateReportSubscriptionInput): UpdateExistingReportSubscription$input['inputs'] {
		const inputs = getCreateReportSubscriptionInputs(reportSubscriptionToUpdate)
		return {
			...inputs,
			reportSubscriptionId: reportSubscriptionToUpdate.id,
		}
	}

	function formatReportSubscription(savedReportSubscription: SavedReportSubscription): ReportSubscription {
		return {
			id: savedReportSubscription.id,
			createdByUserName: savedReportSubscription.createdByUser.fullName,
			sourceType: savedReportSubscription.sourceType,
			destinationType: savedReportSubscription.destinationType,
			destination: savedReportSubscription.destination,
			sendTo: savedReportSubscription.destination?.split(/[;,]/) ?? [],
			title: savedReportSubscription.title,
			emailBody: savedReportSubscription.emailBody,
			emailFrom: savedReportSubscription.emailFrom,
			attachmentName: savedReportSubscription.attachmentName,
			reportId: savedReportSubscription.reportId,
			reportName: savedReportSubscription.report?.name ?? null,
			reportType: savedReportSubscription.report?.type ?? null,
			reportCategory: savedReportSubscription.report?.category ?? null,
			reportDescription: savedReportSubscription.report?.description ?? null,
			nextInstance: savedReportSubscription.nextInstance,
			schedulePeriodType: savedReportSubscription.schedulePeriodType,
			schedulePeriod: savedReportSubscription.schedulePeriod,
			active: savedReportSubscription.active,
			dateCreated: savedReportSubscription.dateCreated,
			comments: savedReportSubscription.comments,
			sourceQuery: savedReportSubscription.sourceQuery,
			parameterDateRange: savedReportSubscription.parameterDateRange,
			parameters:
				savedReportSubscription.parameters?.map(parameter => {
					return {
						...parameter.reportParameter,
						reportParameterId: parameter.reportParameterId,
						value: parameter.value,
						displayValue: null,
						editability: parameter.reportParameter.editability,
						options: parameter.reportParameter.options.map(option => {
							return {
								...option,
								label: option.label ?? '',
							}
						}),
					}
				}) ?? [],
		}
	}

	async function createReportSubscription(reportSubscriptionToCreate: ReportSubscription): Promise<ReportSubscription> {
		const inputs = getCreateReportSubscriptionInputs(reportSubscriptionToCreate)
		const { data } = await newReportSubscriptionMutation.mutate({
			inputs,
		})
		if (!data) {
			throw new Error('No data returned from createReportSubscription')
		}
		return formatReportSubscription(data.createReportSubscription)
	}

	async function updateReportSubscription(reportSubscriptionToUpdate: UpdateReportSubscriptionInput): Promise<ReportSubscription> {
		let reportSubscriptionId = reportSubscriptionToUpdate.id
		const inputs = getUpdateReportSubscriptionInputs(reportSubscriptionToUpdate)
		const { data } = await updateReportSubscriptionMutation.mutate({
			inputs: {
				...inputs,
				reportSubscriptionId,
			},
		})
		if (!data) {
			throw new Error('No data returned from updateReportSubscription')
		}
		return formatReportSubscription(data.updateReportSubscription)
	}

	async function updateReportSubscriptionStatus(reportSubscriptionId: number, active: boolean) {
		try {
			const { data } = await updateReportSubscriptionMutation.mutate({
				inputs: {
					reportSubscriptionId,
					active,
				},
			})
			if (data) {
				const successHeading = active
					? translate('reportViewer.reportSubscriptionActivatedHeading', 'Report Subscription Activated')
					: translate('reportViewer.reportSubscriptionDeactivatedHeading', 'Report Subscription Deactivated')
				const successMessageText = active
					? translate('reportViewer.reportSubscriptionActivatedMessage', 'Report Subscription has been activated successfully.')
					: translate('reportViewer.reportSubscriptionDeactivatedMessage', 'Report Subscription has been deactivated successfully.')
				mediator.call('showMessage', { heading: successHeading, type: 'success', time: 5, message: successMessageText })
				return true
			}
		} catch (err) {
			const error = err as Error
			console.error(err)
			const failedHeading = active
				? translate('reportViewer.failedToActivateReportSubscriptionHeading', 'Failed To Activate Report Subscription')
				: translate('reportViewer.failedToDeactivateReportSubscriptionHeading', 'Failed To Deactivate Report Subscription')
			mediator.call('showMessage', { heading: failedHeading, type: 'danger', time: false, message: error.message })
			return false
		}
		return false
	}

	async function generatePdfPreview({ name, type, parameters }: Parameters<ComponentProps<ReportSubscriptions>['generatePdfPreview']>[0]) {
		const { data } = await generateCrystalReport.mutate({
			reportJob: {
				name,
				parameters: Object.entries(parameters).map(([key, value]) => ({ key, value })),
				type,
				id: selectedReportSubscription?.reportId,
			},
		})
		if (!data) {
			throw new Error('No data returned from generateCrystalReport')
		}
		return data.generateCrystalReport
	}

	async function testQuery(query: string) {
		const { data } = await testSelectQuery.fetch({
			variables: {
				query,
			},
		})
		if (!data) {
			throw new Error('No data returned from testSelectQuery')
		}
		return data.testSelectQuery
	}

	function onPageChange(event: ComponentEvents<ReportSubscriptions>['pageChange']) {
		asr.go(null, { pageNumber: event.detail.pageNumber }, { inherit: true })
	}

	function onShowOnlyMineChange(event: ComponentEvents<ReportSubscriptions>['showOnlyMine']) {
		asr.go(null, { showOnlyMine: event.detail }, { inherit: true })
	}

	const reportSubscriptionParametersQuery = graphql(`
		query ReportSubscriptionParameters($reportSubscriptionId: PositiveInt!) {
			reportSubscriptionParameters(reportSubscriptionId: $reportSubscriptionId) {
				reportParameterId
				value
				reportParameter {
					required
					type
					visible
					label
					name
					editability
					options {
						id
						label
						value
						isDefault
					}
				}
			}
		}
	`)

	const generateCrystalReport = graphql(`
		mutation GenerateCrystalReportForSubscription($reportJob: GenerateCrystalReportInput!) {
			generateCrystalReport(reportJob: $reportJob) {
				data
				mimeType
			}
		}
	`)

	const newReportSubscriptionMutation = graphql(`
		mutation CreateReportSubscription($inputs: NewReportSubscription!) {
			createReportSubscription(inputs: $inputs) {
				id
				createdByUserId
				createdByUser {
					fullName
				}
				sourceType
				destinationType
				destination
				title
				emailBody
				emailFrom
				attachmentName
				reportId
				nextInstance
				schedulePeriodType
				schedulePeriod
				active
				dateCreated
				comments
				sourceQuery
				parameterDateRange
				report {
					id
					name
					type
					category
					description
				}
				parameters {
					reportParameterId
					value
					reportParameter {
						required
						type
						visible
						label
						name
						editability
						options {
							id
							label
							value
						}
					}
				}
			}
		}
	`)

	const updateReportSubscriptionMutation = graphql(`
		mutation UpdateExistingReportSubscription($inputs: UpdateReportSubscription!) {
			updateReportSubscription(inputs: $inputs) {
				id
				createdByUserId
				createdByUser {
					fullName
				}
				sourceType
				destinationType
				destination
				title
				emailBody
				emailFrom
				attachmentName
				reportId
				nextInstance
				schedulePeriodType
				schedulePeriod
				active
				dateCreated
				comments
				sourceQuery
				parameterDateRange
				report {
					id
					name
					type
					category
					description
				}
				parameters {
					reportParameterId
					value
					reportParameter {
						required
						type
						visible
						label
						name
						editability
						options {
							id
							label
							value
						}
					}
				}
			}
		}
	`)

	const testSelectQuery = graphql(`
		query TestSelectQuery($query: String!) {
			testSelectQuery(query: $query)
		}
	`)
</script>

<ReportSubscriptions
	{groups}
	{printers}
	{userAccounts}
	{reportList}
	{timezone}
	{perPageCount}
	{currentPageNumber}
	{totalItemsCount}
	{showOnlyMine}
	sites={plants}
	{testQuery}
	{generatePdfPreview}
	{getReportParameters}
	{getSelectedReportSubscriptionParameters}
	{createReportSubscription}
	{updateReportSubscription}
	updateStatus={updateReportSubscriptionStatus}
	bind:reportSubscriptionList
	bind:selectedReportSubscription
	on:pageChange={onPageChange}
	on:showOnlyMine={onShowOnlyMineChange}
></ReportSubscriptions>
