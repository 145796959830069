import { HoudiniClient, cache } from '$houdini'
import sessionStore, { getSession } from 'stores/session'

let session = getSession()
sessionStore.subscribe(newSession => {
	session = newSession
	// Clear cache on logout
	if (!session?.authToken) {
		cache.reset()
	}
})

const client = new HoudiniClient({
	url: '__apiUrl__',
	fetchParams() {
		return {
			headers: {
				'auth-token': session.authToken,
				'apollographql-client-name': 'PresageWeb',
				'apollographql-client-version': '__buildVersion__',
				'Content-Type': 'application/json',
			},
		}
	},
	// We're used to errors throwing with our custom api fetch, so let's keep that behavior
	// TODO, maybe we can add errors to a more permanent log here?
	throwOnError: {
		operations: ['all'],
		error: (errors, ctx) => {
			if (errors.length > 1) {
				console.error(errors, ctx)
			} else {
				console.error(errors[0], ctx)
			}
			throw new Error(errors[0].message, { cause: errors[0] })
		},
	},
})
export default client
