export default {
    "name": "WorkOrderList",
    "kind": "HoudiniQuery",
    "hash": "c5a16757c3063a227d44d37fc8a5f3a6b9f672a42303b51e1bf03b42a1e2a344",

    "raw": `query WorkOrderList($pagination: PaginatedInput) {
  groups {
    data {
      id
      name
    }
  }
  workOrderTypes {
    data {
      name
      id
    }
  }
  plants(pagination: $pagination) {
    data {
      id
      name
      code
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "groups": {
                "type": "GroupResponse",
                "keyRaw": "groups",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Group",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "workOrderTypes": {
                "type": "WorkOrderTypeResponse",
                "keyRaw": "workOrderTypes",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "WorkOrderType",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4de8a705c898bd9e1d386b7a53c75ae5597b050c326d57286880b25797f00a53";