export default {
    "name": "AttachFileToSample",
    "kind": "HoudiniMutation",
    "hash": "f888e0f51ca0f63821ccbe5c457f04d632eed7abfa9631e427275c810685338a",

    "raw": `mutation AttachFileToSample($input: NewSampleFile!) {
  attachFileToSample(input: $input) {
    fileId
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "attachFileToSample": {
                "type": "SampleFile",
                "keyRaw": "attachFileToSample(input: $input)",

                "selection": {
                    "fields": {
                        "fileId": {
                            "type": "PositiveInt",
                            "keyRaw": "fileId",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewSampleFile"
        },

        "types": {
            "NewSampleFile": {
                "base64String": "String",
                "fileName": "String",
                "rank": "NonNegativeInt",
                "sampleId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b0148aa579c523dd07dee204ac583a711f40641f8aa15042685fd4db58a41d11";