export default {
    "name": "DeleteProductSpecification",
    "kind": "HoudiniMutation",
    "hash": "580a22fdec051d8e60e0feaa4457616791ab9c5a8ad2c8491a812cd7039206ae",

    "raw": `mutation DeleteProductSpecification($ids: [ID!]!) {
  deleteAnalysisOptionChoice(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteAnalysisOptionChoice": {
                "type": "NonNegativeInt",
                "keyRaw": "deleteAnalysisOptionChoice(ids: $ids)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "ids": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=1ad24b896b9d41172eb0d10eedc652eb1be532c0acb95e0007d93b0362c29c13";