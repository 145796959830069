<script lang="ts">
	import type { EntityTag } from 'utility/analysis-management-helper'
	import type { Colors } from '@isoftdata/utility-bootstrap'

	export let tag: Omit<EntityTag, 'uuid'>
	export let color: Colors = 'secondary'

	const iconMap = {
		PLANT: 'industry-windows',
		LOCATION: 'shelves',
		PRODUCT: 'boxes-stacked',
	} as const
</script>

<span class="badge badge-{color}">
	<i class="fas fa-fw fa-{iconMap[tag.entityType]}"></i>
	{tag.name}</span
>
