export default {
    "name": "DeleteEntityTags",
    "kind": "HoudiniMutation",
    "hash": "26f26c0f0a804f0963a1510d4d0f5a222a802eaa9e1d1e3a07e523e8cb172865",

    "raw": `mutation DeleteEntityTags($ids: [PositiveInt!]!) {
  deleteEntityTags(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteEntityTags": {
                "type": "Boolean",
                "keyRaw": "deleteEntityTags(ids: $ids)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "ids": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=129cc461b7cf9ae6448eac1e2b1c8ef9d5e69ceaf07d99db3000f1e1a0f32260";