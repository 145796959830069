<script lang="ts">
	import type { Rule, EntityTag, TagStore, TagMap } from 'utility/analysis-management-helper'
	import type { AddRemoveStore } from 'stores/add-remove-store'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { i18n } from 'types/common'

	type EntityType = keyof TagMap

	import Modal from '@isoftdata/svelte-modal'
	import TagSelection from 'components/TagSelection.svelte'

	import { createEventDispatcher, getContext, tick } from 'svelte'
	import makeAddRemoveStore from 'stores/add-remove-store'
	import { klona } from 'klona'
	import makeCrudStore from '@isoftdata/svelte-store-crud'

	export let show = false
	export let selectedPlantTagIndex: number | null = null
	export let selectedProductTagIndex: number | null = null
	export let selectedLocationTagIndex: number | null = null

	export let tagAddRemoveStore: AddRemoveStore
	export let tagCrudStore: CrudStore<EntityTag, 'uuid'>
	export let tagStore: TagStore

	let ruleIndex: number | null = null
	let ruleName: string = ''
	let rule: (Rule & { originalIndex: number }) | null = null

	let tempPlantTags: Array<EntityTag> = []
	let tempProductTags: Array<EntityTag> = []
	let tempLocationTags: Array<EntityTag> = []

	let hasDuplicatePlantTagNames = false
	let hasDuplicateProductTagNames = false
	let hasDuplicateLocationTagNames = false

	// track whether we're adding/removing tags to the rule so we can update the add/remove store when we confirm the modal
	const tempAddRemoveStore = makeAddRemoveStore()
	const tempTagCrudStore = makeCrudStore<EntityTag, 'uuid'>('uuid')
	const { t: translate } = getContext<i18n>('i18next')

	const dispatch = createEventDispatcher<{
		confirm: { index: number; tags: Array<EntityTag> }
	}>()

	export function open(rule: Rule & { originalIndex: number }) {
		reset(true, rule)
	}

	function reset(newShow: boolean = false, selectedRule: (Rule & { originalIndex: number }) | null = null) {
		show = newShow
		selectedPlantTagIndex = null
		selectedProductTagIndex = null
		selectedLocationTagIndex = null
		ruleName = selectedRule?.description ?? ''
		ruleIndex = selectedRule?.originalIndex ?? null
		rule = selectedRule
		tempPlantTags = klona(tagsForEntityType('PLANT'))
		tempProductTags = klona(tagsForEntityType('PRODUCT'))
		tempLocationTags = klona(tagsForEntityType('LOCATION'))
		tempAddRemoveStore.clear()
		tempTagCrudStore.clear()
	}

	function tagsForEntityType(entityType: EntityType) {
		return Object.values($tagStore[entityType]).filter(Boolean) as Array<EntityTag>
	}

	async function confirmTags() {
		if (!rule) {
			return
		}

		tempTagCrudStore.createdValues.forEach(tag => {
			tagCrudStore.create(tag)
			$tagStore[tag.entityType][tag.uuid] = tag
		})

		tempTagCrudStore.updatedValues.forEach(tag => {
			tagCrudStore.update(tag)
			$tagStore[tag.entityType][tag.uuid] = tag
		})

		tempTagCrudStore.deletedValues.forEach(tag => {
			tagCrudStore.delete(tag)
			delete $tagStore[tag.entityType][tag.uuid]
		})

		await tick()

		tempAddRemoveStore.getAddIds(rule.uuid).forEach(uuid => {
			const tag = $tagStore['PLANT'][uuid] ?? $tagStore['LOCATION'][uuid] ?? $tagStore['PRODUCT'][uuid]
			if (tag) {
				rule!.tags.push(tag)
				tagAddRemoveStore.add(rule!.uuid, uuid)
			} else {
				console.error('tag not found', uuid)
			}
		})

		tempAddRemoveStore.getRemoveIds(rule.uuid).forEach(uuid => {
			rule!.tags = rule!.tags.filter(t => t.uuid !== uuid)
			tagAddRemoveStore.remove(rule!.uuid, uuid)
		})

		show = false
		dispatch('confirm', { index: rule.originalIndex, tags: rule.tags })
	}
</script>

<Modal
	bind:show
	modalSize="xl"
	title={translate('tagSelectionModal.title', "Add Tags to Rule '{{- ruleName}}'", { ruleName })}
	confirmButtonText={translate('common:confirm', 'Confirm')}
	cancelButtonText={translate('common:cancel', 'Cancel')}
	backdropClickCancels={false}
	confirmButtonDisabled={hasDuplicatePlantTagNames || hasDuplicateLocationTagNames || hasDuplicateProductTagNames}
	on:close={() => (show = false)}
	on:confirm={confirmTags}
>
	<div class="text-center">
		{translate('tagSelectionModal.instructionsLine1', 'Choose any number of plant, location, or product tags that must ALL be present on a sample to trigger the rule.')}
	</div>
	<div class="text-center mb-3">
		{translate('tagSelectionModal.instructionsLine2', 'Tip: if you would like to trigger a rule if either of two tags are present, create two rules, one with each tag.')}
	</div>
	<div class="card-group">
		{#if rule}
			{@const ruleUuid = rule.uuid}
			<TagSelection
				entityType="PLANT"
				tags={tempPlantTags}
				tagsInUse={rule.tags}
				tagCrudStore={tempTagCrudStore}
				bind:hasDuplicateTagNames={hasDuplicatePlantTagNames}
				on:tagsInUseAdd={e => tempAddRemoveStore.add(ruleUuid, e.detail.uuid)}
				on:tagsInUseRemove={e => tempAddRemoveStore.remove(ruleUuid, e.detail.uuid)}
			></TagSelection>
			<TagSelection
				entityType="LOCATION"
				tags={tempLocationTags}
				tagsInUse={rule.tags}
				tagCrudStore={tempTagCrudStore}
				bind:hasDuplicateTagNames={hasDuplicateLocationTagNames}
				on:tagsInUseAdd={e => tempAddRemoveStore.add(ruleUuid, e.detail.uuid)}
				on:tagsInUseRemove={e => tempAddRemoveStore.remove(ruleUuid, e.detail.uuid)}
			></TagSelection>
			<TagSelection
				entityType="PRODUCT"
				tags={tempProductTags}
				tagsInUse={rule.tags}
				tagCrudStore={tempTagCrudStore}
				bind:hasDuplicateTagNames={hasDuplicateProductTagNames}
				on:tagsInUseAdd={e => tempAddRemoveStore.add(ruleUuid, e.detail.uuid)}
				on:tagsInUseRemove={e => tempAddRemoveStore.remove(ruleUuid, e.detail.uuid)}
			></TagSelection>
		{/if}
	</div>
</Modal>
