import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/Reports'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ReportsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ReportsStore",
			variables: false,
		})
	}
}

export async function load_Reports(params) {
	await initClient()

	const store = new ReportsStore()

	await store.fetch(params)

	return {
		Reports: store,
	}
}
