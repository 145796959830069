export default {
    "name": "InitialPageLoadData",
    "kind": "HoudiniQuery",
    "hash": "5d62db1be708d27b9ad0decffd0c7f25fd88b106baba88c2f9dde255589dc68f",

    "raw": `query InitialPageLoadData($userAccountsFilter: UserFilter, $reportsFilter: ReportsFilter) {
  reports(filter: $reportsFilter) {
    data {
      id
      category
      description
      name
      numberOfParameters
      averageExecutionTime
      type
      userVisible
    }
  }
  groups {
    data {
      id
      name
    }
  }
  plants {
    data {
      id
      code
      name
    }
  }
  userAccounts(filter: $userAccountsFilter) {
    data {
      id
      name
      workEmail
      firstName
      lastName
      authorizedSites: authorizedPlants {
        id
      }
      userGroups {
        id
      }
    }
  }
  reportQueuePrinters {
    data {
      displayName
      name
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reports": {
                "type": "ReportResponse",
                "keyRaw": "reports(filter: $reportsFilter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Report",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "numberOfParameters": {
                                        "type": "Float",
                                        "keyRaw": "numberOfParameters",
                                        "visible": true
                                    },

                                    "averageExecutionTime": {
                                        "type": "Float",
                                        "keyRaw": "averageExecutionTime",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "userVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "userVisible",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "groups": {
                "type": "GroupResponse",
                "keyRaw": "groups",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Group",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userAccounts": {
                "type": "UserAccountResponse",
                "keyRaw": "userAccounts(filter: $userAccountsFilter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "UserAccount",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "workEmail": {
                                        "type": "String",
                                        "keyRaw": "workEmail",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "authorizedSites": {
                                        "type": "Plant",
                                        "keyRaw": "authorizedSites",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "userGroups": {
                                        "type": "Group",
                                        "keyRaw": "userGroups",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "reportQueuePrinters": {
                "type": "ReportQueuePrinterResponse",
                "keyRaw": "reportQueuePrinters",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ReportQueuePrinter",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "displayName": {
                                        "type": "String",
                                        "keyRaw": "displayName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userAccountsFilter": "UserFilter",
            "reportsFilter": "ReportsFilter"
        },

        "types": {
            "UserAuthorizedPlantsFilter": {
                "ids": "PositiveInt",
                "matches": "AnyAllEnum"
            },

            "UserGroupsFilter": {
                "ids": "PositiveInt",
                "matches": "AnyAllEnum"
            },

            "UserFilter": {
                "activeOnly": "Boolean",
                "authorizedPlants": "UserAuthorizedPlantsFilter",
                "groups": "UserGroupsFilter"
            },

            "ReportsFilter": {
                "type": "String",
                "userVisible": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d875c6794aacf45924c4794560bc8b18f296cf233063bc41881e12579c41c044";