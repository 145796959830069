import artifact from '$houdini/artifacts/UpdateEntityTags'
import { MutationStore } from '../runtime/stores/mutation'

export class UpdateEntityTagsStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
