<script lang="ts">
	import type { Option, Plant, Product, SeverityClass } from 'utility/analysis-management-helper'
	import type { i18n } from 'types/common'

	import Modal from '@isoftdata/svelte-modal'
	import Select from '@isoftdata/svelte-select'

	import MightyMorphingInput from 'components/MightyMorphingInput.svelte'
	import ThresholdTable from 'components/ThresholdTable.svelte'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import Icon from '@isoftdata/svelte-icon'

	import { getValueAcceptabilityStore, type Acceptability$options } from '$houdini'
	import { tick, getContext, type ComponentProps } from 'svelte'
	import valueAcceptabilityMap, { acceptabilityToResultStatus } from 'utility/value-acceptability-map'

	export let selectedOption: Option
	export let plants: Array<Plant>
	export let products: Array<Product>
	export let severityClasses: Array<SeverityClass>
	export let thresholdsMap: Record<string, string>
	export let showDirtyWarning = false
	export let selectedPlant: Plant

	let productId: number | null
	let severityClassId: number | null

	let show = false
	let testValue: string = ''
	let testResult: Acceptability$options | '' = ''
	let displayTestResult = ''

	$: choices =
		selectedOption.choices
			?.slice(0, -1)
			.map(choice => choice.choice)
			.filter(Boolean) ?? []

	let thresholdTable: ThresholdTable | undefined = undefined

	const getValueAcceptabilityQuery = new getValueAcceptabilityStore()
	const { t: translate } = getContext<i18n>('i18next')

	export function open() {
		show = true
		if (selectedOption.valueType === 'BOOLEAN') {
			testValue = 'False'
		}
		fetchData()
	}

	async function fetchData() {
		await tick()
		if (selectedOption.id) {
			const [{ data }] = await Promise.all([
				getValueAcceptabilityQuery.fetch({
					variables: {
						currentResult: testValue.toString(),
						analysisOptionId: selectedOption.id,
						productId: productId,
						plantId: selectedPlant.id,
						severityClassId: severityClassId,
					},
				}),
				thresholdTable?.loadData({
					analysisOptionId: selectedOption.id,
					currentResult: testValue.toString(),
					onlyApplicable: false,
					productId,
					severityClassId,
					plantId: selectedPlant.id,
				}),
			])
			testResult = data?.getValueAcceptability ?? ''
			displayTestResult = thresholdsMap[testResult] ?? ''
		}
	}

	function getAcceptabilityIcon(acceptabilityInput: Acceptability$options): ComponentProps<Icon> {
		const { icon, label, colorClass } = valueAcceptabilityMap.get(acceptabilityToResultStatus[acceptabilityInput])!
		return {
			fixedWidth: true,
			icon: icon,
			title: label,
			class: `text-${colorClass}`,
		}
	}
</script>

<Modal
	bind:show
	modalSize="xl"
	title={translate('testThresholds.title', "Test Thresholds for Analysis Option '{{- option}}'", { option: selectedOption.option })}
	subtitle={translate('testThresholds.subtitle', 'Enter a value to be tested. The system will show which threshold(s) apply and what acceptability is calculated.')}
	cancelButtonText={translate('common:close', 'Close')}
	confirmShown={false}
	on:close={() => {
		show = false
		testValue = ''
		testResult = ''
		displayTestResult = ''
	}}
>
	<div class="form-row">
		<div class="col-3">
			<MightyMorphingInput
				required
				label={translate('testThresholds.testValueLabel', 'Test Value')}
				{choices}
				valueType={selectedOption.valueType}
				bind:value={testValue}
				on:change={fetchData}
			></MightyMorphingInput>
		</div>
		<div class="col-3">
			<SiteAutocomplete
				label={translate('common:plant', 'Plant')}
				options={plants}
				bind:value={selectedPlant}
				on:change={fetchData}
			></SiteAutocomplete>
		</div>
		<div class="col-3">
			<Select
				label={translate('common:severity', 'Severity')}
				emptyValue={null}
				emptyText={translate('common:allSeverities', 'All Severities')}
				options={severityClasses}
				bind:value={severityClassId}
				let:option
				on:change={fetchData}
			>
				{#if option}
					<option value={option.id}>{option.name}</option>
				{/if}
			</Select>
		</div>
		<div class="col-3">
			<Select
				label={translate('common:product', 'Product')}
				emptyValue={null}
				emptyText={translate('common:allProducts', 'All Products')}
				options={products}
				bind:value={productId}
				on:change={fetchData}
				let:option
			>
				{#if option}
					<option value={option.id}>{option.name}</option>
				{/if}
			</Select>
		</div>
	</div>
	<div
		class:mb-4={!testResult}
		class:mt-1={!testResult}
		class:mb-1={testResult}
	>
		{#if testResult}
			<strong>{translate('common:result', 'Result')}: </strong>
			<Icon {...getAcceptabilityIcon(testResult)} />
			<span
				class:text-success={testResult === 'ALLOWED'}
				class:text-warning={testResult === 'MARGINAL'}
				class:text-danger={testResult === 'UNACCEPTABLE'}
				class:text-primary={testResult === 'INVALID'}>{displayTestResult}</span
			>
		{/if}
	</div>
	<ThresholdTable
		fullSize
		{products}
		{plants}
		{severityClasses}
		bind:this={thresholdTable}
	></ThresholdTable>
	{#if showDirtyWarning}
		<p class="mt-3 mb-0 text-info text-center">
			{translate('testThresholds.unsavedChangesWarning', 'The selected analysis option has unsaved changes. Unsaved changes will not be reflected in test results.')}
		</p>
	{/if}
</Modal>
