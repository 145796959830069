export default {
    "name": "WoAnalyses",
    "kind": "HoudiniQuery",
    "hash": "514d4b8ef539566202df44463c2fb5dbb96e9042835ce3f86f88bb25f515217d",

    "raw": `query WoAnalyses($analysesFilter: AnalysisFilter, $pagination: PaginatedInput, $viewAsPlantId: ID!) {
  analyses(filter: $analysesFilter, pagination: $pagination) {
    data {
      ...WoAnalysisData
      sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
      testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
      id
    }
  }
}

fragment WoAnalysisData on Analysis {
  id
  name
  requireAuthentication
  options {
    ...WoAnalysisOptionData
    id
  }
  __typename
}

fragment WoAnalysisOptionData on AnalysisOption {
  active
  id
  option
  valueType
  thresholdType
  rank
  defaultType
  defaultValue
  unit
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $analysesFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "requireAuthentication": {
                                        "type": "Boolean",
                                        "keyRaw": "requireAuthentication",
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                },

                                                "valueType": {
                                                    "type": "ValueType",
                                                    "keyRaw": "valueType",
                                                    "visible": true
                                                },

                                                "thresholdType": {
                                                    "type": "ThresholdType",
                                                    "keyRaw": "thresholdType",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "defaultType": {
                                                    "type": "DefaultType",
                                                    "keyRaw": "defaultType",
                                                    "visible": true
                                                },

                                                "defaultValue": {
                                                    "type": "String",
                                                    "keyRaw": "defaultValue",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "unit": {
                                                    "type": "String",
                                                    "keyRaw": "unit",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoAnalysisOptionData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    },

                                    "sampleTagPrintQuantity": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)",
                                        "visible": true
                                    },

                                    "testingTagPrintQuantity": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WoAnalysisData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "analysesFilter": "AnalysisFilter",
            "pagination": "PaginatedInput",
            "viewAsPlantId": "ID"
        },

        "types": {
            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=38b8b9d1f2f09bd92164fea19cd86b91095e24911b3b212c75ffbe5d50ceb384";