import getValueFromKeypath from 'utility/get-value-from-keypath'
import * as deepmerge from 'deepmerge'
const merge = deepmerge.default || deepmerge

const byPlantQuery = async(apiQuery, apiOptions = {}, plantIds, mediator) => {
	return await mediator.call('apiFetch', apiQuery, merge({ filter: { plantIds } }, apiOptions))
}

export default async({
	previousPlants,
	plants,
	loadKey,
	listKey,
	apiQuery,
	apiOptions,
	dataKeypath,
	ractive,
	forceRequery,
	mediator,
	listMutationFunction,
}) => {
	ractive.set(loadKey, true)
	let list = []

	if (forceRequery) {
		list = ractive.get(listKey)
		const response = await byPlantQuery(apiQuery, apiOptions, plants, mediator)
		list = getValueFromKeypath(dataKeypath, response)
	} else {
		const plantsToAdd = plants.filter(plantId => !previousPlants.includes(plantId))
		const plantsToRemove = previousPlants.filter(plantId => !plants.includes(plantId))

		list = ractive.get(listKey)

		if (plantsToRemove.length > 0) {
			list = list.filter(item => !plantsToRemove.includes(item.plantId))
		}

		if (plantsToAdd.length > 0) {
			const response = await byPlantQuery(apiQuery, apiOptions, plantsToAdd, mediator)
			list = [ ...list, ...getValueFromKeypath(dataKeypath, response) ]
		}
	}

	if (listMutationFunction && typeof listMutationFunction === 'function') {
		list = listMutationFunction(list)
	}

	ractive.set({ [listKey]: list, [loadKey]: false })
	return list
}
