export default {
    "name": "UpdateAutomaticWorkOrders",
    "kind": "HoudiniMutation",
    "hash": "fdbba9409532d973225a9d2f91e0cf1add13547216be79e88543205198e78444",

    "raw": `mutation UpdateAutomaticWorkOrders($automaticWorkOrders: [UpdateAutomaticWorkOrder!]!) {
  updateAutomaticWorkOrders(automaticWorkOrders: $automaticWorkOrders) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateAutomaticWorkOrders": {
                "type": "AutomaticWorkOrder",
                "keyRaw": "updateAutomaticWorkOrders(automaticWorkOrders: $automaticWorkOrders)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "automaticWorkOrders": "UpdateAutomaticWorkOrder"
        },

        "types": {
            "UpdateAutomaticWorkOrder": {
                "active": "Boolean",
                "cloneAdditionalData": "Boolean",
                "cloneMostRecent": "Boolean",
                "dayOfWeek": "NonNegativeInt",
                "dayOffset": "NonNegativeInt",
                "description": "String",
                "id": "PositiveInt",
                "monthOffset": "NonNegativeInt",
                "rank": "NonNegativeInt",
                "scheduleType": "ScheduleType",
                "timeOffset": "String",
                "timezone": "String",
                "workOrderId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=7fffca05797bcd05463f00e320766399246b057c5c87c1932854ad1bdecfa9f9";