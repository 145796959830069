export const locationsQuery = `#graphql
	query ($filter: LocationFilter, $pagination: PaginatedInput) {
		locations(filter:$filter, pagination:$pagination) {
			data {
				id
				plantId
				code
				location
			}
		}
	}`
