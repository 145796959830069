export default {
    "name": "AmUpdateEntityTags",
    "kind": "HoudiniMutation",
    "hash": "c6774170370042141e575faf93fe1847bc8f5f3b496341905fb55364317441f2",

    "raw": `mutation AmUpdateEntityTags($input: [EntityTagUpdate!]!) {
  updateEntityTags(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateEntityTags": {
                "type": "EntityTag",
                "keyRaw": "updateEntityTags(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "EntityTagUpdate"
        },

        "types": {
            "EntityTagUpdate": {
                "active": "Boolean",
                "entityType": "EntityTagType",
                "id": "PositiveInt",
                "name": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=6dbd79caf6dffba70acf069e36e7dbd9dae16bdc04c65aff61fbf234f700ceff";