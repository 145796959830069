import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetLocationsReferenceCounts'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetLocationsReferenceCountsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetLocationsReferenceCountsStore",
			variables: true,
		})
	}
}

export async function load_GetLocationsReferenceCounts(params) {
	await initClient()

	const store = new GetLocationsReferenceCountsStore()

	await store.fetch(params)

	return {
		GetLocationsReferenceCounts: store,
	}
}
