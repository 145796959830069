export default {
    "name": "DeleteInvestigationTriggers",
    "kind": "HoudiniMutation",
    "hash": "1e0bac06108f4ceb34eb9d12ca1d8484b6c0793b023f9f9c9de48f095f2bae9e",

    "raw": `mutation DeleteInvestigationTriggers($ids: [PositiveInt!]!) {
  deleteInvestigationTriggers(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteInvestigationTriggers": {
                "type": "Boolean",
                "keyRaw": "deleteInvestigationTriggers(ids: $ids)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "ids": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=0b015cd6a916e26c9a836176ab1bc96b66273a02fa936643097323c2fa1f32ae";