import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/WorkOrderSettings'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class WorkOrderSettingsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "WorkOrderSettingsStore",
			variables: false,
		})
	}
}

export async function load_WorkOrderSettings(params) {
	await initClient()

	const store = new WorkOrderSettingsStore()

	await store.fetch(params)

	return {
		WorkOrderSettings: store,
	}
}
