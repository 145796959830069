export default {
    "name": "WoProductBatches",
    "kind": "HoudiniQuery",
    "hash": "50fd79b78ee855f472b10fb2a60f70294d7ea0dec82e349e8da4787a7b06994e",

    "raw": `query WoProductBatches($productBatchesFilter: ProductBatchFilter, $pagination: PaginatedInput) {
  productBatches(filter: $productBatchesFilter, pagination: $pagination) {
    data {
      ...WoProductBatchData
      id
    }
  }
}

fragment WoProductBatchData on ProductBatch {
  id
  name
  product {
    ...WoProductData
    id
  }
  location {
    ...WoLocationData
    id
  }
  plantId
  status
  end
  start
  __typename
}

fragment WoProductData on Product {
  id
  name
  productType
  attachmentCount(fileType: IMAGE)
  __typename
}

fragment WoLocationData on Location {
  id
  location
  description
  plantId
  severityClass {
    id
    default
  }
  attachmentCount(fileType: IMAGE)
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "productBatches": {
                "type": "ProductBatchResponse",
                "keyRaw": "productBatches(filter: $productBatchesFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProductBatch",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "product": {
                                        "type": "Product",
                                        "keyRaw": "product",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "productType": {
                                                    "type": "ProductType",
                                                    "keyRaw": "productType",
                                                    "visible": true
                                                },

                                                "attachmentCount": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "attachmentCount(fileType: IMAGE)",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoProductData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "location": {
                                        "type": "Location",
                                        "keyRaw": "location",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "location": {
                                                    "type": "String",
                                                    "keyRaw": "location",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                },

                                                "plantId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "plantId",
                                                    "visible": true
                                                },

                                                "severityClass": {
                                                    "type": "SeverityClass",
                                                    "keyRaw": "severityClass",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "default": {
                                                                "type": "Boolean",
                                                                "keyRaw": "default",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "attachmentCount": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "attachmentCount(fileType: IMAGE)",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoLocationData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProductBatchStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "end": {
                                        "type": "DateTime",
                                        "keyRaw": "end",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "start": {
                                        "type": "DateTime",
                                        "keyRaw": "start",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WoProductBatchData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "productBatchesFilter": "ProductBatchFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "ProductBatchFilter": {
                "name": "String",
                "plantIds": "PositiveInt",
                "statuses": "ProductBatchStatus"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=09c51eeda84e4d8affad03160a508635ce0c9a204cbe2dbf3e69b42052251cf8";