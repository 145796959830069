import {
	graphql,
	type LoadProducts$result,
	type ProductSpecifications$result,
	type LoadProductsPage$result,
	type LoadProductAnalyses$result,
	type LoadProductAnalysisOptions$result,
	ProductSpecificationsStore,
	LoadProductsStore,
	LoadProducts$input,
	LoadProductAnalysisOptionsStore,
	LoadProductAnalysesStore,
	CreateProductEntityTagsStore,
	UpdateProductEntityTagsStore,
	DeleteProductEntityTagsStore,
	CreateOrUpdateProductsStore,
	DeleteProductsStore,
	AttachFileToProductStore,
	DetachFilesFromProductStore,
	CreateProductSpecificationStore,
	UpdateProductSpecificationStore,
	DeleteProductSpecificationStore,
	LoadProductsPageStore,
	GetProductReferenceCountsStore,
} from '$houdini'
import { treeify, type TreeNode } from '@isoftdata/svelte-table'
import type { MetaTag } from 'components/TagSelection.svelte'
import { v4 as uuid } from '@lukeed/uuid'
import hasPermission from './has-permission'
import { Simplify, WritableDeep } from 'type-fest'

export type Product = LoadProducts$result['products']['data'][number]
export type Plant = LoadProductsPage$result['plants']['data'][number]
export type SeverityClass = LoadProductsPage$result['severityClasses']['data'][number]
export type ProductSpecification = Simplify<
	WritableDeep<
		Omit<ProductSpecifications$result['analysisOptionChoices']['data'][number], 'id'> & {
			id: number | null
			uuid: string
			productUuid: string
			delete?: unknown
		}
	>
>
export type Analysis = WritableDeep<LoadProductAnalyses$result['analyses']['data'][number]>
export type AnalysisOption = LoadProductAnalysisOptions$result['analysisOptions']['data'][number]

export type UuidProduct = Simplify<
	Omit<Product, 'attachments'> & {
		uuid: string
		attachments: Array<ProductAttachment>
		imageAttachments: Array<ProductAttachment>
		thumbnailPath: string | null
		inUse: boolean | undefined
	}
>

export type ProductNode = Simplify<
	TreeNode<
		Omit<Product, 'id' | 'severityClass' | 'tags' | 'attachments'> & {
			id: number | null
			uuid: string
			attachments: ProductAttachment[]
			imageAttachments: ProductAttachment[]
			thumbnailPath: string | null
			tags: Array<MetaTag>
			inUse: boolean | undefined
			parentProductUuid: string | null
			depth: number
		},
		'uuid',
		'parentProductUuid'
	> & { dirty?: unknown }
>

export type ProductAttachment = {
	/** New files will have a File object on them*/
	File?: File
	productId: number | null
	productUuid: string
	mimeType: string
	name: string
	path: string
	public: boolean
	rank: number
	size: number
	uuid: string
	fileId?: number
}

export function canEditPlantSpecificFieldsCheck(plantId: number) {
	return hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', plantId)
}

export function makeCanEditGlobalFieldsMap(products: Array<Pick<Product, 'id' | 'inUseAtPlants'>>, thisPlantId: number) {
	return products.reduce((acc: Map<number, boolean>, product) => {
		if (product.id && product.inUseAtPlants) {
			acc.set(
				product.id,
				product.inUseAtPlants.every(plant => hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', plant.id))
			)
		}

		return acc
	}, new Map([[-1, hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', thisPlantId)]])) // -1 = selected plant only
}

export function makePermissionFunctions(products: Array<Pick<Product, 'id' | 'inUseAtPlants'>>, plantId: number) {
	const canEditGlobalFieldsMap = makeCanEditGlobalFieldsMap(products, plantId)
	return {
		canEditGlobalFields: (productId?: number | null) => canEditGlobalFieldsCheck(canEditGlobalFieldsMap, productId),
		canEditPlantSpecificFields: () => canEditPlantSpecificFieldsCheck(plantId),
	}
}

export function canEditGlobalFieldsCheck(map: Map<number, boolean>, productId: number | null = null) {
	return map.get(productId ?? -1) ?? false
}

export async function checkProductReferenceCounts(productIds: number[]) {
	const zeroCountObject = {
		analysisCount: 0,
		childProductCount: 0,
		productBatchCount: 0,
		sampleCount: 0,
	}
	const { data } = await productReferenceCountsQuery.fetch({
		variables: {
			productIds,
		},
	})

	if (!data) {
		return zeroCountObject
	}

	const total = data.getProductReferenceCounts.reduce((count, cur) => {
		return {
			childProductCount: count.childProductCount + cur.childProductCount,
			analysisCount: count.analysisCount + cur.analysisCount,
			productBatchCount: count.productBatchCount + cur.productBatchCount,
			sampleCount: count.sampleCount + cur.sampleCount,
		}
	}, zeroCountObject)

	return total
}

export async function loadProducts(
	filter: LoadProducts$input['filter'],
	selectedPlantId: number,
	orderBy?: LoadProducts$input['orderBy']
): Promise<{ uuidMap: Record<number, string>; productsTree: ProductNode[]; products: Product[] }> {
	const { data } = await loadProductsQuery.fetch({
		variables: {
			filter,
			pagination: {
				pageSize: 0,
			},
			orderBy: orderBy ?? ['NAME_ASC'],
		},
	})

	if (!data) {
		return {
			uuidMap: {},
			productsTree: [],
			products: [],
		}
	}

	const fileBaseUrl = '__apiUrl__'.replace('/graphql', '')

	const { uuidMap, uuidProducts } = data.products.data.reduce(
		({ uuidMap, uuidProducts }: { uuidMap: Record<number, string>; uuidProducts: Array<UuidProduct> }, product) => {
			const theUuid = uuid()
			uuidMap[product.id] = theUuid
			const attachments = product.attachments.map(attachment => ({
				...attachment,
				...attachment.file,
				productId: product.id,
				path: `${fileBaseUrl}${attachment.file.path}`,
				uuid: uuid(),
				productUuid: theUuid,
				rank: attachment.rank ?? 0,
				size: attachment.file.size ?? 0,
			}))
			const imageAttachments = attachments.filter(attachment => attachment.mimeType.startsWith('image/'))
			uuidProducts.push({
				...product,
				attachments,
				imageAttachments,
				thumbnailPath: imageAttachments[0]?.path ?? null,
				inUse: product.inUseAtPlants?.map(plant => plant.id).includes(selectedPlantId),
				tags: product.tags?.sort((a, b) => a.name.length - b.name.length) ?? [],
				uuid: theUuid,
			})
			return { uuidMap, uuidProducts }
		},
		{ uuidMap: {}, uuidProducts: [] }
	)
	const productsTree = treeify(
		uuidProducts.map((product: UuidProduct) => {
			const parentId: number | null = product.parentProductId
			return {
				...product,
				depth: 0,
				parentProductUuid: (parentId && uuidMap[parentId]) ?? null,
			}
		}),
		'uuid',
		'parentProductUuid'
	) as ProductNode[] // This is lying to TS but it makes the recursive children typeing correct. Tecnically its not a ProductNode[] but a TreeNode<Product>[]

	const updatedProductsTree: ProductNode[] = productsTree.map(node => {
		node.depth = 0
		node.children = setTreeDepth(node, 0)
		return node
	})

	return {
		uuidMap,
		productsTree: updatedProductsTree,
		products: data.products.data,
	}
}

function setTreeDepth(node: ProductNode, depth: number): ProductNode[] {
	if (!node.children) {
		return []
	}
	return node.children.map(child => ({
		...child,
		depth: depth + 1,
		children: setTreeDepth(child, depth + 1),
	}))
}

export const LoadProductsPageQuery: LoadProductsPageStore = graphql(`
	query LoadProductsPage($pagination: PaginatedInput, $filter: EntityTagFilter, $severityClassFilter: SeverityClassFilter) {
		plants(pagination: $pagination) {
			data {
				id
				code
				name
				private
			}
			info {
				pageSize
				pageNumber
			}
		}
		entityTags(filter: $filter) {
			name
			active
			entityType
			id
		}
		severityClasses(filter: $severityClassFilter, pagination: $pagination) {
			data {
				id
				name
				description
				default
				plantId
			}
		}
		analyses(pagination: $pagination) {
			data {
				id
				name
				inUseAtPlantIDs
				options {
					id
					option
					valueType
				}
			}
		}
		productCategories
	}
`)

export const loadAnalysisQuery: LoadProductAnalysesStore = graphql(`
	query LoadProductAnalyses($filter: AnalysisFilter, $pagination: PaginatedInput) {
		analyses(filter: $filter, pagination: $pagination) {
			data {
				id
				name
				inUseAtPlantIDs
				options {
					id
					option
					valueType
				}
			}
		}
	}
`)

export const analysisOptionsQuery: LoadProductAnalysisOptionsStore = graphql(`
	query LoadProductAnalysisOptions($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
		analysisOptions(filter: $filter, pagination: $pagination) {
			data {
				id
				active
				analysisId
				option
				unit
				valueType
				defaultValue
				defaultType
				thresholdType
				requiredToPerform
				requiredToClose
				informational
				rank
				productId
				rules {
					id
					analysisOptionId
					active
					restriction
					outcome
					description
					created
					tags {
						id
						name
						entityType
						active
					}
				}
			}
		}
	}
`)

export const loadProductsQuery: LoadProductsStore = graphql(`
	query LoadProducts($filter: ProductFilter, $pagination: PaginatedInput, $orderBy: [ProductOrderBy!]) {
		products(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
			data {
				id
				active
				barcodeFormat
				category
				description
				inUseAtPlants {
					id
				}
				itemNumber
				name
				parentProductId
				productType
				supplierItemNumber
				tags {
					id
					name
					entityType
					active
				}
				attachmentCount
				attachments {
					id
					public
					rank
					fileId
					file {
						path
						name
						created
						updated
						type
						hash
						mimeType
						size
					}
				}
			}
		}
	}
`)

export const loadProductSpecificationsQuery: ProductSpecificationsStore = graphql(`
	query ProductSpecifications($filter: AnalysisOptionChoiceFilter, $pagination: PaginatedInput) {
		analysisOptionChoices(filter: $filter, pagination: $pagination) {
			data {
				id
				plantId
				productId
				choice
				constraint
				boundaryType
				requiredAnalysisOption {
					id
					option
					valueType
					analysis {
						id
						name
					}
				}
				requiredConstraint
				requiredChoice
				productBatchId
				severityClass {
					id
					name
				}
				analysisOption {
					id
					option
					valueType
					analysis {
						id
						name
					}
				}
			}
		}
	}
`)

const productReferenceCountsQuery: GetProductReferenceCountsStore = graphql(`
	query GetProductReferenceCounts($productIds: [NonNegativeInt!]!) {
		getProductReferenceCounts(productIds: $productIds) {
			analysisCount
			childProductCount
			productBatchCount
			sampleCount
		}
	}
`)

export const createTagsMutation: CreateProductEntityTagsStore = graphql(`
	mutation CreateProductEntityTags($input: [NewEntityTag!]!) {
		createEntityTags(input: $input) {
			active
			entityType
			id
			name
		}
	}
`)

export const updateTagsMutation: UpdateProductEntityTagsStore = graphql(`
	mutation UpdateProductEntityTags($input: [EntityTagUpdate!]!) {
		updateEntityTags(input: $input) {
			id
		}
	}
`)

export const deleteTagsMutation: DeleteProductEntityTagsStore = graphql(`
	mutation DeleteProductEntityTags($ids: [PositiveInt!]!) {
		deleteEntityTags(ids: $ids)
	}
`)

export const createOrUpdateProductMutation: CreateOrUpdateProductsStore = graphql(`
	mutation CreateOrUpdateProducts($product: CreateOrUpdateProduct!) {
		createOrUpdateProduct(product: $product) {
			id
			active
			category
			description
			inUseAtPlants {
				id
			}
			name
			parentProductId
			productType
			tags {
				id
				name
				entityType
				active
			}
			attachmentCount
			attachments {
				id
				public
				rank
				fileId
				file {
					path
					name
					created
					updated
					type
					hash
					mimeType
					size
				}
			}
		}
	}
`)

export const deleteProductsMutation: DeleteProductsStore = graphql(`
	mutation DeleteProducts($ids: [PositiveInt!]!) {
		deleteProducts(ids: $ids)
	}
`)

export const attachFileToProductMutation: AttachFileToProductStore = graphql(`
	mutation AttachFileToProduct($input: NewProductFile!) {
		attachFilesToProduct(input: $input) {
			id
		}
	}
`)

export const detachFilesFromProductMutation: DetachFilesFromProductStore = graphql(`
	mutation DetachFilesFromProduct($productId: PositiveInt!, $fileIds: [PositiveInt!]!) {
		detachFilesFromProduct(productId: $productId, fileIds: $fileIds)
	}
`)

export const createSpecificationMutation: CreateProductSpecificationStore = graphql(`
	mutation CreateProductSpecification($analysisOptionChoices: [NewAnalysisOptionChoice!]!) {
		createAnalysisOptionChoices(analysisOptionChoices: $analysisOptionChoices) {
			id
		}
	}
`)

export const updateSpecificationMutation: UpdateProductSpecificationStore = graphql(`
	mutation UpdateProductSpecification($analysisOptionChoices: [AnalysisOptionChoiceUpdate!]!) {
		updateAnalysisOptionChoices(analysisOptionChoices: $analysisOptionChoices) {
			id
		}
	}
`)

export const deleteSpecificationMutation: DeleteProductSpecificationStore = graphql(`
	mutation DeleteProductSpecification($ids: [ID!]!) {
		deleteAnalysisOptionChoice(ids: $ids)
	}
`)
