import type { AppContext } from 'types/common'
import component from './WorkOrderType.svelte'
import { graphql } from '$houdini'
import { getSession } from 'stores/session'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import { v4 as uuid } from '@lukeed/uuid'

export default ({ mediator, stateRouter, hasPermission, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.configuration.work-order-type',
		route: 'work-order-type',
		querystringParameters: ['lastSavedTime', 'lastResetTime', 'plantId'],
		defaultParameters: {
			lastResetTime: null,
			plantId: () => getSession().siteId.toString(),
		},
		template: {
			svelte: true,
			component,
			options: {},
		},
		canLeaveState(svelte) {
			// the DomApi type could be either ractive or svelte, even though we know it's Svelte, we have to check.
			if (svelte.renderer === 'ractive') {
				return true
			}
			return !svelte.hasUnsavedChanges || confirm(translate('common:canLeaveState', 'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.'))
		},
		async resolve(_, parameters) {
			const session = getSession()
			if (!parameters.plantId) {
				parameters.plantId = session.siteId.toString()
			}

			const plantId = parseInt(parameters.plantId, 10)

			if (!hasPermission('CONFIGURATION_CAN_CONFIGURE_WORK_ORDER_TYPES', plantId)) {
				throw showErrorAndRedirect(
					mediator,
					translate('woTypes.permissionDeniedHeading', 'Permission Denied'),
					translate('woTypes.permissionDeniedMessage', 'You do not have permission to configure work order types at this plant.')
				)
			}

			const { data } = await woTypeConfigData.fetch({
				variables: {
					analysesFilter: {
						activeOnly: true,
						plantIds: [plantId],
					},
					pagination: { pageSize: 0 },
				},
			})

			if (!data) {
				throw showErrorAndRedirect(
					mediator,
					translate('woTypes.errorLoadingHeading', 'Error Loading Work Order Type Data'),
					translate('woTypes.errorLoadingMessage', 'No data was returned from the server')
				)
			}

			return {
				workOrderTypes: data.workOrderTypes.data.map(woType => ({
					...woType,
					uuid: uuid(),
				})),
				analyses: data.analyses.data,
				groups: data.groups.data,
				plants:
					session.user.permissions.CONFIGURATION_CAN_CONFIGURE_WORK_ORDER_TYPES === 'GLOBAL'
						? data.plants.data
						: data.plants.data.filter(plant => hasPermission('CONFIGURATION_CAN_CONFIGURE_WORK_ORDER_TYPES', plant.id)),
				selectedPlant: data.plants.data.find(plant => plant.id === plantId),
			}
		},
	})
}

const woTypeConfigData = graphql(`
	query WoTypeConfigData($pagination: PaginatedInput, $analysesFilter: AnalysisFilter) {
		workOrderTypes(pagination: $pagination) {
			data {
				defaultAnalysis {
					id
					name
				}
				daysTillDue
				daysTillVerificationDue
				defaultGroup {
					id
					name
				}
				defaultVerificationGroup {
					id
					name
				}
				visibleGroup {
					id
					name
				}
				id
				verificationRequired
				titleRequired
				productBatchRequired
				showDue
				name
				showProduct
				showSamplingDetail
				showTestingDetail
				showLocation
				showLocationDescription
				showSampling
				showTasks
				showRecipes
				inUseAtPlantIDs
				workOrderCount
			}
		}
		plants(pagination: $pagination) {
			data {
				id
				code
				name
			}
		}
		analyses(pagination: $pagination, filter: $analysesFilter) {
			data {
				id
				name
			}
		}
		groups(pagination: $pagination) {
			data {
				id
				name
			}
		}
	}
`)
