export default {
    "name": "RecalculateSamples",
    "kind": "HoudiniMutation",
    "hash": "3f0a1887e2f09aa7d4a563441c2f17a47ea2b8be8b27a9c69ff4d0db1d8b101e",

    "raw": `mutation RecalculateSamples($input: RecalculateSamplesInput!) {
  recalculateSamples(input: $input)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "recalculateSamples": {
                "type": "Boolean",
                "keyRaw": "recalculateSamples(input: $input)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "RecalculateSamplesInput"
        },

        "types": {
            "RecalculateSamplesInput": {
                "analysisId": "PositiveInt",
                "dateFrom": "DateTime",
                "dateTo": "DateTime",
                "locationId": "PositiveInt",
                "plantId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=f721ba4458cf9d35ccbddd57bec09d690ab90e3296ca03a246ab04a7c728dc89";