<script lang="ts">
	import type { SvelteAsr, i18n } from 'types/common'
	import { getContext, type ComponentProps } from 'svelte'
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	const { t: translate } = getContext<i18n>('i18next')

	import StateCardHeader from 'components/StateCardHeader.svelte'

	export let tabs: ComponentProps<AsrNavTabBar>['tabs']
	export let asr: SvelteAsr
</script>

<div class="card">
	<StateCardHeader
		title={translate('nav.home', 'Home')}
		icon="home"
	>
		<AsrNavTabBar
			{tabs}
			{asr}
			breakpoint="md"
		/>
	</StateCardHeader>

	<div class="card-body p-0 m-3">
		<uiView></uiView>
	</div>
</div>
