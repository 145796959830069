export default {
    "name": "CreateAndUpdateLocations",
    "kind": "HoudiniMutation",
    "hash": "d85a26f4809c0186e6154696711fb8f8d57dd36bf0bce31f6fe3c72047318548",

    "raw": `mutation CreateAndUpdateLocations($locations: [NewUpdateLocation!]!) {
  createAndUpdateLocations(locations: $locations) {
    id
    plantId
    location
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createAndUpdateLocations": {
                "type": "Location",
                "keyRaw": "createAndUpdateLocations(locations: $locations)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "plantId": {
                            "type": "PositiveInt",
                            "keyRaw": "plantId",
                            "visible": true
                        },

                        "location": {
                            "type": "String",
                            "keyRaw": "location",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "locations": "NewUpdateLocation"
        },

        "types": {
            "NewUpdateLocation": {
                "active": "Boolean",
                "code": "NonEmptyString",
                "description": "NonEmptyString",
                "id": "PositiveInt",
                "parentLocationId": "PositiveInt",
                "parentLocationUuid": "NonEmptyString",
                "plantId": "PositiveInt",
                "processZoneId": "PositiveInt",
                "productProximityId": "PositiveInt",
                "tagsToAdd": "PositiveInt",
                "tagsToRemove": "PositiveInt",
                "testable": "Boolean",
                "uuid": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ad339925ef5d8a0ecf4b690b7ab033c92e057f946e5b676ef702ecf99325757b";