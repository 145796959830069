import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/AnalysisManagementData'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class AnalysisManagementDataStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "AnalysisManagementDataStore",
			variables: true,
		})
	}
}

export async function load_AnalysisManagementData(params) {
	await initClient()

	const store = new AnalysisManagementDataStore()

	await store.fetch(params)

	return {
		AnalysisManagementData: store,
	}
}
