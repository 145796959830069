export default {
    "name": "RecalculateSamplesModalLocations",
    "kind": "HoudiniQuery",
    "hash": "d74f74c66665b4507a8bb977534a02a4d12af13a5d432fdcbef68583516ebbe0",

    "raw": `query RecalculateSamplesModalLocations($filter: LocationFilter) {
  locations(filter: $filter, pagination: {pageSize: 0}) {
    data {
      id
      plantId
      code
      location
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "locations": {
                "type": "LocationResponse",
                "keyRaw": "locations(filter: $filter, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Location",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "String",
                                        "keyRaw": "location",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "LocationFilter"
        },

        "types": {
            "ProcessZoneFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "ProductProximityFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "LocationFilter": {
                "activeOnly": "Boolean",
                "location": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "processZone": "ProcessZoneFilter",
                "productProximity": "ProductProximityFilter",
                "testableOnly": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b9e64a3ea77d157b6e722b53e16d514b5187c3ed0fb35ad6a0539e1b4d1f580e";