export default {
    "name": "CreateProductSpecification",
    "kind": "HoudiniMutation",
    "hash": "cd0533ed4c7ab0e6bad2916feac99098d9fa6bb2eb768c627535152d672a63d8",

    "raw": `mutation CreateProductSpecification($analysisOptionChoices: [NewAnalysisOptionChoice!]!) {
  createAnalysisOptionChoices(analysisOptionChoices: $analysisOptionChoices) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createAnalysisOptionChoices": {
                "type": "AnalysisOptionChoice",
                "keyRaw": "createAnalysisOptionChoices(analysisOptionChoices: $analysisOptionChoices)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "analysisOptionChoices": "NewAnalysisOptionChoice"
        },

        "types": {
            "NewAnalysisOptionChoice": {
                "active": "Boolean",
                "analysisOptionId": "PositiveInt",
                "boundaryType": "BoundaryType",
                "choice": "String",
                "constraint": "ConstraintType",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "productId": "PositiveInt",
                "requiredAnalysisOptionId": "PositiveInt",
                "requiredChoice": "String",
                "requiredConstraint": "ConstraintType",
                "severityClassId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=42c1213a697f61ee10dfc9d2cf5aa703e33f995ae31e593fa4c5ed2cab725f05";