export default {
    "name": "WorkOrderSearch",
    "kind": "HoudiniQuery",
    "hash": "053088570cb6942577fd789e7f89e3819685d19b72320838019b975ab4289f6f",

    "raw": `query WorkOrderSearch($filter: ManyWorkOrdersFilter, $pagination: PaginatedInput, $orderBy: WorkOrderOrderByFilter) {
  workOrders(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
    data {
      favorite
      documentStatus
      plant {
        id
        name
        code
      }
      id
      workOrderType {
        id
        name
      }
      title
      scheduled
      dateCreated
      due
      internalNotes
      instructions
      productBatch {
        name
        id
      }
      assignedToGroup {
        name
        id
      }
      verificationDue
      verifiedByUser {
        fullName
        id
      }
      verifiedOn
    }
    info {
      pageNumber
      totalItems
      totalPages
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "workOrders": {
                "type": "WorkOrderResponse",
                "keyRaw": "workOrders(filter: $filter, orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "WorkOrder",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "favorite": {
                                        "type": "Boolean",
                                        "keyRaw": "favorite",
                                        "visible": true
                                    },

                                    "documentStatus": {
                                        "type": "DocumentStatus",
                                        "keyRaw": "documentStatus",
                                        "visible": true
                                    },

                                    "plant": {
                                        "type": "Plant",
                                        "keyRaw": "plant",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "workOrderType": {
                                        "type": "WorkOrderType",
                                        "keyRaw": "workOrderType",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "title": {
                                        "type": "String",
                                        "keyRaw": "title",
                                        "visible": true
                                    },

                                    "scheduled": {
                                        "type": "DateTime",
                                        "keyRaw": "scheduled",
                                        "visible": true
                                    },

                                    "dateCreated": {
                                        "type": "DateTime",
                                        "keyRaw": "dateCreated",
                                        "visible": true
                                    },

                                    "due": {
                                        "type": "DateTime",
                                        "keyRaw": "due",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "internalNotes": {
                                        "type": "String",
                                        "keyRaw": "internalNotes",
                                        "visible": true
                                    },

                                    "instructions": {
                                        "type": "String",
                                        "keyRaw": "instructions",
                                        "visible": true
                                    },

                                    "productBatch": {
                                        "type": "ProductBatch",
                                        "keyRaw": "productBatch",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "assignedToGroup": {
                                        "type": "Group",
                                        "keyRaw": "assignedToGroup",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "verificationDue": {
                                        "type": "DateTime",
                                        "keyRaw": "verificationDue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "verifiedByUser": {
                                        "type": "UserAccount",
                                        "keyRaw": "verifiedByUser",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "fullName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "fullName",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "verifiedOn": {
                                        "type": "DateTime",
                                        "keyRaw": "verifiedOn",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    },

                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "ManyWorkOrdersFilter",
            "pagination": "PaginatedInput",
            "orderBy": "WorkOrderOrderByFilter"
        },

        "types": {
            "ManyWorkOrdersFilter": {
                "closedDocuments": "Boolean",
                "favoriteDocuments": "Boolean",
                "from": "Date",
                "groupId": "PositiveInt",
                "openDocuments": "Boolean",
                "plantId": "NonNegativeInt",
                "to": "Date",
                "workOrderTypeId": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "WorkOrderOrderByFilter": {
                "columnName": "String",
                "direction": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=394e950e30ad979ff61107a4009c1cf1e48e896ca0069d561dbdd28befe3cddf";