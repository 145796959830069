import type { DocumentStatus$options } from '$houdini'
import type { i18n } from 'i18next'
/**
 *
 * @param status The status of a WO or Sample to format
 * @param isSample Whether this is the status of a sample or WO. On WOs, we want to say "Completed" instead of "Sampled"
 * @returns The formatted status
 */
export default function formatDocumentStatus(translate: i18n['t'], status: DocumentStatus$options, isSample = false) {
	// TODO: Translate. Maybe it would make sense to move the 18n initialization to a separate module, so it can be imported here?
	switch (status) {
		case 'OPEN':
			return translate('workOrder.documentStatusOpen', 'Open')
		case 'CLOSED':
			return translate('workOrder.documentStatusClosed', 'Closed')
		case 'CANCELLED':
			return translate('workOrder.documentStatusCancelled', 'Cancelled')
		case 'SAMPLED':
			return isSample ? translate('workOrder.documentStatusSampled', 'Sampled') : translate('workOrder.documentStatusCompleted', 'Completed')
	}
}

export function getDocumentStatusHint(translate: i18n['t'], status: DocumentStatus$options) {
	switch (status) {
		case 'OPEN':
			return translate('workOrder.documentStatusOpenTitle', 'This document is currently open')
		case 'CLOSED':
			return translate('workOrder.documentStatusClosedTitle', "This work order is 'closed'. This marks the document as finalized, keeping any further changes from being made.")
		case 'CANCELLED':
			return translate('workOrder.documentStatusCancelledTitle', 'This document has been cancelled')
		case 'SAMPLED':
			return translate('workOrder.documentStatusCompletedTitle', 'The work order has had all data collected and is ready to either be verified or closed, depending on the type of document.')
	}
}
