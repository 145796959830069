import svelteComponent from './dashboard.svelte'
import { getSession } from 'stores/session'

export default ({ stateRouter }) => {
	stateRouter.addState({
		name: 'app.home.dashboard',
		route: 'dashboard',
		template: {
			svelte: true, // this tells our renderer wrapper that this is a svelte component
			component: svelteComponent, // this is the svelte component
			options: {
				// these are the options passed to the svelte component
			},
		},
		resolve(_data, _parameters) {
			const session = getSession()
			return Promise.resolve({
				authToken: session.authToken,
			})
		},
	})
}
