<script lang="ts">
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import Icon from '@isoftdata/svelte-icon'
	import ResponsiveShadow from '@isoftdata/svelte-responsive-shadow'
	import PasswordFields from '@isoftdata/svelte-password-fields'
	import { getContext, onMount } from 'svelte'

	import type { Mediator } from 'client/services/api-fetch'
	import type { i18n, SvelteAsr } from 'types/common'
	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')

	export let userName: string = ''
	export let pin: string = ''
	export let newPassword: string = ''
	export let newPasswordConfirm: string = ''
	export let userNameInput: HTMLInputElement | undefined = undefined
	export let passwordMismatch: boolean = false
	export let showInvalidPinAlert: boolean = false
	export let isActivatingAccount: boolean = false
	export let passwordRules: Record<string, string>
	export let asr: SvelteAsr

	let passwordValidationFailed = false
	let showPasswordRules = false
	let errorMessage = ''

	$: hasValidPin = pin.replace(/\D/g, '').length === 6
	$: canActivateAccount = userName && hasValidPin && newPassword && newPasswordConfirm && !passwordMismatch

	onMount(() => {
		userNameInput?.focus()
	})

	function pinChange() {
		pin = pin.replace(/\D/g, '')
		showInvalidPinAlert = pin.length !== 6
		if (pin.length >= 3) {
			pin = `${pin.slice(0, 3)}-${pin.slice(3)}`
		}
	}

	async function confirmAccountActivation(userName, pin, newPassword) {
		try {
			isActivatingAccount = true
			const res = await mediator.call(
				'apiFetch',
				`#graphql
			mutation ChangePassword($newPassword: String!, $userName: String!, $resetToken: String) {
				changePassword(newPassword: $newPassword, userName: $userName, resetToken: $resetToken)
			}
		`,
				{
					userName,
					newPassword,
					resetToken: pin.replace(/\D/g, ''),
				},
				{},
			)

			asr.go('login', { username: userName, accountActivationSuccess: true })
		} catch (err: any) {
			console.error(err)
			if (err?.extensions?.message) {
				errorMessage = err?.extensions?.message
			}
		} finally {
			isActivatingAccount = false
		}
	}
</script>

<ResponsiveShadow>
	<a
		class="small"
		href="#/login"><Icon icon="arrow-left" /> {translate('activateAccount.returnToLoginLabel', 'Return to Login')}</a
	>
	<h3>{translate('activateAccount.cardTitle', 'Activate New Account')}</h3>
	<div
		class="alert alert-primary"
		role="alert"
	>
		<p style="font-size: smaller;">{translate('activateAccount.pinProvidedByAdminAlert', 'Your Username & One-Time Activation PIN are provided by your system administrator.')}</p>
		<small>{translate('activateAccount.allFieldsRequiredAlert', 'All fields are required.')}</small>
	</div>
	<Input
		required
		label={translate('activateAccount.usernameLabel', 'Username')}
		bind:input={userNameInput}
		bind:value={userName}
	/>
	<Input
		label={translate('activateAccount.pinLabel', 'One-Time Activation PIN')}
		bind:value={pin}
		placeholder="###-###"
		required
		on:change={() => pinChange()}
	/>
	{#if showInvalidPinAlert}
		<div class="alert alert-danger">
			<h6><i class="fa-solid fa-triangle-exclamation"></i> {translate('activateAccount.invalidPinError', 'Invalid PIN')}</h6>
			<small>{translate('activateAccount.invalidPinErrorHint', 'A valid PIN is 6 numbers(eg. 123-456).')}</small>
		</div>
	{/if}
	<hr />
	<PasswordFields
		bind:password={newPassword}
		bind:confirmPassword={newPasswordConfirm}
		passwordLabel={translate('activateAccount.newPasswordPlaceholder', 'New Password')}
		columnClass="col-12"
		bind:passwordMismatch
		bind:showPasswordRules
		bind:passwordValidationFailed
		validationRules={passwordRules}
	/>
	{#if errorMessage || passwordMismatch}
		<div class="alert alert-danger">
			<ul class="mb-0">
				{#if errorMessage}
					<li>{errorMessage}</li>
				{/if}
				{#if passwordMismatch}
					<li>{translate('activateAccount.passwordMismatchError', 'Passwords do not match')}</li>
				{/if}
			</ul>
		</div>
	{/if}
	<Button
		size="sm"
		color="success"
		class="mt-3 mb-3"
		block
		disabled={!canActivateAccount || passwordValidationFailed}
		isLoading={isActivatingAccount}
		iconClass="check"
		on:click={() => confirmAccountActivation(userName, pin, newPassword)}>Activate Account</Button
	>
</ResponsiveShadow>
