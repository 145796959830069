export default {
    "name": "WoProductAttachments",
    "kind": "HoudiniQuery",
    "hash": "6dc349427e6ad1e9d7b71d4899d7a5d47379b6f173ecd3de750322e73828891b",

    "raw": `query WoProductAttachments($productId: ID!) {
  product(id: $productId) {
    attachments {
      file {
        path
        type
        id
      }
      id
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "product": {
                "type": "Product",
                "keyRaw": "product(id: $productId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "attachments": {
                            "type": "ProductFile",
                            "keyRaw": "attachments",

                            "selection": {
                                "fields": {
                                    "file": {
                                        "type": "File",
                                        "keyRaw": "file",

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "FileType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "productId": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=5cdeba166b344737b4ea3ed4bda4c2f12f2929abb01942a9fcb0a06d31492157";