export default async (mediator, query, keypath?) => {
	if (typeof query === 'string') {
		query = { query, variables: {} }
	}
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
	const res = await mediator.call('apiFetch', query.query, query.variables || {})
	if (keypath) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		let value = res
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		for (const key of keypath.split('.')) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			value = value?.[key]
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return value
	} else {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return res
	}
}
