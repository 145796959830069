<script lang="ts">
	import type { Option } from 'utility/analysis-management-helper'
	import type { ValueType$options } from '$houdini'
	import type { i18n } from 'types/common'

	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Table from '@isoftdata/svelte-table'
	import { NavTabBar } from '@isoftdata/svelte-nav-tab-bar'

	import MightyMorphingInput from 'components/MightyMorphingInput.svelte'

	import { graphql } from '$houdini'
	import { createEventDispatcher, getContext } from 'svelte'
	import { SqlEditor } from '@isoftdata/svelte-code-editor'
	import { stringToBoolean } from '@isoftdata/utility-string'

	export let analysisOptions: Array<Option>
	export let recipesMode = false

	let defaultValue: string = ''
	let defaultType: Option['defaultType'] = 'FIXED'
	let show = false
	let selectedOption: Option | undefined = undefined
	/** Options that aren't the one being edited*/
	let suggestedOptions: Array<Pick<Option, 'id' | 'option' | 'valueType' | 'defaultValue'> & { choices: Array<string> }> = []
	let errorMessage = ''
	let errorDetail = ''
	let testResult = ''
	let isLoading = false
	let selectedTab: 'mysql' | 'options' = 'options'
	let confirmButtonIsLoading = false
	let defaultVerified = false
	let testValues: Record<string, string> = {}
	let fullscreen = false

	$: isFixed = defaultType === 'FIXED' || defaultType === 'PER_BATCH'
	$: tokensInQuery = new Set(Array.from(defaultValue.matchAll(/{\?([^\{\}]+)}/g)).map(val => val[1]) || [])
	$: nonOptionTokens = Array.from(tokensInQuery)
		.filter(token => !suggestedOptions.some(option => option.option === token))
		.map(
			(
				token,
			): {
				option: string
				valueType: ValueType$options
				choices: Array<string>
				defaultValue: string | null
				testValue: string
			} => {
				return {
					option: token,
					valueType: 'TEXT',
					choices: [],
					defaultValue: null,
					testValue: testValues[token] || '',
				}
			},
		)
	$: allTokens = isFixed
		? []
		: suggestedOptions
				.map(option => {
					return {
						option: option.option,
						valueType: option.valueType,
						choices: option.choices,
						defaultValue: option.defaultValue,
						testValue: testValues[option.option] || '',
					}
				})
				.concat(nonOptionTokens)
	$: formattedQuery = formatQuery(defaultValue, allTokens)

	// Maybe want to break these out into categories or something, or add more to the list later
	const sqlKeywords = Object.freeze(['SELECT', 'FROM', 'WHERE', 'AND', 'OR', 'NOT', 'IN', 'LIKE', 'IS', 'NULL', 'NOT NULL', 'LEFT JOIN', 'JOIN', 'GROUP BY', 'ORDER BY', 'ASC', 'DESC'])
	const dispatch = createEventDispatcher<{
		confirm: {
			defaultValue: string
			defaultType: Option['defaultType']
		}
	}>()
	const { t: translate } = getContext<i18n>('i18next')

	export function open(option: Option) {
		selectedOption = option
		defaultValue = option.defaultValue ?? ''
		defaultType = option.defaultType
		suggestedOptions = analysisOptions
			.filter(o => o.uuid !== option.uuid && !!o.option && o.active)
			.map(({ id, option, valueType, defaultValue, choices }) => ({
				id,
				option,
				valueType,
				defaultValue,
				choices: choices && valueType === 'CHOICE' ? choices.map(choice => choice.choice).filter(Boolean) : [],
			}))
		errorMessage = ''
		errorDetail = ''
		testResult = ''
		show = true
	}

	async function confirmDefaultValue() {
		if (!defaultVerified && !isFixed) {
			confirmButtonIsLoading = true
			await testDefaultValue(defaultValue)
			confirmButtonIsLoading = false
		}
		if (isFixed || defaultVerified || confirm(translate('defaultOptionValues.confirmErrorVerifyingFormula', 'There was an error verifying the default value. Use formula despite error?'))) {
			dispatch('confirm', { defaultValue, defaultType })
			show = false
		}
	}

	async function testDefaultValue(defaultValue: string) {
		if (!selectedOption || isFixed) {
			errorMessage = ''
			errorDetail = ''
			return
		}

		if (!defaultValue && !isFixed) {
			errorMessage = translate('defaultOptionValues.defaultValueRequiredError', 'Default Value Required')
			errorDetail = translate('defaultOptionValues.defaultValueRequiredDetail', 'Please enter a value, or change this default value to a fixed value.')
			defaultVerified = false
			return
		}

		function transformResult(result: string) {
			try {
				result = JSON.parse(result)[0].test_result_1
			} catch (err: any) {
				console.error(err)
			}

			if (selectedOption?.valueType === 'BOOLEAN') {
				return stringToBoolean(result) ? 'True' : 'False'
			} else if (result === null) {
				return 'NULL'
			}

			return result
		}

		testResult = ''
		isLoading = true
		errorMessage = ''
		errorDetail = ''

		try {
			const { data } = await testQuery.fetch({ variables: { query: formattedQuery } })

			testResult = data ? transformResult(data.testSelectQuery) : ''
			isLoading = false
			errorMessage = ''
			errorDetail = ''
			defaultVerified = true
		} catch (err: any) {
			console.error(err)
			testResult = ''
			errorMessage = err.message
			errorDetail = `${err.cause.extensions.originalError.sqlMessage}`
			isLoading = false
			defaultVerified = false
		}
	}

	/** Escape any characters in the option name that might interfere with the regex*/
	function escapeRegex(str: string) {
		return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
	}

	function formatQuery(defaultValue: string, tokens: typeof allTokens) {
		if (!tokens?.length) {
			return ''
		}

		// Replace all {?option} with the testValue or defaultValue
		for (const token of tokens) {
			// These are both strings, so it shouldn't use defaultValue if testValue is '0' or 'false'
			const replacementValue = `'${token.testValue || (token.defaultValue && isFixed ? token.defaultValue : '')}'` // TODO maybe don't escape defaultValue if it's a formula?
			defaultValue = defaultValue.replace(new RegExp(`\\{\\?${escapeRegex(token.option)}\\}`, 'g'), replacementValue)
		}

		// turn the rest of the tokens into NULL
		defaultValue = defaultValue.replaceAll(/{\?([^\{\}]+)}/g, "''")
		return `SELECT ${defaultValue} AS test_result_1`
	}

	function insertKeyword(keyword: string) {
		defaultValue += `${defaultValue ? ' ' : ''}${keyword}`
	}

	const testQuery = graphql(`
		query AmTestDefaultValue($query: String!) {
			testSelectQuery(query: $query)
		}
	`)
</script>

<Modal
	bind:show
	modalSize="xl"
	title={translate('defaultOptionValues.title', 'Edit/Test Default Option Value')}
	backdropClickCancels={false}
	subtitle={selectedOption?.option}
	confirmButtonText={isFixed || defaultVerified ? translate('common:confirm', 'Confirm') : translate('defaultOptionValues.verifyAndConfirm', 'Verify & Confirm')}
	confirmButtonIcon="check"
	confirmButtonDisabled={isFixed && defaultValue.length > 100}
	on:confirm={confirmDefaultValue}
	on:close={() => (show = false)}
>
	<div class:card-group={!fullscreen}>
		<div
			class="card"
			class:d-none={fullscreen}
		>
			<div class="card-header">
				<h6>
					{translate('defaultOptionValues.insertableValuesTitle', 'Insertable Values')}
					<small class="card-subtitle"
						>{#if defaultType === 'FIXED'}{translate('defaultOptionValues.onlyCalculatedValuesHint', '(only for calculated values)')}{:else}{translate(
								'defaultOptionValues.clickToInsertHint',
								'(click to insert)',
							)}{/if}</small
					>
				</h6>
				<NavTabBar
					tabs={[
						{ title: translate('defaultOptionValues.optionsTabLabel', 'Options'), name: 'options' },
						{ title: translate('defaultOptionValues.mysqlTabLabel', 'MySQL'), name: 'mysql' },
					]}
					bind:selectedTab
				></NavTabBar>
			</div>
			<div class="card-body overflow-y-auto p-0 mh-60vh">
				<ul class="list-group list-group-flush">
					{#if selectedTab === 'mysql'}
						{#each sqlKeywords as keyword}
							<button
								class="list-group-item list-group-item-action"
								class:list-group-item-secondary={defaultType === 'FIXED'}
								disabled={defaultType === 'FIXED'}
								on:click={() => insertKeyword(keyword)}>{keyword}</button
							>
						{/each}
					{:else if selectedTab === 'options'}
						{#each suggestedOptions as option}
							<button
								class="list-group-item list-group-item-action"
								class:list-group-item-secondary={defaultType === 'FIXED'}
								disabled={defaultType === 'FIXED'}
								on:click={() => insertKeyword(`{?${option.option}}`)}>{option.option}</button
							>
						{:else}
							<li class="list-group-item">{translate('defaultOptionValues.noOptionsPlaceholder', 'No options available')}</li>
						{/each}
					{/if}
				</ul>
			</div>
		</div>
		<div
			class="card"
			style="flex-grow: 1.25"
		>
			<div class="card-header d-flex justify-content-between">
				<h6 class="pb-1">{translate('defaultOptionValues.defaultValueTitle', 'Default Value')}</h6>
				<div>
					<Button
						outline
						size="xs"
						iconClass={fullscreen ? 'compress' : 'expand'}
						on:click={() => (fullscreen = !fullscreen)}>{fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}</Button
					>
				</div>
			</div>
			<div
				class="card-body p-0 d-flex flex-column flex-grow-1 overflow-y-auto"
				style="resize: vertical; height: 400px"
			>
				{#if isFixed}
					<textarea
						class="form-control border-0 w-100 flex-grow-1"
						style="min-height: 200px;"
						spellcheck="false"
						maxlength={100}
						bind:value={defaultValue}
						on:change={() => (defaultVerified = false)}
					></textarea>
				{:else}
					<SqlEditor
						bind:value={defaultValue}
						on:change={() => (defaultVerified = false)}
					></SqlEditor>
				{/if}
			</div>
			<h6
				class="card-header border-top"
				class:d-none={defaultType === 'FIXED'}
			>
				{translate('defaultOptionValues.formattedQueryTitle', 'Query with Test Values')}
			</h6>
			<div
				class="card-body p-0"
				class:d-none={defaultType === 'FIXED'}
				style="min-height: 75px; height: 75px; overflow-y: auto; resize: vertical;"
			>
				{#if formattedQuery}
					<SqlEditor
						readonly
						value={formattedQuery}
					></SqlEditor>
				{/if}
			</div>
		</div>
		<div
			class="card"
			class:d-none={fullscreen}
		>
			<h6 class="card-header pb-4">{translate('defaultOptionValues.testDefaultValueTitle', 'Test Default Value')}</h6>
			<div class="card-body">
				<Table
					responsive
					rows={allTokens.filter(token => tokensInQuery.has(token.option))}
					columns={[
						{
							property: 'option',
							name: translate('defaultOptionValues.tokenColumnName', 'Token'),
							title: translate('defaultOptionValues.tokenColumnTitle', 'The name of other options or tokens that are referenced in the formula'),
						},
						{
							property: 'testValue',
							name: translate('defaultOptionValues.testValueColumnName', 'Test Value'),
							title: translate('defaultOptionValues.testValueColumnTitle', '(Optional) A specific value to test the current value in the formula with'),
						},
					]}
					idProp="option"
					parentClass="mh-50vh"
					let:row
				>
					{@const optionInQuery = tokensInQuery.has(row.option)}
					<tr>
						<td>{row.option}</td>
						<td>
							<MightyMorphingInput
								showLabel={false}
								valueType={row.valueType}
								required={optionInQuery}
								placeholder={row.defaultValue && isFixed ? row.defaultValue : undefined}
								validation={{
									validator: value => (value || defaultValue ? true : ''),
									value: testValues[row.option],
								}}
								choices={row.choices}
								disabled={!optionInQuery}
								value={testValues[row.option]}
								on:change={event => {
									testValues[row.option] = event.detail.value
								}}
							></MightyMorphingInput>
						</td>
					</tr>
					<svelte:fragment slot="no-rows">
						{#if isFixed}
							<tr>
								<td
									colspan="2"
									class="text-center"
								>
									{translate('defaultOptionValues.fixedValueNoRowsMessage', 'Testing options for calculated values only')}
								</td>
							</tr>
						{:else}
							<tr>
								<td
									colspan="2"
									class="text-center">{translate('defaultOptionValues.noOptionsNoRowsMessage', 'After inserting an option, you can enter a test value here.')}</td
								>
							</tr>
						{/if}
					</svelte:fragment>
				</Table>

				<div class="d-flex justify-content-end">
					<Button
						outline
						size="sm"
						class="mt-2"
						iconClass="check"
						{isLoading}
						disabled={!defaultValue || isFixed}
						on:click={() => testDefaultValue(defaultValue)}>{translate('defaultOptionValues.testValueButton', 'Test Value')}</Button
					>
				</div>
			</div>
			<div class="card-header border-top d-flex justify-content-between">
				<h6 class="mt-auto mb-auto">{translate('defaultOptionValues.evaluationResultsTitle', 'Evaluation Results')}</h6>
			</div>
			<div
				class="card-body overflow-auto"
				style={errorMessage ? '' : 'height: 75px; max-height: 75px; overflow-y: auto;'}
			>
				{#if testResult}
					{testResult}
				{:else if isLoading}
					{translate('common:loading', 'Loading')}...
				{/if}
				{#if errorMessage}
					<div
						class="alert alert-danger mb-0"
						role="alert"
					>
						<h4 class="alert-heading">{errorMessage}</h4>
						<p>{errorDetail}</p>
					</div>
				{/if}
			</div>
		</div>
	</div>
	<div
		class="d-flex"
		slot="modalFooter"
	>
		<div class="btn-group btn-group-toggle m-1">
			<label
				class="btn cursor-pointer btn-outline-primary"
				class:active={defaultType === 'FIXED'}
			>
				<input
					type="radio"
					name="defaultType"
					value="FIXED"
					bind:group={defaultType}
				/>
				{translate('defaultOptionValues.fixedRadioLabel', 'Fixed Value')}
			</label>
			{#if recipesMode}
				<label
					class="btn cursor-pointer btn-outline-primary"
					class:active={defaultType === 'PER_BATCH'}
				>
					<input
						type="radio"
						name="defaultType"
						value="PER_BATCH"
						bind:group={defaultType}
					/>
					{translate('defaultOptionValues.perBatchRadioLabel', 'Per-Batch Value')}
				</label>
			{/if}
			<label
				class="btn cursor-pointer btn-outline-primary"
				class:active={defaultType === 'SQL'}
			>
				<input
					type="radio"
					name="defaultType"
					value="SQL"
					bind:group={defaultType}
				/>
				{translate('defaultOptionValues.sqlRadioLabel', 'Calculated Value')}
			</label>
			<label
				class="btn cursor-pointer btn-outline-primary"
				class:active={defaultType === 'STATIC_SQL'}
			>
				<input
					type="radio"
					name="defaultType"
					value="STATIC_SQL"
					bind:group={defaultType}
				/>
				{translate('defaultOptionValues.staticSqlRadioLabel', 'Calculate Once')}
			</label>
		</div>
		{#if isFixed && defaultValue.length > 100}
			<div class="text-danger align-content-around ml-2">{translate('defaultOptionValues.defaultTooLongWarning', 'Value must be less than 100 characters')}</div>
		{/if}
	</div>
</Modal>
