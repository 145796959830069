import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetDocumentsByAssociation'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetDocumentsByAssociationStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetDocumentsByAssociationStore",
			variables: true,
		})
	}
}

export async function load_GetDocumentsByAssociation(params) {
	await initClient()

	const store = new GetDocumentsByAssociationStore()

	await store.fetch(params)

	return {
		GetDocumentsByAssociation: store,
	}
}
