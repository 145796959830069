const isBrowser = true;
let clientStarted = true;
let isPrerender = false;
const error = (code, message) => message;
const redirect = (status, location) => {
};
function setClientStarted() {
  clientStarted = true;
}
export {
  clientStarted,
  error,
  isBrowser,
  isPrerender,
  redirect,
  setClientStarted
};
