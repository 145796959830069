import type { ResultStatus$options, Acceptability$options, BoundaryType$options } from '$houdini'
import type { IconName } from '@fortawesome/fontawesome-common-types'
import type { ButtonColors } from '@isoftdata/utility-bootstrap'

export default new Map<AcceptabilityOption, AcceptabilityObject>([
	[
		'ALLOWED',
		{
			label: 'Acceptable',
			colorClass: 'success',
			buttonIcon: 'fa-check',
			icon: 'check',
			graphValue: 'Allowed',
		},
	],
	[
		'WARNING',
		{
			label: 'Marginal',
			colorClass: 'warning',
			buttonIcon: 'fa-exclamation',
			icon: 'exclamation',
			graphValue: 'Warning',
		},
	],
	[
		'ERROR',
		{
			label: 'Unacceptable',
			colorClass: 'danger',
			buttonIcon: 'fa-exclamation-triangle',
			icon: 'exclamation-triangle',
			graphValue: 'Error',
		},
	],
	[
		'OUT_OF_BOUNDS',
		{
			label: 'Invalid',
			colorClass: 'info',
			buttonIcon: 'fa-ban',
			icon: 'ban',
			graphValue: 'Out of Bounds',
		},
	],
	[
		'NOT_CALCULATED',
		{
			label: 'Not Calculated',
			colorClass: 'secondary',
			buttonIcon: '',
			icon: undefined,
			graphValue: 'Not Calculated',
		},
	],
])

export const acceptabilityToResultStatus: Record<Acceptability$options, ResultStatus$options> = {
	ALLOWED: 'ALLOWED',
	NOT_CALCULATED: 'NOT_CALCULATED',
	INVALID: 'OUT_OF_BOUNDS',
	MARGINAL: 'WARNING',
	UNACCEPTABLE: 'ERROR',
}

export const boundaryTypeToResultStatus: Record<BoundaryType$options, ResultStatus$options> = {
	ALLOWED: 'ALLOWED',
	BOUNDARY: 'OUT_OF_BOUNDS',
	MARGINAL: 'WARNING',
	UNACCEPTABLE: 'ERROR',
}

type AcceptabilityOption = ResultStatus$options

// TODO - buttonIcon is used on sampling history, which still expects the fa- prefix. If we move that to Svelte, remove buttonIcon from this object
type AcceptabilityObject = {
	label: string
	colorClass: ButtonColors
	buttonIcon: string
	icon?: IconName
	graphValue: string
}
