export default {
    "name": "AnalysisManagementData",
    "kind": "HoudiniQuery",
    "hash": "0ccf19af817c89b1dd4c9725c17fee7991789f2fb8190acf262b9024d79900ba",

    "raw": `query AnalysisManagementData($analysesFilter: AnalysisFilter, $entityTagFilter: EntityTagFilter!, $productsFilter: ProductFilter, $severityClassFilter: SeverityClassFilter, $viewAsPlantId: ID!, $pagination: PaginatedInput) {
  analyses(filter: $analysesFilter, pagination: $pagination) {
    data {
      ...AnalysisReturnData
      sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
      testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
      options {
        id
        option
      }
      id
    }
  }
  categories: analysisCategories
  groups(pagination: $pagination) {
    data {
      name
      id
    }
  }
  plants(pagination: $pagination) {
    data {
      id
      name
      code
    }
  }
  tags: entityTags(filter: $entityTagFilter) {
    active
    entityType
    id
    name
  }
  products(filter: $productsFilter, pagination: $pagination) {
    data {
      id
      name
      active
      category
      productType
      inUseAtPlants {
        id
      }
    }
  }
  severityClasses(filter: $severityClassFilter, pagination: $pagination) {
    data {
      id
      name
      description
      default
      plantId
    }
  }
}

fragment AnalysisReturnData on Analysis {
  id
  analysisType
  name
  active
  testingPeriod
  category
  visibleGroup {
    id
    name
  }
  groupSamples
  requireAuthentication
  instructions
  createdProduct {
    id
    name
  }
  batchVolume
  batchUnit
  inUseAtPlantIDs
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $analysesFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "analysisType": {
                                        "type": "AnalysisType",
                                        "keyRaw": "analysisType",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "testingPeriod": {
                                        "type": "Float",
                                        "keyRaw": "testingPeriod",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "visibleGroup": {
                                        "type": "Group",
                                        "keyRaw": "visibleGroup",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "groupSamples": {
                                        "type": "Boolean",
                                        "keyRaw": "groupSamples",
                                        "visible": true
                                    },

                                    "requireAuthentication": {
                                        "type": "Boolean",
                                        "keyRaw": "requireAuthentication",
                                        "visible": true
                                    },

                                    "instructions": {
                                        "type": "String",
                                        "keyRaw": "instructions",
                                        "visible": true
                                    },

                                    "createdProduct": {
                                        "type": "Product",
                                        "keyRaw": "createdProduct",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "batchVolume": {
                                        "type": "Float",
                                        "keyRaw": "batchVolume",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "batchUnit": {
                                        "type": "String",
                                        "keyRaw": "batchUnit",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    },

                                    "sampleTagPrintQuantity": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)",
                                        "visible": true
                                    },

                                    "testingTagPrintQuantity": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)",
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "AnalysisReturnData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "categories": {
                "type": "String",
                "keyRaw": "categories",
                "visible": true
            },

            "groups": {
                "type": "GroupResponse",
                "keyRaw": "groups(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Group",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "tags": {
                "type": "EntityTag",
                "keyRaw": "tags(filter: $entityTagFilter)",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityTagType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "products": {
                "type": "ProductResponse",
                "keyRaw": "products(filter: $productsFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Product",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productType": {
                                        "type": "ProductType",
                                        "keyRaw": "productType",
                                        "visible": true
                                    },

                                    "inUseAtPlants": {
                                        "type": "Plant",
                                        "keyRaw": "inUseAtPlants",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "severityClasses": {
                "type": "SeverityClassResponse",
                "keyRaw": "severityClasses(filter: $severityClassFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "SeverityClass",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "default": {
                                        "type": "Boolean",
                                        "keyRaw": "default",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "analysesFilter": "AnalysisFilter",
            "entityTagFilter": "EntityTagFilter",
            "productsFilter": "ProductFilter",
            "severityClassFilter": "SeverityClassFilter",
            "viewAsPlantId": "ID",
            "pagination": "PaginatedInput"
        },

        "types": {
            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            },

            "EntityTagFilter": {
                "active": "Boolean",
                "entityTypes": "EntityTagType",
                "ids": "PositiveInt"
            },

            "ProductFilter": {
                "active": "Boolean",
                "activeOnly": "Boolean",
                "plantIds": "PositiveInt",
                "plantIdsFilteringByChildren": "PositiveInt",
                "plants": "PositiveInt",
                "productName": "NonEmptyString",
                "productType": "ProductType"
            },

            "SeverityClassFilter": {
                "plantIds": "PositiveInt",
                "severityClassName": "NonEmptyString"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6679c24a9b60fad7d08643fea8e611f6c979339a914d3c97c8b14f0727aedeb9";