export default {
    "name": "AnalysisReturnData",
    "kind": "HoudiniFragment",
    "hash": "c13f05f975e70042a202b9688e7f342aace2a74cdf6d9ed89bee31ee80a09365",

    "raw": `fragment AnalysisReturnData on Analysis {
  id
  analysisType
  name
  active
  testingPeriod
  category
  visibleGroup {
    id
    name
  }
  groupSamples
  requireAuthentication
  instructions
  createdProduct {
    id
    name
  }
  batchVolume
  batchUnit
  inUseAtPlantIDs
  __typename
}
`,

    "rootType": "Analysis",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "analysisType": {
                "type": "AnalysisType",
                "keyRaw": "analysisType",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "testingPeriod": {
                "type": "Float",
                "keyRaw": "testingPeriod",
                "visible": true
            },

            "category": {
                "type": "String",
                "keyRaw": "category",
                "visible": true
            },

            "visibleGroup": {
                "type": "Group",
                "keyRaw": "visibleGroup",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "groupSamples": {
                "type": "Boolean",
                "keyRaw": "groupSamples",
                "visible": true
            },

            "requireAuthentication": {
                "type": "Boolean",
                "keyRaw": "requireAuthentication",
                "visible": true
            },

            "instructions": {
                "type": "String",
                "keyRaw": "instructions",
                "visible": true
            },

            "createdProduct": {
                "type": "Product",
                "keyRaw": "createdProduct",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "batchVolume": {
                "type": "Float",
                "keyRaw": "batchVolume",
                "nullable": true,
                "visible": true
            },

            "batchUnit": {
                "type": "String",
                "keyRaw": "batchUnit",
                "visible": true
            },

            "inUseAtPlantIDs": {
                "type": "PositiveInt",
                "keyRaw": "inUseAtPlantIDs",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=596c7202aef6116d9d196bc5cdffaf17094e6e985c70627f72593917ebc00e1e";