// If you are converting Sampling History to Svelte, this function has already been converted in utility/analysis-management-helper.ts
export default function filterAnalysesAndOptions(filter) {
	const ractive = this
	const filterEnabled = ractive.get('filterEnabled')
	const columnProps = ractive.get('columns').map(({ property }) => property)
	const filterProps = ractive.get('filterProps')
	// Just search option name for now, maybe more later.
	const optionsProps = [ 'option' ]
	let rows = ractive.get('rows')

	if (filterEnabled && filter) {
		let props = filterProps ? filterProps : columnProps

		rows = rows.filter(row => {
			const analysisMatches = props
				.filter(key => {
					if (typeof ractive.getNestedProperty(row, key) === 'string' || typeof ractive.getNestedProperty(row, key) === 'number') {
						return ractive.getNestedProperty(row, key)
							.toString()
							.toUpperCase()
							.indexOf(filter.toUpperCase()) > -1
					}
					return false
				})
			// Also string match the options and return true if at least one option matches the filter.
			const optionMatches = optionsProps
				.filter(key => {
					if (row.options?.length) {
						return row.options
							.some(option => {
								if (typeof option[key] === 'string' || typeof option[key] === 'number') {
									return option[key]
										.toString()
										.toUpperCase()
										.indexOf(filter.toUpperCase()) > -1
								}
								return false
							})
					}
					return false
				})
			return analysisMatches.length > 0 || optionMatches.length > 0
		})
	}

	return rows
}
