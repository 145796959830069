export default {
    "name": "DeleteAlertSubscriptions",
    "kind": "HoudiniMutation",
    "hash": "f52ca75e907a6bf587c104b56d70470cbc07d7366463fa93a7f483282e0a870c",

    "raw": `mutation DeleteAlertSubscriptions($ids: [PositiveInt!]!) {
  deleteAlertSubscriptions(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteAlertSubscriptions": {
                "type": "Void",
                "keyRaw": "deleteAlertSubscriptions(ids: $ids)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "ids": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=e44fc46f523d0d8a06a5eec3eaebd682cfa10c61cd7cdb90f433124b5a1d3089";