export default {
    "name": "Locations",
    "kind": "HoudiniQuery",
    "hash": "0eb01fa68987c4b2b9a53fdbd98757c5a63d76c0c48a1bf9aac11b6c5e415010",

    "raw": `query Locations($filter: LocationFilter, $pagination: PaginatedInput) {
  locations(filter: $filter, pagination: $pagination) {
    data {
      id
      plantId
      code
      location
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "locations": {
                "type": "LocationResponse",
                "keyRaw": "locations(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Location",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "String",
                                        "keyRaw": "location",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "LocationFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "ProcessZoneFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "ProductProximityFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "LocationFilter": {
                "activeOnly": "Boolean",
                "location": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "processZone": "ProcessZoneFilter",
                "productProximity": "ProductProximityFilter",
                "testableOnly": "Boolean"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=7bc71f02131c20f2acef332a4ec7cda735e166546ec608e5e6b0160a5dddbc63";