export default {
    "name": "DeleteGroups",
    "kind": "HoudiniMutation",
    "hash": "59b4179a8cf7e12709778a7509df73d7daf1c6f592b7ec12f6e881abc479a0e1",

    "raw": `mutation DeleteGroups($ids: [PositiveInt!]!) {
  deleteGroups(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteGroups": {
                "type": "Boolean",
                "keyRaw": "deleteGroups(ids: $ids)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "ids": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=8d300681e4d8ba2c608919edf58b61eb5eb0a70f842abd1c3304276d1ecc2e28";