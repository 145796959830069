import template from './sampling-history.ractive.html'
import * as dateTimeUtility from '@isoftdata/utility-date-time'
import loadForPlant from 'utility/load-for-plant'
import groupByPlant from 'utility/group-by-plant'
import { klona } from 'klona'
import { reduceToTruthyValues as originalReduceToTruthyValues } from '@isoftdata/utility-object'
import { v4 as uuid } from '@lukeed/uuid'
import valueAcceptabilityMap from 'utility/value-acceptability-map'
import inverseValueAcceptabilityMap from 'utility/inverse-value-acceptability-map'
import { formatISO, addDays, differenceInDays, differenceInWeeks } from 'date-fns'
import { buildUrl } from '@isoftdata/utility-url'
const formCtrlOpts = { twoway: true, lazy: false }
import { stringToBoolean } from '@isoftdata/utility-string'
import camelCase from 'just-camel-case'
import { locationsQuery } from 'utility/graphql-queries'
import filterAnalysesAndChildren from 'utility/filter-analyses-and-options'
import { getSession } from 'stores/session'

const gqlPagninationAllPages = { pagination: { pageSize: 0, pageNumber: 1 } }

//Ractive components
import makeItInput from '@isoftdata/input'
import makeItSelect from '@isoftdata/select'
import makeItSelectNoTwoWay from '@isoftdata/select'
import makeItModal from '@isoftdata/modal'
import makeItTable from '@isoftdata/table'
import makeAddAnalysesTable from '@isoftdata/table'
import makeItCheckbox from '@isoftdata/checkbox'
import makeItButton from '@isoftdata/button'
import makeItSplitButton from '@isoftdata/split-button'
import makeItDateRange from '@isoftdata/date-range'
import makeItTextarea from '@isoftdata/textarea'
import makeItCollapsibleCard from '@isoftdata/collapsible-card'
import makeItNavTabBar from '@isoftdata/nav-tab-bar-component'
import makeWildSelect from '../../components/wild-select'

//Ractive transistions
import ractiveTransitionsSlide from 'ractive-transitions-slide'

async function loadListsForPlants(previousPlants, plants, ractive) {
	return await Promise.all([
		ractive.loadLocationsForPlants(previousPlants, plants),
		ractive.loadProximitiesForPlants(previousPlants, plants),
		ractive.loadProcessZonesForPlants(previousPlants, plants),
		ractive.loadProductsForPlants(previousPlants, plants),
		ractive.loadAnalysesForPlants(previousPlants, plants),
		ractive.loadWorkOrderTypesForPlants(previousPlants, plants),
		ractive.loadProductBatchesForPlants(previousPlants, plants),
	])
}

function reduceToTruthyValues(object) {
	let obj = originalReduceToTruthyValues({ object, options: { onlyNoNullish: true, objectRecursive: true } })

	//TODO remove this by adding a feature to reduceToTruthyValues to allow for treating empty strings as falsy, in additional to nullish.
	return Object.entries(obj).reduce((acc, [ key, value ]) => {
		if (typeof value === 'string' && !value) {
			return acc
		} else {
			return {
				...acc,
				[key]: value,
			}
		}
	}, {})
}

const savedSampleSearchFields = `
	id
	createdOn
	name
	description
	scope
	plant {
		id
		code
	}
	createdByUser {
		id
		fullName
	}
`

const defaultSavedSearchModalState = Object.freeze({
	data: {
		id: '',
		name: '',
		description: '',
		scope: 'USER',
		search: '',
	},
	show: false,
	replace: false,
	saveError: '',
	title: 'Manage Saved Search',
})

const productBatchesQuery = {
	query: `#graphql
		query ProductBatches($filter: ProductBatchFilter, $pagination: PaginatedInput) {
			productBatches(filter: $filter, pagination: $pagination) {
				data {
					id
					name
				}
			}
		}
	`,
	variables: {
		filter: {
			statuses: 'ACTIVE',
		},
		...gqlPagninationAllPages,
	},
}

const workOrderTypesQuery = {
	query: `#graphql
		query WorkOrderTypes($filter: WorkOrderTypeFilter, $pagination: PaginatedInput) {
			workOrderTypes(filter: $filter, pagination: $pagination) {
				data {
					id
					name
				}
			}
		}
	`,
	variables: { filter: {}, ...gqlPagninationAllPages },
}

const filterAnalysisOptionsToActiveOnly = analysis => {
	return {
		...analysis,
		options: analysis.options.filter(option => option.active),
	}
}

const analysesQuery = {
	query: `#graphql
		query ($filter: AnalysisFilter, $pagination: PaginatedInput) {
			analyses(filter: $filter, pagination: $pagination) {
				data {
					id
					active
					analysisType
					name
					category
					options {
							id
							active
							option
							valueType
							unit
							defaultValue
							choices {
								id
								choice
							}
					}
				}
			}
		}
	`,
	variables: { filter: {}, ...gqlPagninationAllPages }, //TODO maybe make active a server side filter?
}

const searchFilters = Object.freeze({
	fromSavedSearch: (savedSearch, ractive) => {
		let selectedFilters = {}

		if (savedSearch.dateRange) {
			const today = new Date()
			const offsets = {
				valueFrom: savedSearch.dateRange.valueFrom,
				valueTo: savedSearch.dateRange.valueTo,
			}
			selectedFilters.dates = {
				from: typeof offsets.valueFrom === 'number' ? formatISO(addDays(today, offsets.valueFrom), { representation: 'date' }) : null,
				to: typeof offsets.valueTo === 'number' ? formatISO(addDays(today, offsets.valueTo), { representation: 'date' }) : null,
			}
			selectedFilters.dateRangeType = {
				created: savedSearch.dateRange.type === 'CREATED',
				performed: savedSearch.dateRange.type === 'PERFORMED',
			}

			selectedFilters.range = dateTimeUtility.rangeFromDates(selectedFilters.dates.from, selectedFilters.dates.to) || 'Custom'
		} else {
			selectedFilters.dates = { from: null, to: null }
			selectedFilters.range = 'Always'
		}

		// Load Analyses and Option Values
		let selectedAnalysisList = []
		if (savedSearch.analyses?.length) {
			const analysisList = ractive.get('analysisList')
			selectedAnalysisList = savedSearch.analyses.map(analysis => {
				const foundAnalysis = analysisList.find(listAnalysis => listAnalysis.id === analysis.id) || {}
				// only show options that are selected in the saved search
				// This might change in a future version of the API so we don't have to match options to each analysis
				if (foundAnalysis?.options) {
					foundAnalysis.options = foundAnalysis.options
						.reduce((acc, option) => {
							const searchOption = analysis?.options?.find(searchOption => searchOption.id === option.id)
							if (searchOption) {
							// apparently sometimes you need to do valueFilter, not from or to. This is just one case, there are probably more.
								if ([ 'BOOLEAN', 'CHOICE' ].includes(option.valueType)) {
									option.valueFilter = searchOption.valueFrom ?? searchOption.valueTo
									searchOption.valueFrom = undefined
									searchOption.valueTo = undefined
								} else {
									searchOption.valueFrom = optionValueTypes[option.valueType].parse(searchOption.valueFrom)
									searchOption.valueTo = optionValueTypes[option.valueType].parse(searchOption.valueTo)
								}

								// Some of these values are PascalCase from the API, they might change and break this at some point, though.
								acc.push(reduceToTruthyValues({
									...option,
									valueFilterFrom: searchOption.valueFrom,
									valueFilterTo: searchOption.valueTo,
									selected: true,
									lotNumber: searchOption.lotNumber,
									resultStatus: inverseValueAcceptabilityMap.get(searchOption.resultStatus) ?? '',
									expirationFilterFrom: searchOption.expirationFrom ? formatISO(addDays(new Date(), searchOption.expirationFrom), { representation: 'date' }) : null,
									expirationFilterTo: searchOption.expirationTo ? formatISO(addDays(new Date(), searchOption.expirationTo), { representation: 'date' }) : null,
								}))
							}
							return acc
						}, [])
					return { ...foundAnalysis, resultStatus: analysis.resultStatus }
				}
			})
		}

		selectedFilters = reduceToTruthyValues({
			...selectedFilters,
			analysisList: selectedAnalysisList,
			selectedPlants: savedSearch.plantIds,
			samplingComments: savedSearch.samplingComments,
			testingComments: savedSearch.testingComments,
			workOrder: {
				id: savedSearch.workOrderNumber, //TODO change to workOrderId
				title: savedSearch.workOrderTitle,
				typeId: savedSearch.workOrderTypeId,
				typeName: savedSearch.workOrderTypeName,
				productBatch: savedSearch.productBatchName,
				internalNotes: savedSearch.workOrderInternalNotes,
			},
			tagNumber: savedSearch.tagNumber,
			productId: savedSearch.productId,
			productName: savedSearch.productName,
			processZoneId: savedSearch.processZoneId,
			processZoneName: savedSearch.processZoneName,
			productProximityId: savedSearch.productProximityId,
			productProximityName: savedSearch.productProximityName,
			performedByUserId: savedSearch.performedByUserId,
			performedByUserName: savedSearch.performedByUserName,
			locationName: savedSearch.locationName,
			sampleStatuses: savedSearch.sampleStatuses,
			scheduleName: savedSearch.scheduleName,
			matchAllOptionFilters: savedSearch.matchAllOptionFilters,
			isRetest: savedSearch.isRetest,
		})

		if (!Object.keys(selectedFilters.workOrder).length) {
			delete selectedFilters.workOrder
		}

		return klona({
			...ractive.get('defaultSelectedFilters'),
			...selectedFilters,
		})
	},
	toSavedSearch: searchFilters => {
		let dateRange
		if (searchFilters?.dates?.from || searchFilters?.dates?.to) {
			dateRange = {
				from: searchFilters.dates.from ? differenceInDays(new Date(searchFilters.dates.from), new Date()) : undefined,
				to: searchFilters.dates.to ? differenceInDays(new Date(searchFilters.dates.to), new Date()) : undefined,
				type: searchFilters.dateRangeType.created ? 'CREATED' : 'PERFORMED',
			}
		}

		const analyses = searchFilters.analysisList?.map?.(analysis => {
			return {
				id: analysis.id,
				resultStatus: analysis.resultStatus,
				options: analysis.options.map(option => {
					return {
						id: option.id,
						expirationDateRange: 'Custom: Relative',
						resultStatus: option.resultStatus || undefined,
						expirationFrom: option.expirationFilterFrom ? differenceInDays(new Date(option.expirationFilterFrom), new Date()) : undefined,
						expirationTo: option.expirationFilterTo ? differenceInDays(new Date(option.expirationFilterTo), new Date()) : undefined,
						lotNumber: option.lotNumber,
						valueFrom: option.valueFilterFrom?.toString?.(),
						valueTo: option.valueFilterTo?.toString?.(),
					}
				}),
			}
		})

		return reduceToTruthyValues(klona({
			dateRange,
			analyses: analyses?.length ? analyses : undefined,
			locationName: searchFilters.locationName,
			plantIds: searchFilters.selectedPlants,
			processZoneId: searchFilters.processZoneId,
			processZoneName: searchFilters.processZoneName,
			productId: searchFilters.productId,
			productName: searchFilters.productName,
			productProximityId: searchFilters.productProximityId,
			productProximityName: searchFilters.productProximityName,
			performedByUserId: searchFilters.performedByUserId,
			performedByUserName: searchFilters.performedByUserName,
			samplingComments: searchFilters.samplingComments,
			tagNumber: searchFilters.tagNumber,
			testingComments: searchFilters.testingComments,
			matchAllOptionFilters: searchFilters.matchAllOptionFilters,
			workOrderNumber: searchFilters.workOrder?.id?.toString?.(),
			workOrderInternalNotes: searchFilters.workOrder?.internalNotes,
			workOrderTitle: searchFilters.workOrder?.title,
			workOrderTypeId: searchFilters.workOrder?.typeId,
			workOrderTypeName: searchFilters.workOrder?.typeName,
			productBatchName: searchFilters.workOrder?.productBatch,
			statuses: searchFilters.sampleStatuses,
			scheduleName: searchFilters.scheduleName,
			isRetest: searchFilters.isRetest,
		}))
	},
})

const shareableScope = new Map([
	[ 'USER', { key: 'USER', show: true, display: 'Just You', iconClass: 'fa-user' }],
	[ 'SITE', { key: 'SITE', show: true, display: 'Your Plant', iconClass: 'fa-industry-windows' }],
	[ 'GLOBAL', { key: 'GLOBAL', show: true, display: 'Everyone', iconClass: 'fa-globe' }],
])

const optionValueTypes = {
	'BOOLEAN': { element: 'select', showToField: false, parse: stringToBoolean },
	'INTEGER': { element: 'input', type: 'number', inputMode: 'numeric', showToField: true, parse: val => val ? parseInt(val, 10) : undefined },
	'NUMBER': { element: 'input', type: 'number', inputMode: 'decimal', showToField: true, parse: val => val ? parseFloat(val) : undefined },
	'CURRENCY': { element: 'input', type: 'number', inputMode: 'decimal', showToField: true, parse: val => val ? parseFloat(val) : undefined },
	'DATE': { element: 'input', type: 'date', showToField: true, parse: val => val }, // TODO parse
	'TEXT': { element: 'input', type: 'text', showToField: false, parse: val => val },
	'CHOICE': { element: 'select', showToField: false, parse: val => val },
	'TIME': { element: 'input', type: 'time', showToField: false, parse: val => val }, // TODO parse
	'DATETIME': { element: 'input', type: 'datetime-local', showToField: true, parse: val => val }, // TODO parse
}

export default ({ mediator, stateRouter, i18next }) => {
	stateRouter.addState({
		name: 'app.sampling-history',
		route: 'sampling-history',
		querystringParameters: [ 'fromDate', 'toDate' ],
		template: {
			template,
			translate: i18next.t,
			decorators: {
			},
			camelCase,
			components: {
				itInput: makeItInput(formCtrlOpts),
				itSelect: makeItSelect(formCtrlOpts),
				itSelectNoTwoWay: makeItSelectNoTwoWay({ twoway: false }),
				itModal: makeItModal(),
				itTable: makeItTable({ stickyHeader: true }),
				addAnalysesTable: makeAddAnalysesTable({ stickyHeader: true, methods: { filter: filterAnalysesAndChildren } }),
				itCheckbox: makeItCheckbox(),
				itButton: makeItButton(),
				itSplitButton: makeItSplitButton(),
				itDateRange: makeItDateRange(),
				itTextarea: makeItTextarea(formCtrlOpts),
				itCollapsibleCard: makeItCollapsibleCard(),
				itNavTabBar: makeItNavTabBar(stateRouter),
				wildSelect: makeWildSelect(),
			},
			transitions: {
				slide: ractiveTransitionsSlide,
			},
			data: {
				transitionsEnabled: false,
			},
			computed: {
				graphUrl() {
					const authToken = this.get('session.authToken')
					const selectedFilters = this.get('selectedFilters')
					const dateKey = selectedFilters.dateRangeType.created ? 'created' : 'performed'

					const graphingParams = {
						plant_id: selectedFilters.selectedPlants,
						location_name: selectedFilters.locationName,
						product_name: selectedFilters.productId ? this.get('productList').find(product => product.id === selectedFilters.productId)?.name : selectedFilters.productName,
						analysis: selectedFilters.analysisList?.length ? JSON.stringify({ analyses: selectedFilters.analysisList.map(analysis => {
							return {
								id: analysis.id,
								status: analysis.resultStatus ? valueAcceptabilityMap.get(analysis.resultStatus)?.graphValue : undefined,
							}
						}) }) : undefined,
						analysis_option: selectedFilters.analysisList?.length ? JSON.stringify({
							options: selectedFilters.analysisList
								.filter(analysis => analysis.options.length)
								.map(analysis => {
									return analysis.options.map(option => {
										return {
											id: option.id,
											status: option.resultStatus ? valueAcceptabilityMap.get(option.resultStatus)?.graphValue : undefined,
											from: option.valueFilterFrom,
											to: option.valueFilterTo,
											filter: option.valueFilter || undefined,
										}
									})
								}).flat(),
						}) : undefined,
						date_type: `date_${dateKey}`,
						start_date: selectedFilters.dates.from ? `${(new Date(`${selectedFilters.dates.from}T00:00:00Z`)).getTime()}` : undefined,
						end_date: selectedFilters.dates.to ? `${(new Date(`${selectedFilters.dates.to}T23:59:59Z`)).getTime()}` : undefined,
						process_zone_id: selectedFilters.processZoneId,
						process_zone_name: selectedFilters.processZoneName,
						product_proximity_id: selectedFilters.productProximityId,
						product_proximity_name: selectedFilters.productProximityName,
						collected_by_id: selectedFilters.performedByUserId,
						collected_by_name: selectedFilters.performedByUserName,
						tag_number: selectedFilters.tagNumber,
						wo_id: selectedFilters.workOrder?.id,
						wo_title: selectedFilters.workOrder?.title,
						wo_type_id: selectedFilters.workOrder?.typeId,
						wo_type_name: selectedFilters.workOrder?.typeName,
						batch_name: selectedFilters.workOrder?.productBatch, //This should always be name
						comments: selectedFilters.samplingComments,
						findings: selectedFilters.testingComments,
						wo_internal_notes: selectedFilters.workOrder?.internalNotes,
						all_filters: selectedFilters.matchAllOptionFilters ? 'true' : 'false',
						schedule_name: selectedFilters.scheduleName,
					}
					console.log('selectedFilters', selectedFilters)
					console.log('graphing params', reduceToTruthyValues(graphingParams))

					return buildUrl(`/dashboard/samplegraph.php`, {
						chart_type: 'scatter1',
						theme: 'large',
						footer: 'hide',
						show_thresholds: false,
						session_token: authToken,
						...reduceToTruthyValues(graphingParams),
					}, '#')
				},
				displaySavedSearchList() {
					const today = new Date()
					const shareableScope = this.get('shareableScope')

					return this.get('savedSearchList').map(savedSearch => {
						const createdOn = new Date(savedSearch.createdOn)
						let displayCreated

						if (differenceInWeeks(today, createdOn) > 1) {
							displayCreated = `on ${createdOn.toLocaleDateString()}`
						} else {
							const relativeTimeFormatter = new Intl.RelativeTimeFormat('en', { style: 'narrow' })
							displayCreated = relativeTimeFormatter.format(differenceInDays(createdOn, today), 'day')
						}

						return {
							...savedSearch,
							createdOn,
							displayCreated,
							sharedWith: shareableScope.get(savedSearch.scope),
						}
					})
				},
				displayEditableSavedSearchList() {
					const { userAccountId } = this.get('session')

					return this.get('displaySavedSearchList')
						.filter(savedSearch => savedSearch.createdByUser.id === userAccountId)
				},
				shareableScopeObject() {
					return Object.fromEntries(this.get('shareableScope'))
				},
				searchArgs() {
					const selectedFilters = this.get('selectedFilters')
					let args = {}

					const dateKey = selectedFilters.dateRangeType.created ? 'created' : 'performed'
					if (selectedFilters.dates.from) {
						args[`${dateKey}From`] = `${selectedFilters.dates.from}T00:00:00Z`
					}
					if (selectedFilters.dates.to) {
						args[`${dateKey}To`] = `${selectedFilters.dates.to}T23:59:59Z`
					}
					args.plantIds = selectedFilters.selectedPlants
					args.collectedByUserIds = selectedFilters.sampleByUser
					args.productName = selectedFilters.productName
					args.productIds = selectedFilters.productId ? [ selectedFilters.productId ] : undefined
					args.productProximityIds = selectedFilters.productProximityId ? [ selectedFilters.productProximityId ] : undefined
					args.processZoneIds = selectedFilters.processZoneId ? [ selectedFilters.processZoneId ] : undefined
					args.location = {
						productProximity: {
							name: selectedFilters.productProximityName || undefined,
						},
						processZone: {
							name: selectedFilters.processZoneName || undefined,
						},
					}
					args.statuses = selectedFilters.sampleStatuses
					args.locationName = selectedFilters.locationName
					args.tagNumber = selectedFilters.tagNumber
					args.scheduleName = selectedFilters.scheduleName
					if (selectedFilters.workOrder && Object.keys(reduceToTruthyValues(selectedFilters.workOrder)).length > 0) {
						args.workOrder = { type: {} }
						args.workOrder.id = selectedFilters?.workOrder?.id?.toString?.()
						args.workOrder.title = selectedFilters?.workOrder?.title

						args.workOrder.type.id = selectedFilters?.workOrder?.type || undefined

						if (selectedFilters?.workOrder?.productBatch) {
							args.workOrder.productBatch = {
								name: selectedFilters?.workOrder?.productBatch,
							}
						}
						args.workOrder = reduceToTruthyValues(args.workOrder)
					}

					if (selectedFilters.analysisList.length) {
						const optionValueTypeMutations = new Map([
							[ 'DATETIME', val => val.replace("T", " ") ],
						])

						const getResultFilter = ({ valueFilter, valueFilterFrom, valueFilterTo, valueType }) => {
							let result = {
								eq: valueFilter ? `${valueFilter}` : undefined,
								gte: (!valueFilter && valueFilterFrom) ? `${valueFilterFrom}` : undefined,
								lte: (!valueFilter && valueFilterTo) ? `${valueFilterTo}` : undefined,
							}
							result = reduceToTruthyValues(result)

							if (Object.keys(result).length > 0) {
								for (const property in result) {
									result[property] = optionValueTypeMutations.get(valueType) ? optionValueTypeMutations.get(valueType)?.(result[property]) : result[property]
								}
								return result
							} else {
								return undefined
							}
						}

						args.analyses = selectedFilters.analysisList.map(({ id, options, resultStatus }) => {
							return {
								id,
								options: options
									.filter(option => option.resultStatus || option.valueFilter || option.valueFilterFrom || option.valueFilterTo)
									.map(option => {
										return {
											id: option.id,
											resultStatuses: option.resultStatus ? option.resultStatus : undefined,
											lot: option.lotNumber ? option.lotNumber.toString() : undefined,
											result: getResultFilter(option),
										}
									}),
								resultStatuses: resultStatus || undefined,
								matchAllOptionFilters: stringToBoolean(selectedFilters.matchAllOptionFilters),
							}
						})
					}

					args.samplingComments = selectedFilters.samplingComments
					args.testingComments = selectedFilters.testingComments

					return reduceToTruthyValues(args)
				},
				childStates() {
					const isAtChildState = this.get('isAtChildState')

					return [
						{
							stateName: 'app.sampling-history.results',
							title: i18next.t('common:results', 'Results'),
							stateOptions: { inherit: true },
							disabled: !isAtChildState,
						},
						{
							stateName: 'app.sampling-history.graph',
							stateParameters: { url: this.get('graphUrl') },
							title: i18next.t('common:graph', 'Graph'),
							stateOptions: { inherit: true },

						},
					]
				},
				locationsByPlant() {
					return groupByPlant(this.get('locationList'), this.get('plantMap'), 'locations')
				},
				proximitiesByPlant() {
					return groupByPlant(this.get('proximityList'), this.get('plantMap'), 'proximities')
				},
				processZonesByPlant() {
					return groupByPlant(this.get('processZoneList'), this.get('plantMap'), 'processZones')
				},
				displayModalAnalysisList() {
					const {
						inactiveAnalyses: showInactiveAnalyses,
						productionDocuments: showProductionDocuments,
						testingDocuments: showTestingDocuments,
					} = this.get('addAnalysesModal.filterSettings')
					let analysisList = this.get('addAnalysesModal.analysisList')

					if (!showInactiveAnalyses || !showProductionDocuments || !showTestingDocuments) {
						analysisList = analysisList.filter(analysis => {
							return (!showInactiveAnalyses && analysis.active)
								&& ((showProductionDocuments && analysis.analysisType === 'RECIPE')
								|| (showTestingDocuments && analysis.analysisType === 'TESTING'))
						})
					}

					return analysisList.map(analysis =>{
						return {
							...analysis,
							options: analysis.options.filter(option => option.active),
							allOptionsSelected: analysis.options.every(option => option.selected),
							anyOptionsSeleced: analysis.options.some(option => option.selected),
						}
					})
				},
				valueAcceptabilityList() {
					return Object.fromEntries(this.get('valueAcceptabilityMap'))
				},
				lastLoadedSavedSearch() {
					const lastLoadedSavedSearchId = this.get('lastLoadedSavedSearchId')
					if (lastLoadedSavedSearchId) {
						return this.get('savedSearchList').find(savedSearch => savedSearch.id === lastLoadedSavedSearchId)
					}
				},
			},
			outputSavedSearch() {
				console.log(searchFilters.toSavedSearch(this.get('selectedFilters')))
			},
			async createSavedSearch() {
				const savedSearchJson = searchFilters.toSavedSearch(this.get('selectedFilters'))
				const generatedDescription = await this.getGeneratedDescription(savedSearchJson)

				await this.set({
					'savedSearchModal': klona({
						...defaultSavedSearchModalState,
						show: true,
						title: i18next.t('samplingHistory.savedSearches.modal.title.create', 'Create New Saved Search'),
						context: 'ADD',
						data: {
							...defaultSavedSearchModalState.data,
							search: searchFilters.toSavedSearch(this.get('selectedFilters')),
							description: generatedDescription, //When adding a new saved search, we'll generate a description for them as a friendly default.
						},
					}),
				})

				this.find('#savedSearchNameInput')?.select?.()
			},
			async editSavedSearch(savedSearchId) {
				const savedSearchToEdit = this.get('savedSearchList').find(savedSearch => savedSearch.id === savedSearchId)

				await this.set({
					'savedSearchModal': klona({
						...defaultSavedSearchModalState,
						show: true,
						title: i18next.t('samplingHistory.savedSearches.modal.title.edit', 'Edit Saved Search'),
						context: 'EDIT',
						data: {
							...savedSearchToEdit,
						},
					}),
				})

				this.find('#savedSearchNameInput')?.select?.()
			},
			async replaceSavedSearch() {
				//If they've already loaded a saved search, default to that as the replacement
				//Otherwise we'll make them pick one from the dropdown
				const lastLoadedSavedSearch = this.get('lastLoadedSavedSearch')
				let replaceSavedSearchId
				let existingSavedSearch = {}
				if (lastLoadedSavedSearch) {
					existingSavedSearch = this.get('displayEditableSavedSearchList').find(savedSearch => savedSearch.id === lastLoadedSavedSearch.id)
					replaceSavedSearchId = existingSavedSearch?.id
				}

				await this.set({
					savedSearchModal: klona({
						...defaultSavedSearchModalState,
						show: true,
						replace: true,
						title: i18next.t('samplingHistory.savedSearches.modal.title.replace', 'Replace Existing Saved Search'),
						context: 'REPLACE',
						data: {
							...defaultSavedSearchModalState.data,
							...existingSavedSearch,
							search: searchFilters.toSavedSearch(this.get('selectedFilters')),
							id: replaceSavedSearchId || '',
						},
					}),
				})

				if (!replaceSavedSearchId) {
					this.find('#replaceSavedSearchSelect')?.focus?.()
				} else {
					this.find('#savedSearchNameInput')?.select?.()
				}
			},
			async replaceIdSelected(id) {
				const searchModal = this.get('savedSearchModal')
				const savedSearchToReplace = this.get('displayEditableSavedSearchList').find(savedSearch => savedSearch.id === id)

				return await this.set({
					'savedSearchModal.data': klona({
						...searchModal.data,
						...(savedSearchToReplace || defaultSavedSearchModalState.data),
						id,
					}),
				})
			},
			async confirmSaveSearch(event, savedSearchModal) {
				event?.preventDefault?.()

				const searchSave = {
					id: savedSearchModal.data.id,
					name: savedSearchModal.data.name,
					scope: savedSearchModal.data.scope,
					description: savedSearchModal.data.description,
					search: savedSearchModal.data.search,
				}

				try {
					if ([ 'EDIT', 'REPLACE' ].includes(savedSearchModal.context)) {
						const { updateSavedSampleSearch: savedSearch } = await mediator.call('apiFetch', `#graphql
							mutation UpdateSavedSampleSearch($input: UpdateSavedSampleSearchInput!) {
								updateSavedSampleSearch(input: $input) {
									${savedSampleSearchFields}
								}
							}
						`, { input: reduceToTruthyValues(searchSave) })

						this.upsert('savedSearchList', 'id', savedSearch)
						this.set({ 'lastLoadedSavedSearchId': savedSearch.id })
					} else if (savedSearchModal.context === 'ADD') {
						const { createSavedSampleSearch: savedSearch } = await mediator.call('apiFetch', `#graphql
							mutation CreateSavedSampleSearch($input: CreateSavedSampleSearchInput!) {
								createSavedSampleSearch(input: $input) {
									${savedSampleSearchFields}
								}
							}
						`, { input: { ...searchSave, id: undefined } })

						this.upsert('savedSearchList', 'id', savedSearch)
						this.set({ 'lastLoadedSavedSearchId': savedSearch.id })
					}

					this.set({ 'savedSearchModal.show': false })
				} catch (err) {
					this.set({ 'savedSearchModal.saveError': err?.message || 'An unknown error occured. Please contact Presage support.' })
					console.error(err)
				}
			},
			async getGeneratedDescription(searchState) {
				const { generateSampleSearchDescription } = await mediator.call('apiFetch', `#graphql
					query Query($searchState: SampleSearchStateInput!) {
						generateSampleSearchDescription(searchState: $searchState)
					}`, { searchState })

				return generateSampleSearchDescription //TODO: return the description
			},
			async useGeneratedDescription(savedSearch) {
				if (!savedSearch?.description.trim?.() || confirm(i18next.t('samplingHistory.savedSearches.useGeneratedDescriptionConfirmation', 'Are you sure you want to replace your existing search description with a generated one?'))) {
					let generatedDescription

					if (!savedSearch.search && savedSearch.id) {
						const { savedSampleSearch } = await mediator.call('apiFetch', `#graphql
							query SavedSampleSearch($savedSampleSearchId: ID!) {
								savedSampleSearch(id: $savedSampleSearchId) {
									generatedDescription
								}
							}
						`, { savedSampleSearchId: savedSearch.id })
						generatedDescription = savedSampleSearch.generatedDescription
					} else {
						generatedDescription = await this.getGeneratedDescription(savedSearch.search)
					}

					this.set({ 'savedSearchModal.data.description': generatedDescription })
				}
			},
			// eslint-disable-next-line require-await
			async deleteSavedSearch(savedSearch) {
				if (confirm(`"${savedSearch.name}"\n\n${i18next.t('samplingHistory.savedSearches.deleteConfirmation', 'Are you sure you want to delete this saved search?')}`)) {
					try {
						throw new Error('Not implemented')
						/* 						await mediator.call('apiFetch', `#graphql
							mutation DeleteSavedSampleSearch($deleteSavedSampleSearchId: Float!) {
								deleteSavedSampleSearch(id: $deleteSavedSampleSearchId)
							}
						`, { deleteSavedSampleSearchId: savedSearch.id })

						this.splice('savedSearchList', this.get('savedSearchList').findIndex(search => search.id === savedSearch.id), 1) */
					} catch (err) {
						console.error(err)
						alert(i18next.t('samplingHistory.savedSearches.deleteError', 'There was an error deleting the saved search'))
					}
				}
			},
			search() {
				const args = this.get('searchArgs')
				const { to: toDate, from: fromDate } = this.get('selectedFilters.dates')
				stateRouter.go('app.sampling-history.results', { filter: JSON.stringify(args), uuid: uuid(), pageNumber: 1, fromDate, toDate })
			},
			getAnalysisIndexById(analysisId) {
				return this.get('analysisList').findIndex(analysis => analysis.id === analysisId)
			},
			getAnalysisById(analysisId) {
				return this.get('analysisList').find(analysis => analysis.id === analysisId)
			},
			getSavedSearchById(searchId) {
				return this.get('savedSearchList').find(savedSearch => savedSearch.id === searchId).filters
			},
			getAnalysisOptionsById(analysisId) {
				return this.getAnalysisById(analysisId)?.options || []
			},
			async clearFilters() {
				if (confirm(i18next.t('samplingHistory.resetToDefaultFiltersConfirm', 'Are you sure you want to clear all search filters?'))) {
					await this.set({ selectedFilters: klona(this.get('defaultSelectedFilters')) })
					stateRouter.go('app.sampling-history')
				}
			},
			async loadLocationsForPlants(previousPlants = [], plants = []) {
				return await loadForPlant({
					previousPlants, plants, mediator,
					ractive: this,
					loadKey: 'loadingLocations',
					listKey: 'locationList',
					apiQuery: locationsQuery,
					apiOptions: { filter: { activeOnly: true }, pagination: { pageSize: 0, pageNumber: 1 } },
					dataKeypath: 'locations.data',
				})
			},
			async loadProximitiesForPlants(previousPlants = [], plants = []) {
				return await loadForPlant({
					previousPlants, plants, mediator,
					ractive: this,
					loadKey: 'loadingProximities',
					listKey: 'proximityList',
					apiQuery: `#graphql
						query ($filter: ProductProximityFilter, $pagination: PaginatedInput) {
							productProximities(filter:$filter, pagination: $pagination) {
								data {
									id
									plantId
									name
								}
							}
						}`,
					apiOptions: gqlPagninationAllPages,
					dataKeypath: 'productProximities.data',
				})
			},
			async loadProcessZonesForPlants(previousPlants = [], plants = []) {
				return await loadForPlant({
					previousPlants, plants, mediator,
					ractive: this,
					loadKey: 'loadingProcessZones',
					listKey: 'processZoneList',
					apiQuery: `#graphql
						query ($filter: ProcessZoneFilter, $pagination: PaginatedInput) {
							processZones(filter:$filter, pagination: $pagination) {
								data {
									id
									plantId
									name
								}
							}
						}`,
					apiOptions: gqlPagninationAllPages,
					dataKeypath: 'processZones.data',
				})
			},
			async loadProductsForPlants(previousPlants = [], plants = []) {
				return await loadForPlant({
					previousPlants, plants, mediator,
					ractive: this,
					loadKey: 'loadingProducts',
					listKey: 'productList',
					forceRequery: true,
					apiQuery: `#graphql
						query ($filter: ProductFilter, $pagination: PaginatedInput) {
 							products(filter: $filter, pagination: $pagination) {
								data {
									id
									name
									parentProductId
									inUseAtPlants {
										id
									}
								}
							}
						}`,
					apiOptions: gqlPagninationAllPages,
					dataKeypath: 'products.data',
				})
			},
			//TODO when a plant filter is added, this should be updated to use the plant filter
			async loadAnalysesForPlants(previousPlants = [], plants = []) {
				const analyses = await loadForPlant({
					previousPlants, plants, mediator,
					ractive: this,
					forceRequery: true,
					loadKey: 'loadingAnalyses',
					listKey: 'analysisList',
					apiQuery: analysesQuery.query,
					apiOptions: analysesQuery.variables,
					dataKeypath: 'analyses.data',
					listMutationFunction: analyses => analyses.map(filterAnalysisOptionsToActiveOnly),
				})

				return analyses
			},
			async loadWorkOrderTypesForPlants(previousPlants = [], plants = []) {
				const workOrderTypes = await loadForPlant({
					previousPlants, plants, mediator,
					ractive: this,
					forceRequery: true,
					loadKey: 'loadingWorkOrderTypes',
					listKey: 'workOrderTypeList',
					apiQuery: workOrderTypesQuery.query,
					apiOptions: workOrderTypesQuery.variables,
					dataKeypath: 'workOrderTypes.data',
				})

				return workOrderTypes
			},
			async loadProductBatchesForPlants(previousPlants = [], plants = []) {
				const productBatches = await loadForPlant({
					previousPlants, plants, mediator,
					ractive: this,
					forceRequery: true,
					loadKey: 'loadingProductBatches',
					listKey: 'productBatchList',
					apiQuery: productBatchesQuery.query,
					apiOptions: productBatchesQuery.variables,
					dataKeypath: 'productBatches.data',
				})

				return productBatches
			},
			modalToggleAllOptionsForAnalysis(toggledAnalysis) {
				const analysisList = this.get('addAnalysesModal.analysisList')
				const analysisIndex = analysisList.findIndex(analysis => analysis.id === toggledAnalysis.id)
				const currentOptionsSelection = analysisList[analysisIndex].options
				const allOptionsAreSelected = currentOptionsSelection.every(option => option.selected)

				const mutatedOptionsSelection = currentOptionsSelection.map(option => {
					return {
						...option,
						selected: !allOptionsAreSelected,
					}
				})
				this.set(`addAnalysesModal.analysisList[${analysisIndex}].options`, mutatedOptionsSelection)
			},
			modalSelectAnalysis(analysis) {
				let mutatedAnalysis = klona(analysis)
				const newSelectionState = !mutatedAnalysis.selected

				mutatedAnalysis.selected = newSelectionState
				mutatedAnalysis.options = mutatedAnalysis.options.map(option => {
					return { ...option, selected: newSelectionState }
				})
				const index = this.get('addAnalysesModal.analysisList').findIndex(modalAnalysis => modalAnalysis.id === analysis.id)
				this.splice(`addAnalysesModal.analysisList`, index, 1, mutatedAnalysis)
			},
			modalSelectAnalysisOption(analysisContext, toggledOption) {
				const analysisIndex = this.get('addAnalysesModal.analysisList').findIndex(analysis => analysis.id === analysisContext.id)
				const optionIndex = analysisContext.options.findIndex(option => option.id === toggledOption.id)

				this.splice(`addAnalysesModal.analysisList[${analysisIndex}].options`, optionIndex, 1, { ...toggledOption, selected: !toggledOption.selected })
			},
			modalSelectAnalysisResultStatus(analysisContext, context) {
				if (context?.event?.target) {
					const analysisIndex = this.get('addAnalysesModal.analysisList').findIndex(analysis => analysis.id === analysisContext.id)
					this.set(`addAnalysesModal.analysisList[${analysisIndex}]`, {
						...analysisContext,
						resultStatus: context?.event?.target?.value || undefined,
					})
				}
			},
			addAnalysesModalConfirm(addAnalysesModal) {
				const selectedAnalyses = addAnalysesModal.analysisList
					.reduce((acc, analysis) => {
						if (analysis.selected) {
							const options = analysis.options.filter(option => option.selected)
							return [ ...acc, { ...analysis, options }]
						} else {
							return acc
						}
					}, [])

				this.set({
					'selectedFilters.analysisList': klona(selectedAnalyses),
					'selectedFilters.matchAllOptionFilters': addAnalysesModal.matchAllOptionFilters,
					'showAddAnalysesModal': false,
				})
			},
			async loadSavedSearch(savedSearch) {
				const { savedSampleSearch } = await mediator.call('apiFetch', `#graphql
					query SavedSampleSearch($savedSampleSearchId: ID!) {
						savedSampleSearch(id: $savedSampleSearchId) {
							id
							name
							searchState
						}
					}
				`, { savedSampleSearchId: savedSearch.id })

				savedSearch = {
					...savedSampleSearch,
					search: JSON.parse(savedSampleSearch.searchState),
				}

				const ractive = this
				ractive.set({
					savedSearchCardExpanded: false,
					searchFiltersShown: true,
					lastLoadedSavedSearchId: savedSearch.id,
				})

				if (savedSearch.search.plantIds) {
					await loadListsForPlants(ractive.get('selectedFilters.previouslySelectedPlants'), savedSearch.search.plantIds, ractive)
				}

				const selectedFilters = reduceToTruthyValues(searchFilters.fromSavedSearch(savedSearch.search, ractive))

				ractive.set({ selectedFilters })

				return savedSearch
			},
			async runSavedSearch(savedSearch) {
				await this.loadSavedSearch(savedSearch)
				this.search()
			},
		},

		async resolve(data, parameters) {
			let session = getSession()

			const { fromDate, toDate } = parameters
			if (fromDate === undefined && toDate === undefined) {
				const defaultDates = dateTimeUtility.datesFromRange('Last 365 Days')

				return Promise.reject({
					redirectTo: {
						name: null,
						params: { ...parameters, fromDate: defaultDates.from, toDate: defaultDates.to },
					},
				})
			}

			const t0 = performance.now()
			const [
				{ session: { userRoles } },
				//{ plants: { data: allPlants } },
				{ userAccounts: { data: userAccountList } },
				{ analyses: { data: analyses } },
				{ workOrderTypes: { data: workOrderTypeList } },
				{ productBatches: { data: productBatchList } },
				{ userSavedSampleSearches: { data: savedSampleSearches } },
				{ schedules: { data: schedules } },
			] = await Promise.all([
				// For the currently logged in user, grab the list of plantIDs they have access to, and their current permission for searching, which could be None, Plant, or Global
				mediator.call('apiFetch', `#graphql
				query($permissionNames: [NonEmptyString!]) {
				  session {
					userRoles(permissionNames: $permissionNames) {
					  permissionName,
					  value,
					  authorizedPlants {
						id,
						code,
						name
					  }
					}
				  }
				}`, { permissionNames: [ 'SAMPLING_CAN_SEARCH_HISTORY', 'SAMPLING_CAN_MANAGE_SAVED_SEARCHES' ] }),
				//mediator.call('apiFetch', `{ plants { data { id, name, code }}}`),
				mediator.call('apiFetch',
					`#graphql
					query UserAccounts($pagination: PaginatedInput) {
						userAccounts(filter: { activeOnly: false }, pagination: $pagination) {
						  data {
							id
							name
							firstName
							lastName
							status
						  }
						}
					  }
					`, gqlPagninationAllPages),
				mediator.call('apiFetch', analysesQuery.query, analysesQuery.variables),
				mediator.call('apiFetch', workOrderTypesQuery.query, workOrderTypesQuery.variables),
				mediator.call('apiFetch', productBatchesQuery.query, productBatchesQuery.variables),
				mediator.call('apiFetch', `#graphql
					query UserSavedSampleSearches($pagination: PaginatedInput) {
						userSavedSampleSearches(pagination: $pagination) {
							data {
								${savedSampleSearchFields}
							}
						}
					}
			  	`, gqlPagninationAllPages),
				mediator.call('apiFetch', `#graphql
					query Schedules($pagination: PaginatedInput) {
						schedules(pagination: $pagination) {
							data {
								name
							}
						}
					}
				`, gqlPagninationAllPages),
			])
			const t1 = performance.now()
			console.log(`resolve function took ${t1 - t0} milliseconds.`)

			const roles = userRoles.reduce((acc, role) => {
				return { ...acc, [role.permissionName]: role }
			}, {})

			const canEditOthersSavedSearches = [ 'GLOBAL', 'PLANT' ].includes(roles.SAMPLING_CAN_MANAGE_SAVED_SEARCHES?.value)

			// If we didn't have access, this userRole or plant array could be missing
			const authorizedPlants = roles.SAMPLING_CAN_SEARCH_HISTORY?.authorizedPlants ?? []

			let plantMap = new Map()
			for (const plant of authorizedPlants) {
				const { id: plantId, ...rest } = plant
				plantMap.set(plantId, rest)
			}

			const selectedPlants = [ session.siteId ]

			const defaultSelectedFilters = {
				range: dateTimeUtility.rangeFromDates(fromDate, toDate) || 'Custom',
				dates: { from: fromDate, to: toDate },
				locationName: '',
				product: '',
				productId: null,
				productName: '',
				productProximityId: null,
				productProximityName: '',
				processZoneId: null,
				processZoneName: '',
				tagNumber: '',
				sampleByUser: '',
				samplingComments: '',
				testingComments: '',
				previouslySelectedPlants: selectedPlants,
				selectedPlants,
				analysisList: [],
				dateRangeType: {
					created: false,
					performed: true,
				},
				workOrder: {
					id: '',
					title: '',
					typeId: '',
					typeName: '',
					productBatch: '',
					internalNotes: '',
				},
				matchAllOptionFilters: false,
				sampleStatuses: [ 'CLOSED', 'OPEN', 'SAMPLED', 'CANCELLED' ],
			}

			//const savedSearchList = localStorage.getItem('savedSearchList')

			return {
				session,
				canEditOthersSavedSearches,
				transitionsEnabled: false,
				showAddAnalysesModal: false,
				savedSearchCardExpanded: false, //temporary. revert to false before commmit
				searchFiltersShown: true, //temporary. revert to true before commmit
				loadingLocations: false,
				loadingProximities: false,
				loadingProcessZones: false,
				loadingProducts: false,
				loadingWorkOrderTypes: false,
				loadingProductBatches: false,
				//savedSearchList: savedSearchList ? JSON.parse(savedSearchList) : [],
				savedSearchList: savedSampleSearches,
				//allPlants,
				scheduleList: Array.from(new Set(schedules.map(schedule => schedule.name))).map(schedule => {
					return { name: schedule }
				}), //get just the unique schedule names
				plantMap,
				plantList: authorizedPlants || [],
				proximityList: [],
				processZoneList: [],
				locationList: [],
				productList: [],
				analysisList: analyses.map(filterAnalysisOptionsToActiveOnly),
				performedByUserList: userAccountList,
				selectedFilters: klona(defaultSelectedFilters),
				defaultSelectedFilters,
				addAnalysesModal: {
					selectedValueAcceptability: '',
					filterSettings: {
						inactiveAnalyses: false,
						productionDocuments: true,
						testingDocuments: true,
					},
					analysisList: [],
					matchAllOptionFilters: false,
				},
				valueAcceptabilityMap,
				sortedAnalysisList: [],
				optionValueTypes,
				workOrderTypeList,
				productBatchList,
				isAtChildState: false,
				savedSearchModal: klona(defaultSavedSearchModalState),
				shareableScope,
				lastLoadedSavedSearchId: null,
			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext
			console.log(ractive.get('scheduleList'))

			loadListsForPlants([], ractive.get('selectedFilters.selectedPlants'), ractive)
				.catch(err => console.error(err))

			ractive.on('plantSelected', (context, selectedPlants) => {
				loadListsForPlants(ractive.get('selectedFilters.previouslySelectedPlants'), selectedPlants, ractive)
					.catch(err => console.error(err))
			})

			ractive.observe('selectedFilters.selectedPlants', (_plants, previousPlants) => {
				ractive.set({ 'selectedFilters.previouslySelectedPlants': previousPlants })
			})

			window.emitter.on('getAnalyses', cb => {
				const selectedFiltersAnalysisList = ractive.get('selectedFilters.analysisList')
				if (selectedFiltersAnalysisList.length) {
					cb(selectedFiltersAnalysisList)
				} else {
					cb(ractive.get('analysisList'))
				}
			})

			function getComputedSelectedAnalysisList(selectedAnalyses) {
				return ractive.get('analysisList').map(analysis => {
					const selectedAnalysisMatch = selectedAnalyses.find(selectedAnalysis => selectedAnalysis.id === analysis.id)
					if (selectedAnalysisMatch) {
						const options = analysis.options.map(option => {
							const optionMatch = selectedAnalysisMatch.options.find(selectedAnalysisOption => selectedAnalysisOption.id === option.id)
							return optionMatch || option
						})
						return {
							...selectedAnalysisMatch,
							selected: true,
							options,
						}
					} else {
						return analysis
					}
				})
			}

			ractive.on('addAnalysis', () => {
				ractive.set({
					showAddAnalysesModal: true,
					addAnalysesModal: {
						...ractive.get('addAnalysesModal'),
						matchAllOptionFilters: ractive.get('selectedFilters').matchAllOptionFilters,
						analysisList: klona(getComputedSelectedAnalysisList(ractive.get('selectedFilters.analysisList'))),
					},
				})
			})

			ractive.on('clearAnalysisSelections', () => {
				ractive.set({ 'addAnalysesModal.analysisList': getComputedSelectedAnalysisList([]) })
			})

			//ractive.observe('searchArgs', v => console.log('searchArgs', v))
			//ractive.observe('session', v => console.log('session', v))
			//ractive.observe('analysisList', v => console.log('analysisList', v))
			//ractive.observe('displayModalAnalysisList', v => console.log('displayModalAnalysisList', v))
			//ractive.observe('sortedAnalysisList', v => console.log('sortedAnalysisList', v))
			//ractive.observe('addAnalysesModal.analysisList', v => console.log('addAnalysesModal.analysisList', v))
			//ractive.observe('selectedFilters', v => console.log('UI STATE:', v))
			//ractive.observe('productList', v => console.log('PRODUCT LIST', v))
			//ractive.observe('displaySavedSearchList', v => console.log(v))
			//ractive.observe('savedSearchList', v => console.log('saved search list', v))

			stateRouter.on('stateChangeEnd', state => {
				ractive.set({ isAtChildState: state.name.indexOf('app.sampling-history.') > -1 })
			})

			ractive.observe('savedSearchCardExpanded', expanded => {
				const lastLoadedSavedSearchId = ractive.get('lastLoadedSavedSearchId')

				if (expanded && lastLoadedSavedSearchId) {
					ractive.find(`#savedSearchId${lastLoadedSavedSearchId}`)?.scrollIntoView({ behavior: 'smooth' })
				}
			}, { defer: true })
		},
	})
}
