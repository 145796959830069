import template from './user.ractive.html'
import apiFetch from 'utility/api-fetch'
import pProps from 'p-props'
import { klona } from 'klona'
import { v4 as uuid } from '@lukeed/uuid'
import { getSession } from 'stores/session'

//Ractive components
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'
import makeUserConfiguration from '@isoftdata/ractive-user-configuration'
import { stringToBoolean } from '@isoftdata/utility-string'
import camelCase from 'just-camel-case'

const defaultNewUserAccountState = Object.freeze({
	status: null,
	name: '',
	workEmail: '',
	recoveryEmail: null,
	firstName: '',
	lastName: '',
	fullName: '',
	lockNotes: '',
	apiToken: null,
	newPassword: null,
	confirmPassword: null,
	userLastPasswordResetDate: null,
	userSites: [], //Site is our generic name for plant/store
	authorizedSites: [],
	userPermissions: [],
	userRoles: [],
})

const absoluteDateTimeFormatter = date => new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)

export default function({ mediator, stateRouter, i18next }) {
	stateRouter.addState({
		name: 'app.configuration.user',
		route: 'user',
		querystringParameters: [ 'plantId', 'userAccountId', 'lastSavedTime' ],
		defaultParameters: {
			plantId: () => getSession().siteId,
			userAccountId: () => getSession().userAccountId,
		},
		template: {
			template,
			components: {
				itButton: makeButton(),
				itInput: makeInput({ twoway: true }),
				userConfiguration: makeUserConfiguration({
					defaultNewUserAccountState,
					translate: i18next.t,
					updateUserAccount: async(selectedUserAccount, sendPasswordRecoveryToken) => {
						await apiFetch(mediator, {
							query: mutations.updateUserAccount,
							variables: {
								input: {
									id: selectedUserAccount.id,
									firstName: selectedUserAccount.firstName,
									lastName: selectedUserAccount.lastName,
									name: selectedUserAccount.name,
									status: selectedUserAccount.status,
									userGroupIds: selectedUserAccount.userGroups.filter(group => group.isMember).map(group => group.id),
									authorizedPlantIds: selectedUserAccount.userSites.filter(plant => plant.isAuthorized).map(plant => plant.id),
									userPermissions: selectedUserAccount.userPermissions.map(permission => {
										return {
											permissionId: permission.id,
											permissionValue: permission.userPermissionLevel,
										}
									}),
									lockNotes: selectedUserAccount.lockNotes,
									workEmail: selectedUserAccount.workEmail,
									apiToken: selectedUserAccount?.apiToken ?? null,
									newPassword: selectedUserAccount?.newPassword ?? null,
									sendPasswordRecoveryToken,
								},
							},
						}, 'updateUserAccount')
					},
					createNewUserAccount: async(selectedUserAccount, hasPermissionToChangePassword) => {
						if (hasPermissionToChangePassword && !selectedUserAccount.newPassword) {
							throw new Error(i18next.t('configuration.user.newPasswordRequiredError', 'You must set a new password to create a new user account.'))
						}
						if (!selectedUserAccount.name) {
							throw new Error(i18next.t('configuration.user.usernameRequiredError', 'You must set a username to create a new user account.'))
						}
						const newUserAccount = await apiFetch(mediator, {
							query: mutations.newUserAccount,
							variables: {
								input: {
									firstName: selectedUserAccount.firstName,
									lastName: selectedUserAccount.lastName,
									name: selectedUserAccount.name,
									userGroupIds: selectedUserAccount.userGroups.filter(group => group.isMember).map(group => group.id),
									authorizedPlantIds: selectedUserAccount.userSites.filter(plant => plant.isAuthorized).map(plant => plant.id),
									userPermissions: selectedUserAccount.userPermissions.map(permission => {
										return {
											permissionId: permission.id,
											permissionValue: permission.userPermissionLevel,
										}
									}),
									lockNotes: selectedUserAccount.lockNotes,
									workEmail: selectedUserAccount.workEmail,
									apiToken: selectedUserAccount.apiToken ?? null,
									newPassword: selectedUserAccount.newPassword ?? null,
								},
							},
						}, 'createUserAccount')

						return newUserAccount
					},
					generateNewActivationPIN: async(userName, hasWorkEmail) => {
						const newActivationData = await apiFetch(mediator, {
							query: mutations.generateNewActivationPIN,
							variables: {
								inputs: {
									userName,
									hasWorkEmail,
								},
							},
						}, 'generateNewActivationPIN')

						return newActivationData
					},
					errorHandler: (errorHeading, errorMessage) => {
						mediator.call('showMessage', { heading: errorHeading, type: 'danger', time: false, message: errorMessage })
					},
					successHandler: (errorHeading, errorMessage) => {
						mediator.call('showMessage', { heading: errorHeading, type: 'success', time: 3, message: errorMessage })
					},
				}),
			},
			translate: i18next.t,
			generateNewAPIKey() {
				const existingToken = this.get('selectedUserAccount.apiToken')
				const wantsNewToken = existingToken && confirm(i18next.t('configuration.user.generateNewAPIKEYPromptMessage', 'This account has a valid API key, generating a new one will deactivate the previous one. Are you sure you want to continue?'))

				if (!existingToken || wantsNewToken) {
					this.set('selectedUserAccount.apiToken', uuid())
					this.findComponent('userConfiguration').set('haveUnsavedChanges', true)
				}
			},
			copyTextToClipboard() {
				const apiTokenInput = this.find('#apiToken')
				navigator.clipboard.writeText(apiTokenInput?.value)
				mediator.call('showMessage', { type: 'success', time: 3, message: 'Copied to clipboard successfully' })
			},
			deactivateAPIKey() {
				if (confirm(i18next.t('configuration.user.deactivateAPIKeyPromptMessage', 'Are you sure to deactivate the current API key? (Future access attempts with this key will fail)'))) {
					this.set('selectedUserAccount.apiToken', null)
					this.findComponent('userConfiguration').set('haveUnsavedChanges', true)
				}
			},
		},
		async resolve(_data, { plantId, userAccountId }) {
			userAccountId = parseInt(userAccountId, 10) || null
			plantId = parseInt(plantId, 10) || null

			let { plants, permissions, groups } = await pProps({
				plants: apiFetch(mediator, {
					query: queries.plants,
					variables: {
						pagination: {
							pageSize: 0,
						},
					},
				}, 'plants.data'),
				permissions: apiFetch(mediator, {
					query: queries.permissions,
					variables: {
						pagination: {
							pageSize: 0,
						},
					},
				}, 'permissions.data'),
				groups: apiFetch(mediator, {
					query: queries.groups,
					variables: {
						pagination: {
							pageSize: 0,
						},
					},
				}, 'groups.data'),
			})
			// Translate!
			permissions = permissions.map(permission => ({
				...permission,
				category: i18next.t(`permissions:categories.${camelCase(permission.category)}`, permission.category),
				description: i18next.t(`permissions:${camelCase(permission.codeName)}.description`, permission.description),
				displayName: i18next.t(`permissions:${camelCase(permission.codeName)}.displayName`, permission.displayName),
			}))

			const session = getSession()
			let userAccounts = await apiFetch(mediator, {
				query: queries.userAccounts,
				variables: {
					filter: plantId ? {
						authorizedPlants: {
							ids: [ plantId ],
						},
					} : {},
					pagination: {
						pageSize: 0,
					},
				},
			}, 'userAccounts.data')

			// There will be cases where the userAccountId is not in the list of userAccounts when we change the plant filter
			// If that happens, we need to reset the userAccountId to null
			const selectedUserInSite = userAccountId && userAccounts.some(userAccount => userAccount.id === userAccountId)

			userAccounts = userAccounts.map(userAccount => {
				return {
					...userAccount,
					name: session.userAccountId === userAccount.id ? `${userAccount.name} (You)` : userAccount.name,
					lastAccess: userAccount.accessLog?.data?.[0]?.lastAccess ? absoluteDateTimeFormatter(new Date(userAccount.accessLog.data[0].lastAccess)) : '',
				}
			})

			const manageUserPermissionLevel = session.user.permissions.CONFIGURATION_USER_MANAGER
			const manageAPIKeyPermissionLevel = session.user.permissions.CONFIGURATION_CAN_MANAGE_API_KEYS
			let hasPermissionToChangePassword = false

			if (manageUserPermissionLevel !== 'NONE') {
				const changePasswordPermission = await apiFetch(mediator, {
					query: `#graphql
						query GetGlobalSetting($lookup: SettingLookup!) {
							getGlobalSetting(lookup: $lookup) {
								value
							}
						}
					`,
					variables: {
						lookup: {
							name: 'Administrators can set other users\' passwords',
							settingType: 'HIDDEN',
							category: 'Security',
							defaultValue: 'False',
						},
					},
				}, 'getGlobalSetting')

				hasPermissionToChangePassword = stringToBoolean(changePasswordPermission.value)
			}

			if (manageUserPermissionLevel === 'PLANT') {
				// if the permission is "PLANT" level,
				// we need to ony show the plants that the user has access to
				// we also need to only show the plants in the user's access plants/sites
				// only show user accounts that the other's sites is a subset of the session user's access sites
				const sessionUserPlantAccessIds = session.authorizedPlantIDs
				plants = plants.filter(plant => sessionUserPlantAccessIds.includes(plant.id))
				userAccounts = userAccounts.filter(userAccount => {
					const userAccountPlantIds = userAccount.authorizedPlants.map(plant => plant.id)
					return new Set([ ...sessionUserPlantAccessIds, ...userAccountPlantIds ]).size === sessionUserPlantAccessIds.length
				})
			}

			let selectedUserAccount = klona(defaultNewUserAccountState)

			if (userAccountId && selectedUserInSite) {
				selectedUserAccount = await apiFetch(mediator, {
					query: queries.selectedUserAccount,
					variables: {
						userAccountId,
					},
				}, 'userAccount')

				const userPlantIdSet = new Set(selectedUserAccount.authorizedPlants.map(plant => plant.id))
				const userGroupIdSet = new Set(selectedUserAccount.userGroups.map(group => group.id))

				const permissionMap = new Map()
				selectedUserAccount.userRoles.map(role => {
					permissionMap.set(role.permissionId, { grantedPermission: role.value })
				})
				selectedUserAccount.userPermissions.map(userPermission => {
					const permission = permissionMap.get(userPermission.permissionId)
					const permissionValue = userPermission.value
					if (permission) {
						permission.userPermission = permissionValue
					} else {
						permissionMap.set(userPermission.permissionId, { userPermission: permissionValue })
					}
				})
				selectedUserAccount.highestGroupPermissions.map(groupPermission => {
					const permission = permissionMap.get(groupPermission.permissionId)
					const permissionValue = groupPermission.value // move toTitleCase to closer to the display layer
					if (permission) {
						permission.groupPermission = permissionValue
					} else {
						permissionMap.set(groupPermission.permissionId, { groupPermission: permissionValue })
					}
				})

				selectedUserAccount = {
					...selectedUserAccount,
					userLastPasswordResetDate: selectedUserAccount.userLastPasswordResetDate ? absoluteDateTimeFormatter(new Date(selectedUserAccount.userLastPasswordResetDate)) : null,
					activationPIN: selectedUserAccount.userActivationData?.activationPIN ? `${selectedUserAccount.userActivationData?.activationPIN.slice(0, 3)}-${selectedUserAccount.userActivationData?.activationPIN.slice(3)}` : null,
					activationPINExpiration: selectedUserAccount.userActivationData?.activationPINExpiration ? absoluteDateTimeFormatter(new Date(selectedUserAccount.userActivationData.activationPINExpiration)) : null,
					userSites: plants.map(plant => {
						return {
							...plant,
							isAuthorized: userPlantIdSet.has(plant.id),
						}
					}),
					userPermissions: permissions.map(permission => {
						return {
							...permission,
							computedPermissionLevel: permissionMap.get(permission.id)?.grantedPermission.toUpperCase() ?? 'NONE',
							userPermissionLevel: permissionMap.get(permission.id)?.userPermission ?? 'NONE',
							groupPermissionLevel: permissionMap.get(permission.id)?.groupPermission ?? 'NONE',
						}
					}),
					userGroups: groups.map(group => {
						return {
							...group,
							isMember: userGroupIdSet.has(group.id),
						}
					}),
				}
			}

			return {
				plants,
				permissions,
				userAccounts,
				selectedPlantId: plantId,
				selectedUserAccount,
				originalUserAccountData: klona(selectedUserAccount),
				selectedUserAccountId: selectedUserInSite ? userAccountId : null,
				hasPermissionToChangePassword,
				manageAPIKeyPermissionLevel,
				haveUnsavedChanges: false,
				showEditButton: false,
				showApiKeyManagement: false,
			}
		},
		activate({ domApi: ractive }) {
			// We don't need to use the master save button, so hide it
			ractive.get('saveResetProps')?.set(null)

			ractive.observe('selectedPlantId selectedUserAccountId', () => {
				const plantId = ractive.get('selectedPlantId')
				const userAccountId = ractive.get('selectedUserAccountId')
				stateRouter.go(null, { plantId, userAccountId }, { inherit: true })
			}, { init: false })

			ractive.on('userAccountUpdated', (context, userAccountId) => {
				stateRouter.go(null, { userAccountId, lastSavedTime: Date.now() }, { inherit: true })
			})
		},
	})
}

const queryData = {
	userAccountData: `#graphql
        id
		name
		firstName
		lastName
		fullName
		status
		workEmail
		recoveryEmail
		lockNotes
		apiToken
		userLastPasswordResetDate
		userActivationData {
			activationPIN
			activationPINExpiration
		}
		userRoles {
			permissionId
			permissionName
			userAccountId
			value
		}
		userGroups {
			id
			name
			workerGroup
		}
		authorizedPlants {
			id
			name
			code
		}
		highestGroupPermissions {
			id
			groupId
			permissionId
			value
			group {
				id
				name
			}
		}
		userPermissions {
			id
			permissionId
			value
		}
    `,
}

const queries = {
	plants: `#graphql
	query Plants($pagination: PaginatedInput) {
		plants(pagination: $pagination) {
			data {
				id
				name
				code
			}
		}
	}
    `,
	permissions: `#graphql
        query Permissions($pagination: PaginatedInput) {
            permissions(pagination: $pagination) {
                data {
                    id
                    displayName
                    description
                    codeName
                    category
                }
            }
        }
    `,
	groups: `#graphql
        query Groups($pagination: PaginatedInput) {
            groups(pagination: $pagination) {
                data {
                    id
                    name
                    workerGroup
					groupPermissions {
						permissionId
						value
					}
                }
            }
        }
    `,
	selectedUserAccount: `#graphql
        query UserAccount($userAccountId: PositiveInt!) {
            userAccount(id: $userAccountId) {
                ${queryData.userAccountData}
            }
        }
    `,
	userAccounts: `#graphql
        query UserAccounts($filter: UserFilter, $pagination: PaginatedInput) {
            userAccounts(filter: $filter, pagination: $pagination) {
                data {
                    id
                    name
                    status
					authorizedPlants {
						id
					}
                    accessLog {
                        data {
                            lastAccess
                        }
                    }
                }
            }
        }
    `,
}

const mutations = {
	newUserAccount: `#graphql
        mutation CreateUserAccount($input: NewUserAccount!) {
            createUserAccount(input: $input) {
                ${queryData.userAccountData}
            }
        }
    `,
	updateUserAccount: `#graphql
        mutation UpdateUserAccount($input: UpdateUserAccount!) {
            updateUserAccount(input: $input) {
                ${queryData.userAccountData}
            }
        }
    `,
	generateNewActivationPIN: `#graphql
		mutation GenerateNewActivationPIN($inputs: GenerateNewActivationPIN!) {
			generateNewActivationPIN(inputs: $inputs) {
				activationPIN
				activationPINExpiration
			}
		}
	`,
}
