import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/AppData'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class AppDataStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "AppDataStore",
			variables: false,
		})
	}
}

export async function load_AppData(params) {
	await initClient()

	const store = new AppDataStore()

	await store.fetch(params)

	return {
		AppData: store,
	}
}
