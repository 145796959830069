<script lang="ts">
	import type { SvelteAsr } from 'client/types/common'
	import { ReportViewer } from '@isoftdata/svelte-report-viewer'

	export let asr: SvelteAsr

	let selectedTab: 'Reports' | 'History' | 'Subscriptions' = getSelectedTab(asr.getActiveState().name)
	$: selectedTab = getSelectedTab(asr.getActiveState().name)

	function getSelectedTab(stateName: string) {
		if (stateName.includes('subscriptions')) {
			return 'Subscriptions'
		} else if (stateName.includes('history')) {
			return 'History'
		} else {
			return 'Reports'
		}
	}
</script>

<ReportViewer
	reportsHref={asr.makePath('app.report-viewer.reports')}
	historyHref={asr.makePath('app.report-viewer.history')}
	subscriptionsHref={asr.makePath('app.report-viewer.report-subscriptions')}
	bind:selectedTab
>
	<uiView></uiView>
</ReportViewer>
