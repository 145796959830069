import type { AppContext, ResolveParameters } from 'types/common'
import component from './ReportSubscriptions.svelte'
import { graphql } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import { stringToBoolean } from '@isoftdata/utility-string'
import { getSession } from 'stores/session'
import { translateReport } from 'utility/report-viewer-helper'

export default function ({ mediator, stateRouter, i18next }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.report-subscriptions',
		route: 'report-subscriptions',
		defaultParameters: {
			pageNumber: '1',
			showOnlyMine: 'true',
		},
		querystringParameters: ['pageNumber', 'showOnlyMine'],
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, parameters: ResolveParameters<{ pageNumber: number }>) {
			const session = getSession()
			const showOnlyMine = stringToBoolean(parameters.showOnlyMine)
			const [reportSubscriptionsResult, initialPageLoadDataResult] = await Promise.all([
				reportSubscriptionsQuery.fetch({
					variables: {
						reportSubscriptionFilter: {
							createdByUserId: showOnlyMine ? [session.userAccountId] : null,
						},
						pagination: {
							pageSize: 10,
							pageNumber: parseInt(parameters.pageNumber, 10) || 1,
						},
					},
				}),
				initialPageLoadDataQuery.fetch({
					variables: {
						userAccountsFilter: {
							activeOnly: true,
						},
						reportsFilter: {
							userVisible: true,
						},
					},
					policy: 'CacheOrNetwork',
				}),
			])

			const { data } = reportSubscriptionsResult
			const { data: initialData } = initialPageLoadDataResult

			if (!data) {
				throw showErrorAndRedirect(mediator, 'Failed to load Report Subscriptions', 'No data returned from server', { name: 'app' })
			}

			if (!initialData) {
				throw showErrorAndRedirect(mediator, 'Failed to load Initial Page Data', 'No data returned from server', { name: 'app' })
			}

			// Loading the parameters for each report subscription seems to be slow down the page load by a lot.
			// So I changed the query to not include the parameters for now, and only load the parameters on its own query when a report subscription is selected
			const reportSubscriptionList = data.reportSubscriptions.data.map(subscription => {
				const report = subscription.report ? translateReport(subscription.report, i18next) : null

				return {
					...subscription,
					report,
					createdByUserName: subscription.createdByUser.fullName,
					reportName: report?.name ?? null,
					reportType: report?.type ?? null,
					reportCategory: report?.category ?? null,
					reportDescription: report?.description ?? null,
					sendTo: subscription.destination?.split(/[;,]/) ?? [],
					parameters: [],
				}
			})

			return {
				reportSubscriptionList,
				showOnlyMine,
				reportList: initialData.reports.data.map(report => translateReport(report, i18next)),
				userAccounts: initialData.userAccounts.data,
				groups: initialData.groups.data,
				plants: initialData.plants.data,
				printers: initialData.reportQueuePrinters.data,
				perPageCount: data.reportSubscriptions.info.pageSize,
				totalItemsCount: data.reportSubscriptions.info.totalItems,
				currentPageNumber: data.reportSubscriptions.info.pageNumber,
			}
		},
	})
}

const reportSubscriptionsQuery = graphql(`
	query ReportSubscriptions($reportSubscriptionFilter: ReportSubscriptionFilter, $pagination: PaginatedInput) {
		reportSubscriptions(filter: $reportSubscriptionFilter, pagination: $pagination) {
			data {
				id
				createdByUserId
				createdByUser {
					fullName
				}
				sourceType
				destinationType
				destination
				title
				emailBody
				emailFrom
				attachmentName
				reportId
				nextInstance
				schedulePeriodType
				schedulePeriod
				active
				dateCreated
				comments
				sourceQuery
				parameterDateRange
				report {
					id
					name
					type
					category
					description
				}
			}
			info {
				totalItems
				totalPages
				pageSize
				pageNumber
			}
		}
	}
`)

const initialPageLoadDataQuery = graphql(`
	query InitialPageLoadData($userAccountsFilter: UserFilter, $reportsFilter: ReportsFilter) {
		reports(filter: $reportsFilter) {
			data {
				id
				category
				description
				name
				numberOfParameters
				averageExecutionTime
				type
				userVisible
			}
		}
		groups {
			data {
				id
				name
			}
		}
		plants {
			data {
				id
				code
				name
			}
		}
		userAccounts(filter: $userAccountsFilter) {
			data {
				id
				name
				workEmail
				firstName
				lastName
				authorizedSites: authorizedPlants {
					id
				}
				userGroups {
					id
				}
			}
		}
		reportQueuePrinters {
			data {
				displayName
				name
			}
		}
	}
`)
